import { acceptHMRUpdate, defineStore } from 'pinia'
import { redirectTo, supabase, supabaseDomain } from '@/plugins/supabase'

// eslint-disable-next-line import/prefer-default-export
export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: null,
    isLoggedIn: null,
    lastsRequest: null,
  }),
  getters: {
    GET_USER(state) {
      return state.user ? state.user : null
    },
  },
  actions: {
    async uploadAdminAvatar(img) {
      const { data, error } = await supabase.storage.from('admins').upload(`${this.user?.id}/${img.name}`, img,
        {
          cacheControl: '3600',
          upsert: true,
        })
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async getAdminImgUrl(img) {
      const { data } = await supabase.storage.from('admins').getPublicUrl(`${this.user?.id}/${img.name}`)

      return data
    },

    async getUser(user) {
      const permissions = await this.getPermissions()
      let query
      if (permissions?.find(p => p.resource === 'workspaces')) {
        query = supabase.from('admins').select('*, members!members_admin_id_fkey(*, organization:organizations(id, projects(id), workspaces(id)), role:roles(id, permissions(resource, type, parent)))').eq('id', user.id).single()
      } else {
        query = supabase.from('admins').select('*, members!members_admin_id_fkey(*, organization:organizations(id, projects(id)), role:roles(id, permissions(resource, type, parent)))').eq('id', user.id).single()
      }

      let { data } = await query

      if (!data) data = user
      if (user.identities) data.identities = user.identities
      if (user.providers) data.providers = user.providers
      if (user.app_metadata) data.app_metadata = user.app_metadata
      if (user.user_metadata) data.user_metadata = user.user_metadata
      if (this.isLoggedIn === null) this.isLoggedIn = await this.isMFA()
      data.permissions = permissions

      return data
    },

    async getAdmin(token) {
      if (localStorage.getItem(supabaseDomain)) {
        const supabaseToken = JSON.parse(localStorage.getItem(supabaseDomain))
        const accessToken = supabaseToken?.access_token
        const { data } = await supabase.auth.getUser(accessToken)
        if (data.user) {
          return this.getUser(data.user)
        }
      } else if (token) {
        const { data } = await supabase.auth.getUser(token)
        if (data.user) {
          return this.getUser(data.user)
        }
      }

      return null
    },

    async refreshAdmin(token) {
      if ((this.lastsRequest && new Date().getTime() - this.lastsRequest.getTime() < 10000)) {
        return this.user
      }
      this.lastsRequest = new Date()

      return this.getAdmin(token)
    },

    async getPermissions() {
      const { data, error } = await supabase.from('permissions_grouped').select('resource, permissions').eq('role', 'default')
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    listenUser() {
      if (supabaseDomain.indexOf('localhost') < 0) {
        supabase
          .channel('listenUser')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'admins' }, payload => {
            this.getUser(payload.new)
          })
          .subscribe()
      }

      return null
    },

    async setUser(user) {
      const old = await this.getUser(user)
      const { error } = await supabase.from('admins').upsert({
        id: user.id,
        fullname: user.fullname,
        email: user.email,
        phone: user.phone,
        avatar: user.avatar,
      })

      if (error) {
        return Promise.reject(error)
      }

      if (old.email !== user.email) {
        supabase.auth.updateUser({ email: user.email })

        return { user: this.getUser(user), emailChanged: true }
      }

      return { user: this.getUser(user), emailChanged: false }
    },

    async login(email, password, captchaToken) {
      this.isLoggedIn = null
      this.user = null
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
        options: { captchaToken },
      })
      if (error) {
        return Promise.reject(error)
      }

      const userData = await this.getUser(data.user)

      if (!userData) {
        return Promise.reject(new Error('User does not exist'))
      }

      this.user = userData

      return userData
    },

    async register(email, password, captchaToken) {
      this.isLoggedIn = null
      this.user = null

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: { captchaToken },
      })

      if (error) {
        return Promise.reject(error)
      }
      const userData = await this.getUser(data.user)

      if (!userData) {
        return Promise.reject(new Error('User does not exist'))
      }

      this.user = userData

      return { user: userData, session: data.session }
    },

    async signInWithAuth(provider) {
      this.isLoggedIn = null
      this.user = null

      const { user, error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo,
        },
      })
      if (error) {
        return Promise.reject(error)
      }

      return user
    },

    async resetPassword(email) {
      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        options: {
          redirectTo,
        },
      })
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async updatePassword(newpassword) {
      const { data, error } = await supabase.auth.updateUser({ password: newpassword })
      if (error) {
        return Promise.reject(error)
      }

      return this.getUser(data.user)
    },

    async signOut() {
      const { error } = await supabase.auth.signOut()
      this.user = null
      this.isLoggedIn = null
      localStorage.removeItem(supabaseDomain)

      if (error) {
        return Promise.reject(error)
      }

      return true
    },
    removeChannel(subscription) {
      if (!subscription) return
      supabase.removeChannel(subscription)
    },
    getChannels() {
      return supabase.getChannels()
    },
    async getMFAEnroll() {
      const { data, error } = await supabase.auth.mfa.enroll({
        factorType: 'totp',
      })

      if (error) {
        return Promise.reject(error)
      }

      return data
    },
    async getMFAUnEnroll(factorId) {
      const { data, error } = await supabase.auth.mfa.unenroll({
        factorId,
      })

      if (error) {
        return Promise.reject(error)
      }

      return data
    },
    async getMFAChallenge(factorId, verifyCode) {
      const challenge = await supabase.auth.mfa.challenge({ factorId })
      if (challenge.error) {
        return Promise.reject(challenge.error)
      }

      const challengeId = challenge.data.id

      const verify = await supabase.auth.mfa.verify({
        factorId,
        challengeId,
        code: verifyCode,
      })
      if (verify.error) {
        return Promise.reject(verify.error)
      }

      this.isLoggedIn = await this.isMFA()

      return verify
    },
    async isMFA() {
      const { data, error } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel()
      if (error) {
        throw error
      }

      return data.nextLevel === data.currentLevel
    },
    async getAuthenticatorAssuranceLevel() {
      const { data, error } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel()
      if (error) {
        throw error
      }

      return data
    },
    async getMFAFactors() {
      const factors = await supabase.auth.mfa.listFactors()
      if (factors.error) {
        throw factors.error
      }

      return factors.data.all
    },
    async getMFAAuth(verifyCode) {
      const factors = await supabase.auth.mfa.listFactors()
      if (factors.error) {
        throw factors.error
      }
      const totpFactor = factors.data.totp[0]

      if (!totpFactor) {
        return Promise.reject(new Error('No TOTP factors found!'))
      }
      const factorId = totpFactor.id

      return this.getMFAChallenge(factorId, verifyCode)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
