<template>
  <div id="misc">
    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary">
        {{ $t('NotAuthorized') }}
      </h2>
      <p class="text-sm">
        {{ $t('NotAuthorizedDetails') }}
      </p>

      <div class="d-flex justify-center">
        <v-icon size="150">
          mdi-lock
        </v-icon>
      </div>

      <v-btn
        color="primary"
        to="/"
        class="my-6"
      >
        {{ $t('BackToHome') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { appConfig } from '@/plugins/micleoConfig'

export default {
  metaInfo: {
    title: 'Not Authorized',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.name },
    ],
  },
  setup() {
    return {
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
