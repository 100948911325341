<template>
  <v-list-item
    v-if="!source.isFiltred"
    class="cursor-pointer"
    @click="toggleSelect"
  >
    <v-list-item-action>
      <v-simple-checkbox
        v-model="source.isSelected"
        color="primary"
      ></v-simple-checkbox>
    </v-list-item-action>
    <v-list-item-title>
      <table-cell-item :source="source"
                       :header="header"
                       :item="item"
      ></table-cell-item>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { useVModel } from '@vueuse/core'
import TableCellItem from '@/views/table/TableCellItem.vue'

export default {
  name: 'TableFilterItem',
  components: {
    TableCellItem,
  },
  props: {
    source: {
      type: Object,
      default() {
        return {}
      },
    },
    header: {
      type: Object,
      default() {
        return {}
      },
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  setup(props, { emit }) {
    const source = useVModel(props, 'source', emit)
    const toggleSelect = () => {
      source.value.isSelected = !source.value.isSelected
      emit('on-change-filter')
    }

    return {
      source,
      toggleSelect,
    }
  },
}
</script>
