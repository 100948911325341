<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mx-5 mt-2">
        <h1>{{ $t('AllOrganizations') }}</h1>
        <v-spacer></v-spacer>
        <div :class="$vuetify.breakpoint.xs?'mt-3 d-flex align-center':'d-flex align-center'">
          <v-text-field
            v-model="searchQuery"
            class="me-3"
            single-line
            dense
            outlined
            hide-details
            :placeholder="$t('SearchOrganization')"
            clearable
            prepend-inner-icon="mdi-home-search"
            clear-icon="mdi-close"
          ></v-text-field>
          <v-tooltip
            open-delay="500"
            top
          >
            <template #activator="{ on: onTooltip }">
              <v-btn
                elevation="2"
                color="primary"
                :disabled="!$canACL('create', 'organizations')"
                v-on="onTooltip"
                @click="$router.push({ name: 'create-organization' })"
              >
                <v-icon class="me-1">
                  mdi-bank-plus
                </v-icon>
              </v-btn>
            </template>
            <span> {{ $t('CreateOrganization') }}</span>
          </v-tooltip>
        </div>
      </v-row>
      <v-row class="mt-10">
        <card-organization
          v-if="loading"
          :user="userStore.user"
        >
        </card-organization>
        <card-organization
          v-for="organization in listTable"
          :key="organization.id"
          :organization="organization"
          :user="userStore.user"
        >
        </card-organization>
        <v-col
          v-if="!loading && (!listTable|| listTable.length === 0)"
          lg="3"
          sm="4"
          cols="12"
        >
          <v-card
            height="100%"
            min-height="180"
            min-width="300"
            justify-center
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-btn
              elevation="2"
              color="primary"
              :disabled="!$canACL('create', 'organizations')"
              @click="$router.push({ name: 'create-organization' })"
            >
              <v-icon class="me-1">
                mdi-bank-plus
              </v-icon>
              {{ $t('CreateOrganization') }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-pagination
        v-model="page"
        class="mt-10"
        :length="nbPages"
      ></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
import { useOrganizationsStore } from '@/stores/organizations.store'
import { useUserStore } from '@/stores/user.store'
import CardOrganization from '@/views/organizations/cards/CardOrganization.vue'
import useOrganizationsList from '@/views/organizations/useOrganizationsList'
import { appConfig } from '@/plugins/micleoConfig'
import {
  onBeforeMount,
  onMounted, onUnmounted,
  ref,
} from 'vue'

export default {
  metaInfo: {
    title: 'Organizations',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    CardOrganization,
  },
  setup() {
    const subscription = ref(null)
    const organizationsStore = useOrganizationsStore()
    const userStore = useUserStore()

    const {
      listTable, totalListTable, searchQuery, page, nbPages, loading,
    } = useOrganizationsList()

    onBeforeMount(async () => {
    })

    onMounted(async () => {
      organizationsStore.organization = null
      subscription.value = await organizationsStore.listenOrganizations()
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    return {
      userStore,
      organizationsStore,
      searchQuery,
      page,
      listTable,
      totalListTable,
      nbPages,
      loading,
    }
  },
}
</script>
<style lang="scss" scoped></style>
