<template>
  <!-- current plan -->
  <v-row
    class="pa-3 mt-2"
  >
    <div>
      <alert-error :is-alert-visible.sync="isAlertVisible"
                   :error="errors.organization"
      ></alert-error>
    </div>

    <v-col v-if="!organization.subscription"
           cols="12"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("PricingPlans") }}
          </h5>
          <v-spacer></v-spacer>
        </v-card-title>

        <step-plan
          :organization-data="organizationData"
          :prices="prices"
        ></step-plan>
      </v-card>
    </v-col>
    <v-col
      v-if="organization && organization.subscription"
      cols="12"
      md="12"
      lg="8"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ organization.subscription?.price?.product?.name }}
          </h5>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <h4 class="mb-1">
                <span class="font-weight-medium me-1">{{ $t("SubscriptionStatus") }}</span>
                <v-chip
                  small
                  :color="statusColor"
                  class="v-chip-bg error--text font-weight-semibold text-uppercase"
                >
                  {{ $t(organization.subscription?.status) }}
                </v-chip>
              </h4>
              <h4 class="mb-5">
                <span class="font-weight-medium me-1">{{ $t("PaymentInterval") }}</span>
                <span

                  class="text-uppercase"
                >{{ $t(organization.subscription?.price.interval) }}</span>
              </h4>
              <h4 class="mb-1">
                <span class="font-weight-medium me-1">{{ $t("CreatedAt") }}</span>
                <span>{{ $moment(organization.subscription?.created_at).format("lll") }}</span>
              </h4>
              <h4 class="mb-1">
                <span class="font-weight-medium me-1">{{ $t("StartAt") }}</span>
                <span>{{ $moment(organization.subscription?.current_period_start).format("lll") }}</span>
              </h4>
              <h4 class="mb-1">
                <span class="font-weight-medium me-1">{{ $t("EndAt") }}</span>
                <span>{{ $moment(organization.subscription?.current_period_end).format("lll") }}</span>
              </h4>
              <v-alert
                v-if="organization.subscription?.cancel_at"
                border="left"
                colored-border
                text
                color="error"
                class="mt-5 mb-0"
              >
                <p class="text-sm mb-0">
                  {{ $t("CancelAt") }} <span class="font-weight-bold me-1">{{ $moment(organization.subscription?.cancel_at).format("lll") }}</span>
                </p>
              </v-alert>
            </v-col>

            <v-col
              cols="12"
              md="5"
            >
              <v-alert
                v-if="organization.subscription?.status === 'incomplete'"
                border="left"
                colored-border
                text
                color="error"
              >
                <p class="font-weight-semibold mb-1">
                  {{ $t("NeedAttention") }}
                </p>
                <p class="text-sm mb-0">
                  {{ $t("SubscriptionIncomplete") }}
                </p>
                <v-btn
                  color="error"
                  class="mt-3 mb-1"
                  small
                  :href="organization.subscription?.invoice_url"
                  target="_blank"
                >
                  {{ $t("PayInvoice") }}
                </v-btn>
              </v-alert>

              <p
                class="d-flex font-weight-semibold text--primary mt-3 mb-2"
              >
                <span>{{ $t("Days") }}</span>
                <v-spacer></v-spacer>
                <span>{{ getCurrentDay(organization.subscription) }} / {{ getTotalDays(organization.subscription) }} {{ $t("Days") }}</span>
              </p>
              <v-progress-linear
                :value="getPercentDay(organization.subscription)"
                height="10"
                rounded
              ></v-progress-linear>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="d-flex flex-column align-self-stretch align-end"
            >
              <v-btn
                color="primary"
                class="me-3 mb-3 mb-sm-0"
                outlined
                :loading="loadingPortail === 'portail'"
                :disabled="loadingPortail === 'portail' || !$canACL('update', 'subscriptions', organization)"
                @click.stop="openPortail('portail')"
              >
                <v-icon>
                  mdi-account-search-outline
                </v-icon>
                <span class="d-none d-sm-block">{{ $t("SeeMyPortail") }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="organization.subscription"
           cols="12"
           md="12"
           lg="3"
    >
      <card-subscription
        :key="organization.subscription?.id"
        :subscription="organization.subscription"
        :organization="organization"
        :loading="loadingPortail === 'upgrade'"
        @open-portail="openPortail"
      >
      </card-subscription>
    </v-col>
    <v-col v-if="organization.subscription"
           cols="12"
           md="12"
           lg="8"
    >
      <usage-card
        :subscription="organization.subscription"
        :organization="organization"
      ></usage-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, toRef, computed } from 'vue'
import StepPlan from '@/views/organizations/StepPlan.vue'
import UsageCard from '@/views/organizations/UsageCard.vue'
import AlertError from '@/components/AlertError.vue'
import CardSubscription from '@/views/organizations/cards/CardSubscription.vue'
import { useOrganizationsStore } from '@/stores/organizations.store'

export default {
  components: {
    StepPlan,
    UsageCard,
    CardSubscription,
    AlertError,
  },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    prices: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const errors = ref({})
    const loadingPortail = ref(false)
    const isAlertVisible = ref(false)
    const organizationStore = useOrganizationsStore()

    const organizationData = ref({
      name: '',
      email: '',
      website: '',
      phone: '',
      subscription: {
        price: {
          product: {},
        },
      },
      interval: 'month',
      organization: props.organization,
    })

    // conserver la reactivité sur cette donnée
    const organization = toRef(props, 'organization')
    organizationData.value.organization = toRef(props, 'organization')

    const statusColor = computed(() => {
      if (!organization.value || !organization.value.subscription) return ''
      if (organization.value.subscription?.status === 'incomplete'
      || organization.value.subscription?.status === 'incomplete_expired'
      || organization.value.subscription?.status === 'canceled'
      || organization.value.subscription?.status === 'unpaid'
      || organization.value.subscription?.status === 'past_due'
      || organization.value.subscription?.status === 'quota_exceeded') {
        return 'error'
      }

      if (organization.value.subscription?.status === 'trialing') { return 'warning' }

      return 'primary'
    })

    const openPortail = mode => {
      errors.value = {}
      loadingPortail.value = mode
      organizationStore.createPortailLink(organization.value).then(data => {
        loadingPortail.value = null
        window.location.href = data.url
      }).catch(error => {
        loadingPortail.value = null
        isAlertVisible.value = true
        errors.value = {
          organization: error,
        }
      })
    }

    const datediff = (first, second) => Math.round((second - first) / (1000 * 60 * 60 * 24))

    const getTotalDays = subscription => {
      const days = datediff(
        new Date(subscription.current_period_start),
        new Date(subscription.current_period_end),
      )

      return days
    }

    const getCurrentDay = subscription => {
      const days = datediff(new Date(subscription.current_period_start), new Date())

      return days
    }

    const getPercentDay = subscription => Math.round((getCurrentDay(subscription) / getTotalDays(subscription)) * 100)

    return {
      organizationData,
      organization,
      errors,
      isAlertVisible,
      loadingPortail,
      openPortail,
      datediff,
      getPercentDay,
      getTotalDays,
      getCurrentDay,
      statusColor,
    }
  },
}
</script>
