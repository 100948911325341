<template>
  <v-snackbar
    v-if="error"
    v-model="isAlertVisible"
    color="error"
    outlined
    text
    shaped
    top
    right
  >
    <span>{{ error?.message ? $t(error?.message, error?.params) : error }}</span>

    <v-tooltip
      v-if="error && error?.details"
      bottom
      max-width="350"
      z-index="100000"
    >
      <template #activator="{ on, attrs }">
        <v-icon
          outlined
          class="mx-1"
          small
          color="error"
          v-bind="attrs"
          v-on="on"
        >
          mdi-information-outline
        </v-icon>
      </template>
      <span>{{ error?.details }}</span>
    </v-tooltip>
    <template #action="{ attrs }">
      <v-btn
        icon
        color="error"
        class="ma-0"
        v-bind="attrs"
        @click="isAlertVisible = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { useVModel } from '@vueuse/core'

export default {
  props: {
    isAlertVisible: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const isAlertVisible = useVModel(props, 'isAlertVisible', emit)

    return {
      isAlertVisible,
    }
  },
}
</script>
