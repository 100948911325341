import { computed } from 'vue'
import { getVuetify } from '@core/utils'
import { useConfigStore } from './config.store'

export default function useAppConfig() {
  const $vuetify = getVuetify()

  const appConfig = useConfigStore()

  const appName = computed({
    get: () => appConfig.app.name,
    set: value => {
      appConfig.app.name = value
    },
  })

  const footerName = computed({
    get: () => appConfig.app.copyright,
    set: value => {
      appConfig.app.copyright = value
    },
  })

  const appDescription = computed({
    get: () => appConfig.app.description,
    set: value => {
      appConfig.app.description = value
    },
  })

  const appLogo = computed({
    get: () => appConfig.app.logo,
    set: value => {
      appConfig.app.logo = value
    },
  })

  const appLogoDark = computed({
    get: () => appConfig.app.logoDark,
    set: value => {
      appConfig.app.logoDark = value
    },
  })

  const appContentLayoutNav = computed({
    get: () => appConfig.app.contentLayoutNav,
    set: value => {
      appConfig.app.contentLayoutNav = value
      if (!$vuetify.breakpoint.xs) localStorage.setItem('menu', value)
    },
  })

  const appRouteTransition = computed({
    get: () => appConfig.app.routeTransition,
    set: value => {
      appConfig.app.routeTransition = value
    },
  })

  const appSkinVariant = computed({
    get: () => appConfig.app.skinVariant,
    set: value => {
      appConfig.app.skinVariant = value
      localStorage.setItem('theme-skin-variant', value)
    },
  })

  const appContentWidth = computed({
    get: () => appConfig.app.contentWidth,
    set: value => {
      appConfig.app.contentWidth = value
    },
  })

  const menuIsMenuHidden = computed({
    get: () => appConfig.menu.isMenuHidden,
    set: value => {
      appConfig.menu.isMenuHidden = value
    },
  })

  const menuIsVerticalNavMini = computed({
    get: () => appConfig.menu.isVerticalNavMini,
    set: value => {
      appConfig.menu.isVerticalNavMini = value
    },
  })

  const appBarType = computed({
    get: () => appConfig.appBar.type,
    set: value => {
      appConfig.appBar.type = value
    },
  })

  const appBarIsBlurred = computed({
    get: () => appConfig.appBar.isBlurred,
    set: value => {
      appConfig.appBar.isBlurred = value
    },
  })

  const footerType = computed({
    get: () => appConfig.footer.type,
    set: value => {
      appConfig.footer.type = value
    },
  })

  const isDark = computed({
    get: () => $vuetify.theme.dark,
    set: value => {
      $vuetify.theme.dark = value
      localStorage.setItem('active-theme', value ? 'dark' : 'light')
    },
  })

  const isRtl = computed({
    get: () => $vuetify.rtl,
    set: value => {
      $vuetify.rtl = value
    },
  })

  const themes = computed({
    get: () => $vuetify.theme.themes,
    set: value => {
      appConfig.themes = value

      // ? We have to assign light and dark individually to make it reflect changes in UI
      // ! `$vuetify.theme.themes = value` isn't reactive
      $vuetify.theme.themes.light = value.light
      $vuetify.theme.themes.dark = value.dark
    },
  })

  return {
    appName,
    footerName,
    appDescription,
    appLogo,
    appLogoDark,
    appContentLayoutNav,
    appRouteTransition,
    appSkinVariant,
    appContentWidth,
    menuIsMenuHidden,
    menuIsVerticalNavMini,
    appBarType,
    appBarIsBlurred,
    footerType,
    isDark,
    isRtl,
    themes,
  }
}
