<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="450"
    persistent
  >
    <v-card
      class="pa-sm-6 pa-3"
    >
      <v-form
        ref="form"
        class="multi-col-validation"
        @submit.prevent="save"
      >
        <v-card-title
          class="justify-center text-h5"
        >
          {{ $t("EditListings") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="listings"
                :label="$t('Listings')"
                outlined
                dense
                item-text="name"
                item-value="id"
                :items="listingsItems"
                multiple
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-alert
          v-if="errors.listing"
          border="left"
          colored-border
          text
          color="error"
          class="subtitle-2"
        >
          {{ errors.listing }}
        </v-alert>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :loading="loadingAction"
            :disabled="loadingAction"
            type="submit"
            color="primary"
            class="me-3"
          >
            {{ $t('Submit') }}
          </v-btn>
          <v-btn
            outlined
            color="secondary"
            @click="isDialogVisible = false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch, getCurrentInstance } from 'vue'
import { useVModel } from '@vueuse/core'
import { required } from '@core/utils/validation'
import { useListingsStore } from '@/stores/listings.store'
import { TABLE_CONTACTS } from '@/@core/utils'

export default {
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    item: {
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const loadingAction = ref(false)
    const form = ref(null)
    const errors = ref({})
    const isDialogVisible = useVModel(props, 'isDialogVisible', emit)
    const item = useVModel(props, 'item', emit)
    const listings = ref([])
    const listingsItems = ref([])
    const listingsStore = useListingsStore()

    watch(isDialogVisible, () => {
      if (form.value) form.value.resetValidation()

      if (isDialogVisible.value) {
        errors.value = {}
        listingsItems.value = []
        listingsStore.listings.forEach(it => {
          listingsItems.value.push({
            name: it.mode ? `${it.name} (${it.mode})` : it.name,
            id: it.id,
            disabled: it.type !== 'manual' && vm.$router.currentRoute.name === TABLE_CONTACTS,
          })
        })
        if (item.value.listings) { listings.value = JSON.parse(JSON.stringify(item.value.listings)) }
      }
    })

    const save = () => {
      const tab = {}
      item.value.listings = []
      listings.value.forEach(it => {
        tab[it?.id || it] = true
        const listing = listingsStore.getListingItem(it?.id || it)
        if (listing) {
          item.value.listings.push(listing)
        }
      })

      emit('on-change', item.value)
      isDialogVisible.value = false
      loadingAction.value = false
    }

    return {
      form,
      isDialogVisible,
      loadingAction,
      listingsStore,
      listings,
      listingsItems,
      errors,
      save,
      validators: {
        required,
      },
    }
  },
}
</script>

<style lang="">
</style>
