import { defineStore, acceptHMRUpdate } from 'pinia'
import { required } from '@core/utils/validation'
import { TABLE_PROPERTIES_ITEMS, displayMode } from '@core/utils'

// eslint-disable-next-line import/prefer-default-export
export const usePropertiesItemsStore = defineStore({
  id: TABLE_PROPERTIES_ITEMS,
  state: () => ({

  }),
  actions: {
    getHeader(payload, item) {
      return {
        title: 'Options',
        icon: 'mdi-format-list-bulleted',
        name: ['name'],
        folder: payload?.folder,
        display: displayMode(TABLE_PROPERTIES_ITEMS, 'table'),
        newData: 'NewOption',
        actionData: 'EditOptions',
        editData: 'EditOption',
        search: 'SearchOption',
        searchIcon: 'mdi-text-search',
        add: {
          text: 'NewOption',
          icon: 'mdi-playlist-plus',
        },
        actions: [{
          title: 'Save', icon: 'mdi-content-save-outline', action: 'save', loading: false, acl: 'create,update',
        }, {
          title: 'Duplicate',
          icon: 'mdi-content-duplicate',
          action: 'duplicate',
          acl: 'create',
          disabled: !item?.created_at,
        },
        {
          title: 'Delete',
          icon: 'mdi-delete-outline',
          action: 'delete',
          acl: 'delete',
          color: 'error',
          disabled: !item?.created_at,
        }],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['sort_index'],
        sortDesc: [false],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: -1,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        { text: '#', value: 'sort_index', type: 'drag' },
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
          search: true,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        // general
        name: null,
        property_id: payload?.item,
        sort_index: null,
        project_id: payload?.project_id,
      }
    },

    getSchema(items, payload) {
      const schema = {
        general: {
          title: 'General',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 6 },
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {
            name: {
              type: 'text',
              label: 'Name',
              class: 'mx-1 mb-2',
              prependInnerIcon: 'mdi-database',
              rules: items.length > 0 ? [] : [required],
              hidden: !!items.length,
            },
          },
        },
      }

      return schema
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePropertiesItemsStore, import.meta.hot))
}
