<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              v-if="$vuetify.theme.isDark && appLogoDark || !$vuetify.theme.isDark && appLogo"
              :src="$vuetify.theme.isDark ? appLogoDark: appLogo"
              max-height="30px"
              max-width="30px"
              :alt="appName"
              contain
            ></v-img>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <h2 class="ml-1 font-weight-semibold appName"
                    v-bind="attrs"
                    v-on="on"
                >
                  {{ appName }}
                </h2>
              </template>
              <span>{{ options.version }}</span>
            </v-tooltip>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text v-if="false">
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('WelcomeSignin') }}
          </p>
          <p class="mb-2">
            {{ $t('WelcomeSigninDetails') }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="refVForm"
            @submit.prevent="onSubmit"
          >
            <v-text-field
              v-model="email"
              outlined
              :label="$t('Email')"
              placeholder="john@example.com"
              prepend-inner-icon="mdi-email-outline"
              :error-messages="errors.email != '' ? $t(errors.email) : errors.email"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              prepend-inner-icon="mdi-form-textbox-password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="$t('Password')"
              :error-messages="errors.password != '' ? $t(errors.password) : errors.password"
              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline':'mdi-eye-outline'"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-3"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <vue-hcaptcha
              v-if="options.sitekey && false"
              ref="invisibleHcaptcha"
              :sitekey="options.sitekey"
              :theme="$vuetify.theme.isDark ? 'dark': 'light'"
              size="normal"
              @verify="onVerify"
              @expired="onExpire"
              @challengeExpired="onChallengeExpire"
              @error="onError"
            ></vue-hcaptcha>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- forgot link -->
              <router-link
                :to="{ name: 'auth-forgot-password' }"
                class="mt-1"
              >
                {{ $t('ForgotPassword') }}
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              type="submit"
              class="mt-6"
              :loading="loading"
              :disabled="options.url.indexOf('localhost')<0 && options.sitekey && (loading || !options.verified)"
            >
              {{ $t('Login') }}
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">{{ $t('Or') }}</span>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <AuthProvider />
        </v-card-actions>

        <v-card-text class="d-flex align-center justify-center flex-wrap">
          <span class="caption mb-0">
            {{ $t('SigninTermsPolicy') }}
          </span>
        </v-card-text>

        <v-divider></v-divider>
        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap">
          <span class="me-2">
            {{ $t('NewOnPlatform') }}
          </span>
          <router-link :to="{ name: 'auth-register' }">
            {{ $t('CreateAnAccount') }}
          </router-link>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { hcaptcha } from '@/plugins/hcaptcha'
import { redirectTo, version } from '@/plugins/supabase'
import { appConfig } from '@/plugins/micleoConfig'
import { useUserStore } from '@/stores/user.store'
import { emailValidator, required } from '@core/utils/validation'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import { getCurrentInstance, ref } from 'vue'
import AuthProvider from '../views/authentification/AuthProvider.vue'

export default {
  metaInfo: {
    title: 'Login',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    AuthProvider,
    VueHcaptcha,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const userStore = useUserStore()

    const isPasswordVisible = ref(false)
    const errors = ref({
      email: '',
      password: '',
    })
    const refVForm = ref()

    const email = ref('')
    const password = ref('')
    const loading = ref(false)

    const options = ref({
      url: redirectTo,
      sitekey: hcaptcha,
      verified: false,
      expired: false,
      token: null,
      eKey: null,
      error: null,
      version,
    })

    const onVerify = (token, ekey) => {
      options.value.verified = true
      options.value.token = token
      options.value.eKey = ekey
    }

    const onExpire = () => {
      options.value.verified = false
      options.value.token = null
      options.value.eKey = null
      options.value.expired = true
    }

    const onChallengeExpire = () => {
      options.value.verified = false
      options.value.token = null
      options.value.eKey = null
      options.value.expired = true
    }

    const onError = err => {
      options.value.token = null
      options.value.eKey = null
      options.value.error = err
    }

    const login = () => {
      loading.value = true
      errors.value = {
        email: '',
        password: '',
      }
      userStore.login(email.value, password.value, options.value.token)
        .then(userData => {
          if (userData !== null) {
            loading.value = false
            vm.$router.push('/')
          }
        })
        .catch(err => {
          loading.value = false
          if (err.message.toLowerCase().includes('password')) {
            errors.value.password = err.message
          } else {
            errors.value.email = err.message
          }
        })
    }

    const onSubmit = () => {
      const isValid = refVForm.value?.validate()
      if (isValid) { login() }
    }

    return {
      onSubmit,
      isPasswordVisible,
      email,
      password,
      errors,
      loading,
      validators: {
        required,
        emailValidator,
      },

      // appConfig.theme
      appName: appConfig.theme.app.name,
      appLogo: appConfig.theme.app.logo,
      appLogoDark: appConfig.theme.app.logoDark,
      refVForm,
      options,
      onVerify,
      onError,
      onExpire,
      onChallengeExpire,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
