<template>
  <v-row class="pa-3 mt-2">
    <confirm-dialog-delete
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingDelete"
      :is-confirm="false"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>
    <v-snackbar
      v-model="isSavedPassword"
      color="primary"
      outlined
      text
      shaped
      top
      right
    >
      {{ $t('PaswwordSavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSavedPassword = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="isSaved"
      color="primary"
      outlined
      text
      shaped
      top
      right
    >
      {{ $t('ProfileSavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-col
      cols="12"
      lg="8"
    >
      <v-card>
        <v-card-title>
          <h3 class="font-weight-bold text-button">
            {{ $t("YourPassword") }}
          </h3>
        </v-card-title>
        <v-card-text>
          <p>
            {{ $t('PasswordInfo') }}
          </p>
          <v-btn
            color="primary"
            @click="isPasswordDialog = true"
          >
            <v-icon
              left
            >
              mdi-pencil-lock-outline
            </v-icon>
            {{ $t("ChangePassword") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-if="false"
      cols="12"
      lg="8"
    >
      <app-card-actions
        :loading="!factors"
        no-actions
      >
        <template slot="title">
          <h3> {{ $t('ConnectedAccounts') }}</h3>
        </template>
        <v-card-subtitle>
          Display content from your connected accounts on your site
        </v-card-subtitle>

        <v-card-text class="pb-2">
          <v-list
            dense
            class="py-0"
          >
            <v-list-item
              v-for="account in connectedAccounts"
              :key="account.title"
              class="px-0"
            >
              <v-img
                max-width="35"
                contain
                class="me-3"
                :src="require(`@/assets/images/logos/${account.img}`)"
              ></v-img>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-2">
                  <v-list-item-title class="text-sm">
                    {{ account.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ account.text }}
                  </v-list-item-subtitle>
                </div>

                <v-spacer></v-spacer>

                <v-switch
                  v-model="account.connected"
                  disabled
                ></v-switch>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </app-card-actions>
    </v-col>
    <v-col
      cols="12"
      md="12"
      lg="8"
    >
      <app-card-actions
        no-actions
        :loading="!factors"
      >
        <template slot="title">
          <h3 class="font-weight-bold text-button">
            {{ $t('MFA') }}
          </h3>
        </template>
        <v-card-text>
          <p
            v-if="!MFAEnabled"
            class="font-weight-semibold"
          >
            {{ $t('MFADisabled') }}
          </p>
          <p
            v-else
            class="font-weight-semibold"
          >
            {{ $t('MFAEnabled') }}
          </p>
          <p>
            {{ $t('MFAInfo') }}
          </p>

          <v-alert
            v-if="errors.mfa"
            border="left"
            colored-border
            text
            color="error"
            class="subtitle-2"
          >
            {{ errors.mfa }}
          </v-alert>
          <v-btn
            v-if="!MFAEnabled"
            color="primary"
            @click="getMFAEnroll"
          >
            <v-icon
              left
            >
              mdi-lock-check
            </v-icon>
            <span>{{ $t('EnableMFA') }}</span>
          </v-btn>
        </v-card-text>

        <v-data-table
          v-if="factors.length >0"
          :headers="tableHeadersFactors"
          :items="factors"
          hide-default-footer
          class="text-no-wrap"
        >
          <template
            #[`item.factor_type`]="{item}"
          >
            <span class="text-no-wrap font-weight-bold primary--text text-uppercase">{{ item.factor_type }}</span>
          </template>
          <template #[`item.created_at`]="{item}">
            {{ $moment(item.created_at).format("lll") }}
          </template>
          <template #[`item.updated_at`]="{item}">
            {{ $moment(item.updated_at).format("lll") }}
          </template>

          <template #[`item.status`]="{item}">
            <v-chip
              :color="item.status ==='verified' ? 'primary':'secondary'"
              :class="`text-uppercase v-chip-light-bg ${item.status==='verified' ? 'primary':'secondary'}--text font-weight-semibold`"
            >
              {{ $t(item.status) }}
            </v-chip>
          </template>

          <!-- actions -->
          <template #[`item.actions`]="{item}">
            <div class="d-flex align-center justify-center">
              <v-btn
                v-if="item.status==='unverified'"
                small
                outlined
                icon
                color="primary"
                class="me-3"
                @click="challengeMFA(item)"
              >
                <v-icon> mdi-lock-check-outline</v-icon>
              </v-btn>
              <v-btn
                small
                outlined
                icon
                color="error"
                @click="openDialogDelete(item)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </app-card-actions>
    </v-col>

    <v-col
      v-if="false"
      cols="12"
      md="12"
      lg="8"
    >
      <!-- recent divices -->
      <v-card>
        <v-card-title>
          <h3 class="font-weight-bold text-button">
            {{ $t('RecentDevices') }}
          </h3>
        </v-card-title>

        <v-data-table
          :headers="tableHeadersDevices"
          :items="recenntDevices"
          hide-default-footer
          class="text-no-wrap"
        >
          <template #[`item.browser`]="{item}">
            <div class="d-flex align-center">
              <v-avatar
                size="22"
                class="me-2"
              >
                <v-img :src="require(`@/assets/images/logos/${item.browser}`)"></v-img>
              </v-avatar>
              <span class="text--primary text-no-wrap font-weight-medium">{{ item.name }}</span>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <!-- dialog -->
    <v-dialog
      v-model="isMFADialogOpen"
      max-width="500"
      persistent
    >
      <v-card class="pa-sm-6 pa-3">
        <v-card-title class="justify-center text-h5">
          {{ $t('MFA') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <span class="text-subtitle-1">{{ $t('ScanQRCodeTOTP') }}</span>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-img
                :src="qr"
                max-width="160"
              ></v-img>
            </v-col>   <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-btn
                color="secondary"
                outlined
                type="reset"
                @click.prevent="cancelMFA"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                class="ms-auto"
                @click.prevent="challengeMFA"
              >
                {{ $t('Next') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialog -->
    <v-dialog
      v-model="isTOTPOpen"
      max-width="500"
      persistent
    >
      <v-card class="pa-sm-6 pa-3">
        <v-card-title class="justify-center text-h5">
          {{ $t('MFA') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <span class="text-subtitle-1">{{ $t('EnterCodeMFA') }}</span>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-alert
                v-if="errors.totp"
                border="left"
                colored-border
                text
                color="error"
                icon="mdi-lock"
              >
                <span class="text-overline">{{ $t(errors.totp) }}</span><br />
                <span class="text-caption"> {{ $t(errors.details) }}</span>
              </v-alert>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-otp-input
                :disabled="loading"
                length="6"
                type="number"
                @finish="onFinish"
                @input="onChange"
              ></v-otp-input>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-btn
                color="secondary"
                outlined
                type="reset"
                @click.prevent="cancelMFA(false)"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isPasswordDialog"
      max-width="450"
    >
      <v-card class="pa-sm-6 pa-3">
        <v-card-title class="justify-center text-h5">
          {{ $t("ChangePassword") }}
        </v-card-title>
        <v-form
          ref="form"
          class="multi-col-validation mt-6"
          @submit.prevent="savePassword"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="newPassword"
                  dense
                  outlined
                  prepend-inner-icon="mdi-form-textbox-password"
                  :rules="[validators.required, validators.passwordValidator]"
                  :error-messages="errors.password != '' ? $t(errors.password) : errors.password"
                  :type="isNewPasswordVisible ? 'text' : 'password'"
                  :append-icon="isNewPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  :label="$t('NewPassword')"
                  @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                />
              </v-col>

              <v-col
                cols="12"
              >
                <!-- 👉 confirm password -->
                <v-text-field
                  v-model="confirmPassword"
                  dense
                  outlined
                  prepend-inner-icon="mdi-form-textbox-password"
                  :rules="[validators.required, validators.confirmedValidator(newPassword, confirmPassword)]"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  :append-icon="isConfirmPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  :label="$t('ConfirmPassword')"
                  @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <p class="text-base font-weight-medium mt-2">
              {{ $t('PasswordRequirements') }}
            </p>

            <ul class="d-flex flex-column gap-y-3">
              <li
                v-for="item in passwordRequirements"
                :key="item"
                class="d-flex"
              >
                <div>
                  <v-icon
                    size="7"
                    class="me-3"
                  >
                    mdi-circle
                  </v-icon>
                </div>
                <span class="font-weight-medium">{{ $t(item) }}</span>
              </li>
            </ul>
            <div class="mt-6 d-flex justify-center">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                class="me-3"
                type="submit"
              >
                {{ $t('Submit') }}
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                type="reset"
                @click.prevent="resetForm"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ref, onMounted, toRef, getCurrentInstance, onBeforeMount,
} from 'vue'
import {
  required, emailValidator, passwordValidator, confirmedValidator,
} from '@core/utils/validation'
import { useUserStore } from '@/stores/user.store'
import { setAbilities } from '@/plugins/acl/ability'
import AppCardActions from '@/components/AppCardActions.vue'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'

export default {
  components: {
    AppCardActions,
    ConfirmDialogDelete,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const loading = ref(false)
    const isSaved = ref(false)
    const isSavedPassword = ref(false)
    const form = ref(null)
    const errors = ref({})
    const user = toRef(props, 'user')
    const isNewPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const newPassword = ref(null)
    const confirmPassword = ref(null)
    const isMFADialogOpen = ref(null)
    const isTOTPOpen = ref(null)
    const qr = ref(null)
    const factorId = ref(null)
    const factors = ref([])
    const loadingDelete = ref(false)
    const currentItem = ref(null)
    const isDeleteDialogVisible = ref(false)
    const MFAEnabled = ref(false)
    const isPasswordDialog = ref(false)
    const connectedAccounts = [
      {
        img: 'google.png',
        title: 'Google',
        text: 'Google Auth',
        connected: true,
      },
    ]

    const vm = getCurrentInstance().proxy

    const passwordRequirements = [
      'PasswordMinimum8',
      'Password1LowerCase',
      'Password1Symbol',
    ]

    const tableHeadersDevices = [
      { text: 'Browser', value: 'browser', sortable: false },
      { text: 'Device', value: 'device', sortable: false },
      { text: 'Location', value: 'location', sortable: false },
      { text: 'RecentActivity', value: 'recentActivity', sortable: false },
    ]

    const tableHeadersFactors = [
      { text: 'Type', value: 'factor_type', sortable: false },
      { text: 'CreatedAt', value: 'created_at', sortable: false },
      { text: 'UpdatedAt', value: 'updated_at', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      {
        text: 'Action',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const recenntDevices = [
      {
        browser: 'chrome.png',
        name: 'Chrome on Windows',
        device: 'Dell XPS 15',
        location: 'United States',
        recentActivity: '10, Jan 2020 20:07',
      },
      {
        browser: 'chrome.png',
        name: 'Chrome on Android',
        device: 'Google Pixel 3a',
        location: 'Ghana',
        recentActivity: '11, Jan 2020 10:16',
      },
      {
        browser: 'chrome.png',
        name: 'Chrome on MacOS',
        device: 'Apple iMac',
        location: 'Mayotte',
        recentActivity: '11, Jan 2020 12:10',
      },
      {
        browser: 'chrome.png',
        name: 'Chrome on IPhone',
        device: 'Apple iPhone XR',
        location: 'Mauritania',
        recentActivity: '12, Jan 2020 8:29',
      },
    ]

    const userStore = useUserStore()

    onBeforeMount(() => {
      tableHeadersDevices.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
      tableHeadersFactors.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
    })
    onMounted(async () => {
      factors.value = await userStore.getMFAFactors()
      if (factors.value.findIndex(x => x.status === 'verified') >= 0) { MFAEnabled.value = true } else MFAEnabled.value = false
    })

    const savePassword = () => {
      errors.value = {
      }
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      loading.value = true
      userStore.updatePassword(newPassword.value)
        .then(userData => {
          if (userData !== null) {
            loading.value = false
            isSavedPassword.value = true
            isPasswordDialog.value = false
          }
        })
        .catch(err => {
          loading.value = false
          errors.value = {
            password: err.message,
          }
        })
    }

    const resetForm = async () => {
      form.value.reset()
      isPasswordDialog.value = false
    }

    const getMFAEnroll = async () => {
      errors.value = {
      }
      loading.value = true
      userStore.getMFAEnroll().then(data => {
        isMFADialogOpen.value = true
        loading.value = false
        qr.value = data.totp.qr_code
        factorId.value = data.id
      })
        .catch(err => {
          loading.value = false
          errors.value = {
            mfa: err.message,
          }
        })
    }

    const openDialogDelete = _item => {
      isDeleteDialogVisible.value = true
      currentItem.value = _item
    }
    const confirmDelete = () => {
      if (!currentItem.value) { return }
      loadingDelete.value = true
      userStore.getMFAUnEnroll(currentItem.value.id)
        .then(async () => {
          isDeleteDialogVisible.value = false
          loadingDelete.value = false
          factors.value = await userStore.getMFAFactors()
          if (factors.value.findIndex(x => x.status === 'verified') >= 0) { MFAEnabled.value = true } else MFAEnabled.value = false
        })
        .catch(() => {
          loadingDelete.value = false
        })
    }
    const cancelMFA = bool => {
      isMFADialogOpen.value = false
      isTOTPOpen.value = false
      if (bool !== false) {
        userStore.getMFAUnEnroll(factorId.value)
          .then(async () => {
            factors.value = await userStore.getMFAFactors()
            if (factors.value.findIndex(x => x.status === 'verified') >= 0) { MFAEnabled.value = true } else MFAEnabled.value = false
          })
          .catch(() => {
          })
      }
    }
    const challengeMFA = _item => {
      if (_item.id) { factorId.value = _item.id }
      isTOTPOpen.value = true
      isMFADialogOpen.value = false
    }

    const onFinish = rsp => {
      errors.value = {
        totp: null,
        details: null,
      }
      loading.value = true

      userStore.getMFAChallenge(factorId.value, rsp).then(async () => {
        userStore.user = await userStore.getAdmin()
        loading.value = false
        isTOTPOpen.value = false
        vm.$ability.update(setAbilities())
        factors.value = await userStore.getMFAFactors()
        if (factors.value.findIndex(x => x.status === 'verified') >= 0) { MFAEnabled.value = true } else MFAEnabled.value = false
      })
        .catch(err => {
          loading.value = false
          errors.value.totp = 'InvalidTOTP'
          errors.value.details = err
        })
    }

    const onChange = () => {
      errors.value = {
        totp: null,
        details: null,
      }
    }

    return {
      user,
      form,
      isSaved,
      isSavedPassword,
      loading,
      validators: {
        required,
        emailValidator,
        passwordValidator,
        confirmedValidator,
      },
      errors,
      savePassword,
      resetForm,
      passwordRequirements,
      isNewPasswordVisible,
      isConfirmPasswordVisible,
      newPassword,
      confirmPassword,
      tableHeadersDevices,
      recenntDevices,
      isMFADialogOpen,
      getMFAEnroll,
      qr,
      factorId,
      connectedAccounts,
      tableHeadersFactors,
      factors,
      openDialogDelete,
      confirmDelete,
      isDeleteDialogVisible,
      loadingDelete,
      currentItem,
      cancelMFA,
      challengeMFA,
      isTOTPOpen,
      onFinish,
      onChange,
      MFAEnabled,
      isPasswordDialog,
    }
  },
}
</script>
