function getId(type) {
  const url = window.location.pathname

  if (url.indexOf(type) > 0) {
    const splitted = url.split('/')
    const index = splitted.findIndex(param => param === type)

    return splitted[index + 1]
  }

  return null
}

export default {
  getId,
}
