<template>
  <div v-if="canViewVerticalNavMenuGroup(item)">
    <v-list-item
      :to="item.to"
      class="vertical-nav-menu-link"
      active-class="bg-gradient-primary"
    >
      <v-list-item-icon>
        <v-icon
          :class="{'alternate-icon-small': !item.icon}"
          :color="isActive ? 'white': null"
        >
          {{ item.icon || alternateIcon }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-title :class="{'white--text': isActive}">
        {{ $t(item.title) }}
      </v-list-item-title>
      <v-list-item-action
        class="flex-shrink-0"
      >
        <v-icon v-if="isOpen">
          mdi-chevron-down
        </v-icon>
        <v-icon v-else>
          mdi-chevron-right
        </v-icon>
      </v-list-item-action>
    </v-list-item>
    <div v-if="isOpen || isActive">
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children.filter(c => c.title !== item.title)"
        :key="`${child.title}-${child.to?.params?.id }%`|| child.title"
        :item="child"
      ></component>
    </div>
  </div>
</template>

<script>
import {
  inject, ref, watch,
} from 'vue'
// eslint-disable-next-line object-curly-newline

// SFCs
import VerticalNavMenuLink from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuSectionTitle from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue'

// Composable
import useAppConfig from '@/stores/appconfig'
import useVerticalNavGroupLink from '@core/layouts/composable/vertical-nav/useVerticalNavGroupLink'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { appConfig } from '@/plugins/micleoConfig'

// Other
import useNav from '@/@core/layouts/composable/useNav'
import useVerticalNavMenu from '@/@core/layouts/composable/vertical-nav/useVerticalNavMenu'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuSectionTitle,
    VerticalNavMenuLink,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveNavItemComponent } = useVerticalNavMenu()
    const { isOpen, updateGroupOpen, isActive } = useVerticalNavGroupLink(props.item)
    const { canViewVerticalNavMenuGroup } = useAclUtils()
    const { isNavGroupActive } = useNav()
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')

    // Template ref
    const isGroupExpanded = ref(false)

    isGroupExpanded.value = isActive.value

    watch(isActive, value => {
      isGroupExpanded.value = value
    })

    watch(menuIsVerticalNavMini, val => {
      if (val && !isMouseHovered) isGroupExpanded.value = false
      else {
        isGroupExpanded.value = isOpen.value
      }
    })

    return {
      isGroupExpanded,
      resolveNavItemComponent,
      isNavGroupActive,
      menuIsVerticalNavMini,
      isMouseHovered,
      isOpen,
      isActive,
      updateGroupOpen,

      // Alternate Icon
      alternateIcon: appConfig.theme.menu.groupChildIcon,

      // ACL
      canViewVerticalNavMenuGroup,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-group {
  &.v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: none;
  }

  & > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: rotate(-90deg);
  }

  & & .v-list-group__items .v-list-item__icon {
    visibility: hidden;
  }
}

@include theme(vertical-nav-menu-group) using ($material) {
  &.vertical-nav-menu-group-active > .v-list-group__header {
    background-color: rgba(map-deep-get($material, 'text', 'primary'), map-deep-get($material, 'states', 'selected'));
  }
}

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
</style>
