<template>
  <v-text-field
    :value="swatchStyle.backgroundColor"
    clearable
    outlined
    dense
    readonly
    :label="$t(obj?.schema?.label) || label"
    v-bind="obj?.schema"
    @click:clear="clear"
    @click="menu = true"
  >
    <template #prepend>
      <v-menu
        v-model="menu"
        top
        nudge-bottom="105"
        nudge-right="100"
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <div
            :style="swatchStyle"
            v-on="on"
          />
        </template>
        <v-card flat>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="color"
              mode="hexa"
              flat
              @input="$emit('change',swatchStyle.backgroundColor ==='#FFFFE0FF' ? null:swatchStyle.backgroundColor)"
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>
<script>
import { ref, computed } from 'vue'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    obj: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const menu = ref(false)
    const color = ref(props.value)

    const swatchStyle = computed(() => ({
      backgroundColor: color.value?.hexa || color.value,
      cursor: 'pointer',
      height: '40px',
      width: '40px',
      position: 'relative',
      top: '-8px',
      borderRadius: '50%',
      border: '2px solid rgba(94, 86, 105, 0.14)',
      transition: 'border-radius 200ms ease-in-out',
    }))

    const clear = () => {
      color.value = '#FFFFE0FF'
      emit('change', null)
    }

    return {
      menu,
      color,
      swatchStyle,
      clear,
    }
  },
}
</script>
