<template>
  <div>
    <stripe-pricing-table :pricing-table-id="stripePricingTable"
                          :publishable-key="stripePK"
                          :customer-email="organizationData.organization.email"
                          :client-reference-id="organizationData.organization.id"
    >
    </stripe-pricing-table>
  </div>
</template>
<script>
import { appConfig } from '@/plugins/micleoConfig'

export default {

  components: {
  },
  props: {
    organizationData: {
      type: Object,
      default: null,
    },
    prices: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const stripePK = appConfig?.tokens?.STRIPE_PUBLISHABLE_KEY
    const stripePricingTable = appConfig?.tokens?.STRIPE_PRICING_TABLE
    const getPrices = () => props.prices.filter(price => price.interval === props.organizationData.interval)

    return {
      getPrices,
      stripePK,
      stripePricingTable,
    }
  },
}
</script>
