<template>
  <v-menu
    v-if="userStore.user"
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template #activator="{ on, attrs }">
      <v-badge
        :value="false"
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userStore.user.avatar"
            :src="userStore.user.avatar"
          ></v-img>
          <span
            v-else
            class="font-weight-semibold"
          >{{ avatarText(userStore.user.fullname) }}</span>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          :value="false"
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-2"
          dot
        >
          <v-avatar
            size="40"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userStore.user.avatar"
              :src="userStore.user.avatar"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold"
            >{{ avatarText(userStore.user.fullname) }}</span>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userStore.user.fullname }}
          </span>
          <small class="text--disabled">{{ userStore.user.email }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item :to="{ name: 'account-settings', params: {tab: 0} }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            mdi-account
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('Profile') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'account-settings', params: {tab: 1} }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            mdi-lock
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('Security') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            mdi-logout-variant
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/plugins/acl/config'
import { useUserStore } from '@/stores/user.store'

export default {
  setup() {
    const userStore = useUserStore()
    const vm = getCurrentInstance().proxy

    const logoutUser = () => {
      userStore.signOut()
      vm.$ability.update(initialAbility)
    }

    return {
      userStore,
      logoutUser,
      avatarText,
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
