<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="450"
  >
    <v-card
      v-if="folder"
      class="pa-sm-6 pa-3"
    >
      <v-form
        ref="form"
        class="multi-col-validation"
        @submit.prevent="saveFolder"
      >
        <v-card-title
          v-if="folder.updated_at"
          class="justify-center text-h5"
        >
          {{ $t("EditFolder") }}
        </v-card-title>
        <v-card-title
          v-else
          class="justify-center text-h5"
        >
          {{ $t("NewFolder") }}
        </v-card-title>
        <v-card-text class="pt-3">
          <v-row class="mt-1">
            <v-col
              cols="12"
              class="py-1"
            >
              <v-text-field
                v-model="folder.name"
                autofocus
                prepend-inner-icon="mdi-folder-open"
                outlined
                dense
                :rules="[validators.required]"
                hide-details
                :label="$t('Name')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="pb-3"
            >
              <v-select
                v-model="folder.parent_id"
                :disabled="folder.root === true"
                :label="$t('Path')"
                :placeholder="$t('Path')"
                prepend-inner-icon="mdi-subdirectory-arrow-right"
                :items="foldersStore.folders.filter(f => f.id !== folder.id)"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
                clearable
              >
                <template #selection="data">
                  <span class="text-subtitle-2"> {{ getParent(data.item.id, foldersStore.folders) }}</span>
                </template>
                <template #item="data">
                  <span class="text-subtitle-2"> {{ getParent(data.item.id, foldersStore.folders) }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-alert
          v-if="errors.folder"
          border="left"
          colored-border
          text
          color="error"
          class="subtitle-2"
        >
          {{ errors.folder }}
        </v-alert>
        <v-card-actions class="mt-6 d-flex justify-center">
          <v-btn
            v-if="folder.updated_at"
            :loading="loadingAction"
            :disabled="loadingAction"
            type="submit"
            color="primary"
            class="me-3"
          >
            {{ $t('Save') }}
          </v-btn>
          <v-btn
            v-else
            :loading="loadingAction"
            :disabled="loadingAction"
            type="submit"
            color="primary"
            class="me-3"
          >
            {{ $t('Create') }}
          </v-btn>
          <v-btn
            outlined
            color="secondary"
            @click="isDialogVisible = false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, getCurrentInstance, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { useFoldersStore } from '@/stores/folders.store'
import { getParent } from '@core/utils/folders'
import { required } from '@core/utils/validation'
import { uuidv7 } from 'uuidv7'

export default {
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false,
    },
    folder: {
      type: Object,
      default: null,
    },
    folders: {
      type: Array,
      default: null,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const loadingAction = ref(false)
    const form = ref(null)
    const errors = ref({})
    const isDialogVisible = useVModel(props, 'isDialogVisible', emit)
    const folder = useVModel(props, 'folder', emit)
    const foldersStore = useFoldersStore()

    watch(isDialogVisible, () => {
      errors.value = {}
      if (form.value) form.value.resetValidation()
    })

    const saveFolder = () => {
      folder.value.children = undefined
      folder.value.properties = undefined
      folder.value.folders = undefined
      folder.value.query = undefined
      foldersStore.upsert([{
        id: folder.value.id || uuidv7(), name: folder.value.name, parent_id: folder.value.parent_id, project_id: folder.value.project_id,
      }]).then(data => {
        isDialogVisible.value = false
        foldersStore.getFolders(vm.$router.currentRoute.params.id).then(() => {
          if (folder.value.id) emit('set-folder', data[0])
        })
      }).catch(error => {
        errors.value = {
          folder: error.message,
        }
        if (error.response) {
          errors.value = {
            folder: error.response.data?.error?.message || error.response.data?.message,
          }
        }
      })
    }

    return {
      form,
      isDialogVisible,
      loadingAction,
      folder,
      errors,
      saveFolder,
      validators: {
        required,
      },
      getParent,
      foldersStore,
    }
  },
}
</script>

<style lang="">
</style>
