<template>
  <div v-resize="onResize">
    <confirm-dialog
      v-if="menu"
      :is-confirm-dialog-visible.sync="isConfirm"
      :is-loading="loadingAction"
      :message="menu.message"
      :payload="{ campaign: item?.name, recipients: item.recipients }"
      @confirm-action="confirmAction"
    ></confirm-dialog>
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.item"
    ></alert-error>
    <v-row ref="form-content">
      <v-col cols="12">
        <v-card flat
                :ripple="false"
                @click="isInput = false"
        >
          <v-navigation-drawer v-if="!directory[directory.length - 1]?.editor"
                               v-model="drawer"
                               :mini-variant="mini"
                               permanent
                               right
                               absolute
          >
            <v-list-item class="px-2">
              <v-list-item-avatar v-if="mini">
                <v-btn icon
                       @click.stop="mini = !mini"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-list-item-avatar>

              <v-list-item-title class="text-button text-center primary--text font-weight-semibold">
                {{ $t('Menu') }}
              </v-list-item-title>

              <v-btn icon
                     @click.stop="mini = !mini"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item>

            <v-divider v-if="config.header.menu?.length > 0"></v-divider>

            <v-list v-if="config.header.menu?.length > 0"
                    dense
            >
              <v-list-item
                v-for="it in config.header.menu"
                :key="it.title"
                class="px-2"
                :disabled="it.disabled"
                @click="onAction(it)"
              >
                <v-list-item-avatar v-if="mini">
                  <v-btn icon
                         :loading="it.loading"
                         :disabled="it.loading || it.disabled || !$canACL(it.acl, config.currentTable, { id: $router.currentRoute.params.id })"
                  >
                    <v-icon :color="it.color || 'secondary'">
                      {{ it.icon }}
                    </v-icon>
                  </v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="text-overline px-2">
                    {{ $t(it.title) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-if="config.header.actions?.length > 0"></v-divider>
            <v-list v-if="config.header.actions?.length > 0"
                    dense
            >
              <v-list-item
                v-for="it in config.header.actions"
                :key="it.title"
                class="px-2"
                :disabled="it.disabled || !$canACL(it.acl, config.currentTable, { id: $router.currentRoute.params.id })"
                @click="onAction(it)"
              >
                <v-list-item-avatar v-if="mini">
                  <v-btn icon
                         :loading="it.loading"
                         :disabled="it.loading || it.disabled || !$canACL(it.acl, config.currentTable, { id: $router.currentRoute.params.id })"
                  >
                    <v-icon :color="it.color || 'primary'">
                      {{ it.icon }}
                    </v-icon>
                  </v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="text-overline px-2">
                    {{ $t(it.title) }} {{ $canACL(it.acl, config.currentTable, { id: $router.currentRoute.params.id }) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
          <v-card-text class="pa-0">
            <v-form ref="form"
                    class="multi-col-validation"
                    @submit.prevent
            >
              <v-card flat
                      class="overflow-y-auto h-full"
                      style="background-color: rgba(94, 86, 105, 0.04)"
              >
                <v-progress-linear
                  v-show="firstload || loadingAction"
                  style="position: absolute"
                  indeterminate
                  color="primary"
                ></v-progress-linear>
                <v-form-base
                  v-if="!directory[directory.length - 1]?.editor"
                  id="form-base-container"
                  class="pa-3 mr-13"
                  :col="12"
                  :model="item"
                  :schema="config.schema"
                  @input="onChange"
                  @click="onClick"
                >
                </v-form-base>

                <container-form-editor
                  v-if="directory[directory.length - 1]?.editor === 'form-editor'"
                  :item.sync="item"
                  @on-change="onChange"
                  @on-save="onSave"
                  @on-click="onClick"
                ></container-form-editor>

                <container-workflow-editor
                  v-if="directory[directory.length - 1]?.editor === 'workflow-editor'"
                  :item.sync="item"
                  @on-change="onChange"
                  @on-save="onSave"
                ></container-workflow-editor>
                <container-email-editor
                  v-if="directory[directory.length - 1]?.editor === 'template-editor'"
                  :item.sync="item"
                ></container-email-editor>
                <container-file-viewer
                  v-if="directory[directory.length - 1]?.editor === 'file-viewer'"
                  :item.sync="item"
                ></container-file-viewer>
              </v-card>
              <v-divider></v-divider>

              <v-card-actions v-if="!directory[directory.length - 1]?.editor"
                              class="pt-4 pb-4 d-flex justify-center"
              >
                <v-btn
                  :loading="loadingAction"
                  :disabled="
                    loadingAction ||
                      !$canACL('update', config.currentTable, { id: $router.currentRoute.params.id }) ||
                      !config.header.actions ||
                      config.header.actions?.find(h => h.action === 'save')?.disabled
                  "
                  color="primary"
                  class="me-3"
                  @click="onSave"
                >
                  <v-icon class="d-sm-none">
                    mdi-content-save
                  </v-icon>
                  <span class="d-none d-sm-block">{{ $t('Save') }}</span>
                </v-btn>
                <v-btn outlined
                       color="secondary"
                       @click="onCancel"
                >
                  <v-icon class="d-sm-none">
                    mdi-close
                  </v-icon>
                  <span class="d-none d-sm-block">{{ $t('Cancel') }}</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  ref, getCurrentInstance, onMounted, onBeforeUnmount,
} from 'vue'
import { useVModel } from '@vueuse/core'
import types from '@/views/projects/settings/types.json'
import appearances from '@/views/projects/settings/appearances.json'
import { useTableStore } from '@/stores/table.store'
import ContainerEmailEditor from '@/views/projects/settings/ContainerEmailEditor.vue'
import ContainerWorkflowEditor from '@/views/projects/settings/ContainerWorkflowEditor.vue'
import ContainerFormEditor from '@/views/projects/settings/ContainerFormEditor.vue'
import ContainerFileViewer from '@/views/projects/settings/ContainerFileViewer.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import AlertError from '@/components/AlertError.vue'
import { useCampaignsStore } from '@/stores/campaigns.store'
import {
  required, emailValidator, urlValidator, regexValidator, minLen, maxLen,
} from '@core/utils/validation'
import { useFoldersStore } from '@/stores/folders.store'
import { useListingsStore } from '@/stores/listings.store'
import { usePropertiesStore } from '@/stores/properties.store'

export default {
  components: {
    ContainerEmailEditor,
    ContainerWorkflowEditor,
    ContainerFormEditor,
    ContainerFileViewer,
    ConfirmDialog,
    AlertError,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    directory: {
      type: Array,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    item: {
      default: null,
    },
    total: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      default: null,
    },
    currentTableDirectEdit: {
      type: Boolean,
      default: false,
    },
    firstload: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const loadingAction = ref(false)
    const form = ref(null)
    const errors = ref({})
    const item = useVModel(props, 'item', emit)
    const items = useVModel(props, 'items', emit)
    const categories = ref([])
    const config = useVModel(props, 'config', emit)
    const directory = useVModel(props, 'directory', emit)
    const tableStore = useTableStore()
    const folder = ref(null)
    const template = ref(null)
    const formHeight = ref(0)
    const isAlertVisible = ref(null)
    const submodels = ref([config.value.submodels])
    const vm = getCurrentInstance().proxy
    let isInput = false
    const drawer = ref(true)
    const mini = ref(true)
    const filteringOptions = ref(null)
    const isConfirm = ref(false)
    const menu = ref(null)

    const campaignsStore = useCampaignsStore()
    const foldersStore = useFoldersStore()
    const listingsStore = useListingsStore()
    const propertiesStore = usePropertiesStore()
    const onSave = () => {
      if (
        !form.value.validate()
        || listingsStore.isListings
        || propertiesStore.isModels
        || campaignsStore.isTest
        || directory.value[directory.value.length - 1]?.editor
      ) {
        if (menu.value) {
          menu.value.loading = false
        }

        return
      }
      items.value?.forEach(it => {
        Object.keys(item.value).forEach(key => {
          it[key] = item.value[key]
        })
      })

      loadingAction.value = true
      tableStore
        .upsert(items.value?.length ? items.value : [item.value])
        .then(data => {
          loadingAction.value = false
          if (menu.value) {
            menu.value.loading = false
          }
          emit('on-save')
          emit('on-previous', undefined, props.currentTableDirectEdit ? data[0][0] : false)
        })
        .catch(error => {
          if (menu.value) {
            menu.value.loading = false
          }
          isAlertVisible.value = true
          loadingAction.value = false
          errors.value = {
            item: error,
          }
        })
    }

    const onCancel = () => {
      emit('on-previous', undefined, props.currentTableDirectEdit)
    }

    const keyListener = e => {
      if (e.key === 'Enter') {
        if (!isInput) {
          e.preventDefault() // present "Save Page" from getting triggered.
          onSave()
        }
      } else if (e.key === 'Escape') {
        e.preventDefault() // present "Save Page" from getting triggered.
        onCancel()
      }
    }

    const focusChanged = e => {
      const el = e.target
      if (el.tagName === 'TEXTAREA') {
        isInput = e.target
      } else if (el.className === 'ql-editor') {
        isInput = e.target
      } else isInput = null
    }

    onMounted(() => {
      document.addEventListener('keydown', keyListener, true)
      document.addEventListener('focusin', focusChanged, true)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', keyListener, true)
      document.removeEventListener('focusin', focusChanged, true)
    })

    const handleFileSelected = val => {
      val.data[val.key] = false
      const sch = tableStore.getCurrentSchema(val.key)
      emit('on-change', val)
      const slug = val.key.replace('properties->>', '').replace('properties_crypted->>', '')
      const folderID = sch.folder || foldersStore.root.id
      const fileName = val.value.name
      tableStore
        .uploadFile(folderID, slug, fileName, val.value)
        .then(data => {
          val.data[val.key] = `${config.value.currentTable}/${data.path}`
          emit('on-change', val)
        })
        .catch(async error => {
          isAlertVisible.value = true
          val.data[val.key] = `${config.value.currentTable}/${vm.$router.currentRoute.params.id}/${folderID}/${slug}/${fileName}`
          errors.value = {
            item: error.message,
          }
          emit('on-change', val)
        })
    }

    const onChange = val => {
      if (val.value instanceof File) {
        handleFileSelected(val)
      }
      emit('on-change', val)
    }

    const setFolder = val => {
      item.value.folder_id = val.id
    }
    const setTemplate = val => {
      item.value.template_id = val.id
    }
    const onResize = () => {
      formHeight.value = window.innerHeight - 320
    }

    // eslint-disable-next-line no-extend-native, func-names
    Array.prototype.swapItems = function (a, b) {
      // eslint-disable-next-line prefer-destructuring
      this[a] = this.splice(b, 1, this[a])[0]

      return this
    }

    const onClick = val => {
      emit('on-click', val)
    }

    const onAction = async it => {
      menu.value = it
      if (it.action === 'save') {
        menu.value.loading = true
        onSave()
      } else if (it.action === 'delete') {
        emit('on-delete', item.value)
      } else if (it.action === 'duplicate') {
        tableStore.onAction(it.action, { item: item.value }).then(data => {
          const copy = JSON.parse(JSON.stringify(data))
          if (config.value?.header?.name) {
            config.value.header.name.forEach(n => {
              copy[n] = ` ## Copy ${copy[n] || ''}`
            })
          }
          delete copy.id
          delete copy.created_at
          delete copy.created_by
          delete copy.updated_at
          delete copy.updated_by
          delete copy.sort_index

          emit('on-copy', copy)
        })
      } else if (it.action === 'activities') {
        emit('on-sub-table', 'activities', true)
      } else if (it.confirm) {
        isConfirm.value = true
      } else {
        menu.value.loading = false
        tableStore.upsert(items.value?.length ? items.value : [item.value]).then(() => {
          tableStore.onAction(it.action, { item: item.value }).then(() => {
          })
        })
      }
    }

    const confirmAction = () => {
      isConfirm.value = false
      tableStore.upsert(items.value?.length ? items.value : [item.value]).then(() => {
        tableStore
          .onAction(menu.value.action, { item: item.value })
          .then(data => {
            loadingAction.value = false
            emit('on-save')
            emit('on-previous', undefined, props.currentTableDirectEdit ? data[0][0] : false)
          })
          .catch(error => {
            isAlertVisible.value = true
            loadingAction.value = false
            errors.value = {
              item: vm.$t(error.message, error.params) || 'error',
            }
          })
      })
    }

    return {
      config,
      errors,
      form,
      item,
      items,
      directory,
      loadingAction,
      isAlertVisible,
      formHeight,
      onSave,
      onCancel,
      onChange,
      types,
      appearances,
      categories,
      setFolder,
      setTemplate,
      folder,
      template,
      onResize,
      onClick,
      submodels,

      required,
      emailValidator,
      urlValidator,
      regexValidator,
      minLen,
      maxLen,
      filteringOptions,
      isInput,
      drawer,
      mini,
      onAction,
      isConfirm,
      confirmAction,
      menu,
      campaignsStore,
    }
  },
}
</script>

<style>
.v-card--link:before {
  background: none;
}
.prop-conditions {
  border: 1px solid #e8e7ea;
  padding-top: 10px !important;
  padding-left: 10px !important;
  margin-bottom: 10px !important;
}
</style>
