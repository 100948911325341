<template>
  <div>
    <v-menu
      bottom
      offset-y
    >
      <template #activator="{ on: onMenu }">
        <v-tooltip
          open-delay="500"
          top
        >
          <template #activator="{ on: onTooltip }">
            <v-btn
              color="primary"
              dark
              class="mt-1 mx-1"
              :small="!$vuetify.breakpoint.xs"
              :x-small="$vuetify.breakpoint.xs"
              v-on="{ ...onMenu, ...onTooltip }"
            >
              <v-icon
                :left="!$vuetify.breakpoint.xs"
              >
                mdi-database-plus
              </v-icon>
              <span class="d-none d-sm-block">{{ $t("AddProperty") }}</span>
            </v-btn>
          </template>
          <span>{{ $t("AddPropertyTootltip") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item @click="$parent.$emit('on-add')">
          <v-list-item-icon>
            <v-icon class="me-2">
              mdi-database-plus
            </v-icon>
            <v-list-item-title class="overline">
              {{ $t('NewProperty') }}
            </v-list-item-title>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="(propertiesStore.showModelsDialog($router.currentRoute.params.id))">
          <v-list-item-icon>
            <v-icon class="me-2">
              mdi-database-eye
            </v-icon>
            <v-list-item-title class="overline">
              {{ $t('ModelsProperties') }}
            </v-list-item-title>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { usePropertiesStore } from '@/stores/properties.store'
import { useModelsStore } from '@/stores/models.store'

export default {

  setup() {
    const propertiesStore = usePropertiesStore()
    const modelsStore = useModelsStore()

    return {
      propertiesStore,
      modelsStore,
    }
  },
}
</script>
