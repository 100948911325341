import { defineStore, acceptHMRUpdate } from 'pinia'
import { useChatsStore } from '@/stores/chats.store'
import { required } from '@core/utils/validation'
import { supabase } from '@/plugins/supabase'
import { canACL } from '@/plugins/acl/checkaccess'
import { TABLE_MODELS, displayMode } from '@core/utils'

const slugify = require('slugify')

// eslint-disable-next-line import/prefer-default-export
export const useModelsStore = defineStore({
  id: TABLE_MODELS,
  state: () => ({
    models: [],
    model: null,
  }),
  actions: {

    getHeader(payload, item) {
      return {
        title: 'Models',
        icon: 'mdi-shape',
        name: ['name'],
        folder: payload?.folder,
        display: displayMode(TABLE_MODELS, 'grid'),
        newData: 'NewModel',
        actionData: 'EditModels',
        editData: 'EditModel',
        search: 'SearchModel',
        searchIcon: 'mdi-magnify',
        add: {
          text: 'AddModel',
          icon: 'mdi-shape-plus',
        },
        actions: [{
          title: 'Save', icon: 'mdi-content-save-outline', action: 'save', loading: false, acl: 'create,update',
        }, {
          title: 'Duplicate',
          icon: 'mdi-content-duplicate',
          action: 'duplicate',
          acl: 'create',
          disabled: !item?.created_at,
        },
        {
          title: 'Delete',
          icon: 'mdi-delete-outline',
          action: 'delete',
          acl: 'delete',
          color: 'error',
          disabled: !item?.created_at,
        }],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['updated_at'],
        sortDesc: [true],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: -1,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
          search: true,
        },
        {
          text: 'Data',
          value: 'objects',
          type: 'button',
          filterable: false,
          sortable: false,
          action: 'view__objects',
          icon: 'mdi-magnify',
        },
        {
          text: 'Properties',
          value: 'properties',
          type: 'button',
          filterable: false,
          sortable: false,
          action: 'view__properties',
          icon: 'mdi-magnify',
        },
        {
          text: 'UpdatedAt',
          value: 'updated_at',
          type: 'date',
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        // general
        name: null,
        slug: null,
        project_id: payload?.project_id,
      }
    },

    getSchema(item, items, payload) {
      const chatsStore = useChatsStore()

      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }

      schema.col1.schema.general = {
        title: 'General',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'px-3 ma-2',
        hidden: !!items.length,
        schema: {
          name: {
            type: 'text',
            label: 'Name',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-format-header-1',
            rules: items.length > 0 ? [] : [required],
          },
          slug: {
            type: 'text',
            label: 'Slug',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-key-outline',
            disabled: true,
          },
        },
      }

      schema.col2.schema.properties = {
        title: 'Properties',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'pa-3 ma-2',
        hidden: items.length > 0,
        schema: {
          view__properties: {
            type: 'btn',
            small: true,
            outlined: true,
            label: 'EditProperties',
            class: 'mb-3',
            iconLeft: 'mdi-pencil',
            color: 'primary',
            offset: 3,
            col: 6,
            disabled: !item?.id || !canACL('view', 'properties', { id: payload?.project_id }),
          },
        },
      }

      schema.col2.schema.models = {
        title: 'Data',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'pa-3 ma-2',
        hidden: item?.slug === 'contacts' || items.length > 0,
        schema: {
          view__objects: {
            type: 'btn',
            label: 'EditData',
            outlined: true,
            small: true,
            class: 'mb-3',
            iconLeft: 'mdi-pencil',
            color: 'primary',
            offset: 3,
            col: 6,
            disabled: !item?.id || !canACL('view', 'objects', { id: payload?.project_id }),
          },
        },
      }

      if (item?.id) {
        schema.col3.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col3.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col3.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          label: 'UpdatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }

      return schema
    },

    async onAction(action, payload) {
      return payload?.item
    },

    async getModels(projectId) {
      const { data, error } = await supabase.from(TABLE_MODELS).select('*').eq('project_id', projectId)
      if (error) {
        return Promise.reject(error)
      }
      this.models = data
      this.model = data.find(f => f.slug === 'contacts')

      return this.models
    },

    async getModelSlug(projectId, slug) {
      const { data, error } = await supabase.from(TABLE_MODELS).select('*').match({ project_id: projectId, slug }).single()
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    onChangeName(name, item) {
      if (!item?.created_at) {
        if (name) {
          item.slug = slugify(name, {
            replacement: '-', // replace spaces with replacement character, defaults to `-`
            remove: /[^a-zA-Z0-9 ]/g, // remove characters that match regex, defaults to `undefined`
            lower: true, // convert to lower case, defaults to `false`
            strict: false, // strip special characters except replacement, defaults to `false`
            trim: true, // trim leading and trailing replacement chars, defaults to `true`
          })
        } else {
          // table.value.item.id = ''
        }
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModelsStore, import.meta.hot))
}
