import axios from 'axios'

const defaultThemeConfig = {
  app: {
    name: 'MICLEO',
    copyright: 'MICLEO',
    version: '1.0.0',
    description:
      "Accelerate your SaaS / CRM development with the | 's scalable and production ready template / boilerplate / starter kit.",
    logo: require('@/assets/images/svg/logo.png'),
    logoDark: require('@/assets/images/svg/logo-dark.png'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    skinVariant: 'semi-dark', // default, bordered, semi-dark
    contentWidth: 'content', // boxed, content
    gtag: 'G-G7B6GXP1LX',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false, // mini menu
    verticalMenuAccordion: true,
    groupChildIcon: 'mdi-checkbox-blank-circle-outline',
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    type: 'static', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'static', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#274dd2', // '#274dd2'
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
    dark: {
      primary: '#274dd2',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
  },
}

// eslint-disable-next-line import/no-mutable-exports
let config = {
  url: null,
  theme: defaultThemeConfig,
  tokens: {
    SUPABASE_ANON: null,
  },
}
try {
  const remoteConfig = await axios.post(
    `${process.env.VUE_APP_MICLEO_URL}/app/${window.location.hostname}`,
  )
  if (Object.keys(remoteConfig?.data?.tokens).length === 0) throw Error('missing keys')
  config = remoteConfig?.data
  console.log(`Config loaded: ${config?.theme?.app.name}`)
} catch (err) {
  console.log(`Config error: ${err.response?.data?.message || err.message}`)
}

// eslint-disable-next-line import/prefer-default-export
export const appConfig = config
