<template>
  <app-card-actions action-refresh
                    :loading="loading"
                    @refresh="onRefresh"
  >
    <template slot="title">
      <h5 class="font-weight-bold text-uppercase">
        {{ $t("UsageSummary") }}
      </h5>
    </template>

    <v-card flat
            class="my-0"
    >
      <v-card-text>
        <v-row>
          <v-col v-for="(item) in statisticsData"
                 v-show="$canACL('view', item.to, organization) && subscription.price.product.metadata"
                 :key="item.avatar"
                 cols="12"
                 md="6"
          >
            <div
              class="d-flex align-start"
            >
              <v-avatar
                rounded
                size="38"
                color="#5e56690a"
                class="me-4"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-avatar>

              <v-row class="d-flex align-center flex-grow-1 flex-wrap">
                <v-col cols="5"
                       md="4"
                >
                  <h4 class="font-weight-medium">
                    {{ item.title }}
                  </h4>
                  <span v-if="item.subtitle"
                        class="text-xs text-no-wrap text-caption"
                  >{{ $t(item.subtitle) }}(s)</span>
                </v-col>

                <v-col cols="7"
                       md="8"
                >
                  <p class="text--primary font-weight-bold mb-1">
                    {{ item.total }} / {{ item.max }} <span v-if="item.subtitle">/ {{ $t(item.subtitle) }}</span>
                  </p>
                  <v-progress-linear
                    :value="item.progress"
                    :color="item.color"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </app-card-actions>
</template>

<script>
import { onMounted, getCurrentInstance, ref } from 'vue'
import AppCardActions from '@/components/AppCardActions.vue'
import { avatarText } from '@core/utils/filter'
import {
  TABLE_ACTIVITIES, TABLE_CONTACTS, TABLE_INTEGRATIONS,
} from '@/@core/utils'
import { useOrganizationsStore } from '@/stores/organizations.store'

export default {
  components: {
    AppCardActions,
  },
  props: {
    subscription: {
      type: Object,
      default: () => {},
    },
    organization: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const organizationsStore = useOrganizationsStore()
    const vm = getCurrentInstance().proxy
    const loading = ref(true)

    const statisticsData = ref([
      {
        title: 'Projects',
        to: 'projects',
        total: '',
        icon: 'mdi-folder-table',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Contacts',
        to: TABLE_CONTACTS,
        total: '',
        icon: 'mdi-account-group',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Members',
        to: 'members',
        total: '',
        icon: 'mdi-shield-account',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Activities',
        subtitle: 'month',
        to: TABLE_ACTIVITIES,
        total: '',
        icon: 'mdi-timeline-check-outline',
        color: 'primary',
        action: 'manage',
      },
      {
        title: 'Integrations',
        to: TABLE_INTEGRATIONS,
        total: '',
        icon: 'mdi-store',
        color: 'primary',
        action: 'manage',
      },
    ])

    const goTo = item => {
      vm.$router.push({
        name: item.to,
        params: {
          id: vm.$router.currentRoute.params.id,
          path: btoa(JSON.stringify([{ t: item.to }])),
          mode: 'view',
          folder: 0,
        },
      })
    }

    const onRefresh = hideOverlay => {
      loading.value = true
      organizationsStore.getBillingUsage(vm.$router.currentRoute.params.id).then(data => {
        if (data?.length) {
          statisticsData.value.forEach(item => {
            item.total = data[0][item.title.toLocaleLowerCase()] || 0
            item.max = props.subscription.price.product.metadata[item.title.toLocaleLowerCase()] || 0
            item.progress = (item.total / item.max) * 100 || 0
            item.activities = data[0].activities
            item.color = (item.total > item.max) ? 'error' : 'primary'
          })
        }

        if (hideOverlay) { hideOverlay() }
        loading.value = false
      })
    }

    onMounted(async () => {
      onRefresh()
    })

    return {
      organizationsStore,
      statisticsData,
      goTo,
      onRefresh,
      avatarText,
      loading,
    }
  },
}
</script>
