<template>
  <div>
    <div>
      <v-snackbar
        v-model="isFormSubmittedSnackbarVisible"
        shaped
        top
        right
      >
        {{ $t('OrganizationCreatedSuccessfully') }}

        <template #action="{ attrs }">
          <v-btn
            icon
            color="secondary"
            v-bind="attrs"
            @click="isFormSubmittedSnackbarVisible = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-stepper
      v-model="activeStep"
      class="custom-header"
    >
      <!-- Header -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
          :rules="[() => stepValidationResult[1]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3"
              :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
            >01</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
              >{{ $t('OrganizationDetails') }}</span>
              <span
                class="text-xs"
                :class="stepValidationResult[1] ? 'text--secondary' : 'error--text'"
              >{{ $t('SetupOrganizationDetails') }}</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <v-form
            ref="step1Form"
            class="pb-6 pt-2"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="organizationData.name"
                  :label="$t('Name')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-home-city"
                  :rules="[validators.required, v => (v || '' ).length <= 25 || $t('FieldMaxLength', {length: 25})]"
                  :placeholder="$t('Name')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="organizationData.website"
                  :label="$t('Website')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-link-variant"
                  :rules="[validators.urlValidator]"
                  placeholder="https://domain.com"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="organizationData.email"
                  :label="$t('Email')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-email-outline"
                  :rules="[validators.required, validators.emailValidator]"
                  placeholder="john@example.com"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <phone-number
                  v-model="organizationData.phone"
                  :phone="organizationData.phone"
                ></phone-number>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-alert
                  v-if="errors.organization"
                  border="left"
                  colored-border
                  text
                  color="error"
                  class="subtitle-2"
                >
                  {{ errors.organization }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-space-between">
            <v-btn
              outlined
              @click="cancel()"
            >
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn
              v-if="organizationData.id"
              color="primary"
              class="me-2"
              :loading="loadingCreateOrga"
              :disabled="loadingCreateOrga"
              @click="handleStepValidation(1)"
            >
              {{ $t("Next") }}
            </v-btn>

            <v-btn
              v-else
              color="primary"
              class="me-2"
              :loading="loadingCreateOrga"
              :disabled="loadingCreateOrga"
              @click="handleStepValidation(1, true)"
            >
              {{ $t("Create") }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import PhoneNumber from '@/components/PhoneNumber.vue'
import { useOrganizationsStore } from '@/stores/organizations.store'
import { useUserStore } from '@/stores/user.store'
import { appConfig } from '@/plugins/micleoConfig'
import {
  alphaDashValidator,
  alphaValidator,
  confirmedValidator,
  emailValidator,
  required,
  urlValidator,
} from '@core/utils/validation'
import {
  getCurrentInstance,
  onMounted, onUnmounted,
  ref,
} from 'vue'

export default {
  metaInfo: {
    title: 'Create Organization',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    PhoneNumber,
  },
  setup() {
    // Stepper's active step
    const activeStep = ref(1)
    const loadingUpload = ref(false)
    const loadingCreateOrga = ref(false)
    const errors = ref({})
    const subscription = ref(null)

    const vm = getCurrentInstance().proxy
    const organizationsStore = useOrganizationsStore()
    const userStore = useUserStore()

    onMounted(async () => {
      await organizationsStore.getPrices()
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    const organizationData = ref({
      name: '',
      email: '',
      website: '',
      phone: '',
      subscription: {
        price: {
          product: {},
        },
      },
      interval: 'month',
      organization: {},
    })

    const isFormSubmittedSnackbarVisible = ref(false)

    // Template refs
    const step1Form = ref(null)

    const stepValidationResult = ref({
      1: true,
    })
    organizationsStore.organization = JSON.parse(JSON.stringify(organizationData.value))

    const cancel = () => {
      organizationsStore.removeOrganization(organizationData.value.organization)
      vm.$router.push({ name: 'organizations' })
    }

    const handleStepValidation = (step, isFinalStep = false) => {
      // Get Form templateRef of Step from argument
      const templateRef = (() => {
        if (step === 1) return step1Form.value

        return null
      })()

      // If no validate step templateRef is found just return
      if (!templateRef) return

      // If step is valid proceed to the next step
      const isStepValid = templateRef.validate()

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid

      if (isStepValid) {
        if (isFinalStep) {
          errors.value = {}
          loadingCreateOrga.value = true
          loadingCreateOrga.value = true
          organizationsStore.createOrganization(organizationData.value)
            .then(async data => {
              organizationData.value.organization = data
              organizationData.value.id = data.id
              subscription.value = await organizationsStore.listenOrganization(data.id)
              userStore.getUser(userStore.user).then(dataUser => {
                userStore.user = dataUser
                loadingCreateOrga.value = false
                isFormSubmittedSnackbarVisible.value = true
                setTimeout(() => vm.$router.push({ name: 'edit-organization', params: { id: data.id, tab: 1 } }), 500)
              })
            })
            .catch(error => {
              loadingCreateOrga.value = false
              errors.value = {
                organization: error.message,
              }
              if (error.response) {
                errors.value = {
                  organization: error.response.data?.error?.message || error.response.data?.message,
                }
              }
            })
        }
      }
    }

    return {
      organizationsStore,
      userStore,
      activeStep,
      organizationData,
      loadingUpload,
      loadingCreateOrga,
      errors,

      // Field Validators
      validators: {
        required,
        alphaDashValidator,
        confirmedValidator,
        alphaValidator,
        urlValidator,
        emailValidator,
      },

      // Step Validations
      stepValidationResult,

      // Template Refs
      step1Form,

      // Snackbar
      isFormSubmittedSnackbarVisible,
      cancel,
      handleStepValidation,
    }
  },
}
</script>
