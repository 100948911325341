<template>
  <div v-if="config">
    <v-card :class="config.tableOptions.height? 'max-h-content-container position-relative overflow-hidden' :'position-relative overflow-hidden'">
      <table-folders
        :config="config"
        :item="table?.item"
        :directory.sync="directory"
        :is-left-sidebar-open.sync="isLeftSidebarOpen"
        @on-refresh="onRefresh"
      ></table-folders>
      <div
        class="h-full d-flex flex-column"
        :style="contentStyles"
      >
        <table-model
          :key="config.path"
          ref="table_model"
          v-model="table"
          :config="config"
          :directory.sync="directory"
          :is-left-sidebar-open.sync="isLeftSidebarOpen"
          @on-refresh="onRefresh"
        ></table-model>
      </div>
    </v-card>
  </div>
</template>

<script>
import TableFolders from '@/views/table/TableFolders.vue'
import TableModel from '@/views/table/TableModel.vue'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { required } from '@core/utils/validation'
import { appConfig } from '@/plugins/micleoConfig'
import {
  getCurrentInstance,
  onMounted, onUnmounted,
  ref,
  watch,
} from 'vue'

import { useFoldersStore } from '@/stores/folders.store'
import { useProjectsStore } from '@/stores/projects.store'
import { usePropertiesStore } from '@/stores/properties.store'
import { useTableStore } from '@/stores/table.store'
import { useUserStore } from '@/stores/user.store'

import { useCampaignsStore } from '@/stores/campaigns.store'
import { useFormsStore } from '@/stores/forms.store'
import { useListingsStore } from '@/stores/listings.store'
import { useModelsStore } from '@/stores/models.store'
import { useTemplatesStore } from '@/stores/templates.store'
import appearances from '@/views/projects/settings/appearances.json'
import {
  TABLE_CAMPAIGNS,
  TABLE_FOLDERS,
  TABLE_FORMS,
  TABLE_LISTINGS,
  TABLE_MODELS, TABLE_PROPERTIES,
  TABLE_TEMPLATES,
  getLastItemID,
} from '@core/utils'

export default {
  metaInfo: {
    title: 'Project',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.name },
    ],
  },
  components: {
    TableModel,
    TableFolders,
  },

  setup() {
    const vm = getCurrentInstance().proxy
    const table = ref(null)
    const config = ref(null)
    const tableStore = useTableStore()
    const errors = ref({})
    const searchQuery = ref(null)
    const loadingAction = ref(false)
    const isAlertVisible = ref(false)
    const subscription = ref(null)
    const directory = ref([])

    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 374 })
    isLeftSidebarOpen.value = false
    const userStore = useUserStore()
    const projectsStore = useProjectsStore()
    const propertiesStore = usePropertiesStore()
    const foldersStore = useFoldersStore()
    const modelsStore = useModelsStore()
    const campaignsStore = useCampaignsStore()
    const listingsStore = useListingsStore()
    const templatesStore = useTemplatesStore()
    const formsStore = useFormsStore()

    const initConfig = () => {
      const step = directory.value.length - 1 > 0 ? directory.value.length - 1 : 0
      const currentDirectory = directory.value[step]
      const routeName = tableStore.route.name
      const item = table.value?.item
      const currentTable = table.value?.currentTable
      const subTable = directory.value[step - 2]?.table || currentDirectory?.table
      const items = table.value?.items || []
      const isExport = table.value?.isExport
      const isImport = table.value?.isImport
      const folder = currentDirectory?.folder

      if (currentTable === TABLE_PROPERTIES) {
        appearances.forEach(it => {
          it.disabled = propertiesStore.isDisabledAppearance(it, item)
        })
        propertiesStore.onChangeType(item?.type, item)
        propertiesStore.onChangeName(item?.name, item)
      }
      if (currentTable === TABLE_MODELS) {
        modelsStore.onChangeName(item?.name, item)
      }
      if (currentTable === TABLE_FORMS) {
        formsStore.onChangeName(item?.name, item)
      }
      if (currentTable === TABLE_CAMPAIGNS) {
        campaignsStore.onRecipients(item)
      }
      if (currentTable === TABLE_LISTINGS) {
        listingsStore.onChangeGroup(item)
      }
      if (currentTable === TABLE_TEMPLATES) {
        templatesStore.onChangeSMSContent(item)
      }

      const schema = tableStore.getSchema(currentTable, items, item, {
        folders: propertiesStore.folders, project_id: tableStore.route.params.id, properties: propertiesStore.properties, isLeftSidebarOpen: isLeftSidebarOpen.value,
      })
      const model = tableStore.getModel(currentTable, {
        folder_id: folder?.id || foldersStore.root?.id, project_id: tableStore.route.params.id, properties: propertiesStore.properties, item: getLastItemID(directory.value), model_id: getLastItemID(directory.value),
      })
      const header = tableStore.getHeader(currentTable, { folder, properties: propertiesStore.properties }, item)
      const subheader = currentTable ? tableStore.getHeader(currentTable) : null
      const tableOptions = tableStore.getTableOptions(currentTable)
      const tableColumns = tableStore.getTableColumns(currentTable, { properties: propertiesStore.properties })

      let query = {}
      if (folder) { query = folder.query }
      const matchQuery = tableStore.getMatchQuery(subTable, currentTable, { query, item: getLastItemID(directory.value) })

      config.value = {
        project_id: vm.$router.currentRoute.params.id,
        path: routeName,
        currentTable,
        folder,
        selectQuery: currentTable ? tableStore.getSelectQuery(folder?.table || currentTable) : tableStore.getSelectQuery(routeName),
        // eslint-disable-next-line no-nested-ternary
        matchQuery,
        isLeftSidebarOpen: isLeftSidebarOpen.value,
        header,
        subheader,
        model,
        schema,
        tableColumns,
        tableOptions,
        isExport,
        isImport,
      }
      tableStore.config = config.value
    }
    const onRefresh = () => {
      if (!vm.$refs.table_model) return
      vm.$refs.table_model.listTable = []
      vm.$refs.table_model.loading = true

      setTimeout(() => {
        vm.$refs.table_model?.fetchDatas()
      }, 0)
    }

    onUnmounted(() => {
      tableStore.config = null
      userStore.removeChannel(subscription.value)
    })

    onMounted(async () => {
      if (vm.$router.currentRoute.name === TABLE_FOLDERS) {
        isLeftSidebarOpen.value = true
      }
      subscription.value = await projectsStore.listenProject(vm.$router.currentRoute.params.id)
      initConfig()
    })

    let loop = null
    watch(table, () => {
      if (!loop || new Date().getTime() - loop.getTime() > 50) {
        initConfig()
        loop = new Date()
      }
    }, { deep: true })

    watch(isLeftSidebarOpen, () => {
      if (!loop || new Date().getTime() - loop.getTime() > 50) {
        initConfig()
        loop = new Date()
      }
    }, { deep: true })

    return {
      config,
      table,
      isLeftSidebarOpen,
      contentStyles,
      searchQuery,
      validators: {
        required,
      },
      errors,
      loadingAction,
      isAlertVisible,
      onRefresh,
      directory,
      propertiesStore,
    }
  },
}
</script>
