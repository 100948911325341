<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mx-5 mt-2">
        <h1>{{ $t('AllWorkspaces') }} </h1>
        <v-spacer></v-spacer>
        <div :class="$vuetify.breakpoint.xs? 'mt-3 d-flex align-center':'d-flex align-center'">
          <v-text-field
            v-model="searchQuery"
            class="me-3 search-table"
            single-line
            dense
            outlined
            hide-details
            :placeholder="$t('Search')"
            clearable
            prepend-inner-icon="mdi-folder-search"
            clear-icon="mdi-close"
          ></v-text-field>
          <v-tooltip
            open-delay="500"
            top
          >
            <template #activator="{ on: onTooltip }">
              <v-btn
                elevation="2"
                color="primary"
                :disabled="!$canACL('create', 'workspaces')"
                v-on="onTooltip"
                @click="$router.push({ name: 'create-workspace' })"
              >
                <v-icon>
                  mdi-folder-plus
                </v-icon>
              </v-btn>
            </template>
            <span> {{ $t('CreateWorkspace') }}</span>
          </v-tooltip>
        </div>
      </v-row>
      <v-row class="mt-10">
        <card-workspace
          v-for="workspace in listTable"
          :key="workspace.id"
          :workspace="workspace"
        >
        </card-workspace>
        <v-col
          v-if="!loading && (!listTable|| listTable.length === 0)"
          lg="3"
          sm="4"
          cols="12"
        >
          <v-card
            height="100%"
            min-height="250"
            min-width="300"
            justify-center
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-btn
              elevation="2"
              color="primary"
              :disabled="!$canACL('create', 'workspaces')"
              @click="$router.push({ name: 'create-workspace' })"
            >
              <v-icon class="me-1">
                mdi-folder-plus
              </v-icon>
              {{ $t('CreateWorkspace') }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-pagination
        v-model="page"
        class="mt-10"
        :length="nbPages"
      ></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
import { useUserStore } from '@/stores/user.store'
import { useWorkspacesStore } from '@/stores/workspaces.store'
import CardWorkspace from '@/views/workspaces/CardWorkspace.vue'
import useWorkspacesList from '@/views/workspaces/useWorkspacesList'
import { appConfig } from '@/plugins/micleoConfig'
import {
  onBeforeMount,
  onMounted, onUnmounted,
  ref,
} from 'vue'

export default {
  metaInfo: {
    title: 'Workspaces',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    CardWorkspace,
  },
  setup() {
    const subscription = ref(null)

    const userStore = useUserStore()
    const workspaceStore = useWorkspacesStore()

    const {
      listTable, totalListTable, searchQuery, page, nbPages, loading,
    } = useWorkspacesList()

    onBeforeMount(async () => {
    })

    onMounted(async () => {
      workspaceStore.workspace = null
      subscription.value = await workspaceStore.listenWorkspaces()
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    return {
      searchQuery,
      page,
      listTable,
      totalListTable,
      nbPages,
      loading,
    }
  },
}
</script>
<style lang="scss" scoped></style>
