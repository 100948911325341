<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          v-if="$vuetify.theme.isDark && appLogoDark || !$vuetify.theme.isDark && appLogo"
          :src="$vuetify.theme.isDark ? appLogoDark: appLogo"
          max-height="30px"
          max-width="30px"
          :alt="appName"
          contain
        ></v-img>

        <h2 class="ml-1 font-weight-semibold appName">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex align-center h-full pa-16 pe-0 mr-16">
              <v-img
                contain
                max-width="100%"
                height="500"
                :src="require(`@/assets/images/contacts-large-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          cols="12"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <!-- title -->
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t('WelcomeSignup') }}
                  </p>
                  <p class="mb-2">
                    {{ $t('WelcomeSignupDetails') }}
                  </p>
                </v-card-text>

                <v-card-text>
                  <v-alert
                    v-model="isAlertVisible"
                    border="left"
                    colored-border
                    text
                    color="primary"
                    icon="mdi-email-open-outline"
                  >
                    <p class="text-base font-weight-medium mb-1">
                      {{ $t('ConfirmYourEmailSignup') }}
                    </p>
                    <v-btn
                      class="mt-3 mb-1"
                      small
                      color="primary"
                      dark
                      :loading="loading"
                      :error-messages="errors.email != '' ? $t(errors.email) : errors.email"
                      @click.prevent="register"
                    >
                      {{ $t('ResendConfirmation') }}
                    </v-btn>

                    <p
                      v-if="errors.email"
                      class="caption mt-3 mb-1"
                    >
                      {{ errors.email }}
                    </p>
                  </v-alert>
                </v-card-text>

                <!-- register form -->
                <v-card-text v-if="isAlertVisible===false">
                  <v-form
                    ref="registerForm"
                    @submit.prevent="onSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      prepend-inner-icon="mdi-email-outline"
                      :error-messages="errors.email != '' ? $t(errors.email) : errors.email"
                      :rules="[validators.required, validators.emailValidator]"
                      outlined
                      :label="$t('Email')"
                      placeholder="john@example.com"
                      hide-details="auto"
                      class="mb-3"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      prepend-inner-icon="mdi-form-textbox-password"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :error-messages="errors.password != '' ? $t(errors.password) : errors.password"
                      :rules="[validators.required, validators.passwordValidator]"
                      :label="$t('Password')"
                      :append-icon="isPasswordVisible ? 'mdi-eye-off-outline':'mdi-eye-outline'"
                      hide-details="auto"
                      class="mb-3"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <vue-hcaptcha
                      v-if="options.sitekey && false"
                      ref="invisibleHcaptcha"
                      :sitekey="options.sitekey"
                      :theme="$vuetify.theme.isDark ? 'dark': 'light'"
                      size="normal"
                      @verify="onVerify"
                      @expired="onExpire"
                      @challengeExpired="onChallengeExpire"
                      @error="onError"
                    ></vue-hcaptcha>
                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="loading"
                      :disabled="options.url.indexOf('localhost')<0 && options.sitekey && (loading || !options.verified)"
                    >
                      {{ $t('SignUp') }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- divider -->
                <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">{{ $t('Or') }}</span>
                  <v-divider></v-divider>
                </v-card-text>

                <v-card-actions class="d-flex justify-center">
                  <AuthProvider />
                </v-card-actions>
                <v-card-text class="d-flex align-center justify-center flex-wrap">
                  <span class="caption mb-0">
                    {{ $t('SignupTermsPolicy') }}
                  </span>
                </v-card-text>

                <v-divider></v-divider>
                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <span class="me-2">
                    {{ $t('AlreadyHaveAccount') }}
                  </span>
                  <router-link :to="{name:'auth-login'}">
                    {{ $t('SignInInstead') }}
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { hcaptcha } from '@/plugins/hcaptcha'
import { redirectTo } from '@/plugins/supabase'
import { useUserStore } from '@/stores/user.store'
import { emailValidator, passwordValidator, required } from '@core/utils/validation'
import { appConfig } from '@/plugins/micleoConfig'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import { getCurrentInstance, ref } from 'vue'
import AuthProvider from '../views/authentification/AuthProvider.vue'

export default {
  metaInfo: {
    title: 'Register',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    AuthProvider,
    VueHcaptcha,
  },

  setup() {
    const registerForm = ref(null)
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const optin = ref('')
    const errors = ref({
      email: '',
      password: '',
    })
    const isDialogVisible = ref(false)
    const isAlertVisible = ref(false)
    const loading = ref(false)
    const vm = getCurrentInstance().proxy

    const options = ref({
      url: redirectTo,
      sitekey: hcaptcha,
      verified: false,
      expired: false,
      token: null,
      eKey: null,
      error: null,
    })

    const userStore = useUserStore()

    const onVerify = (token, ekey) => {
      options.value.verified = true
      options.value.token = token
      options.value.eKey = ekey
    }

    const onExpire = () => {
      options.value.verified = false
      options.value.token = null
      options.value.eKey = null
      options.value.expired = true
    }

    const onChallengeExpire = () => {
      options.value.verified = false
      options.value.token = null
      options.value.eKey = null
      options.value.expired = true
    }

    const onError = err => {
      options.value.token = null
      options.value.eKey = null
      options.value.error = err
    }

    const register = () => {
      loading.value = true
      errors.value = {
        email: '',
        password: '',
      }
      userStore.register(email.value, password.value, options.value.token)
        .then(userData => {
          loading.value = false
          if (userData.session) {
            vm.$router.push('/')
          } else {
            isAlertVisible.value = true
          }
        })
        .catch(err => {
          loading.value = false
          if (err.message.toLowerCase().includes('password')) {
            errors.value.password = err.message
          } else {
            errors.value.email = err.message
          }
        })
    }

    const onSubmit = () => {
      const isValid = registerForm.value?.validate()
      if (isValid) { register() }
    }

    return {
      onSubmit,
      register,
      options,
      isPasswordVisible,
      isAlertVisible,
      email,
      password,
      optin,
      errors,
      loading,
      isDialogVisible,

      // appConfig.theme
      appName: appConfig.theme.app.name,
      appLogo: appConfig.theme.app.logo,
      appLogoDark: appConfig.theme.app.logoDark,
      validators: {
        required,
        emailValidator,
        passwordValidator,
      },
      registerForm,
      onVerify,
      onError,
      onExpire,
      onChallengeExpire,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
