<template>
  <v-card
    class="text-center"
    :outlined="subscription && subscription.id ? false : true"
  >
    <v-card-text>
      <!-- chip -->
      <div
        v-show="subscription.price.product.metadata && subscription.price.product.metadata.popular"
        class="pricing-badge text-right mt-n3"
      >
        <v-chip
          small
          color="primary"
          class="v-chip-light-bg primary--text font-weight-semibold"
        >
          {{ $t("Popular") }}
        </v-chip>
      </div>

      <!-- image -->
      <div
        v-if="subscription.price.product.image"
        class="d-flex justify-center"
      >
        <img
          height="50"
          alt="product"
          :src="subscription.price.product.image"
          class="mx-auto mt-3"
        >
      </div>

      <!-- title -->
      <h3 class="font-weight-bold text-uppercase">
        {{ subscription.price.product.name }}
      </h3>
      <p>{{ subscription.price.product.description }}</p>
      <div
        v-if="subscription.price.interval ==='month'"
      >
        <div class="plan-price d-flex align-center justify-center">
          <sup class="text-sm text-primary">{{ getCurrency() }}</sup>
          <span class="pricing-basic-value text-5xl primary--text font-weight-semibold">
            {{ getPrice('month') }}
          </span>
          <sub class="pricing-duration text-sm mb-n3">/{{ $t("month") }}</sub>
        </div>
        <small>{{ getPrice('year') }} {{ getCurrency() }}/{{ $t("year") }}</small>
      </div>

      <div
        v-else
      >
        <div class="plan-price d-flex align-center justify-center">
          <sup class="text-sm text-primary">{{ getCurrency() }}</sup>
          <span class="pricing-basic-value text-5xl primary--text font-weight-semibold">
            {{ getPrice('year') }}
          </span>
          <sub class="pricing-duration text-sm mb-n3">/{{ $t("year") }}</sub>
        </div>
        <small>{{ getPrice('month') }} {{ getCurrency() }}/{{ $t("month") }}</small>
      </div>
    </v-card-text>

    <v-card-text>
      <div class="pb-2">
        <div
          v-for="benefits in subscription.price.product.planBenefits"
          :key="benefits"
          class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
        >
          <v-icon
            size="14"
            class="me-2 mt-1"
          >
            'mdi-checkbox-blank-circle-outline'
          </v-icon>
          <span class="text-sm text--secondary">{{ benefits }}</span>
        </div>
      </div>
      <v-btn
        block
        outlined
        color="primary"
        :loading="loading"
        :disabled="!$canACL('update', 'subscriptions', organization) || loading"
        @click="$emit('open-portail', 'upgrade')"
      >
        <v-icon
          left
        >
          mdi-calendar-import
        </v-icon>
        {{ $t("UpgradePlan") }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  model: {
    prop: 'projectData',
    event: 'update:project',
  },
  props: {
    subscription: {
      type: Object,
      default: () => {},
    },
    organization: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const getPrice = interval => {
      if (props.subscription.price.unit_amount === 0) return 0

      if (props.subscription.price.interval === interval) return props.subscription.price.unit_amount / 100
      if (interval === 'year' && props.subscription.price.interval === 'month') return (props.subscription.price.unit_amount * 12) / 100
      if (interval === 'month' && props.subscription.price.interval === 'year') return ((props.subscription.price.unit_amount / 12) / 100).toFixed(2)

      return (props.subscription.price.unit_amount) / 100
    }

    const getCurrency = () => {
      if (props.subscription.price.currency === 'eur') { return '€' }
      if (props.subscription.price.currency === 'usd') { return '$' }

      return '$'
    }

    return {
      getPrice,
      getCurrency,
    }
  },
}
</script>

<style lang="">
</style>
