import vFormBase from '@/components/vFormBase.vue'
import '@/styles/styles.scss'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Clipboard from 'v-clipboard'
import Vue from 'vue'
import VueCryptoJs from 'vue-cryptojs'
import vueCustomElement from 'vue-custom-element'
import VueGtag from 'vue-gtag'
import { VueMasonryPlugin } from 'vue-masonry'
import Meta from 'vue-meta'
import VueObserveVisibility from 'vue-observe-visibility'
import VuePapaParse from 'vue-papa-parse'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import VueIframe from 'vue-iframes'
import { appConfig } from '@/plugins/micleoConfig'
import App from './App.vue'
import './plugins/acl'
import { i18n } from './plugins/i18n'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.use(Meta)
Vue.use(vueCustomElement)
Vue.use(PiniaVuePlugin)
Vue.use(VuePapaParse)
const pinia = createPinia()

Vue.use(pinia)
Vue.use(VueMasonryPlugin)
Vue.use(VueTelInputVuetify, {
  vuetify,
})
Vue.config.productionTip = false
Vue.use(VueCryptoJs)
Vue.component('VFormBase', vFormBase)
Vue.use(VueGtag, {
  config: { id: appConfig?.app?.gtag },
})
Vue.use(Clipboard)
Vue.use(VueObserveVisibility)
Vue.use(VueIframe)
Vue.prototype.$moment = moment

/*
new Vue({
  router,
  i18n,
  vuetify,
  pinia,
  render: h => h(App),
}).$mount('#app')
*/

App.i18n = i18n
App.pinia = pinia
App.vuetify = vuetify
Vue.customElement('widget-micleo', App)

App.router = router
App.i18n = i18n
App.pinia = pinia
App.vuetify = vuetify
Vue.customElement('app-micleo', App)

// ok
