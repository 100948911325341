var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],staticClass:"pa-3 mt-2"},[_c('alert-error',{attrs:{"is-alert-visible":_vm.isAlertVisible,"error":_vm.errors.token},on:{"update:isAlertVisible":function($event){_vm.isAlertVisible=$event},"update:is-alert-visible":function($event){_vm.isAlertVisible=$event}}}),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-card',[_c('v-card-title',[_c('div',[_c('h5',{staticClass:"font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('Logs'))+" ")])]),_c('v-spacer'),_c('v-btn',{staticClass:"me-3",attrs:{"icon":"","color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.fetchDatas()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-divider',{staticClass:"mx-5"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex flex-grow-1 align-center pb-3"})]),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.listTable,"options":_vm.options,"server-items-length":_vm.totalListTable,"loading":_vm.loading,"single-select":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('table-cell-item',{attrs:{"filter":false,"source":{
              type: 'chip-type',
              value: item.status,
              text: item.status,
            }}})]}},{key:`item.payload`,fn:function({ item }){return [_c('table-cell-item',{attrs:{"filter":false,"source":{
              type: 'json',
              value: item.payload,
              text: item.payload,
            }}})]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('lll'))+" ")]}},{key:`item.updated_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).format('lll'))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{staticClass:"me-3",attrs:{"x-small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.editDialog(item)}}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.isAddDialogVisible),callback:function ($$v) {_vm.isAddDialogVisible=$$v},expression:"isAddDialogVisible"}},[(_vm.log)?_c('v-card',{staticClass:"pa-sm-6 pa-3"},[_c('v-card-title',{staticClass:"justify-center text-h5 text-uppercase"},[_vm._v(" "+_vm._s(_vm.log.name)+" ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[(_vm.log.message)?_c('vue-json-pretty',{attrs:{"data":_vm.log.message}}):_vm._e(),_c('div',{staticClass:"mt-6 d-flex justify-center"},[(false)?_c('v-btn',{staticClass:"me-3",attrs:{"loading":_vm.loadingAction,"disabled":_vm.loadingAction,"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")]):_vm._e(),_c('v-btn',{attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){_vm.isAddDialogVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }