<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/valid-v-for -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div :style="`min-height: ${height}px !important;`">
    <v-progress-linear v-if="loading"
                       indeterminate
    ></v-progress-linear>
    <div
      class="align-center flex-wrap pb-0 mx-3 d-flex"
    >
      <div class="d-flex flex-grow-1 align-center pb-3"></div>
      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-3">
        <v-select
          v-model="config.tableOptions.sortBy[0]"
          outlined
          dense
          hide-details
          :items="config.tableColumns.filter(c => c.type.indexOf('date') >= 0)"
          prepend-inner-icon="mdi-magnify"
          :label="$t('SortBy')"
          class="me-3"
        >
          <template #selection="data">
            {{ $t(data.item.text) }}
          </template>
          <template #item="data">
            {{ $t(data.item.text) }}
          </template>
        </v-select>
        <v-btn-toggle
          v-model="config.tableOptions.sortDesc[0]"
          mandatory
        >
          <v-btn
            depressed
            :value="false"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn
            depressed
            :value="true"
          >
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <v-row class="my-2"
           no-gutters
           justify="center"
           style="zoom: 0.7;"
    >
      <v-col cols="12"
             md="10"
      >
        <v-timeline :dense="$vuetify.breakpoint.smAndDown"
                    class="ma-0 pa-0"
        >
          <v-timeline-item
            v-for="(item, index) in listTable"
            color="primary"
            small
            class="px-0 ma-0"
            :right="index % 2 === 0"
          >
            <span slot="opposite"><span class="text-caption">{{ $moment(item[config.tableOptions.sortBy[0] || 'updated_at']).fromNow() }} / {{ $moment(item[config.tableOptions.sortBy[0] || 'updated_at']).format("lll") }}</span></span>
            <v-alert
              border="left"
              colored-border
              text
              outlined
              color="primary"
              class="subtitle-2 cursor-pointer"
              @click="$emit('on-edit', item)"
            >
              <v-simple-checkbox
                v-if="item?.id?.toString().indexOf('_') === -1"
                style="position: absolute; top: 8px; right: -6px"
                color="primary"
                :value="
                  !!items.find(
                    it => it[config.tableOptions.itemKey || 'id'] === item[config.tableOptions.itemKey || 'id'],
                  )
                "
                @click="$emit('toggle-select', item)"
              ></v-simple-checkbox>

              <div>
                <template v-for="header in getHeaders(item)">
                  <v-row no-gutters
                         class="d-flex text-left"
                  >
                    <v-col cols="12"
                           md="4"
                    >
                      <span class="font-weight-bold text-overline">{{ $t(header.text) }}:</span>
                    </v-col>
                    <v-col cols="12"
                           md="8"
                           class="pt-1"
                    >
                      <table-cell-item
                        :header="header"
                        :item="item"
                        :filter="false"
                        :source="{
                          type: header.type.replace('text-button', 'text-uppercase'),
                          value: item[header.value]?.name || item[header.value],
                          text: item[header.value]?.name || item[header.value],
                        }"
                        @on-click="onClick"
                      >
                      </table-cell-item>
                    </v-col>
                    <v-col cols="12">
                      <v-divider class="my-2"></v-divider>
                    </v-col>
                  </v-row>
                </template>
              </div>
            </v-alert>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>

    <div v-if="totalTable > 0"
         class="d-flex justify-space-between ma-4"
    >
      <div class="flex-grow-1 flex-shrink-0">
        <v-row justify="center"
               no-gutters
        >
          <v-col cols="6">
            <v-pagination
              v-model="options.page"
              :length="totalTable ? Math.ceil(totalTable / options.itemsPerPage) : 0"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
      <v-select
        v-model="options.itemsPerPage"
        class="flex-grow-0 flex-shrink-0"
        :items="[config.tableOptions.itemsPerPage, 10, 12, 20, 100, 1000, -1]"
        outlined
        dense
        hide-details
        style="width: 90px"
      ></v-select>
    </div>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import useAppConfig from '@/stores/appconfig'
import TableCellItem from '@/views/table/TableCellItem.vue'
import TableFilterItem from '@/views/table/TableFilterItem.vue'
import { useFilesStore } from '@/stores/files.store'

export default {
  components: {
    TableCellItem,
  },
  props: {
    config: {
      type: Object,
      default: null,
      required: true,
    },
    options: {
      type: Object,
      default: null,
      required: true,
    },
    listTable: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totalTable: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  setup(props, { emit }) {
    const filesStore = useFilesStore()
    const item = useVModel(props, 'item', emit)
    const config = useVModel(props, 'config', emit)
    const options = useVModel(props, 'options', emit)
    const isDelete = useVModel(props, 'isDelete', emit)
    const listTable = useVModel(props, 'listTable', emit)
    const items = useVModel(props, 'items', emit)
    const loading = useVModel(props, 'loading', emit)

    const { appContentLayoutNav } = useAppConfig()

    const getGrid = () => {
      if (appContentLayoutNav.value === 'horizontal') {
        return config.value.isLeftSidebarOpen ? 3 : 2
      }

      return config.value.isLeftSidebarOpen ? 4 : 3
    }

    const getImageCol = () => config.value.tableColumns.find(h => h.type.indexOf('file') >= 0)

    const getHeaders = () => {
      const headers = []
      config.value.tableColumns
        .filter(h => !h.hidden && h.type !== 'actions')
        .forEach(header => {
          headers.push(header)
        })

      return headers
    }

    const getDetails = it => {
      const headers = []
      config.value.tableColumns
        .filter((h, index) => h.type.indexOf('file') === -1 && h.type !== 'actions' && h.type !== 'date' && index >= 3)
        .forEach(header => {
          if (it[header.value] && JSON.stringify(it[header.value]) !== '{}') {
            headers.push(header)
          }
        })

      return headers
    }

    const onClick = (val, _item) => {
      emit('on-click', val, _item)
    }

    return {
      item,
      options,
      isDelete,
      listTable,
      items,
      loading,
      getImageCol,
      filesStore,
      appContentLayoutNav,
      getGrid,
      getHeaders,
      getDetails,
      onClick,
    }
  },
  data() {
    return {
      filterItem: TableFilterItem,
    }
  },
}
</script>
