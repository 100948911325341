<template>
  <v-card
    v-if="chat"
    outlined
    class="chat-contact d-flex align-center pa-3 cursor-pointer rounded-lg"
    :class="{'bg-gradient-primary active-chat-contact': isActive}"
    v-on="$listeners"
  >
    <div
      class="v-avatar-group me-2"
    >
      <avatar-member
        v-for="admin in getAdmins(chat.members)"
        :key="admin.id+''+admin.online"
        :member="admin"
        small
      ></avatar-member>
    </div>
    <div class="flex-grow-1 overflow-hidden">
      <p class="ma-0 pa-0 font-weight-medium text--primary text-overline text-no-wrap">
        {{ chat.name }}
      </p>
      <p
        v-if="chat.last_message"
        class="text--disabled ma-0 pa-0 text-truncate text-caption"
      >
        {{ chat.last_message }}
      </p>
    </div>
    <div
      class="d-flex flex-column align-self-stretch align-end pa-0 ma-0"
    >
      <v-badge
        :color="getUnseenMessages()?'primary':''"
        inline
        :content="getUnseenMessages()"
        class="number-badge"
      >
      </v-badge>
      <p
        v-if="chat.last_message"
        style="font-size:0.65rem !important; max-width: 100px;"
        class="ma-0 mt-2 px-2 py-0 text-caption text-truncate"
      >
        {{ $moment(chat.last_message_time).fromNow() }}
      </p>
    </div>
  </v-card>
</template>

<script>
import { formatDateToMonthShort, avatarText } from '@core/utils/filter'
import AvatarMember from '@/components/AvatarMember.vue'

export default {
  components: {
    AvatarMember,
  },
  props: {
    chat: {
      type: Object,
      default: null,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const getUnseenMessages = () => {
      if (!props.chat.members) { return null }
      const member = props.chat.members.find(item => item.admin_id === props.user.id)

      if (member) {
        return member.unseen_messages
      }

      return null
    }
    const getAdmins = members => {
      const admins = []
      members.forEach(item => {
        const admin = props.contacts.find(contact => contact.id === item.admin_id)
        if (admin) { admins.push(admin) }
      })

      return admins
    }

    return {
      formatDateToMonthShort, avatarText, getUnseenMessages, getAdmins,
    }
  },
}
</script>

<style>
</style>
