<template>
  <v-container
    class="ma-0 pa-0 overflow-hidden"
    fluid
    :style="item.metadata.mimetype !== 'application/pdf'? style : null"
  >
    <v-snackbar
      v-model="isSaved"
      outlined
      text
      color="primary"
      shaped
      bottom
      right
    >
      {{ $t('SavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-img
      v-if="item.metadata.mimetype.indexOf('image') >= 0"
      :max-height="height"
      :size="height"
      contain
      class="mt-3"
      :src="filesStore.getUrl(item)"
    ></v-img>
    <VueDocPreview v-else-if="item.metadata.mimetype.indexOf('office') >= 0 || item.metadata.mimetype.indexOf('excel') >= 0"
                   :value="filesStore.getUrl(item)"
                   type="office"
    />
    <vue-pdf-embed v-else-if="item.metadata.mimetype === 'application/pdf'"
                   :source="filesStore.getUrl(item)"
    />
    <a v-else
       :href="filesStore.getUrl(item)"
    >
      <v-img
        :max-height="height"
        :size="height"
        contain
        class="mt-3"
        :src="filesStore.getThumbnail(`${item?.bucket_id}/${item?.project_id}/${item?.folder_id}/${item?.name}`, item?.metadata?.mimetype)"
      ></v-img></a>
  </v-container>
</template>
<script>
import { ref, computed } from 'vue'
import { useVModel } from '@vueuse/core'
import { required } from '@core/utils/validation'
import useAppConfig from '@/stores/appconfig'
import { useFilesStore } from '@/stores/files.store'
import VueDocPreview from 'vue-doc-preview'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    VueDocPreview,
    VuePdfEmbed,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const loadingAction = ref(true)
    const form = ref(null)
    const errors = ref({})
    const isSaved = ref(false)
    const item = useVModel(props, 'item', emit)
    const filesStore = useFilesStore()

    const { isDark } = useAppConfig()

    const height = computed(() => window.innerHeight - 300)
    const style = computed(() => `height: ${window.innerHeight - 260}px;`)
    const docType = computed(() => 'office')

    return {
      form,
      loadingAction,
      item,
      errors,
      validators: {
        required,
      },
      filesStore,
      isSaved,
      isDark,
      height,
      style,
      docType,
    }
  },

  methods: {
  },
}
</script>
