<template>
  <div
    v-if="source.type === 'color'"
    class="d-flex"
  >
    <div
      class="mr-2"
      :style="{
        backgroundColor: source.text,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        position: 'relative',
        borderRadius: '50%',
        border: source.text? '2px solid rgba(94, 86, 105, 0.14)':'',
        transition: 'border-radius 200ms ease-in-out',
      }"
    ></div>{{ source.text }}
  </div>
  <v-chip
    v-else-if="source.type === 'chip-type-properties'"
    color="primary"
    outlined
    class="cursor-pointer"
  >
    <v-icon class="me-3">
      {{ getType(source.text ).icon }}
    </v-icon>{{ $t(getType(source.text ).value) }}
  </v-chip>
  <v-chip
    v-else-if="source.type === 'chip-appearance-properties'"
    color="primary"
    outlined
    class="cursor-pointer"
  >
    <v-icon class="me-3">
      {{ getAppearance(item.appearance || source.value).icon }}
    </v-icon>{{ $t(getAppearance(item.appearance || source.value).value) }}
  </v-chip>
  <div v-else-if="source.type === 'chip-type'">
    <v-chip
      v-if="source.text"
      outlined
      class="text-button cursor-pointer"
      :color="getChipColor(source.text)"
    >
      {{ $t(source.text) }}
    </v-chip>
  </div>
  <span v-else-if="source.type === 'folder'"
        class="text-uppercase"
  >
    {{ getParent(source.value, foldersStore.folders) }}</span>

  <div v-else-if="source.type === 'date' && header.appearance === 'date-picker'">
    <span v-if="source.text">{{ $moment(source.text).format("LL") }}</span>
  </div>
  <div v-else-if="source.type === 'date' && ( header.appearance === 'date-time-picker' || !header.appearance)">
    <span v-if="source.text">{{ $moment(source.text).format("lll") }}</span>
  </div>
  <div v-else-if="source.type === 'date' && header.appearance === 'time-picker'">
    <span v-if="source.text">{{ source.text }} </span>
  </div>
  <div v-else-if="source.type === 'ago'">
    <span v-if="source.text">{{ $moment(source.text).fromNow() }}</span>
  </div>
  <div v-else-if="source.type === 'datetimeago'">
    <span v-if="source.text">{{ $moment(source.text).fromNow() }} / <span class="font-weight-bold">{{ $moment(source.text).format("lll") }}</span></span>
  </div>

  <v-simple-checkbox
    v-else-if="source.type === 'simple-checkbox'"
    :value="parseBoolean(source.text)"
    disabled
    class="pa-0 ma-0"
  ></v-simple-checkbox>
  <div
    v-else-if="source.type === 'array'"
  >
    <v-dialog
      :key="source.text?.length"
      max-width="300px"
    >
      <template #activator="{ on: { click } }">
        <span
          v-if="source.text"
          :key="source.text?.length"
          @click="click"
        >{{ source.text?.length }} {{ $t('items') }}</span>
      </template>
      <v-card>
        <v-list>
          <v-list-item
            v-for="(row, index) in source.text"
            :key="index"
          >
            {{ row?.name || row }}
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
  <v-rating
    v-else-if="source.type === 'number' && header.appearance ==='rating'"
    :value="Number(source.text)"
    :length="header.maximum > 0 ? header.maximum : 5"
    dense
    disabled
    small
  >
  </v-rating>

  <div v-else-if="source.type === 'template'">
    <v-chip v-if="source.text"
            class="text-button cursor-pointer"
    >
      {{ templatesStore.templates?.find(t => t.id === source.value)?.name || source.value }}
    </v-chip>
  </div>
  <span v-else-if="source.type === 'size'">
    {{ source.text?.length || 0 }}</span>
  <div v-else-if="source.type === 'json'">
    <vue-json-pretty
      v-if="source.text && Object.keys(source.text).length > 0"
      :data="source.text"
    />
  </div>
  <div v-else-if="source.type === 'json-beautifier'">
    <v-row v-if="source.text"
           no-gutters
    >
      <v-col v-for="(key, index) in Object.keys(source.text)?.sort()"
             :key="index"
             cols="12"
             md="6"
             class="text-xs"
      >
        {{ $t(key) }} -> <b>{{ source.text[key] }}</b>
      </v-col>
    </v-row>
  </div>
  <div
    v-else-if="source.type?.indexOf('file') >= 0"
  >
    <v-avatar
      :size="getThumbSize(source.type)"
      color="primary"
      class="v-avatar-light-bg primary--text ma-1"
      tile
    >
      <v-img
        v-if="source.type?.indexOf('file') >= 0 && source.value"
        :src="filesStore.getThumbnail(source.value)"
      ></v-img>
      <v-img
        v-else-if="source.type?.indexOf('files') >= 0 && item.property_slug"
        :src="filesStore.getThumbnail(`${item?.bucket_id}/${item?.project_id}/${item?.folder_id}/${item?.property_slug}/${item?.name}`, item?.metadata?.mimetype)"
      ></v-img>
      <v-img
        v-else-if="source.type?.indexOf('files') >= 0 && !item.property_slug"
        :src="filesStore.getThumbnail(`${item?.bucket_id}/${item?.project_id}/${item?.folder_id}/${item?.name}`, item?.metadata?.mimetype)"
      ></v-img>
      <v-icon v-else
              color="primary"
      >
        {{ source.value || header.icon || 'mdi-image-outline' }}
      </v-icon>
    </v-avatar>
  </div>
  <span v-else-if="source.type === 'mimetype'">
    {{ getExtension(source.text) }}</span>

  <div v-else-if="source.type === 'button'">
    <v-btn x-small
           outlined
           class="me-2"
           :color="header.color || 'secondary'"
           @click="$emit('on-click', { key: header.action }, item)"
    >
      <v-icon
        left
      >
        {{ header.icon }}
      </v-icon>
      <span v-if="source.text"
            class="text-button"
      >{{ $t(header.text) }} ({{ source.text }})</span>
      <span v-else
            class="text-button"
      >{{ $t(header.text) }}</span>
    </v-btn>
  </div>
  <div
    v-else
    :class="source.type"
    style="max-width:350px !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
  >
    <span v-if="source.type?.indexOf('translate') >=0">{{ $t(source.value) }}</span>
    <span v-else><span v-if="source.value!=='NULL'">{{ source.value }}</span></span>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueJsonPretty from 'vue-json-pretty'
import { getParent } from '@core/utils/folders'
import { useFoldersStore } from '@/stores/folders.store'
import { useTemplatesStore } from '@/stores/templates.store'
import types from '@/views/projects/settings/types.json'
import appearances from '@/views/projects/settings/appearances.json'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'vue-json-pretty/lib/styles.css'
import { avatarText } from '@core/utils/filter'
import {
  sanatize,
} from '@core/utils/validation'
import { useFilesStore } from '@/stores/files.store'

const mimeDB = require('mime-db')

export default {
  name: 'TableCellItem',
  components: {
    VueJsonPretty,
  },
  props: {
    source: {
      type: Object,
      default() {
        return {}
      },
    },
    header: {
      type: Object,
      default() {
        return {}
      },
    },
    item: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const source = useVModel(props, 'source', emit)
    const foldersStore = useFoldersStore()
    const templatesStore = useTemplatesStore()
    const filesStore = useFilesStore()

    const toggleSelect = () => {
      source.value.isSelected = !source.value.isSelected
      emit('on-change-filter')
    }

    const getChipColor = val => {
      if (val === 'opened' || val === 'open' || val === 'unique_opened' || val === 'click' || val === 'clicked') { return 'success' }
      if (val === 'done' || val === 'sent' || val === 'delivered') { return 'primary' }
      if (val === 'processing' || val === 'bounce' || val === 'unsubscribed') { return 'warning' }
      if (val === 'error' || val === 'hard_bounce' || val === 'blocked') { return 'error' }

      return 'secondary'
    }

    const getType = value => {
      const type = types?.filter(t => t.value === value)

      return type?.length > 0 ? type[0] : {}
    }

    const getAppearance = value => {
      const appearance = appearances?.filter(a => a.value === value)

      return appearance?.length > 0 ? appearance[0] : {}
    }

    const getExtension = type => mimeDB[type]?.extensions?.join(' / ').toUpperCase()

    const getThumbSize = type => {
      if (type?.indexOf('big') >= 0) {
        return '200'
      }
      if (type?.indexOf('medium') >= 0) {
        return '100'
      }
      if (type?.indexOf('small') >= 0) {
        return '50'
      }

      return '50'
    }

    const parseBoolean = val => sanatize('boolean', val)

    return {
      source,
      toggleSelect,
      getChipColor,
      getType,
      getAppearance,
      getParent,
      foldersStore,
      templatesStore,
      filesStore,
      avatarText,
      getExtension,
      getThumbSize,
      parseBoolean,
    }
  },
}
</script>
