// eslint-disable-next-line max-classes-per-file
class Entity {
  constructor(attrs) {
    Object.assign(this, attrs)
  }
}

export class Organizations extends Entity {
  static get modelName() {
    return 'organizations'
  }
}
export class Projects extends Entity {
  static get modelName() {
    return 'projects'
  }
}
export class Activities extends Entity {
  static get modelName() {
    return 'activities'
  }
}
export class Campaigns extends Entity {
  static get modelName() {
    return 'campaigns'
  }
}
export class Contacts extends Entity {
  static get modelName() {
    return 'contacts'
  }
}
export class Folders extends Entity {
  static get modelName() {
    return 'folders'
  }
}
export class Forms extends Entity {
  static get modelName() {
    return 'forms'
  }
}
export class Integrations extends Entity {
  static get modelName() {
    return 'integrations'
  }
}
export class Invoices extends Entity {
  static get modelName() {
    return 'invoices'
  }
}
export class Listings extends Entity {
  static get modelName() {
    return 'listings'
  }
}
export class Logs extends Entity {
  static get modelName() {
    return 'logs'
  }
}
export class Members extends Entity {
  static get modelName() {
    return 'members'
  }
}
export class Models extends Entity {
  static get modelName() {
    return 'models'
  }
}
export class Objects extends Entity {
  static get modelName() {
    return 'objects'
  }
}
export class Properties extends Entity {
  static get modelName() {
    return 'properties'
  }
}
export class PropertiesItems extends Entity {
  static get modelName() {
    return 'properties_items'
  }
}
export class Roles extends Entity {
  static get modelName() {
    return 'roles'
  }
}
export class Subscriptions extends Entity {
  static get modelName() {
    return 'subscriptions'
  }
}
export class Tasks extends Entity {
  static get modelName() {
    return 'tasks'
  }
}
export class Templates extends Entity {
  static get modelName() {
    return 'templates'
  }
}
export class Workflows extends Entity {
  static get modelName() {
    return 'workflows'
  }
}
export class Dashboard extends Entity {
  static get modelName() {
    return 'dashboard'
  }
}
export class Settings extends Entity {
  static get modelName() {
    return 'settings'
  }
}
export class Files extends Entity {
  static get modelName() {
    return 'files'
  }
}
export class Repositories extends Entity {
  static get modelName() {
    return 'repositories'
  }
}
export class Workspaces extends Entity {
  static get modelName() {
    return 'workspaces'
  }
}
