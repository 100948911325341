<script>
import { ref, getCurrentInstance } from 'vue'
import { useUserStore } from '@/stores/user.store'
import { setAbilities } from '@/plugins/acl/ability'
import { useRouter } from '@/@core/utils'

export default {
  setup() {
    const userStore = useUserStore()
    const router = useRouter()
    const vm = getCurrentInstance().proxy
    const loading = ref(false)

    const authProviders = [
      {
        icon: 'mdi-facebook',
        provider: 'facebook',
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: 'mdi-google',
        provider: 'google',
        color: '#db4437',
        colorInDark: '#db4437',
      },
      {
        icon: 'mdi-github',
        provider: 'github',
        color: '#000',
        colorInDark: '#000',
      },
      {
        icon: 'mdi-linkedin',
        provider: 'linkedin_oidc',
        color: '#0073b1',
        colorInDark: '#0073b1',
      },
    ]

    const signInWithAuth = provider => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
      }, 3000)
      userStore.signInWithAuth(provider).then(userData => {
        if (userData !== null) {
          loading.value = false
          vm.$ability.update(setAbilities())
          router.push('/')
        }
      })
        .catch(() => {
          loading.value = false
        })
    }

    return {
      authProviders,
      loading,
      signInWithAuth,
    }
  },
}
</script>

<template>
  <div>
    <v-btn
      v-for="link in authProviders"
      :key="link.icon"
      :color="link.color"
      class="me-2"
      dark
      icon
      outlined
      :loading="loading"
      :disabled="loading"
      @click="signInWithAuth(link.provider)"
    >
      <v-icon>
        {{ link.icon }}
      </v-icon>
    </v-btn>
  </div>
</template>
