<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="isDark"
      @click="isDark = !isDark"
    >
      {{ isDark ? 'mdi-weather-sunny' : 'mdi-weather-night' }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import useAppConfig from '@/stores/appconfig'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
    }
  },
}
</script>

<style>
</style>
