<template>
  <div
    class="pa-2 mx-1"
  >
    <v-row>
      <v-col
        cols="4"
      >
        <label
          class="v-label theme--light"
          style="left: 0px; right: auto; position: relative;"
        >{{ obj.schema.label }}</label>
      </v-col>
      <v-col
        cols="8"
      >
        <v-rating
          v-model="rate"
          dense
          hover
          clearable
          :length="obj.schema.max"
          :rules="obj.schema.rules"
          @input="updateInput($event)"
        >
        </v-rating>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ref } from 'vue'

export default {

  props: {
    value: {
      type: String,
      default: null,
    },
    obj: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const modal = ref(false)
    const rate = ref(Number(props.value))

    const updateInput = value => {
      emit('change', value.toString())
    }

    return {
      modal,
      rate,
      updateInput,
    }
  },
}
</script>
