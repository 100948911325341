<template>
  <v-dialog
    v-model="modal"
    max-width="300px"
  >
    <template #activator="{ on: { click } }">
      <v-text-field
        :value="datetime"
        class="mx-1 mb-2"
        clearable
        outlined
        dense
        :label="$t(obj.schema.label)"
        v-bind="obj.schema"
        @click:clear="clear"
        @click="click"
      ></v-text-field>
    </template>
    <v-tabs
      v-model="tab"
      centered
      grow
    >
      <v-tab><v-icon>mdi-calendar</v-icon></v-tab>
      <v-tab><v-icon>mdi-clock</v-icon></v-tab>
    </v-tabs>
    <v-date-picker
      v-if="tab === 0"
      v-model="date"
      scrollable
      actions
      @input="$emit('change',datetime)"
    >
    </v-date-picker>
    <v-time-picker
      v-else
      v-model="time"
      scrollable
      actions
      @input="$emit('change',datetime)"
    >
    </v-time-picker>
  </v-dialog>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue'

export default {

  props: {
    value: {
      type: String,
      default: null,
    },
    obj: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const modal = ref(false)
    const date = ref('')
    const time = ref('')
    const tab = ref(0)
    const vm = getCurrentInstance().proxy

    if (props.value) {
      const val = vm.$moment(props.value).format('YYYY-MM-DD HH:mm')
      const splitted = val.split(' ')
      // eslint-disable-next-line prefer-destructuring
      date.value = splitted[0]
      // eslint-disable-next-line prefer-destructuring
      time.value = splitted[1]
    }
    const datetime = computed(() => (date.value && time.value ? `${date.value} ${time.value}` : props.value))
    const clear = () => {
      date.value = null
      time.value = null
      emit('change', null)
    }

    return {
      modal,
      date,
      time,
      datetime,
      tab,
      clear,
    }
  },
}
</script>
