<template>
  <v-row
    v-if="organization"
    class="pa-3 mt-2"
  >
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.organization"
    ></alert-error>
    <v-snackbar
      v-model="isSaved"
      outlined
      text
      color="primary"
      shaped
      top
      right
    >
      {{ $t('OrganizationSavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <confirm-dialog-delete
      :message="$t('DeleteOrganizationDetails')"
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingDelete"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>

    <v-col
      cols="12"
      lg="9"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("General") }}
          </h5>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            class="multi-col-validation mt-6"
            :disabled="!$canACL('update', 'organizations', organization)"
            @submit.prevent="save"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="organization.name"
                  :label="$t('Name')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-home-city"
                  :rules="[validators.required, v => (v || '' ).length <= 25 || $t('FieldMaxLength', {length: 25})]"
                  :placeholder="$t('Name')"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="organization.website"
                  :label="$t('Website')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-link-variant"
                  :rules="[validators.urlValidator]"
                  placeholder="https://domain.com"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="organization.email"
                  :label="$t('Email')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-email-outline"
                  :rules="[validators.required, validators.emailValidator]"
                  placeholder="john@example.com"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <phone-number
                  v-model="organization.phone"
                  :phone="organization.phone"
                  :disabled="!$canACL('update', 'organizations', organization)"
                ></phone-number>
              </v-col>
              <v-col cols="12">
                <v-btn
                  :loading="loading"
                  :disabled="loading || !$canACL('update', 'organizations', organization)"
                  color="primary"
                  class="me-3 mt-4"
                  type="submit"
                >
                  {{ $t("Save") }}
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-4"
                  type="reset"
                  :disabled="loading || !$canACL('update', 'organizations', organization)"
                  @click.prevent="resetForm"
                >
                  {{ $t("Cancel") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-alert
        border="left"
        colored-border
        text
        color="error"
      >
        <h5 class="font-weight-bold text-uppercase mb-1">
          {{ $t('DangerZone') }}
        </h5>
        <p class="text-sm mb-0">
          {{ $t("DeleteOrganizationDetails") }}
        </p>
        <v-btn
          color="error"
          class="mt-3 mb-1"
          small
          outlined
          target="_blank"
          :disabled="!$canACL('delete', 'organizations', organization)"
          @click="isDeleteDialogVisible= true"
        >
          {{ $t("DeleteOrganization") }}
        </v-btn>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { ref, getCurrentInstance, toRef } from 'vue'
import {
  required, urlValidator, emailValidator,
} from '@core/utils/validation'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import AlertError from '@/components/AlertError.vue'
import { useOrganizationsStore } from '@/stores/organizations.store'

export default {
  components: {
    ConfirmDialogDelete,
    AlertError,
  },
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const loading = ref(false)
    const loadingUpload = ref(false)
    const isSaved = ref(false)
    const form = ref(null)
    const errors = ref({})
    const isDeleteDialogVisible = ref(false)
    const loadingDelete = ref(false)
    const isAlertVisible = ref(false)

    const vm = getCurrentInstance().proxy
    const organizationsStore = useOrganizationsStore()

    const organization = toRef(props, 'organization')
    const save = () => {
      errors.value = {}
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      loading.value = true
      organizationsStore.saveOrganization(organization.value)
        .then(() => {
          loading.value = false
          isSaved.value = true
        })
        .catch(error => {
          loading.value = false
          isAlertVisible.value = true
          errors.value = {
            organization: error.message,
          }
          if (error.response) {
            errors.value = {
              organization: error.response.data?.error?.message || error.response.data?.message,
            }
          }
        })
    }

    const resetForm = () => {
      errors.value = {}
      organizationsStore.getOrganization(organization.value.id)
        .then(organizationData => {
          organizationsStore.organization = organizationData
        })
    }

    const confirmDelete = () => {
      loadingDelete.value = true
      organizationsStore.deleteOrganization(organization.value).then(() => {
        loadingDelete.value = false
        isDeleteDialogVisible.value = false
        vm.$router.push({ name: 'organizations' })
      }).catch(error => {
        loadingDelete.value = false
        isAlertVisible.value = true
        errors.value = {
          organization: error,
        }
      })
    }

    return {
      organization,
      form,
      save,
      resetForm,
      confirmDelete,
      isSaved,
      loading,
      loadingUpload,
      loadingDelete,
      isAlertVisible,
      errors,
      isDeleteDialogVisible,
      validators: {
        required,
        urlValidator,
        emailValidator,
      },
    }
  },
}
</script>
