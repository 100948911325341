<template>
  <v-row no-gutters
         class="workflows"
  >
    <v-col :cols="(currentNode?.slug === 'Filters' || currentNode?.slug === 'SetProperties' ) ? 4 : 8"
           class="max-h-content-container position-relative overflow-hidden"
    >
      <div id="ports-demo"
           :style="style"
      >
        <div class="viewport"
             :style="style"
        >
          <screen ref="screen">
            <edge v-for="edge in graph.edges"
                  :key="edge.id"
                  :data="edge"
                  :nodes="graph.nodes"
            ></edge>
            <node v-for="node in graph.nodes"
                  ref="node"
                  :key="node.id"
                  :data="node"
            >
              <v-card width="250"
                      :outlined="node.payload?.length > 0"
                      :elevation="currentNode?.id === node.id ? 2 : 0"
                      @click="onEdit(node)"
              >
                <v-card-title>
                  <v-icon size="1.5rem"
                          class="me-2"
                          :color="node.color"
                  >
                    {{ node.icon }}
                  </v-icon>
                  <span class="text-button">{{ $t(node.type) }}</span>

                  <v-spacer></v-spacer>

                  <v-btn icon
                         x-small
                         color="secondary"
                         class="me-1"
                         @click="onDelete(node)"
                  >
                    <v-icon> mdi-delete-outline </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle>{{ $t(node.title) }}</v-card-subtitle>
                <v-card-actions class="pa-0">
                  <table width="100%">
                    <td style="border-bottom: none">
                      <tr v-for="input in node.inputs"
                          :key="node.id + ':' + input"
                      >
                        <port :id="node.id + ':' + input"
                              ref="port"
                              :edges-to="getInputEdges(node, input)"
                        >
                          <div
                            class="port-inner"
                            :class="getInputEdges(node, input).length && 'connected'"
                            @mousedown.prevent.stop="evt => startConnect(node, { input }, evt)"
                            @mouseup.prevent.stop="createConnect(node, { input })"
                          ></div>
                        </port>
                        {{
                          $t(input)
                        }}
                      </tr>
                    </td>
                    <td style="border-bottom: none;">
                      <tr v-for="output in node.outputs"
                          :key="node.id + ':' + output"
                      >
                        {{
                          $t(output)
                        }}
                        <port :id="node.id + ':' + output"
                              ref="port"
                              :edges-from="getOutputEdges(node, output)"
                        >
                          <div
                            class="port-inner"
                            :class="getOutputEdges(node, output).length && 'connected'"
                            @mousedown.prevent.stop="evt => startConnect(node, { output }, evt)"
                            @mouseup.prevent.stop="createConnect(node, { output })"
                          ></div>
                        </port>
                      </tr>
                    </td>
                  </table>
                </v-card-actions>
              </v-card>
            </node>
          </screen>
        </div>
      </div>
    </v-col>
    <v-col :cols="(currentNode?.slug === 'Filters' || currentNode?.slug === 'SetProperties' ) ? 8 : 4">
      <v-card v-if="currentNode"
              :style="style"
              style="overflow-y: scroll;"
      >
        <v-card-title>
          <v-icon size="1.5rem"
                  class="me-2"
                  :color="currentNode.color"
          >
            {{ currentNode.icon }}
          </v-icon>
          <span class="text-button">{{ $t(currentNode.type) }}</span>

          <v-spacer></v-spacer>

          <v-btn icon
                 color="secondary"
                 @click="currentNode = null"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>{{ $t(currentNode.title) }}</v-card-subtitle>
        <v-card-text>
          <v-autocomplete
            v-if="currentNode.slug === 'NewObjectInListing'"
            v-model="currentNode.payload"
            :items="listingsStore.listings.filter(l => l.type === 'auto' || l.type === 'manual')"
            item-text="name"
            item-value="id"
            single-line
            chips
            outlined
            dense
            hide-details
            clearable
            multiple
            :label="$t('Listings')"
          >
            <template #selection="{ item: it, index }">
              <v-chip v-if="currentNode?.payload?.length <= 1"
                      class="me-1 grey--text text-caption"
              >
                {{ it.name }}
              </v-chip>
              <v-chip v-if="currentNode?.payload?.length> 1 && index === 1"
                      class="me-1 grey--text text-caption"
              >
                {{ currentNode?.payload?.length }} {{ $t('items') }}
              </v-chip>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-if="currentNode.slug === 'AddToListing'"
            v-model="currentNode.payload"
            :items="listingsStore.listings.filter(l => l.type === 'manual')"
            item-text="name"
            item-value="id"
            single-line
            chips
            outlined
            dense
            hide-details
            clearable
            multiple
            :label="$t('Listings')"
          >
            <template #selection="{ item: it, index }">
              <v-chip v-if="currentNode?.payload?.length <= 1"
                      class="me-1 grey--text text-caption"
              >
                {{ it.name }}
              </v-chip>
              <v-chip v-if="currentNode?.payload?.length> 1 && index === 1"
                      class="me-1 grey--text text-caption"
              >
                {{ currentNode?.payload?.length }} {{ $t('items') }}
              </v-chip>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-if="currentNode.slug === 'SendCampaign'"
            v-model="currentNode.payload"
            :items="campaignsStore.campaigns"
            item-text="name"
            item-value="id"
            single-line
            chips
            outlined
            dense
            hide-details
            clearable
            multiple
            :label="$t('Campaigns')"
          >
            <template #selection="{ item: it, index }">
              <v-chip v-if="currentNode?.payload?.length <= 1"
                      class="me-1 grey--text text-caption"
              >
                {{ it.name }}
              </v-chip>
              <v-chip v-if="currentNode?.payload?.length> 1 && index === 1"
                      class="me-1 grey--text text-caption"
              >
                {{ currentNode?.payload?.length }} {{ $t('items') }}
              </v-chip>
            </template>
          </v-autocomplete>
          <v-select
            v-if="currentNode.slug === 'Delay'"
            v-model="currentNode.payload"
            :items="delays"
            item-text="name"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            :label="$t('Delays')"
          >
          </v-select>

          <v-form-base
            v-if="currentNode.slug === 'Filters'"
            id="form-base-container"
            :col="12"
            :model="currentNode.payload"
            :schema="schemaFilters"
            @click="onClick"
          >
          </v-form-base>
          <v-form-base
            v-if="currentNode.slug === 'SetProperties'"
            id="form-base-container"
            :col="12"
            :model="currentNode.payload"
            :schema="schemaProperties"
            @click="onClick"
          >
          </v-form-base>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
      <v-card v-else
              class="pa-0 ma-0"
      >
        <v-list subheader
                dense
                class="overflow-auto"
                :height="height"
        >
          <template v-for="(it, index) in items">
            <v-list-item :key="it.title">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon :color="it.color">
                    {{ it.icon }}
                  </v-icon>
                  <span class="text-button">{{ $t(it.type) }}</span>
                </v-list-item-title>
                <v-list-item-subtitle> {{ $t(it.title) }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-btn color="primary"
                       x-small
                       :disabled="isDisabled(it)"
                       @click="addNode(it)"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  Screen, Node, Edge, graph, Port,
} from 'vnodes'
import { uuidv7 } from 'uuidv7'
import { useVModel } from '@vueuse/core'
import {
  ref, onUnmounted, getCurrentInstance, computed,
} from 'vue'
import { useListingsStore } from '@/stores/listings.store'
import { useCampaignsStore } from '@/stores/campaigns.store'
import { usePropertiesStore } from '@/stores/properties.store'
import { useWorkflowsStore } from '@/stores/workflows.store'

export default {
  components: {
    Screen,
    Node,
    Edge,
    Port,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const tabs = ref(null)
    const item = useVModel(props, 'item', emit)
    const vm = getCurrentInstance().proxy
    const listingsStore = useListingsStore()
    const workflowsStore = useWorkflowsStore()
    const campaignsStore = useCampaignsStore()
    const propertiesStore = usePropertiesStore()
    const currentNode = ref(null)
    const delays = ref([{ name: '10 seconds', value: 10 }, { name: '1 minute', value: 60 }, { name: '1 hour', value: 3600 }, { name: '1 day', value: 86400 }, { name: '30 days', value: 604800 }, { name: '1 month', value: 2592000 }])
    const schemaFilters = ref(workflowsStore.getFiltersSchema({ type: 'auto' }, { properties: propertiesStore.properties, isLeftSidebarOpen: true }))
    const schemaProperties = ref(workflowsStore.getPropertiesSchema({ type: 'auto' }, { properties: propertiesStore.properties, isLeftSidebarOpen: true }))

    onUnmounted(() => {
      item.value['payload->>design'] = vm.graph
      document.removeEventListener('mouseup', vm.cancelConnect)
      document.removeEventListener('mousemove', vm.onmousemove)
    })
    const height = computed(() => window.innerHeight - 260)
    const style = computed(() => `height: ${window.innerHeight - 260}px;`)

    const onEdit = node => {
      currentNode.value = node
    }

    // LISTINGS METHODS
    const getCondition = () => ({
      condition: 'OR',
      property: '',
      operation: 'equals',
      value: '',
    })

    const addCondition = index => {
      currentNode.value.payload.filters[index[0]].conditions.push(getCondition())
    }

    const removeFilter = index => {
      currentNode.value.payload.filters.splice(index[0], 1)
    }

    const removeCondition = index => {
      currentNode.value.payload.filters[index[0]].conditions.splice(index[1], 1)
      if (currentNode.value.payload.filters[index[0]].conditions.length === 0) {
        removeFilter(index[0])
      }
    }

    const addFilter = () => {
      currentNode.value.payload.filters.push({
        group: 'AND',
        conditions: [getCondition()],
      })
    }

    const onClick = val => {
      const {
        index, key,
      } = val
      if (key === 'add_filter') {
        vm.$nextTick(addFilter())
      } else if (key === 'add_condition') {
        addCondition(index)
      } else if (key === 'remove_condition') {
        removeCondition(index)
      } else if (key === 'delete_filter') {
        removeFilter(index)
      }
    }

    return {
      tabs,
      height,
      style,
      currentNode,
      onEdit,
      onClick,
      listingsStore,
      campaignsStore,
      delays,
      schemaFilters,
      schemaProperties,
    }
  },
  data() {
    return {
      // eslint-disable-next-line new-cap
      graph: new graph(),
      connecting: null, // { node: {}, input: str, output: str }
      mousePrev: { x: 0, y: 0 },
      zoom: 0.2,
      items: [
        {
          title: 'NewObjectInListing',
          slug: 'NewObjectInListing',
          type: 'Triggers',
          icon: 'mdi-eye',
          color: 'primary',
          inputs: ['Input'],
          outputs: ['Output'],
        },
        {
          title: 'NewActivity',
          slug: 'NewActivity',
          type: 'Triggers',
          icon: 'mdi-eye',
          color: 'primary',
          inputs: ['Input'],
          outputs: ['Output'],
        },
        {
          title: 'SendCampaignAction',
          slug: 'SendCampaign',
          type: 'Actions',
          icon: 'mdi-flash',
          color: 'error',
          inputs: ['Input'],
          outputs: ['Output'],
        },
        {
          title: 'AddToListing',
          slug: 'AddToListing',
          type: 'Actions',
          icon: 'mdi-flash',
          color: 'error',
          inputs: ['Input'],
          outputs: ['Output'],
        },
        {
          title: 'SetProperties',
          slug: 'SetProperties',
          type: 'Actions',
          icon: 'mdi-flash',
          color: 'error',
          inputs: ['Input'],
          outputs: ['Output'],
          payload: {},
        },
        {
          title: 'Delay',
          slug: 'Delay',
          type: 'Times',
          icon: 'mdi-clock',
          color: 'secondary',
          inputs: ['Input'],
          outputs: ['Output'],
        },
        {
          title: 'Filters',
          slug: 'Filters',
          type: 'Conditions',
          icon: 'mdi-filter',
          color: 'secondary',
          inputs: ['Input'],
          outputs: ['Yes', 'No'],
          payload: {},
        },
      ],
    }
  },
  computed: {
    activeEdge: vm => vm.graph.edges.find(e => e.active),
  },
  mounted() {
    this.loadWorkflow()
  },
  methods: {
    startConnect(node, { input, output }, evt) {
      if (this.connecting) return
      const port = this.$refs.port.find(p => p.id === `${node.id}:${input || output}`)

      const edge = input && this.getInputEdges(node, input).reverse()[0]
      if (edge) {
        // edit exiting edge
        edge.active = true
        this.connecting = {
          node: this.graph.nodes.find(n => (input ? edge.from === n.id : edge.to === n.id)),
          input: output,
          output: input,
        }
      } else {
        // new edge
        this.graph.createEdge({
          from: node.id,
          to: node.id,
          fromPort: input || output,
          toPort: input || output,
          fromAnchor: { ...port.offset },
          toAnchor: { ...port.offset },
          active: true,
          type: 'hsmooth',
        })
        this.connecting = {
          node,
          input,
          output,
        }
      }

      this.mousePrev = { x: evt.clientX, y: evt.clientY }
      this.zoom = this.$refs.screen.panzoom.getZoom()
    },
    createConnect(node, { input, output }) {
      if (!this.connecting) return
      if (this.isValidConnection({ node, input, output }, this.connecting)) {
        if (input) {
          this.activeEdge.to = node.id
          this.activeEdge.toPort = input
        } else if (output) {
          this.activeEdge.from = node.id
          this.activeEdge.fromPort = output
        }
        this.stopConnect()
      } else {
        this.cancelConnect()
      }
    },
    cancelConnect() {
      if (!this.connecting) return
      this.graph.removeEdge(this.activeEdge)
      this.stopConnect()
    },
    stopConnect() {
      if (this.activeEdge) {
        this.activeEdge.active = false
      }
      this.$nextTick(() => {
        this.connecting = null
      })
    },
    isValidConnection(conA, conB) {
      const nodeInput = conA.input ? conA.node : conB.node
      const nodeOutput = conA.output ? conA.node : conB.node

      if (nodeInput.type === 'Actions' && !['Triggers', 'Conditions', 'Times'].includes(nodeOutput.type)) {
        return false
      }

      if (nodeInput.type === 'Triggers' && ![].includes(nodeOutput.type)) {
        return false
      }

      if (nodeInput.type === 'Conditions' && !['Actions', 'Triggers', 'Times'].includes(nodeOutput.type)) {
        return false
      }

      if (nodeInput.type === 'Times' && !['Actions', 'Conditions'].includes(nodeOutput.type)) {
        return false
      }

      return (
        conA
        && conB
        && conA.node
        && conB.node
        && conA.node !== conB.node
        && ((conA.input && conB.output) || (conB.input && conA.output))
      )
    },

    // edges that go to this input
    getInputEdges(node, input) {
      return this.graph.edges.filter(e => e.to === node.id && e.toPort === input)
    },

    // edges that start from this output
    getOutputEdges(node, output) {
      return this.graph.edges.filter(e => e.from === node.id && e.fromPort === output)
    },
    onmousemove(e) {
      if (this.connecting) {
        const offset = {
          x: (e.clientX - this.mousePrev.x) / this.zoom,
          y: (e.clientY - this.mousePrev.y) / this.zoom,
        }
        const anchor = this.connecting.input ? this.activeEdge.fromAnchor : this.activeEdge.toAnchor

        anchor.x += offset.x
        anchor.y += offset.y
        this.mousePrev = { x: e.clientX, y: e.clientY }
      }
    },
    addNode(node) {
      const newNode = {
        id: uuidv7(),
        title: node.title,
        slug: node.slug,
        payload: node.payload,
        type: node.type,
        icon: node.icon,
        color: node.color,
        inputs: node.inputs,
        outputs: node.outputs,
      }

      this.graph.createNode(newNode)

      if (this.graph.nodes && this.graph.nodes.length >= 2) {
        if (
          this.isValidConnection(
            {
              node: this.graph.nodes[this.graph.nodes.length - 2],
              output: this.graph.nodes[this.graph.nodes.length - 2].outputs[0],
            },
            { node: newNode, input: node.inputs[0] },
          )
        ) {
          this.graph.createEdge({
            from: this.graph.nodes[this.graph.nodes.length - 2].id,
            to: newNode.id,
            fromPort: this.graph.nodes[this.graph.nodes.length - 2].outputs[0],
            toPort: node.inputs[0],
            active: false,
            type: 'hsmooth',
          })
        }
      }

      this.$nextTick(() => {
        this.graph.graphNodes({ spacing: 75 })
        this.$refs.screen.zoomNodes(this.graph.nodes, { scale: 0.5 })
      })
    },

    onDelete(node) {
      this.graph.nodes = this.graph.nodes.filter(n => n.id !== node.id)
      this.graph.edges = this.graph.edges.filter(e => e.from !== node.id && e.to !== node.id)
    },

    isDisabled(node) {
      if (node.type === 'Triggers') {
        return !!this.graph.nodes.find(n => n.type === node.type)
      }

      return false
    },

    loadWorkflow() {
      // eslint-disable-next-line new-cap
      this.graph = new graph()
      window.ports = this // DELETEM
      this.$nextTick(() => {
        this.graph.graphNodes({ spacing: 75 })
        this.$refs.screen.zoomNodes(this.graph.nodes, { scale: 0.5 })
      })
      document.addEventListener('mouseup', this.cancelConnect)
      document.addEventListener('mousemove', this.onmousemove)
      setTimeout(() => {
        this.item['payload->>design']?.nodes?.forEach(node => {
          this.graph.createNode(node)
        })
        this.item['payload->>design']?.edges?.forEach(edge => {
          this.graph.createEdge(edge)
        })
        this.$nextTick(() => {
          this.graph.graphNodes({ spacing: 75 })
          this.$refs.screen.zoomNodes(this.graph.nodes, { scale: 0.5 })
        })
      }, 0)
    },
  },
}
</script>
