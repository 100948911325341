<template>
  <v-container
    class="ma-0 pa-0 overflow-hidden"
    fluid
    :style="style"
  >
    <v-snackbar
      v-model="isSaved"
      outlined
      text
      color="primary"
      shaped
      bottom
      right
    >
      {{ $t('SavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <workflow-editor
      :item.sync="item"
    ></workflow-editor>
  </v-container>
</template>
<script>
import { ref, computed } from 'vue'
import { useVModel } from '@vueuse/core'
import { useTemplatesStore } from '@/stores/templates.store'
import { required } from '@core/utils/validation'
import useAppConfig from '@/stores/appconfig'
import WorkflowEditor from '@/views/projects/settings/workflows/WorkflowEditor.vue'

export default {
  components: {
    WorkflowEditor,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const loadingAction = ref(true)
    const form = ref(null)
    const errors = ref({})
    const isSaved = ref(false)
    const item = useVModel(props, 'item', emit)
    const templatesStore = useTemplatesStore()

    const { isDark } = useAppConfig()

    const style = computed(() => `height: ${window.innerHeight - 260}px;`)

    return {
      form,
      loadingAction,
      item,
      errors,
      validators: {
        required,
      },
      templatesStore,
      isSaved,
      isDark,
      style,
    }
  },

  methods: {
  },
}
</script>
