// axios
import axios from 'axios'
import Vue from 'vue'
import { supabaseDomain } from '@/plugins/supabase'
import { appConfig } from '@/plugins/micleoConfig'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API,

  // timeout: 1000,
  headers: {
    'x-micleo-id': appConfig?.id,
  },

  /* headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'referer, origin, content-type',
  }, */
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const supabaseToken = JSON.parse(localStorage.getItem(supabaseDomain))
    const accessToken = supabaseToken?.access_token

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
    config.headers.apiKey = appConfig.tokens.SUPABASE_ANON

    return config
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
