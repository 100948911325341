import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase } from '@/plugins/supabase'

// eslint-disable-next-line import/prefer-default-export
export const useTasksStore = defineStore({
  id: 'tasks',
  state: () => ({
    token: null,
  }),
  getters: {

  },
  actions: {
    async createTask(task) {
      const { data, error } = await supabase
        .from('tasks')
        .insert(task)

      if (error) {
        return Promise.reject(error)
      }

      return data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTasksStore, import.meta.hot))
}
