<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
  >
    <template #activator="{ on, attrs }">
      <v-icon
        v-if="!notifications.length"
        v-bind="attrs"
        v-on="on"
      >
        mdi-bell-outline
      </v-icon>

      <v-icon
        v-else
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        mdi-bell-ring
      </v-icon>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
            class="d-flex"
            link
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">{{ $t('Notifications') }}</span>
              <v-chip
                v-if="notifications.length"
                class="v-chip-light-bg primary--text font-weight-semibold"
                small
              >
                {{ notifications.length }}
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <template v-for="(chat, index) in notifications">
            <v-list-item
              :key="chat.name+'-'+getUnseenMessages(chat)"
              link
              @click="gotToChat(chat)"
            >
              <!-- Avatar -->
              <v-list-item-avatar
                v-if="getContact(chat.last_message_admin_id)"
                :class="[{'v-avatar-light-bg primary--text justify-center': getContact(chat.last_message_admin_id)}]"
                size="38"
              >
                <avatar-member
                  :key="chat.last_message_admin_id"
                  small
                  :member="getContact(chat.last_message_admin_id)"
                ></avatar-member>
              </v-list-item-avatar>

              <!-- Content -->
              <v-list-item-content class="d-block">
                <v-list-item-title class="text-sm font-weight-semibold">
                  {{ chat.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-sm">
                  {{ chat.last_message }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <!-- Item Action/Time -->
              <v-list-item-action>
                <span class="text--secondary text-xs">{{ $moment(chat.last_message_time).format("lll") }}</span>
                <v-chip
                  class="v-chip-light-bg primary--text font-weight-semibold"
                  small
                >
                  {{ getUnseenMessages(chat) }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
          <v-list-item
            key="read-all-btn"
            class="read-all-btn-list-item"
          >
            <v-btn
              :disabled="!notifications"
              block
              color="primary"
              @click="readAll"
            >
              {{ $t('ReadAllNotifications') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import AvatarMember from '@/components/AvatarMember.vue'
import { useChatsStore } from '@/stores/chats.store'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
    AvatarMember,
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const getUnseenMessages = chat => {
      if (!chat.members) { return null }
      const member = chat.members.find(item => item.admin_id === props.user.id)

      if (member) {
        return member.unseen_messages
      }

      return null
    }
    const getContact = id => props.contacts.find(c => c.id === id)

    const chatsStore = useChatsStore()

    const readAll = () => {
      props.notifications.forEach(chat => {
        chatsStore.chat = chat
        chatsStore.updateSeen()
      })
    }

    const gotToChat = chat => {
      localStorage.setItem('chat', chat.id)
      chatsStore.getChat(chat.id).then(data => {
        chatsStore.chat = data
        chatsStore.updateSeen()
        vm.$router.push({ name: 'chat' })
      }).catch(() => {
        localStorage.removeItem('chat')
      })
    }

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      getContact,
      perfectScrollbarOptions,
      readAll,
      gotToChat,
      getUnseenMessages,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
