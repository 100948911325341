import { ref, watch } from 'vue'
import { useWorkspacesMigrationsStore } from '@/stores/workspaces_migrations.store'

export default function useList(workspaceId) {
  const listTable = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'Name', value: 'name' },
    { text: 'Status', value: 'status' },
    { text: 'CreatedAt', value: 'created_at' },
    {
      text: 'Action',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['created_at'],
    sortDesc: [false],
    itemsPerPage: 100,
  })
  const totalListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])
  const isAddDialogVisible = ref(false)
  const isDeleteDialogVisible = ref(false)
  const migrationsStore = useWorkspacesMigrationsStore()

  const fetchDatas = () => {
    loading.value = true
    migrationsStore
      .getMigrations({ workspaceId, search: searchQuery.value, options: options.value })
      .then(data => {
        listTable.value = data.data
        totalListTable.value = data.count
        loading.value = false
      })
      .catch(() => {
        listTable.value = []
        totalListTable.value = 0
        loading.value = false
      })
  }

  watch([searchQuery, options, isAddDialogVisible, isDeleteDialogVisible], () => {
    loading.value = true
    selectedTableData.value = []
    fetchDatas()
  })

  migrationsStore.$subscribe(mutation => {
    if (mutation.payload) {
      const index = listTable.value.findIndex(it => it.id === mutation.payload.item.id)
      if (mutation.payload.eventType === 'UPDATE') {
        if (index !== -1) listTable.value.splice(index, 1, mutation.payload.item)
      } else if (mutation.payload.eventType === 'INSERT') {
        if (index === -1) listTable.value.splice(0, 0, mutation.payload.item)
        else listTable.value.splice(index, 1, mutation.payload.item)
      } else if (mutation.payload.eventType === 'DELETE') {
        if (index !== -1) listTable.value.splice(index, 1)
      }
    }
  })

  return {
    tableColumns,
    searchQuery,
    options,
    listTable,
    totalListTable,
    loading,
    selectedTableData,
    fetchDatas,
    isAddDialogVisible,
    isDeleteDialogVisible,
  }
}
