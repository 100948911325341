<template>
  <v-dialog
    v-model="modal"
    max-width="300px"
  >
    <template #activator="{ on: { click } }">
      <v-text-field
        v-model="date"
        class="mx-1 mb-2"
        clearable
        outlined
        dense
        :label="$t(obj.schema.label)"
        v-bind="obj.schema"
        @click:clear="clear"
        @click="click"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="date"
      scrollable
      actions
      @input="$emit('change',date)"
    >
    </v-time-picker>
  </v-dialog>
</template>
<script>
import { ref } from 'vue'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    obj: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const modal = ref(false)
    const date = ref(props.value)

    const clear = () => {
      date.value = null
      emit('change', null)
    }

    return {
      modal,
      date,
      clear,
    }
  },
}
</script>
