<template>
  <v-row class="pa-3 mt-2">
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.workspace"
    ></alert-error>
    <v-snackbar v-model="isSaved"
                color="primary"
                text
                outlined
                shaped
                top
                right
    >
      {{ $t('WorkspaceSavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn icon
               color="primary"
               v-bind="attrs"
               @click="isSaved = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12"
           md="10"
    >
      <v-card style="min-height: 400px;">
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t('Supabase') }}
          </h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-tabs :vertical="!$vuetify.breakpoint.mdAndDown"
                show-arrows
                @change="onTabEvent"
        >
          <v-tab>
            {{ $t('Project') }}
          </v-tab>
          <v-tab>
            {{ $t('Health') }}
          </v-tab>
          <v-tab>
            {{ $t('ProjectAPIKeys') }}
          </v-tab>

          <v-tab>
            {{ $t('APIsettings') }}
          </v-tab>
          <v-tab>
            {{ $t('Database') }}
          </v-tab>
          <v-tab v-if="false">
            {{ $t('Database') }}
          </v-tab>
          <v-tab>
            {{ $t('SMTPSettings') }}
          </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-title>
                <h3 class="font-weight-bold text-button">
                  {{ $t('Project') }}
                </h3>
              </v-card-title>
              <v-card-text>
                <v-form ref="form"
                        class="multi-col-validation"
                        @submit.prevent="save"
                >
                  <v-row v-if="workspace.settings">
                    <v-col cols="12">
                      <v-text-field
                        v-model="workspace.settings.url"
                        :label="$t('ProjectUrl')"
                        outlined
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-web"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="workspace.settings.region"
                        :label="$t('Region')"
                        outlined
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-web"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="workspace.settings.database.host"
                        :label="$t('DatabaseHost')"
                        outlined
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-database"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="workspace.settings.database.version"
                        :label="$t('DatabaseVersion')"
                        outlined
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-database"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <app-card-actions action-refresh
                              flat
                              :loading="loading.health"
                              @refresh="getHealth()"
            >
              <template slot="title">
                <h3 class="font-weight-bold text-button">
                  {{ $t('Health') }}
                </h3>
              </template>
              <v-card-text v-if="health">
                <v-card outlined>
                  <v-data-table
                    :items="health"
                    :headers="tableHeadersHealth"
                    hide-default-footer
                    class="text-no-wrap"
                  >
                    <template
                      #[`item.name`]="{item}"
                    >
                      <span class="text-no-wrap font-weight-bold primary--text text-uppercase">{{ item.name }}</span>
                    </template>
                    <template
                      #[`item.status`]="{item}"
                    >
                      <v-chip outlined
                              :color="item.status === 'ACTIVE_HEALTHY' ? 'primary': 'error'"
                      >
                        {{ item.status }}
                      </v-chip>
                    </template>
                    <template
                      #[`item.error`]="{item}"
                    >
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-if="item.error "
                            color="error"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        <span>{{ item.error }}</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-card-text>
            </app-card-actions>
          </v-tab-item>
          <v-tab-item>
            <app-card-actions action-refresh
                              flat
                              :loading="loading['api-keys']"
                              @refresh="getApiKeys()"
            >
              <template slot="title">
                <h3 class="font-weight-bold text-button">
                  {{ $t('ProjectAPIKeys') }}
                </h3>
              </template>
              <v-card-text>
                <v-card v-if="apiKeys"
                        outlined
                >
                  <v-data-table
                    :items="apiKeys"
                    :headers="tableHeadersAPIKeys"
                    hide-default-footer
                    class="text-no-wrap"
                  >
                    <template
                      #[`item.name`]="{item}"
                    >
                      <span class="text-no-wrap font-weight-bold primary--text text-uppercase">{{ item.name }}</span>
                    </template>
                    <template
                      #[`item.api_key`]="{item}"
                    >
                      <v-text-field
                        :value="item.api_key"
                        outlined
                        dense
                        :type="item.visible ? 'text' : 'password'"
                        :append-icon="item.visible ? 'mdi-eye-off-outline':'mdi-eye-outline'"
                        :rules="[validators.required]"
                        hide-details="auto"
                        class="my-2"
                        @click:append="toggleInput(apiKeys, item)"
                      ></v-text-field>
                    </template>
                  </v-data-table>
                </v-card>
              </v-card-text>
            </app-card-actions>
          </v-tab-item>

          <v-tab-item>
            <app-card-actions action-refresh
                              flat
                              :loading="loading.postgrest"
                              @refresh="getPostgrest()"
            >
              <template slot="title">
                <h3 class="font-weight-bold text-button">
                  {{ $t('APIsettings') }}
                </h3>
              </template>
              <v-card-text>
                <v-form ref="form"
                        class="multi-col-validation"
                >
                  <v-row v-if="postgrest">
                    <v-col cols="12">
                      <v-text-field
                        :value="postgrest.jwt_secret"
                        outlined
                        dense
                        :label="$t('jwt_secret')"
                        :type="isVisibleJWT ? 'text' : 'password'"
                        :append-icon="isVisibleJWT ? 'mdi-eye-off-outline':'mdi-eye-outline'"
                        :rules="[validators.required]"
                        hide-details="auto"
                        class="my-2"
                        @click:append="isVisibleJWT= !isVisibleJWT"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgrest.db_schema"
                        :label="$t('db_schema')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgrest.db_extra_search_path"
                        :label="$t('db_extra_search_path')"
                        outlined
                        dense
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgrest.max_rows"
                        :label="$t('max_rows')"
                        outlined
                        dense
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                               type="submit"
                               disabled
                        >
                          {{ $t('Save') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </app-card-actions>
          </v-tab-item>
          <v-tab-item>
            <app-card-actions action-refresh
                              flat
                              :loading="loading.pgbouncer"
                              @refresh="getPgBouncer()"
            >
              <template slot="title">
                <h3 class="font-weight-bold text-button">
                  {{ $t('Database') }}
                </h3>
              </template>
              <v-card-text>
                <v-form ref="form"
                        class="multi-col-validation"
                >
                  <v-row v-if="pgbouncer">
                    <v-col cols="12">
                      <v-text-field
                        :value="pgbouncer.pool_mode"
                        :label="$t('pool_mode')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="pgbouncer.default_pool_size"
                        :label="$t('max_rows')"
                        outlined
                        dense
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="pgbouncer.ignore_startup_parameters"
                        :label="$t('ignore_startup_parameters')"
                        outlined
                        dense
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="pgbouncer.max_client_conn"
                        :label="$t('max_client_conn')"
                        outlined
                        dense
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        :value="pgbouncer.connection_string"
                        :label="$t('connection_string')"
                        outlined
                        dense
                        height="60"
                        :rules="[validators.required]"
                        disabled
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                               type="submit"
                               disabled
                        >
                          {{ $t('Save') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </app-card-actions>
          </v-tab-item>
          <v-tab-item v-if="false">
            <app-card-actions action-refresh
                              flat
                              :loading="loading.postgres"
                              @refresh="getPostgres()"
            >
              <template slot="title">
                <h3 class="font-weight-bold text-button">
                  {{ $t('Database') }}
                </h3>
              </template>
              <v-card-text>
                <v-form ref="form"
                        class="multi-col-validation"
                >
                  <v-row v-if="postgres">
                    <v-col cols="12">
                      <v-text-field
                        :value="postgres.statement_timeout"
                        :label="$t('statement_timeout')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgres.effective_cache_size"
                        :label="$t('effective_cache_size')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgres.maintenance_work_mem"
                        :label="$t('maintenance_work_mem')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgres.max_connections"
                        :label="$t('max_connections')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgres.max_locks_per_transaction"
                        :label="$t('max_locks_per_transaction')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgres.max_parallel_maintenance_workers"
                        :label="$t('max_parallel_maintenance_workers')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgres.max_parallel_workers"
                        :label="$t('max_parallel_workers')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgres.max_parallel_workers_per_gather"
                        :label="$t('max_parallel_workers_per_gather')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="postgres.max_parallel_workers"
                        :label="$t('max_parallel_workers')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                               type="submit"
                               disabled
                        >
                          {{ $t('Save') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </app-card-actions>
          </v-tab-item>
          <v-tab-item>
            <app-card-actions action-refresh
                              flat
                              :loading="loading.auth"
                              @refresh="getAuthConfig()"
            >
              <template slot="title">
                <h3 class="font-weight-bold text-button">
                  {{ $t('SMTPSettings') }}
                </h3>
              </template>
              <v-card-text>
                <v-form ref="form"
                        class="multi-col-validation"
                >
                  <v-row v-if="authconfig">
                    <v-col cols="12">
                      <v-text-field
                        :value="authconfig.smtp_sender_name"
                        :label="$t('smtp_sender_name')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="authconfig.smtp_admin_email"
                        :label="$t('smtp_admin_email')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        :value="authconfig.smtp_host"
                        :label="$t('smtp_host')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="authconfig.smtp_port"
                        :label="$t('smtp_port')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="authconfig.smtp_user"
                        :label="$t('smtp_user')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="authconfig.smtp_pass"
                        :label="$t('smtp_pass')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="false"
                           cols="12"
                    >
                      <v-text-field
                        :value="authconfig.smtp_max_frequency"
                        :label="$t('smtp_max_frequency')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col v-if="false"
                           cols="12"
                    >
                      <v-text-field
                        :value="authconfig.rate_limit_email_sent"
                        :label="$t('rate_limit_email_sent')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                               type="submit"
                               disabled
                        >
                          {{ $t('Save') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </app-card-actions>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref, onMounted, getCurrentInstance, toRef,
} from 'vue'
import { avatarText } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import AlertError from '@/components/AlertError.vue'
import AppCardActions from '@/components/AppCardActions.vue'
import axios from '@axios'

export default {
  components: {
    AlertError,
    AppCardActions,
  },
  props: {
    workspace: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const loading = ref({
      'api-keys': false,
      health: false,
      auth: false,
      pgbouncer: false,
      postgrest: false,
      postgres: false,
    })
    const loadingUpload = ref(false)
    const loadingDelete = ref(false)
    const isSaved = ref(false)
    const form = ref(null)
    const errors = ref({})
    const isAlertVisible = ref(false)
    const isDeleteDialogVisible = ref(false)
    const workspace = toRef(props, 'workspace')
    const vm = getCurrentInstance().proxy
    const apiKeys = ref(null)
    const health = ref(null)
    const authconfig = ref(null)
    const pgbouncer = ref(null)
    const postgres = ref(null)
    const postgrest = ref(null)
    const isVisibleJWT = ref(false)

    const tableHeadersAPIKeys = [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'api_key', value: 'api_key', sortable: false },
    ]

    const tableHeadersHealth = [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Error', value: 'error', sortable: false },
    ]

    const getApiKeys = () => {
      apiKeys.value = null
      loading.value['api-keys'] = true
      axios.post('admin/workspaces/api-keys', { organization_id: props.workspace.organization_id, workspace_id: props.workspace.id })
        .then(response => {
          loading.value['api-keys'] = false
          apiKeys.value = response.data
        }).catch(error => {
          loading.value['api-keys'] = false
          isAlertVisible.value = true
          errors.value = { workspace: error }
        })
    }

    const getHealth = () => {
      health.value = null
      loading.value.health = true
      axios.post('admin/workspaces/health', { organization_id: props.workspace.organization_id, workspace_id: props.workspace.id })
        .then(response => {
          loading.value.health = false
          health.value = response.data
        }).catch(error => {
          loading.value.health = false
          isAlertVisible.value = true
          errors.value = { workspace: error }
        })
    }

    const getAuthConfig = () => {
      authconfig.value = null
      loading.value.auth = true
      axios.post('admin/workspaces/config/auth', { organization_id: props.workspace.organization_id, workspace_id: props.workspace.id })
        .then(response => {
          loading.value.auth = false
          authconfig.value = response.data
        }).catch(error => {
          loading.value.auth = false
          isAlertVisible.value = true
          errors.value = { workspace: error }
        })
    }

    const getPgBouncer = () => {
      pgbouncer.value = null
      loading.value.pgbouncer = true
      axios.post('admin/workspaces/config/database/pgbouncer', { organization_id: props.workspace.organization_id, workspace_id: props.workspace.id })
        .then(response => {
          loading.value.pgbouncer = false
          pgbouncer.value = response.data
        }).catch(error => {
          loading.value.pgbouncer = false
          isAlertVisible.value = true
          errors.value = { workspace: error }
        })
    }

    const getPostgres = () => {
      postgres.value = null
      loading.value.postgres = true
      axios.post('admin/workspaces/config/database/postgres', { organization_id: props.workspace.organization_id, workspace_id: props.workspace.id })
        .then(async response => {
          loading.value.postgres = false
          postgres.value = response.data
        }).catch(error => {
          loading.value.postgres = false
          isAlertVisible.value = true
          errors.value = { workspace: error }
        })
    }

    const getPostgrest = () => {
      postgrest.value = null
      loading.value.postgrest = true
      axios.post('admin/workspaces/postgrest', { organization_id: props.workspace.organization_id, workspace_id: props.workspace.id })
        .then(async response => {
          loading.value.postgrest = false
          postgrest.value = response.data
        }).catch(error => {
          loading.value.postgrest = false
          isAlertVisible.value = true
          errors.value = { workspace: error }
        })
    }

    onMounted(() => {
      tableHeadersAPIKeys.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
      tableHeadersHealth.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
    })

    const onTabEvent = t => {
      if (t === 1) {
        getHealth()
      } else if (t === 2) {
        getApiKeys()
      } else if (t === 3) {
        getPostgrest()
      } else if (t === 4) {
        getPgBouncer()
      } else if (t === 555) {
        getPostgres()
      } else if (t === 5) {
        getAuthConfig()
      }
    }

    const toggleInput = (array, item) => {
      const itemIndex = array.indexOf(item)
      item.visible = !item.visible
      array.splice(itemIndex, 1, item)
    }

    return {
      workspace,
      form,
      isSaved,
      loading,
      loadingUpload,
      loadingDelete,
      errors,
      isAlertVisible,
      isDeleteDialogVisible,
      validators: {
        required,
      },
      avatarText,
      apiKeys,
      health,
      authconfig,
      pgbouncer,
      postgres,
      postgrest,
      getApiKeys,
      getAuthConfig,
      getHealth,
      getPostgres,
      getPgBouncer,
      getPostgrest,
      onTabEvent,
      tableHeadersAPIKeys,
      tableHeadersHealth,
      toggleInput,
      isVisibleJWT,
    }
  },
}
</script>
