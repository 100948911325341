<template>
  <div>
    <component :is="resolveLayoutVariant"

               :class="`skin-variant--${appSkinVariant}`"
    >
      <widget-form v-if="name==='form'"
                   :params="params"
      ></widget-form>
      <transition
        v-else
        :name="appRouteTransition"
        mode="out-in"
        appear
      >
        <router-view :key="$route.name"></router-view>
      </transition>
    </component>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, onBeforeMount, watch,
} from 'vue'
import moment from 'moment'
import useAppConfig from '@/stores/appconfig'
import WidgetForm from '@/pages/WidgetForm.vue'
import { useRouter } from '@core/utils'
import { useVModel } from '@vueuse/core'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts

import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/content/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/content/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/blank/LayoutBlank.vue'
import LayoutEmpty from '@/layouts/empty/LayoutEmpty.vue'
import { supabase } from '@/plugins/supabase'
import { appConfig } from '@/plugins/micleoConfig'
import { initialAbility } from '@/plugins/acl/config'
import { useUserStore } from '@/stores/user.store'
import { loadLanguageAsync } from '@/plugins/i18n'
import router from './router'

export default {
  metaInfo: {
    title: 'App',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.name },
    ],
  },
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    LayoutEmpty,
    WidgetForm,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    params: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    project: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { route } = useRouter()
    const userStore = useUserStore()
    const name = useVModel(props, 'name', emit)
    const params = useVModel(props, 'params', emit)

    const {
      appContentLayoutNav, appSkinVariant, appRouteTransition,
    } = useAppConfig()

    const vm = getCurrentInstance().proxy
    const { handleBreakpointLayoutSwitch } = useLayout()

    handleBreakpointLayoutSwitch()

    onBeforeMount(async () => {
      if (props.locale) {
        vm.$vuetify.lang.current = props.locale
        loadLanguageAsync(props.locale)
      }

      if (props.name) return
      supabase.auth.getSession().then(async ({ data }) => {
        if (!data.session) {
          supabase.removeAllChannels()
        }
      })

      supabase.auth.onAuthStateChange(async (event, session) => {
        if (session === null) {
          userStore.user = null
          userStore.isLoggedIn = null
          supabase.removeAllChannels()
          vm.$ability.update(initialAbility)
          if (event !== 'INITIAL_SESSION') { router.push('/') }
        }
      })
    })

    if (vm.$i18n.locale) { moment.locale(vm.$i18n.locale.replace('en', 'en-gb')) }
    watch(() => vm.$i18n.locale, newVal => {
      moment.locale(newVal.replace('en', 'en-gb'))
    })

    const resolveLayoutVariant = computed(() => {
      if (name.value) return 'layout-empty'
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'empty') return 'layout-empty'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      name,
      params,
    }
  },
}
</script>
