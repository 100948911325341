<template>
  <div v-resize="onResize">
    <v-snackbar
      v-model="isAlertVisible"
      color="error"
      outlined
      text
      shaped
      top
      right
    >
      {{ errors.item }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="error"
          v-bind="attrs"
          @click="isAlertVisible = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="isAlertSuccessVisible"
      color="primary"
      outlined
      text
      shaped
      top
      right
    >
      <span v-if="messageImport && messageImport.updates!==undefined">{{ $t('ItemsImportedDetails', messageImport) }}</span>
      <span v-else>{{ $t('ItemsImported') }}</span>
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isAlertSuccessVisible = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-stepper
      v-model="activeStep"
      class="custom-header overflow-y-auto"
      :height="formHeight"
      style="background-color: rgba(94, 86, 105, 0.04);"
    >
      <!-- Header -->
      <v-stepper-header class="ma-0 pa-0">
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
          :rules="[() => stepValidationResult[1]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3"
              :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
            >01</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
              >{{ $t('ImportData') }}</span>
              <span
                class="text-xs"
                :class="stepValidationResult[1] ? 'text--secondary' : 'error--text'"
              >{{
                $t('ImportDataDetails')
              }}</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="activeStep > 2"
          step="2"
          :rules="[() => stepValidationResult[2]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3"
              :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
            >02</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
              >{{ $t('MappingImport') }}</span>
              <span
                class="text-xs"
                :class="stepValidationResult[2] ? 'text--secondary' : 'error--text'"
              >{{
                $t('MappingImportDetails')
              }}</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="activeStep > 3"
          step="3"
          :rules="[() => stepValidationResult[3]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3"
              :class="stepValidationResult[3] ? 'text--primary' : 'error--text'"
            >03</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[3] ? 'text--primary' : 'error--text'"
              >{{ $t('ConfirmImport') }}</span>
              <span
                class="text-xs"
                :class="stepValidationResult[3] ? 'text--secondary' : 'error--text'"
              >{{
                $t('ConfirmImportDetails')
              }}</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>
      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content
          class="pa-0 ma-0 px-3 pt-3"
          step="1"
        >
          <v-form
            ref="step1Form"
          >
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-card
                  outlined
                  shaped
                >
                  <v-card-title class="font-weight-bold">
                    <v-icon class="me-1">
                      mdi-content-duplicate
                    </v-icon>{{ $t('CopyPaste') }}
                  </v-card-title>
                  <v-card-text>
                    <v-textarea
                      v-model="textArea"
                      class="ma-0 pa-0"
                      outlined
                      clearable
                      @input="onChange"
                    ></v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  outlined
                  shaped
                >
                  <v-card-title class="font-weight-bold">
                    <v-icon class="me-1">
                      mdi-download
                    </v-icon>{{ $t('ImportFile') }}
                  </v-card-title>
                  <v-card-text>
                    <v-btn
                      color="primary"
                      class="me-3"
                      :loading="loadingUpload"
                      :disabled="loadingUpload"
                      @click="uploadClick"
                    >
                      <v-icon :left="!$vuetify.breakpoint.xs">
                        mdi-file-download
                      </v-icon>
                      <span class="d-none d-sm-block">{{ $t('ImportFile') }}</span>
                    </v-btn>

                    <input
                      ref="refInputEl"
                      type="file"
                      accept="application/x-iwork-keynote-sffnumbers,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                      :hidden="true"
                      @change="handleFileSelected"
                    />
                    <p class="text-sm mt-5">
                      {{ $t('AllowedImportFormats') }}
                    </p>
                  </v-card-text>
                </v-card>

                <v-alert
                  v-if="data?.data"
                  border="left"
                  colored-border
                  text
                  color="primary"
                  class="ma-1 mt-3 subtitle-2"
                >
                  {{ header && isCopyPaste ? (data?.data?.length -1): data?.data?.length }} {{ $t('items') }}
                  <v-checkbox
                    v-model="header"
                    :label="$t('Header')"
                  ></v-checkbox>
                </v-alert>
                <v-alert
                  v-for="(error, index) in data?.errors"
                  :key="index"
                  border="left"
                  colored-border
                  text
                  color="error"
                  class="ma-1 subtitle-2"
                >
                  {{ error.message }}
                </v-alert>
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-alert
                  v-if="errors.import"
                  border="left"
                  colored-border
                  text
                  color="error"
                  class="subtitle-2"
                >
                  {{ errors.import }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <!-- Stepper Content: Step 2 -->
        <v-stepper-content
          step="2"
          class="pa-0 ma-0 px-3 pt-3 pb-2"
        >
          <v-form
            ref="step2Form"
          >
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-card
                  outlined
                  shaped
                  class="pa-3 ma-0 mb-3"
                >
                  <v-card-title class="font-weight-semibold">
                    {{ $t('DragDrop') }}
                  </v-card-title>
                  <v-card-subtitle class="d-flex">
                    <v-checkbox
                      v-model="header"
                      :label="$t('Header')"
                    ></v-checkbox>
                    <v-spacer></v-spacer>
                    <span class="text-caption mt-1">{{ header && isCopyPaste ? (data?.data?.length -1): data?.data?.length }} {{ $t('items') }}</span>
                  </v-card-subtitle>
                  <draggable
                    :list="itemsImport"
                    class="list-group"
                    group="dragitem"
                  >
                    <v-alert
                      v-for="(it, i) in itemsImport"
                      :key="i"
                      text
                      border="left"
                      colored-border
                      color="primary"
                      class="text-caption font-weight-bold"
                    >
                      <span style="display: inline-block; overflow: hidden; text-overflow: ellipsis; width:100%; white-space: nowrap;">{{ it.name }}</span>
                    </v-alert>
                  </draggable>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-card
                  outlined
                  shaped
                  class="pa-5 ma-0 mb-3"
                >
                  <v-row>
                    <v-col
                      v-for="(model, t) in models"
                      :key="t"
                      cols="12"
                      md="4"
                      class="ma-0 px-0 py-0"
                    >
                      <div class="ma-0">
                        <span class="font-weight-bold text-overline"> {{ model.name }} <v-icon
                          v-if="model.primary"
                          small
                          color="primary"
                          class="me-2"
                        >mdi-key</v-icon><v-icon
                          v-if="model.required"
                          small
                          color="primary"
                          class="me-2"
                        >mdi-asterisk</v-icon></span>
                        <v-card
                          class="ma-0 mx-1 pa-1"
                          min-height="40"
                          outlined
                        >
                          <draggable
                            class="list-group"
                            :list="list[model.value]"
                            group="dragitem"
                            :disabled="list[model.value]?.length >0"
                            style="min-height:65px;"
                          >
                            <v-alert
                              v-for="(it, l) in list[model.value]"
                              :key="l"
                              border="left"
                              colored-border
                              text
                              style="height:65px"
                              class="pb-0 pt-1 ma-0"
                              color="primary"
                              dismissible
                              @toggle="onClose"
                            >
                              <div>
                                <span
                                  style="display: inline-block; overflow: hidden; text-overflow: ellipsis; width:100%; white-space: nowrap;"
                                  class="text-caption font-weight-bold"
                                >{{ it.name }}</span>
                                <v-switch
                                  v-show="!model.primary"
                                  v-model="model.action"
                                  class="mx-1 mt-1 pa-0 ma-0 text-subtitle-2"
                                  :disabled="model.action === null"
                                  true-value="merge"
                                  false-value="replace"
                                  :label="`${$t(model.action || 'replace')}`"
                                ></v-switch>
                              </div>

                              <template #close="{ toggle }">
                                <v-icon
                                  :style="!model.primary?'top:-10px;':'top:13px;'"
                                  @click="onClose(it, model, toggle)"
                                >
                                  mdi-close
                                </v-icon>
                              </template>
                            </v-alert>
                          </draggable>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <!-- Stepper Content: Step 3 -->
        <v-stepper-content
          step="3"
          class="pa-0 ma-0 px-0 pb-2"
        >
          <v-form
            ref="step3Form"
          >
            <table-content
              v-if="subconfig"
              :config.sync="subconfig"
              :options.sync="options"
              :list-table.sync="listTable"
              :list-local.sync="listLocal"
              :is-delete.sync="isDelete"
              :loading.sync="loading"
              :item="{}"
              :items.sync="items"
              @on-refresh="onRefresh"
            ></table-content>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card class="pa-3 ma-0">
      <div class="d-flex justify-space-between">
        <v-btn
          v-if="activeStep==1"
          outlined
          @click="$emit('on-previous')"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          v-if="activeStep==2"
          outlined
          @click="handleStepValidation(1, false)"
        >
          {{ $t('Previous') }}
        </v-btn>
        <v-btn
          v-if="activeStep==3"
          outlined
          @click="handleStepValidation(2, false)"
        >
          {{ $t('Previous') }}
        </v-btn>
        <v-btn
          v-if="activeStep ===1"
          color="primary"
          class="me-2"
          :loading="loadingStep"
          :disabled="!data"
          @click="handleStepValidation(2, true)"
        >
          {{ $t('Next') }}
        </v-btn>
        <v-btn
          v-if="activeStep ===2"
          color="primary"
          class="me-2"
          :loading="loadingStep"
          :disabled="isDisabled()"
          @click="handleStepValidation(2, true)"
        >
          {{ $t('Next') }}
        </v-btn>

        <div
          v-if="activeStep ===3"
          class="d-flex"
        >
          <v-badge
            :value="!loadingStep"
            :color="countImport(items.length ? items : listTable).errors > 0? 'error' :'secondary'"
            :content="countImport(items.length ? items : listTable).corrects+' ✔  / '+ countImport(items.length ? items : listTable).errors+'  ✖'"
            overlap
            top
            left
          >
            <v-btn
              color="primary"
              class="me-2"
              :loading="loadingStep"
              :disabled="loadingStep || countImport(items.length ? items : listTable).errors > 0"
              @click="handleStepValidation(2, true, true)"
            >
              {{ $t('Complete') }}
            </v-btn>
          </v-badge>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import {
  ref, onMounted, getCurrentInstance, watch,
} from 'vue'
import { useVModel } from '@vueuse/core'
import Draggable from 'vuedraggable'
import { uuidv7 } from 'uuidv7'
import { useTableStore } from '@/stores/table.store'
import TableContent from '@/views/table/TableContent.vue'
import useTable from '@/views/table/useTable'
import { usePropertiesStore } from '@/stores/properties.store'
import {
  sanatize,
} from '@core/utils/validation'
import { useListingsStore } from '@/stores/listings.store'
import { useFoldersStore } from '@/stores/folders.store'
import { TABLE_CONTACTS, getLastFolderID } from '@core/utils'

export default {
  components: {
    Draggable,
    TableContent,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    directory: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const activeStep = ref(1)
    const loadingUpload = ref(false)
    const loadingStep = ref(false)
    const textArea = ref(null)
    const data = ref(null)
    const config = useVModel(props, 'config', emit)
    const subconfig = ref(null)
    const vm = getCurrentInstance().proxy
    const list = ref({})
    const identities = ref({})
    const itemsImport = ref([])
    const types = ref({})
    const models = ref([])
    const formHeight = ref(0)
    const header = ref(false)
    const isAlertSuccessVisible = ref(false)
    const step1Form = ref(null)
    const step2Form = ref(null)
    const step3Form = ref(null)
    const isCopyPaste = ref(false)
    const dataImports = ref(null)
    const messageImport = ref(null)
    const tableStore = useTableStore()
    const propertiesStore = usePropertiesStore()
    const listingsStore = useListingsStore()
    const foldersStore = useFoldersStore()

    const {
      listTable,
      setLocalData,
      listLocal,
      searchQuery,
      totalTable,
      loading,
      item,
      items,
      isDelete,
      options,
      isAlertVisible,
      errors,
      fetchDatas,
    } = useTable(subconfig.value || props.config, false)

    watch(data, () => {
      data.value.data = data.value?.data.filter(row => {
        if (Array.isArray(row)) {
          return row.join('').length !== 0
        }

        return true
      })
      subconfig.value = JSON.parse(JSON.stringify(config.value))
      itemsImport.value = []
      models.value = []
      models.value.push({
        name: 'ID', col: 'ID', value: 'id', action: null, primary: true, required: true,
      })
      Object.keys(config.value?.model).forEach(col => {
        const schema = tableStore.getCurrentSchema(col)
        if (schema) {
          if (config.value.currentTable === TABLE_CONTACTS) {
            const prop = propertiesStore.properties?.find(p => (col.split('->>').length === 2 && col.split('->>')[1] === p.slug))
            if (col.split('->>').length === 2) {
              list.value[col] = []
              types.value[col] = prop?.appearance
              models.value.push({
                name: vm.$t(prop.name), col: prop.name, value: col, action: schema.type === 'array' ? 'merge' : null, primary: prop?.single, required: prop.required,
              })
            }
          } else {
            list.value[col] = []
            types.value[col] = schema.type
            models.value.push({
              name: vm.$t(schema.label), col: schema.label, value: col, slug: col, action: schema.type === 'array' ? 'merge' : null, primary: false, required: schema.rules.length > 0,
            })
          }
        }
      })
      list.value.id = []
      if (data.value && data.value.data?.length > 0) {
        Object.keys(data.value.data[0]).forEach(col => {
          const it = {
            name: `${data.value.data[0][col]} (${col})`, col: isCopyPaste.value ? data.value.data[0][col] : col, value: data.value.data[0][col].toLowerCase(), index: isCopyPaste.value ? parseInt(col, 10) : col, action: 'replace',
          }
          if (!header.value && models.value.find(m => m.col.toLowerCase() === it.col.toLowerCase() || vm.$t(m.col).toLowerCase() === vm.$t(it.col).toLowerCase())) {
            header.value = true
          }
          itemsImport.value.push(it)
        })
      }
      itemsImport.value.forEach((it, index) => {
        const model = models.value.find(m => m.col.toLowerCase() === it.col.toLowerCase() || vm.$t(m.col).toLowerCase() === vm.$t(it.col).toLowerCase())
        if (list.value[model?.value]?.length === 0) {
          list.value[model?.value].push(it)
          itemsImport.value[index] = null
        }
        if (list.value[it.index]?.length === 0) {
          list.value[it.index].push(it)
          itemsImport.value[index] = null
        }
      })
      itemsImport.value = itemsImport.value.filter(it => it !== null)
      subconfig.value?.tableColumns.splice(0, 0, {
        text: 'Conflict',
        value: '_conflict',
        type: 'simple-checkbox',
        sortable: true,
        filterable: true,
      })
      subconfig.value?.tableColumns.splice(0, 0, {
        text: 'Error',
        value: '_error',
        type: 'simple-checkbox',
        sortable: true,
        filterable: true,
      })
    })

    const stepValidationResult = ref({
      1: true,
      2: true,
      3: true,
    })

    const isConflict = importData => {
      let isIn = false
      Object.keys(importData).forEach(key => {
        if (identities[key]) {
          const obj = identities[key].find(it => it.name === importData[key])

          if (obj) isIn = true
        }
      })

      return isIn
    }

    onMounted(() => {
      vm.$refs.refInputEl = vm.$el.querySelector('input[type=file]')
      vm.$refs.refInputEl.style.display = 'none'
    })

    const handleFileSelected = async e => {
      loadingUpload.value = true
      import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({ excel2json }) => {
        excel2json(e.target.files, sheets => {
          data.value = { data: [] }
          isCopyPaste.value = false
          Object.values(sheets).forEach(rows => {
            data.value.data = data.value.data.concat(rows)
          })
          loadingUpload.value = false
        }, '')
      }).catch(error => {
        loadingUpload.value = false
        errors.value = {
          import: JSON.stringify(error),
        }
      })
    }

    const uploadClick = () => {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      vm.$refs.refInputEl.dispatchEvent(event)
    }

    const onChange = () => {
      if (textArea.value) {
        data.value = vm.$papa.parse(textArea.value)
        isCopyPaste.value = true
      } else { data.value = null }
    }

    const onClose = (e, model, toggle) => {
      list.value[model.value] = []
      itemsImport.value.push(e)
      toggle()
    }

    const isDisabled = () => {
      let count = 0
      Object.values(list.value).forEach(array => {
        count += array.length
      })

      return count === 0
    }

    const onResize = () => {
      formHeight.value = window.innerHeight - 320
    }

    const onRefresh = () => {
      fetchDatas()
    }

    const onSave = () => {
      errors.value = {}
      dataImports.value = []
      if (items.value.length === 0) {
        dataImports.value = JSON.parse(JSON.stringify(listTable.value))
      } else {
        dataImports.value = JSON.parse(JSON.stringify(items.value))
      }
      const listingId = uuidv7()
      if (dataImports.value.length) {
        dataImports.value.forEach(it => {
          if (!it.listings) it.listings = {}
          it.listings[listingId] = true
          it.project_id = vm.$router.currentRoute.params.id
          Object.keys(it).forEach(key => {
            if (key === 'id' && it[key] === '') {
              delete it[key]
            }
          })
        })
      }
      loadingStep.value = true
      tableStore
        .upsert(dataImports.value)
        .then(d => {
          if (config.value.currentTable === TABLE_CONTACTS) {
            listingsStore.upsert([{
              id: listingId,
              name: `Import ${vm.$moment(new Date()).format('l')} ${vm.$moment(new Date()).format('LT')}`,
              type: 'fixed',
              folder_id: getLastFolderID(props.directory) || foldersStore.root?.id,
              project_id: vm.$router.currentRoute.params.id,
            }])
          }
          messageImport.value = d
          isAlertSuccessVisible.value = true
          setTimeout(() => {
            item.value = null
            loadingStep.value = false

            // emit('on-refresh')
            emit('on-previous', config.value.currentTable ? 2 : 0)
          }, 2000)
        })
        .catch(error => {
          isAlertVisible.value = true
          loadingStep.value = false
          if (error?.response) {
            errors.value = {
              item: vm.$t(error.response.data.message, error.response.data.params) || 'error',
            }
          } else {
            errors.value = {
              item: vm.$t(error?.message, error?.params) || 'error',
            }
          }
        })
    }

    // Step Validation
    const handleStepValidation = async (step, navigateNext = true, isFinalStep = false) => {
      // Get Form templateRef of Step from argument
      const templateRef = (() => {
        if (step === 1) return step1Form.value
        if (step === 2) return step2Form.value
        if (step === 3) return step3Form.value

        return null
      })()

      // If no validate step templateRef is found just return
      if (!templateRef) return

      // If step is valid proceed to the next step
      const isStepValid = templateRef.validate()

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid

      if (activeStep.value === 2 && navigateNext) {
        loadingStep.value = true
        subconfig.value.tableColumns = subconfig.value.tableColumns?.filter(col => col.value === '_error' || col.value === '_conflict' || col.value === 'actions' || list.value[col.value]?.length > 0)
        const localData = []
        const mapping = {}
        await Promise.all(Object.keys(list.value).map(async col => {
          if (list.value[col].length > 0) {
            mapping[list.value[col][0].index] = col

            const model = models.value.find(c => c.value === col)
            if (model?.primary && model.value !== 'id') {
              identities[col] = await tableStore.getKeys(vm.$router.currentRoute.params.id, model.value)
            }
          }
        }))

        data.value.data?.forEach((d, i) => {
          if (!header.value || i !== 0 || !isCopyPaste.value) {
            const newData = {}
            // eslint-disable-next-line no-underscore-dangle
            newData._error = false
            Object.keys(mapping).forEach(index => {
              const value = sanatize(types.value[mapping[index]], d[index])
              if (value === null) {
              // eslint-disable-next-line no-underscore-dangle
                newData._error = true
                newData[mapping[index]] = d[index]
              } else {
                newData[mapping[index]] = value
              }
            })
            Object.keys(identities).forEach(k => {
              if (newData[k]) {
                const id = identities[k].find(it => it.name === newData[k])
                if (id) newData.id = id.id
              }
            })
            // eslint-disable-next-line no-underscore-dangle
            newData._conflict = isConflict(newData)
            if (!newData.id) { newData.id = uuidv7() }
            localData.push(newData)
          }
        })
        setLocalData(localData, subconfig.value.tableOptions)
        loadingStep.value = false
      }

      if (isStepValid) {
        if (navigateNext && !isFinalStep) activeStep.value += 1
        else if (!navigateNext) activeStep.value -= 1
      }

      if (isFinalStep && isStepValid) {
        onSave()
      }
    }

    const countImport = array => {
      // eslint-disable-next-line no-underscore-dangle
      if (Array.isArray(array)) { return { errors: array.filter(it => it._error === true).length, corrects: array.filter(it => it._error === false).length } }

      return { errors: 0, corrects: 0 }
    }

    return {
      handleStepValidation,
      activeStep,
      loadingUpload,
      loadingStep,

      // Step Validations
      stepValidationResult,

      // Template Refs
      step1Form,
      step2Form,
      step3Form,
      formHeight,
      handleFileSelected,
      uploadClick,
      textArea,
      data,
      onChange,
      onClose,
      onResize,
      onRefresh,
      items,
      itemsImport,
      models,
      list,
      isDisabled,
      listTable,
      listLocal,
      searchQuery,
      totalTable,
      loading,
      item,
      isDelete,
      options,
      isAlertVisible,
      errors,
      fetchDatas,
      subconfig,
      header,
      isAlertSuccessVisible,
      isCopyPaste,
      countImport,
      dataImports,
      messageImport,
    }
  },
}
</script>
<style>
.v-label{
  font-size:12px;
}
</style>
