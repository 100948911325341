<template>
  <v-row v-observe-visibility="visibilityChanged"
         class="pa-3 mt-2"
  >
    <v-col
      cols="12"
      lg="9"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("Invoices") }}
          </h5>
        </v-card-title>
        <v-divider class="mx-5"></v-divider>
        <!-- search -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex flex-grow-1 align-center pb-3">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              :placeholder="$t('SearchInvoice')"
              clearable
              prepend-inner-icon="mdi-file-find"
              clear-icon="mdi-close"
              class="me-3 search-table"
            ></v-text-field>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center pb-3">
            <v-select
              v-model="statusFilter"
              :items="statusOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              :placeholder="$t('SelectStatus')"
              class="invoice-list-status"
            >
              <template #selection="data">
                {{ $t(data.item) }}
              </template>
              <template #item="data">
                {{ $t(data.item) }}
              </template>
            </v-select>
          </div>
        </v-card-text>

        <v-data-table
          v-model="selectedTableData"
          :headers="tableColumns"
          :items="listTable"
          :options.sync="options"
          :server-items-length="totalListTable"
          :loading="loading"
          class="text-no-wrap"
        >
          <template #[`item.id`]="{item}">
            <span class="text-no-wrap font-weight-bold primary--text">#{{ item.number }}</span>
          </template>

          <template #[`item.total`]="{item}">
            <span class="text-no-wrap font-weight-bold">{{ getCurrency(item) }} {{ item.total / 100 }}</span>
          </template>

          <template #[`item.status`]="{item}">
            <v-chip
              :color="item.status ==='paid' ? 'primary':'secondary'"
              :class="`text-uppercase v-chip-light-bg ${item.status==='paid' ? 'primary':'secondary'}--text font-weight-semibold`"
            >
              {{ $t(item.status) }}
            </v-chip>
          </template>

          <template #[`item.created_at`]="{item}">
            <span class="text-no-wrap">{{ $moment(item.created_at).format("lll") }}</span>
          </template>

          <template #[`item.actions`]="{item}">
            <div class="d-flex align-center justify-center">
              <v-tooltip
                v-if="item.status==='paid'"
                open-delay="500"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    x-small
                    outlined
                    color="primary"
                    :href="item.invoice_url"
                    target="_blank"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-text-box-search-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span class="d-none d-sm-block">{{ $t("ViewInvoice") }}</span>
              </v-tooltip>
              <v-btn
                v-else
                small
                outlined
                color="primary"
                :href="item.invoice_url"
                target="_blank"
              >
                <v-icon
                  :left="!$vuetify.breakpoint.xs"
                >
                  mdi-credit-card-sync-outline
                </v-icon>
                <span class="d-none d-sm-block">{{ $t("PayInvoice") }}</span>
              </v-btn>
              <v-btn
                class="ml-5"
                x-small
                outlined
                color="secondary"
                :href="item.invoice_pdf"
                target="_blank"
              >
                <v-icon
                  center
                >
                  mdi-download
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, getCurrentInstance, onBeforeMount } from 'vue'
import useInvoicesList from './useInvoiceList'

export default {
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const statusOptions = ref(['open', 'draft', 'paid', 'uncollectible', 'void'])
    const vm = getCurrentInstance().proxy
    const {
      listTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalListTable,
      loading,
      selectedTableData,
      fetchDatas,
    } = useInvoicesList(vm.$router.currentRoute.params.id)

    const visibilityChanged = isVisible => {
      if (isVisible) {
        fetchDatas()
      }
    }

    onBeforeMount(() => {
      tableColumns.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
    })

    const getCurrency = item => {
      if (item.currency === 'eur') { return '€' }
      if (item.currency === 'usd') { return '$' }

      return '$'
    }

    return {
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,
      statusOptions,
      getCurrency,
      visibilityChanged,
    }
  },
}
</script>
