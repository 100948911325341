<template>
  <v-col
    md="4"
    sm="6"
    cols="12"
  >
    <v-card
      :disabled="!$canACL('view', 'organizations',organization)"
      elevation="10"
      @click.prevent="gotToOrganization"
    >
      <v-card-text class="position-relative">
        <v-btn
          v-if="organization"
          class="float-right mt-5"
          color="primary"
        >
          {{ $t('View') }}
        </v-btn>
        <v-skeleton-loader
          v-if="!organization"
          class="float-right mt-5"
          width="80"
          type="button"
        ></v-skeleton-loader>
        <div class="d-flex justify-space-between flex-wrap pt-5 ">
          <div class="me-2 mb-2">
            <v-card-title
              v-if="organization"
              class="pt-0 px-0 font-weight-bold text-truncate mr-8"
            >
              <h5 class="font-weight-bold text-uppercase">
                {{ organization.name }}
              </h5>
            </v-card-title>
            <v-skeleton-loader
              v-if="!organization"
              width="200"
              type="heading"
            ></v-skeleton-loader>
            <v-card-subtitle
              v-if="organization"
              class="text-xs pa-0"
            >
              {{ organization.email || '-' }}
            </v-card-subtitle>
            <v-skeleton-loader
              v-if="!organization"
              class="mt-2"
              width="200"
              type="text"
            ></v-skeleton-loader>
          </div>
        </div>

        <!-- Mutual Friends -->
        <div class="d-flex justify-space-between align-center mt-8">
          <div>
            <span
              v-if="organization"
              v-show="$canACL('view', 'projects', organization)"
              class="text--primary font-weight-medium me-2"
            >
              {{ organization?.projects?.length }} <v-icon size="20">
                mdi-folder-table</v-icon>
            </span>
            <span v-show="$canACL('view', 'members', organization)"
                  class="text--primary font-weight-medium me-2"
            >
              {{ organization?.members?.length }}   <v-icon size="20">
                mdi-account-group
              </v-icon></span>
          </div>
          <div
            v-if="organization && organization.subscription"
          >
            <v-chip
              small
              :color="statusColor"
              class="text-uppercase"
            >
              {{ $t(organization.subscription.status) }}
            </v-chip>
          </div>

          <div
            v-else-if="organization"
          >
            <v-chip
              small
              color="error"
              class="text-uppercase"
            >
              {{ $t('incomplete') }}
            </v-chip>
          </div>
          <avatar-members
            v-if="organization"
            :members="organization.members"
            xsmall
          ></avatar-members>
          <v-skeleton-loader
            v-if="!organization"
            class="v-avatar-group"
            width="340"
            type="text"
          ></v-skeleton-loader>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import {
  getCurrentInstance, toRef, computed,
} from 'vue'
import { useOrganizationsStore } from '@/stores/organizations.store'
import AvatarMembers from '@/components/AvatarMembers.vue'

export default {
  components: {
    AvatarMembers,
  },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const organizationsStore = useOrganizationsStore()
    const organization = toRef(props, 'organization')

    const statusColor = computed(() => {
      if (!organization.value || !organization.value.subscription) return ''
      if (organization.value.subscription?.status === 'incomplete'
      || organization.value.subscription?.status === 'incomplete_expired'
      || organization.value.subscription?.status === 'canceled'
      || organization.value.subscription?.status === 'unpaid'
      || organization.value.subscription?.status === 'past_due'
      || organization.value.subscription?.status === 'quota_exceeded') {
        return 'error'
      }

      if (organization.value.subscription?.status === 'trialing') { return 'warning' }

      return 'primary'
    })

    const gotToOrganization = async () => {
      organizationsStore.organization = await organizationsStore.getOrganization(props.organization.id)
      vm.$router.push({ name: 'edit-organization', params: { id: props.organization.id, tab: 0 } })
    }

    return {
      gotToOrganization,
      statusColor,
    }
  },
}
</script>

<style lang="">
</style>
