<template>
  <layout-empty>
    <slot></slot>
  </layout-empty>
</template>

<script>
import LayoutEmpty from '@/@core/layouts/empty/LayoutEmpty.vue'

export default {
  components: {
    LayoutEmpty,
  },
}
</script>

<style>
</style>
