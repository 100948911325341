<template>
  <div class="user-profile-sidebar-content h-full">
    <!-- Close Button -->
    <div class="pt-2 me-2 text-right">
      <v-btn
        icon
        small
        @click="$emit('close-chat-info')"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </div>

    <!-- User Avatar + Name + Role -->
    <div class="text-center px-6 pt-4">
    </div>

    <!-- User Data -->
    <perfect-scrollbar
      class="ps-chat-user-profile-sidebar-content pb-6 px-6"
      :options="perfectScrollbarOptions"
    >
      <v-list class="py-0">
        <!-- Header -->
        <v-list-item
          class="d-flex"
        >
          <div class="d-flex align-center justify-space-between flex-grow-1">
            <span class="font-weight-semibold">{{ chat.name }}</span>

            <p
              v-if="chat.members"
              class="mb-0 text--primary"
            >
              {{ chat.members.length }} <v-icon small>
                mdi-account-outline
              </v-icon>
            </p>
          </div>
        </v-list-item>
        <v-divider></v-divider>

        <template v-for="(member, index) in chat.members">
          <v-list-item
            :key="member.admin_id"
            link
          >
            <!-- Avatar -->
            <v-list-item-avatar
              class="v-avatar-light-bg primary--text justify-center"
              size="38"
            >
              <avatar-member
                small
                :member="getAdmin(member)"
              ></avatar-member>
            </v-list-item-avatar>

            <!-- Content -->
            <v-list-item-content
              v-if="getAdmin(member)"
              class="d-block"
            >
              <v-list-item-title class="text-sm font-weight-semibold">
                {{ getAdmin(member).fullname }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-sm">
                {{ getAdmin(member).email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
// eslint-disable-next-line object-curly-newline
import useVuetify from '@core/utils/vuetify'
import { avatarText } from '@core/utils/filter'
import AvatarMember from '@/components/AvatarMember.vue'
import useChat from './useChat'

export default {
  components: {
    PerfectScrollbar,
    AvatarMember,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { resolveAvatarBadgeVariant } = useChat()
    const { rootThemeClasses } = useVuetify()

    const userStatusRadioOptions = [
      { title: 'Online', value: 'online', color: 'success' },
      { title: 'Away', value: 'away', color: 'warning' },
      { title: 'Do not disturb', value: 'busy', color: 'error' },
      { title: 'Offline', value: 'offline', color: 'secondary' },
    ]

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const getAdmin = member => props.contacts.find(contact => contact.id === member.admin_id)

    return {
      // useChat
      resolveAvatarBadgeVariant,

      userStatusRadioOptions,

      // Perfect Scrollbar
      perfectScrollbarOptions,

      // Vuetify
      rootThemeClasses,

      // Filter
      avatarText,
      getAdmin,
    }
  },
}
</script>

<style>
</style>
