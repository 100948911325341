<template>
  <v-app>
    <v-main>
      <slot></slot>
    </v-main>
    <div
      class="d-flex"
    >
    </div>
  </v-app>
</template>

<script>

export default {
  components: {
  },
}
</script>

<style scoped>
.v-application {
  background: none !important;
}
</style>
