<template>
  <div class="chat-left-sidebar h-full">
    <div class="d-flex align-center pa-4">
      <v-badge
        :value="false"
        bottom
        overlap
        dot
        bordered
        color="primary"
        offset-x="11"
        offset-y="11"
        class="me-3 user-status-badge"
      >
        <v-avatar
          size="2.375rem"
          :class="`v-avatar-light-bg primary--text`"
          @click="$emit('open-chat-user-profile-sidebar-content', true); $vuetify.breakpoint.smAndDown && $emit('close-left-sidebar')"
        >
          <v-img
            v-if="profileUserMinimalData.avatar"
            :src="profileUserMinimalData.avatar"
          ></v-img>
          <span v-else>{{ avatarText(profileUserMinimalData.fullname) }}</span>
        </v-avatar>
      </v-badge>
      <v-text-field
        v-model="searchQuery"
        :placeholder="$t('Search')"
        hide-details
        outlined
        dense
        class="chat-search"
        clearable
        clear-icon="mdi-close"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-icon
        class="d-inline-flex d-md-none ms-1"
        @click="$emit('close-left-sidebar')"
      >
        mdi-close
      </v-icon>
    </div>

    <v-divider></v-divider>

    <perfect-scrollbar
      :options="perfectScrollbarOptions"
      class="ps-chat-left-sidebar pt-7 pb-3 px-3"
    >
      <!-- Chats -->
      <div class="d-flex">
        <p class="font-weight-medium text-xl primary--text ms-3">
          {{ $t('Chats') }} ({{ totalListTable }})
        </p>
        <v-spacer></v-spacer>

        <v-tooltip
          open-delay="500"
          top
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              small
              v-bind="attrs"
              v-on="on"
              @click="addChat"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("CreateChat") }}</span>
        </v-tooltip>
      </div>
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <chat-contact
        v-for="chat in chats"
        v-else
        :key="`chat-${chat.id}`"
        :chat="chat"
        :user="user"
        :contacts="contacts"
        :is-active="activeChatId === chat.id"
        :class="{'bg-gradient-primary active-chat-contact': activeChatId === chat.id}"
        @click="$emit('open-chat', chat.id)"
      />
      <v-pagination
        v-model="page"
        :length="nbPages"
      ></v-pagination>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import useChat from '@/views/chats/useChat'
import { avatarText } from '@core/utils/filter'
import ChatContact from './ChatContact.vue'

export default {
  components: {
    PerfectScrollbar,
    ChatContact,
  },
  props: {
    activeChatId: {
      type: String,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    chats: {
      type: Array,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      totalListTable, searchQuery, page, nbPages, loading,
    } = useChat()

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    const addChat = () => {
      emit('add-chat')
    }

    return {
      // Perfect Scrollbar options
      perfectScrollbarOptions,

      // Filter
      avatarText,
      addChat,
      searchQuery,
      page,
      totalListTable,
      nbPages,
      loading,

    }
  },
}
</script>
