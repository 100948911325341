import {
  TABLE_CONTACTS, TABLE_FOLDERS, TABLE_PROPERTIES, TABLE_MODELS, TABLE_LISTINGS, TABLE_TEMPLATES, TABLE_CAMPAIGNS, TABLE_APPS, TABLE_ACTIVITIES, TABLE_LOGS, TABLE_WORKFLOWS, TABLE_FORMS, TABLE_FILES,
} from '@core/utils'

const projects = [
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/pages/projects/Projects.vue'),
    meta: {
      layout: 'content',
      resource: 'projects',
      action: 'view',
    },
  },
  {
    path: '/create/project',
    name: 'create-project',
    component: () => import('@/pages/projects/CreateProject.vue'),
    meta: {
      layout: 'content',
      resource: 'projects',
      action: 'create',
    },
  },
  {
    path: '/dashboard/project/:id',
    name: 'dashboard',
    component: () => import('@/pages/projects/Dashboard.vue'),
    meta: {
      layout: 'content',
      resource: 'dashboard',
      action: 'view',
    },
  },
  {
    path: '/settings/:tab/project/:id/',
    name: 'settings',
    component: () => import('@/pages/projects/Project.vue'),
    meta: {
      layout: 'content',
      resource: 'settings',
      action: 'view',
    },
  },
  {
    path: '/contacts/project/:id/:folder/:mode/:path/',
    name: TABLE_CONTACTS,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'contacts',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/apps/project/:id/',
    name: TABLE_APPS,
    component: () => import('@/pages/projects/Apps.vue'),
    meta: {
      layout: 'content',
      resource: 'projects',
      action: 'view',
    },
  },
  {
    path: '/campaigns/project/:id/:folder/:mode/:path/',
    name: TABLE_CAMPAIGNS,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'campaigns',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/activities/project/:id/:mode/:path/',
    name: TABLE_ACTIVITIES,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'activities',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/workflows/project/:id/:folder/:mode/:path/',
    name: TABLE_WORKFLOWS,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'workflows',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/properties/project/:id/:folder/:mode/:path/',
    name: TABLE_PROPERTIES,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'properties',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/models/project/:id/:mode/:path/',
    name: TABLE_MODELS,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'models',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/folders/project/:id/:folder/:mode/:path/',
    name: TABLE_FOLDERS,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'folders',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/listings/project/:id/:folder/:mode/:path/',
    name: TABLE_LISTINGS,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'listings',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/templates/project/:id/:folder/:mode/:path/',
    name: TABLE_TEMPLATES,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'templates',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/forms/project/:id/:folder/:mode/:path/',
    name: TABLE_FORMS,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'forms',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/table/project/:id/:folder/:mode/:path/',
    name: 'table',
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'projects',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/logs/project/:id/:mode/:path/',
    name: TABLE_LOGS,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'logs',
      action: 'view',
      table: true,
    },
  },
  {
    path: '/files/project/:id/:folder/:mode/:path/',
    name: TABLE_FILES,
    component: () => import('@/pages/projects/Table.vue'),
    meta: {
      layout: 'content',
      resource: 'files',
      action: 'view',
      table: true,
    },
  },
]

export default projects
