import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase, supabaseDomain } from '@/plugins/supabase'
import { getChildren } from '@core/utils/folders'
import { required } from '@core/utils/validation'
import { useChatsStore } from '@/stores/chats.store'
import {
  TABLE_CONTACTS, TABLE_FOLDERS, TABLE_PROPERTIES, TABLE_LISTINGS, displayMode,
} from '@core/utils'

// eslint-disable-next-line import/prefer-default-export
export const useFoldersStore = defineStore({
  id: TABLE_FOLDERS,
  state: () => ({
    config: null,
    folders: [],
    allFolders: {},
    root: null,
  }),
  actions: {
    getHeader(payload, item) {
      return {
        title: payload?.folder?.title || 'Folders',
        icon: 'mdi-folder-multiple',
        folder: payload?.folder,
        display: displayMode(TABLE_FOLDERS, 'grid'),
        newData: 'NewFolder',
        actionData: 'EditFolders',
        editData: 'EditFolder',
        search: 'SearchFolder',
        searchIcon: 'mdi-folder-search',
        add: {
          text: 'NewFolder',
          icon: 'mdi-folder-plus',
        },
        actions: [{
          title: 'Save', icon: 'mdi-content-save-outline', action: 'save', loading: false, acl: 'create,update',
        }, {
          title: 'Duplicate',
          icon: 'mdi-content-duplicate',
          action: 'duplicate',
          acl: 'create',
          disabled: !item?.created_at,
        },
        {
          title: 'Delete',
          icon: 'mdi-delete-outline',
          action: 'delete',
          acl: 'delete',
          color: 'error',
          disabled: !item?.created_at,
        }],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['created_at'],
        sortDesc: [true],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: 12,
        fixedHeader: false,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
          search: true,
        },
        {
          text: 'Path',
          value: 'parent_id',
          type: 'folder',
          filterable: true,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        // general
        name: null,
        parent_id: null,
        project_id: payload?.project_id,
      }
    },

    getSchema(item, items, payload) {
      const chatsStore = useChatsStore()

      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }

      schema.col1.schema.general = {
        title: 'General',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'pa-3 ma-2',
        schema: {
          name: {
            type: 'text',
            label: 'Name',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-text',
            col: 12,
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
          },
          parent_id: {
            type: 'select',
            subtype: 'folders',
            label: 'Path',
            class: 'mx-1 mb-2',
            col: 12,
            clearable: true,
            complex: true,
            prependInnerIcon: 'mdi-folder-open',
            disabled: item?.root,
            items: this.folders.filter(f => f.id !== item?.id),
            dense: true,
          },
        },
      }

      if (item?.id) {
        if (!schema.col2.schema) schema.col2.schema = {}

        schema.col2.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col2.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col2.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col2.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col2.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col2.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          label: 'UpdatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }

      return schema
    },

    async onAction(action, payload) {
      return payload?.item
    },

    formatItems(items, payload) {
      const folders = items
      payload?.folder?.children?.forEach(child => {
        if (!folders.find(f => f.id === child.id)) {
          folders.push({
            id: child.id,
            name: `${child.title} (${child.count})`,
            icon: child.icon,
          })
        }
      })

      return folders
    },

    async getFolders(projectId, path) {
      let select = '*, folders(id)'
      if (path === TABLE_CONTACTS || path === TABLE_LISTINGS) {
        select = '*, folders(id), listings(id, name, objects)'
      } else if (path === TABLE_PROPERTIES) {
        select = '*, folders(id), properties(*)'
      } else if (path === TABLE_FOLDERS) {
        select = '*, properties(id), folders(id), listings(id, name, objects),  campaigns(id, name),  templates(id, name),  workflows(id, name), files(id, name)'
      }
      const { data, count, error } = await supabase
        .from(TABLE_FOLDERS)
        .select(select, { count: 'exact' })
        .eq('project_id', projectId)
        .order('created_at')
      if (error) {
        return Promise.reject(error)
      }
      this.folders = data
      this.root = data.find(f => f.root === true)

      return { data, count }
    },

    async getFolder(projectId, id) {
      const { data, error } = await supabase
        .from(TABLE_FOLDERS)
        .select('*, properties(id), folders(id), listings(id, name, objects),  campaigns(id, name),  templates(id, name),  workflows(id, name), files(id, name)')
        .match({ project_id: projectId, id })
        .single()
      if (error) {
        return Promise.reject(error)
      }
      this.folders?.findIndex(f => f.id === data.id)
      const index = this.folders.findIndex(it => it.id === data.id)
      if (index !== -1) this.folders.splice(index, 1, data)

      this.allFolders[data.id] = data

      return data
    },

    async upsert(folders) {
      const { data, error } = await supabase.from(TABLE_FOLDERS.replace('_view', '')).upsert(folders).select()
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async deleteFolders(object) {
      let query
      if (object.id) query = await supabase.rpc('delete_folder', { folderid: object.id, projectid: object.project_id })
      else {
        object.forEach(async item => {
          query = await supabase.rpc('delete_folder', { folderid: item.id, projectid: item.project_id })
        })
      }
      if (query.error) {
        return Promise.reject(query.error)
      }

      return true
    },

    async listenFolders(projectId) {
      if (supabaseDomain.indexOf('localhost') < 0) {
        return supabase
          .channel('listenFolders')
          .on(
            'postgres_changes',
            {
              event: '*',
              schema: 'public',
              table: TABLE_FOLDERS.replace('_view', ''),
              filter: `project_id=eq.${projectId}`,
            },
            async payload => {
              this.$patch({ eventType: payload.eventType, item: payload.new.id ? payload.new : payload.old })
            },
          )
          .subscribe()
      }

      return null
    },

    getNestedChildren(type, folders, parent) {
      const out = []
      folders.forEach(folder => {
        // folder.disabled = (!folder.listings || folder.listings?.length === 0) && (!folder.properties || folder.properties?.length === 0) && (!folder.folders || folder.folders?.length === 0)
        this.allFolders[folder.id] = folder
        if (type === TABLE_FOLDERS) {
          folder.query = { column: 'parent_id', operator: 'eq', value: folder.id }
        } else if (type === TABLE_CONTACTS) {
          folder.query = {}
        } else {
          folder.query = { column: 'folder_id', operator: 'eq', value: folder.id }
        }
        // eslint-disable-next-line eqeqeq
        if (folder.parent_id == parent) {
          const children = this.getNestedChildren(type, folders, folder.id)
          if (children.length) {
            folder.children = children

            // folder.disabled = false
            const childs = getChildren(type, folder)
            childs.forEach(child => {
              this.allFolders[child.id] = child
              folder.children.push(child)
            })
          } else {
            folder.children = []
            const childs = getChildren(type, folder)
            childs.forEach(child => {
              // folder.disabled = false
              this.allFolders[child.id] = child
              folder.children.push(child)
            })
          }
          out.push(folder)
        }
      })

      return out
    },

    getTree(type, folders) {
      return this.getNestedChildren(type, folders || this.folders)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFoldersStore, import.meta.hot))
}
