<template>
  <div class="form-builder">
    <div class="form-builder-container">
      <div class="form-builder-main">
        <components :groups="groups"
                    :style="style"
                    style="position: absolute; left:0; width:300px;"
                    :composants="composants"
        ></components>

        <div class=" d-flex flex-column">
          <div
            style="margin-right:300px; margin-left:300px; overflow-y: scroll;background-color: rgba(94, 86, 105, 0.04);"
          >
            <div v-if="form.list.length == 0"
                 class="form-empty"
            >
              {{ $t('fm.description.containerEmpty') }}
            </div>
            <v-form
              :style="getStyle()"
              @submit.prevent="onSubmit"
            >
              <div class="widget-form-container"
                   :style="style"
              >
                <draggable
                  v-model="form.list"
                  v-bind="{ group: 'people', ghostClass: 'ghost', animation: 200, handle: '.drag-widget' }"
                  @add="handleWidgetAdd"
                >
                  <transition-group name="fade"
                                    tag="div"
                                    class="widget-form-list"
                  >
                    <template v-for="(element, index) in form.list">
                      <template v-if="element.options?.appearance == 'grid'">
                        <v-row
                          :key="element.key"
                          no-gutters
                          :align="form.options.align"
                          :justify="form.options.justify"
                          class="widget-col widget-view"
                          :class="{ active: selectWidget.key == element.key }"
                          @click="handleSelectWidget(index)"
                        >
                          <v-col v-for="(col, colIndex) in element.columns"
                                 :key="colIndex"
                                 :cols="col.cols"
                                 :class="getClass(col.options)"
                          >
                            <draggable
                              v-model="col.list"
                              :no-transition-on-drag="true"
                              v-bind="{ group: 'people', ghostClass: 'ghost', animation: 200, handle: '.drag-widget' }"
                              @add="handleWidgetColAdd($event, element, colIndex)"
                            >
                              <transition-group name="fade"
                                                tag="div"
                                                class="widget-col-list"
                              >
                                <template v-for="(el, i) in col.list">
                                  <widget-form-item
                                    v-if="el.key"
                                    :key="el.key"
                                    :element="el"
                                    :select.sync="selectWidget"
                                    :index="i"
                                    :data="col"
                                    :options="form.options"
                                  >
                                  </widget-form-item>
                                </template>
                              </transition-group>
                            </draggable>
                          </v-col>
                          <div v-if="selectWidget.key == element.key"
                               class="widget-view-action widget-col-action"
                          >
                            <v-icon style="color:white !important;"
                                    small
                                    class="mx-1"
                                    @click.stop="handleWidgetDelete(index)"
                            >
                              mdi-delete-outline
                            </v-icon>
                          </div>

                          <div v-if="selectWidget.key == element.key"
                               class="widget-view-drag widget-col-drag"
                          >
                            <v-icon style="color:white !important;"
                                    small
                                    class="mx-1 drag-widget"
                            >
                              mdi-arrow-all
                            </v-icon>
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <widget-form-item
                          v-if="element && element.key"
                          :key="element.key"
                          :element="element"
                          :select.sync="selectWidget"
                          :index="index"
                          :data="form"
                          :options="form.options"
                        ></widget-form-item>
                      </template>
                    </template>
                  </transition-group>
                </draggable>
              </div>
            </v-form>
          </div>
        </div>
        <settings :form="form"
                  :style="style"
                  style="position: absolute; top: 0px; right:0; width:300px;"
                  :groups="groups"
                  :composants="composants"
                  :tab-settings.sync="tabSettings"
                  :select-widget.sync="selectWidget"
                  @add-property="addProperty"
        ></settings>
      </div>
    </div>
  </div>
</template>
<script>
import { usePropertiesStore } from '@/stores/properties.store'
import appearances from '@/views/projects/settings/appearances.json'
import Components from '@/views/projects/settings/form-builder/Components.vue'
import Settings from '@/views/projects/settings/form-builder/Settings.vue'
import WidgetFormItem from '@/views/projects/settings/form-builder/WidgetFormItem.vue'
import types from '@/views/projects/settings/types.json'
import { useVModel } from '@vueuse/core'
import {
  computed,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from 'vue'
import Draggable from 'vuedraggable'

import { useModelsStore } from '@/stores/models.store'

export default {
  components: {
    Draggable,
    Components,
    Settings,
    WidgetFormItem,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const form = ref({
      list: [],
      options: {
        align: 'center', justify: 'center', width: 100, fontfamily: 'Arial, sans-serif',
      },
    })
    const item = useVModel(props, 'item', emit)
    const propertiesStore = usePropertiesStore()
    const modelsStore = useModelsStore()
    const vm = getCurrentInstance().proxy
    const searchQuery = ref(null)
    const selectWidget = ref({})
    const composants = ref({})
    const groups = ref([])
    const tabSettings = ref(1)
    const style = computed(() => `height: ${window.innerHeight - 340}px;`)

    const getStyle = () => ` width:${form.value.options.width}%; font-family:${form.value.options.fontfamily} !important;`

    watch(form, () => {
      if (form.value.list && form.value.options) {
        item.value['payload->>list'] = form.value.list
        item.value['payload->>options'] = form.value.options
      }
    }, { deep: true })

    onMounted(() => {
      modelsStore.getModels(vm.$router.currentRoute.params.id)
      propertiesStore.getProperties(vm.$router.currentRoute.params.id, 'contacts').then(properties => {
        const initList = [
          {
            key: `${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}`,
            columns: [
              {
                cols: 12,
                list: [
                ],
                options: {
                  text: 'left',
                },
              },
            ],
            options: {
              type: 'others',
              appearance: 'grid',
            },
          },
        ]
        properties.forEach(p => {
          initList[0].columns[0].list.push({
            key: p.slug,
            options: {
              ...p,
            },
          })
        })
        initList[0].columns[0].list.push({
          key: 'optin',
          options: {
            name: 'I agree and accept the data privacy statement.',
            type: 'boolean',
            required: true,
            appearance: 'simple-checkbox',
          },
        })
        initList[0].columns[0].list.push({
          key: 'html',
          options: {
            name: 'By clicking below to submit this form, you acknowledge that the information you provided will be transferred to Micleo for processing in accordance with their terms of use',
            appearance: 'html',
          },
        })
        initList[0].columns[0].list.push({
          key: 'button',
          options: {
            name: 'Send',
            type: 'others',
            outlined: true,
            appearance: 'button',
          },
        })
        form.value = {
          list: (item.value['payload->>list'] || item.value.payload?.list) || initList,
          options: (item.value['payload->>options'] || item.value.payload?.options) || {
            align: 'start',
            justify: 'start',
            width: 100,
            fontfamily: 'Arial, sans-serif',
          },
        }
        form.value.list?.forEach(i => {
          if (i.key === localStorage.getItem('form-builder-selected')) {
            selectWidget.value = i
          }
        })
        groups.value = [{ value: 'others', icon: 'mdi-cogs' }]
        composants.value.others = [{ value: 'grid', icon: 'mdi-grid' }, { value: 'button', icon: 'mdi-button-pointer' }, { value: 'captcha', icon: 'mdi-lock-smart' }, { value: 'html', icon: 'mdi-code-tags' }]
        types.forEach(t => {
          groups.value.push(t)
          composants.value[t.value] = appearances.filter(
            a => propertiesStore.isDisabledAppearance(a, { type: t.value }) === false,
          )
        })
      })
    })

    watch(selectWidget, () => {
      tabSettings.value = 0
      localStorage.setItem('form-builder-selected', selectWidget.value?.key)
    })

    const handleWidgetDelete = index => {
      if (form.value.list.length - 1 === index) {
        if (index === 0) {
          selectWidget.value = {}
        } else {
          selectWidget.value = form.value.list[index - 1]
        }
      } else {
        selectWidget.value = form.value.list[index + 1]
      }

      vm.$nextTick(() => {
        form.value.list.splice(index, 1)
      })
    }
    const handleSelectWidget = index => {
      if (index !== null) { selectWidget.value = form.value.list[index] } else selectWidget.value = {}
    }
    const handleWidgetAdd = $event => {
      const { newIndex } = $event
      // eslint-disable-next-line no-shadow
      const { item } = $event

      let key = item.getAttribute('type') ? `${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}` : selectWidget.value.key
      if (item.getAttribute('appearance') === 'captcha' || item.getAttribute('appearance') === 'button') {
        key = item.getAttribute('appearance')
      }
      const options = item.getAttribute('type') ? {
        type: item.getAttribute('type'),
        appearance: item.getAttribute('appearance'),
      } : selectWidget.value.options

      vm.$set(form.value.list, newIndex, {
        options,
        columns:
          item.getAttribute('appearance') === 'grid'
            ? [
              {
                list: [],
                cols: 6,
                options: {},
              },
            ]
            : null,
        key,
      })
      selectWidget.value = form.value.list[newIndex]
    }

    const handleMove = () => true

    const handleWidgetColAdd = ($event, row, colIndex) => {
      const { newIndex } = $event
      // eslint-disable-next-line no-shadow
      const { item } = $event

      let key = item.getAttribute('type') ? `${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}` : selectWidget.value.key
      if (item.getAttribute('appearance') === 'captcha' || item.getAttribute('appearance') === 'button') {
        key = item.getAttribute('appearance')
      }
      const options = item.getAttribute('type') ? {
        type: item.getAttribute('type'),
        appearance: item.getAttribute('appearance'),
      } : selectWidget.value.options

      vm.$set(row.columns[colIndex].list, newIndex, {
        options,
        key,
      })

      selectWidget.value = row.columns[colIndex].list[newIndex]
    }

    const getAppearances = it => {
      const data = appearances.filter(a => propertiesStore.isDisabledAppearance(a, { type: it.value }) === false)

      return data
    }

    const addProperty = (slug, options) => {
      const it = propertiesStore.properties.find(p => p.slug === slug)
      emit('on-click', { key: 'subtable', path: `properties/${it?.id || ''}${options || ''}` })
    }

    const onSubmit = () => {

    }

    const getClass = options => {
      let className = ''

      className += options.margin ? ` mt-${options.marginTop} mb-${options.marginBottom}
    mr-${options.marginRight} ml-${options.marginLeft}` : ''

      className += options.padding ? ` pl-${options.paddingLeft} pr-${options.paddingRight}
    pt-${options.paddingTop} pb-${options.paddingBottom}` : ''

      className += ` text-${options.text || ''}`

      return className
    }

    return {
      form,
      searchQuery,
      propertiesStore,
      handleWidgetAdd,
      handleMove,
      handleSelectWidget,
      handleWidgetDelete,
      handleWidgetColAdd,
      composants,
      groups,
      getAppearances,
      selectWidget,
      tabSettings,
      addProperty,
      onSubmit,
      getClass,
      style,
      getStyle,
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
