<template>
  <div v-if="schema"
       class="form pa-5"
  >
    <v-snackbar v-model="isSaved"
                outlined
                text
                color="primary"
                shaped
                top
                right
    >
      {{ $t('SavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn icon
               color="primary"
               v-bind="attrs"
               @click="isSaved = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="isAlertVisible"
                color="error"
                outlined
                text
                shaped
                top
                right
    >
      {{ errors }}
    </v-snackbar>
    <v-form ref="form"
            class="multi-col-validation"
            :style="getStyle()"
            @submit.prevent
    >
      <v-form-base
        :model="model"
        :schema="schema"
        :row="{ noGutters: true, justify: formData.options.justify, align: formData.options.align }"
        @click="onClick"
        @input="onChange"
      />
    </v-form>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from 'vue'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useContactsStore } from '@/stores/contacts.store'
import { useVModel } from '@vueuse/core'
import { getObjectKeys } from '@core/utils'
import { useTableStore } from '@/stores/table.store'
import { useProjectsStore } from '@/stores/projects.store'
import useAppConfig from '@/stores/appconfig'
import axios from '@axios'

export default {
  components: {
  },
  props: {
    params: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const schema = ref(null)
    const model = ref({})
    const options = ref({})
    const form = ref(null)
    const formData = ref(null)
    const loading = ref(null)
    const formId = ref(null)
    const folderId = ref(null)
    const errors = ref(null)
    const isAlertVisible = ref(null)
    const visitorId = ref(null)
    const isSaved = ref(null)
    const contactsStore = useContactsStore()
    const tableStore = useTableStore()
    const projectsStore = useProjectsStore()
    const params = useVModel(props, 'params', emit)
    const { isDark } = useAppConfig()

    const trackUserData = async () => {
      const fp = await FingerprintJS.load()

      const result = await fp.get()
      visitorId.value = result.visitorId
    }

    const getStyle = () => ` width:${options.value.width}%; font-family:${options.value.fontfamily} !important;`

    const getClass = opts => {
      let className = ''

      className += opts.margin
        ? ` mt-${opts.marginTop} mb-${opts.marginBottom}
    mr-${opts.marginRight} ml-${opts.marginLeft}`
        : ''

      className += opts.padding
        ? ` pl-${opts.paddingLeft} pr-${opts.paddingRight}
    pt-${opts.paddingTop} pb-${opts.paddingBottom}`
        : ''

      className += ` text-${opts.text || ''}`

      return className
    }

    onMounted(async () => {
      if (vm.$router.currentRoute.query.dark === 'true') {
        isDark.value = true
      } else {
        isDark.value = false
      }
      trackUserData()

      const data = await axios.post('/form', {
        form_id: params.value || vm.$router.currentRoute.params.id,
      })

      schema.value = {}
      formData.value = data.payload
      projectsStore.project = { id: data.project_id }
      formId.value = data.id
      folderId.value = data.folder_id
      schema.value.form = {
        type: 'wrap',
        col: 12,
        flat: true,
        nobackground: true,
        schema: {},
      }
      options.value = data.payload?.options
      data.payload?.list.forEach(item => {
        if (item.options?.appearance === 'grid') {
          item.columns?.forEach((col, index) => {
            // eslint-disable-next-line prefer-template
            const k = item.key + '-' + index
            schema.value.form.schema[k] = {
              type: 'wrap',
              col: { cols: 12, md: col.cols },
              flat: true,
              nobackground: true,
              class: getClass(col.options),
              schema: {},
            }

            col.list.forEach(element => {
              schema.value.form.schema[k].schema[element.key] = contactsStore.getSchemaProperty(
                element.options,
                false,
              )
              if (element.key.indexOf('_') === -1) {
                model.value[element.key] = null
              }
            })
          })
        } else {
          schema.value.form.schema[item.key] = contactsStore.getSchemaProperty(item.options, false)
          if (item.key.indexOf('_') === -1) {
            model.value[item.key] = null
          }
        }
      })
    })

    const getCurrentSchema = col => {
      const currentPath = getObjectKeys(schema.value).filter(path => path.indexOf(`schema.${col}`) >= 0)[0]
      if (!currentPath) return null

      return currentPath?.split('.').reduce((o, k) => o && o[k], schema.value)
    }

    const onClick = async val => {
      // eslint-disable-next-line no-shadow
      const { params, key } = val

      if (params.tag === 'button') {
        if (!form.value.validate()) {
          return
        }
        const btn = getCurrentSchema(key)
        btn.disabled = true
        btn.loading = true
        btn.label += ' '

        axios.post('upsert-objects', {
          objects: [{ properties: model.value }],
          project_id: projectsStore.project?.id,
          model_id: null,
          form_id: formId.value,
          visitor_id: visitorId.value,
        })
          .then(() => {
            btn.disabled = false
            btn.loading = false
            isSaved.value = true
            form.value.reset()
            btn.label = btn.label.trim()
          }).catch(error => {
            isAlertVisible.value = true
            btn.disabled = false
            btn.loading = false
            btn.label = btn.label.trim()
            errors.value = vm.$t(error.response.data.message, error.response.data.params) || 'error'
          })
      }
    }

    const handleFileSelected = val => {
      model.value[val.key] = false
      const slug = val.key.replace('properties->>', '').replace('properties_crypted->>', '')
      const fileName = val.value.name
      tableStore
        .uploadFile(folderId.value, slug, fileName, val.value, 'contacts')
        .then(data => {
          model.value[val.key] = `contacts/${data.path}`
        })
        .catch(async error => {
          isAlertVisible.value = true
          model.value[val.key] = `contacts/${projectsStore.project?.id}/${folderId.value}/${slug}/${fileName}`
          errors.value = {
            item: error.message,
          }
        })
    }

    const onChange = val => {
      if (val.value instanceof File) {
        handleFileSelected(val)
      }
    }

    return {
      schema,
      model,
      form,
      formData,
      getClass,
      contactsStore,
      onClick,
      onChange,
      loading,
      errors,
      isAlertVisible,
      isSaved,
      options,
      getStyle,
    }
  },
}
</script>
<style scoped>
.form {
  margin: 0;
  padding: 0;
  height: 100%;
}

legend {
  font-size: 16px !important;
}
</style>
