import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase, supabaseDomain } from '@/plugins/supabase'
import axios from '@axios'

// eslint-disable-next-line import/prefer-default-export
export const useOrganizationsStore = defineStore({
  id: 'organizations',
  state: () => ({
    organizations: null,
    organization: null,
    products: null,
    prices: null,
    eventType: null,
    organizationId: null,
    roles: null,
  }),
  getters: {
    GET_ORGANIZATIONS(state) {
      return state.organizations ? state.organizations : null
    },
    GET_ORGANIZATION(state) {
      return state.organization ? state.organization : {}
    },
    GET_PRODUCTS(state) {
      return state.products ? state.products : []
    },
    GET_PRICES(state) {
      return state.prices ? state.prices : []
    },
  },
  actions: {
    async getOrganizations(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0
      let query = supabase
        .from('organizations')
        .select(
          '*, subscription:subscriptions(* , price:prices(*, product:products(*))), members(details:admins!members_admin_id_fkey(id, fullname, avatar)), projects:projects(id)',
          { count: 'exact' },
        )
        .order('created_at', { ascending: false })
      if (payload?.search) {
        query = query.or(`name.ilike.%${payload.search}%`)
      }
      if (payload?.options?.itemsPerPage !== -1 && from !== to) query = query.range(from, to)
      const { data, count, error } = await query
      if (error) {
        throw error
      }
      this.organizations = data

      return { data, count }
    },

    async getOrganization(id) {
      const { data, error } = await supabase
        .from('organizations')
        .select(
          '*, subscription:subscriptions(* , price:prices(*, product:products(*))), members(details:admins!members_admin_id_fkey(id, fullname, avatar))',
        )
        .eq('id', id)
        .single()
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async listenOrganizations() {
      if (supabaseDomain.indexOf('localhost') < 0) {
        return supabase
          .channel('listenOrganizations')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'organizations' }, async payload => {
            this.$patch({ eventType: payload.eventType, item: payload.new.id ? payload.new : payload.old })
          })
          .subscribe()
      }

      return null
    },

    async listenOrganization(id) {
      this.organization = await this.getOrganization(id)

      return supabase
        .channel(`listenOrganization-${id}`)
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'organizations',
            filter: `id=eq.${id}`,
          },
          async () => {
            this.organization = await this.getOrganization(id)
          },
        )
        .subscribe()
    },

    async createOrganization(organization) {
      const { data, error } = await supabase
        .from('organizations')
        .upsert([
          {
            name: organization.name,
            email: organization.email,
            phone: organization.phone,
            website: organization.website,
          },
        ])
        .select()

      if (error) {
        return Promise.reject(error)
      }

      return this.getOrganization(data[0].id)
    },

    async saveOrganization(organization) {
      const { data, error } = await supabase
        .from('organizations')
        .upsert([
          {
            id: organization.id,
            name: organization.name,
            email: organization.email,
            phone: organization.phone,
            website: organization.website,
          },
        ])
        .select()
      if (error) {
        return Promise.reject(error)
      }

      return this.getOrganization(data[0].id)
    },

    async removeOrganization(organization) {
      if (!organization.id) {
        return false
      }
      const { error } = await supabase.rpc('delete_organization', { organizationid: organization.id })
      if (error) {
        return Promise.reject(error)
      }

      return true
    },

    async getMembers(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0
      let query = supabase
        .from('members')
        .select('*, admin:admins!members_admin_id_fkey!inner(id, fullname, email, avatar), role:roles(id, name, permissions(resource, type))', { count: 'exact' })
      if (payload.search) {
        query = query.or(`fullname.ilike.%${payload.search}%, email.ilike.%${payload.search}%`, {
          foreignTable: 'admins',
        })
      }
      if (payload.options.itemsPerPage !== -1) query = query.range(from, to)
      query = query.eq('organization_id', payload.organizationId).order('created_at', { ascending: false })
      const { data, count, error } = await query
      if (error) {
        return Promise.reject(error)
      }

      return { data, count }
    },

    async deleteMember(member) {
      const { error } = await supabase.from('members').delete().match({ admin_id: member.admin_id, organization_id: member.organization_id })

      if (error) {
        return Promise.reject(error)
      }

      return true
    },

    async getProjectsOrganization(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0
      let query = supabase.from('projects').select('*', { count: 'exact' })
      if (payload?.search) query = query.or(`name.ilike.%${payload.search}%, description.ilike.%${payload.search}%`)
      if (payload?.options?.itemsPerPage !== -1 && from !== to) query = query.range(from, to)
      if (payload?.organizationId) query = query.eq('organization_id', payload.organizationId) // .order('created_at', { ascending: false })
      if (payload?.options?.sortBy && payload?.options?.sortDesc) {
        let i = 0
        payload.options.sortBy.forEach(item => {
          if (item === 'subscription.price.product.name') {
            query = query.order('name', { ascending: payload.options.sortDesc[i], foreignTable: 'products' })
          } else query = query.order(item, { ascending: payload.options.sortDesc[i] })
          i += 1
        })
      }
      const { data, count, error } = await query
      if (error) {
        return Promise.reject(error)
      }

      return { data, count }
    },

    async getPrices() {
      const { data, error } = await supabase
        .from('prices')
        .select('*, product:products(*)')
        .eq('products.active', true)
        .eq('active', true)
        .order('metadata->index', { foreignTable: 'products' })
        .order('unit_amount')
      if (error) {
        throw error
      }
      this.prices = data
    },

    async getInvoices(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0
      let query = supabase
        .from('invoices')
        .select('*', { count: 'exact' })
        .eq('organization_id', payload.organizationId)
      if (payload.options.itemsPerPage !== -1) query = query.range(from, to)
      if (payload.search) {
        query = query.or(`number.ilike.%${payload.search}%`)
      }
      if (payload.status) query = query.eq('status', payload.status)
      if (payload.options.sortBy && payload.options.sortDesc) {
        let i = 0
        payload.options.sortBy.forEach(item => {
          query = query.order(item, { ascending: payload.options.sortDesc[i] })
          i += 1
        })
      }
      const { data, error, count } = await query
      if (error) {
        return Promise.reject(error)
      }

      return { data, count }
    },

    async getBillingUsage(organizationid) {
      const { data, error } = await supabase.rpc('get_billing_usage', { organizationid })
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async getRoles(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0
      let query = supabase
        .from('roles')
        .select('*, members(*), permissions(*)', { count: 'exact' }).order('name')
      if (payload?.search) {
        query = query.or(`name.ilike.%${payload.search}%`)
      }
      if (payload?.options?.itemsPerPage !== -1 && from !== to) query = query.range(from, to)
      query = query.eq('organization_id', payload.organizationId).order('created_at', { ascending: false })
      const { data, count, error } = await query
      if (error) {
        return Promise.reject(error)
      }

      return { data, count }
    },

    async getPermissions() {
      const { data, error } = await supabase
        .from('permissions')
        .select('*')
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async getRole(roleid) {
      const { data, error } = await supabase.from('roles').select('*, members(*), permissions(*)').eq('id', roleid)
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async deleteOrganization(organization) {
      try {
        const data = await axios.post('delete-organization', { record: organization })

        return data.data
      } catch (error) {
        return Promise.reject(error.response.data)
      }
    },

    async deleteRole(roleid) {
      const { data, error } = await supabase.from('roles').delete().eq('id', roleid)
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async createPortailLink(organization) {
      try {
        const data = await axios.post('create-portail-link', { record: organization })

        return data.data
      } catch (error) {
        return Promise.reject(error.response.data)
      }
    },

    async upsertMember(member, organizationid) {
      try {
        const data = await axios.post('upsert-member', { record: member, organization_id: organizationid })

        return data.data
      } catch (error) {
        return Promise.reject(error.response.data)
      }
    },

    async updatePermissions(rolePermissions, permissions, organization) {
      try {
        const data = await axios.post('update-permissions', { role: rolePermissions, permissions, organization_id: organization.id })

        return data.data
      } catch (error) {
        return Promise.reject(error.response.data)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrganizationsStore, import.meta.hot))
}
