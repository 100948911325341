import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase, supabaseDomain } from '@/plugins/supabase'

// eslint-disable-next-line import/prefer-default-export
export const useTokensStore = defineStore({
  id: 'tokens',
  state: () => ({
    token: null,
  }),
  getters: {

  },
  actions: {
    async getTokens(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0

      let query = supabase
        .from('tokens')
        .select(
          '*',
          { count: 'exact' },
        )
        .order('created_at', { ascending: false })
      if (payload?.search) {
        query = query.or(`name.ilike.%${payload.search}%, description.ilike.%${payload.search}%`)
      }
      if (payload?.options?.itemsPerPage !== -1 && from !== to && from !== to) query = query.range(from, to)
      const { data, count, error } = await query
      if (error) {
        throw error
      }

      return { data, count }
    },

    async getToken(id) {
      const { data, error } = await supabase
        .from('decrypted_tokens')
        .select(
          '*',
        )
        .eq('id', id)
        .single()
      if (error) {
        throw error
      }
      data.secret = data.decrypted_secret

      return data
    },

    async listenTokens() {
      if (supabaseDomain.indexOf('localhost') < 0) {
        return supabase
          .channel('listenTokens')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'tokens' }, async payload => {
            this.$patch({ eventType: payload.eventType, item: payload.new.id ? payload.new : payload.old })
          })
          .subscribe()
      }

      return null
    },

    async listenToken(id) {
      this.token = await this.getToken(id)

      return supabase
        .channel(`listenToken-${id}`)
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'tokens',
            filter: `id=eq.${id}`,
          },
          async () => {
            this.token = await this.getToken(id)
          },
        )
        .subscribe()
    },

    async createToken(token) {
      const { data, error } = await supabase
        .from('tokens')
        .insert({
          name: token.name,
          description: token.description,
          secret: token.secret,
        })
        .select()
        .single()

      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async saveToken(token) {
      const { error } = await supabase
        .from('tokens')
        .update({
          name: token.name,
          description: token.description,
          secret: token.secret,
        })
        .eq('id', token.id)

      if (error) {
        return Promise.reject(error)
      }

      return true
    },

    async deleteToken(token) {
      const { error } = await supabase
        .from('tokens').delete()
        .eq('id', token.id)

      if (error) {
        throw error
      }

      return true
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTokensStore, import.meta.hot))
}
