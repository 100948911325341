import { defineStore, acceptHMRUpdate } from 'pinia'
import sortBy from 'lodash/sortBy'
import { useChatsStore } from '@/stores/chats.store'
import { useListingsStore } from '@/stores/listings.store'
import { useFoldersStore } from '@/stores/folders.store'
import { usePropertiesStore } from '@/stores/properties.store'
import { getParent } from '@core/utils/folders'
import {
  required,
  emailValidator,
  urlValidator,
  regexValidator,
  minLen,
  maxLen,
  between,
  dateValidator,
  timeValidator,
  datetimeValidator,
} from '@core/utils/validation'
import { canACL } from '@/plugins/acl/checkaccess'
import { TABLE_OBJECTS, displayMode } from '@core/utils'

// eslint-disable-next-line import/prefer-default-export
export const useObjectsStore = defineStore({
  id: TABLE_OBJECTS,
  state: () => ({}),
  actions: {

    getHeader(payload, item) {
      return {
        title: 'Data',
        icon: 'mdi-database',
        name: null,
        folder: payload?.folder,
        display: displayMode(TABLE_OBJECTS, 'table'),
        newData: 'AddData',
        actionData: 'EditDatas',
        editData: 'EditData',
        search: 'SearchData',
        searchIcon: 'mdi-database-search',
        add: {
          text: 'AddData',
          icon: 'mdi-database-plus',
        },
        actions: [{
          title: 'Save', icon: 'mdi-content-save-outline', action: 'save', loading: false, acl: 'create,update',
        }, {
          title: 'Duplicate',
          icon: 'mdi-content-duplicate',
          action: 'duplicate',
          acl: 'create',
          disabled: !item?.created_at,
        },
        {
          title: 'Delete',
          icon: 'mdi-delete-outline',
          action: 'delete',
          acl: 'delete',
          color: 'error',
          disabled: !item?.created_at,
        }],
        menu: [],
      }
    },

    getTableOptions() {
      return {
        sortBy: [],
        sortDesc: [],
        dense: false,
        multiSort: false,
        disableSort: false,
        itemsPerPage: 20,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        height: 0,
        page: 1,
        filters: {},
      }
    },

    getTableColumns(payload) {
      const columns = []
      payload?.properties?.forEach(property => {
        if (!property.hidden && property.tableheader) {
          columns.push({
            text: property.name,
            // eslint-disable-next-line prefer-template
            value: !property.encrypt ? 'properties->>' + property.slug : 'properties_crypted->>' + property.slug,
            filterable: true,
            type: property.type,
            search: !property.encrypt && property.type === 'text',
            appearance: property.appearance,
            minimum: property.minimum,
            maximum: property.maximum,
          })
        }
      })

      columns.push({
        text: 'UpdatedAt',
        value: 'updated_at',
        type: 'date',
      })
      columns.push({
        text: 'Action',
        value: 'actions',
        align: 'center',
        type: 'actions',
        sortable: false,
      })

      return columns
    },

    getModel(payload) {
      const model = {}
      model.project_id = payload?.project_id
      model.listings = []
      model.properties = {}
      model.properties_crypted = {}
      model.model_id = payload?.model_id
      payload?.properties.forEach(property => {
        if (property.encrypt) {
          model.properties_crypted[property.slug] = null
        } else model.properties[property.slug] = null
      })

      return model
    },

    getSchema(item, items, payload) {
      const chatsStore = useChatsStore()
      const listingsStore = useListingsStore()
      const foldersStore = useFoldersStore()
      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }
      payload?.folders.forEach((folder, index) => {
        const group = payload?.properties.filter(p => p.folder_id === folder)

        // eslint-disable-next-line no-mixed-operators, prefer-template
        const id = 'col' + ((index % 2) + 1)

        if (group.length) {
          schema[id].schema[folder] = {
            title: getParent(folder, foldersStore.folders),
            type: 'wrap',
            outlined: true,
            shaped: true,
            col: 12,
            ripple: false,
            class: 'pa-3 ma-2',
            schema: {},
          }
          group.forEach(property => {
            schema[id].schema[folder].schema[
              // eslint-disable-next-line prefer-template
              !property.encrypt ? 'properties->>' + property.slug : 'properties_crypted->>' + property.slug
            ] = {
              disabled: !canACL('update', 'objects', { id: payload?.project_id }),
              type: this.getType(property),
              ext: this.getExt(property),
              label: property.name,
              col: 12,
              rules: this.getRules(item, items, property),
              hidden: !!items.length && property.single,
              items: this.getItems(property),
              min: property.minimum,
              max: property.maximum > 0 ? property.maximum : 5,
              minimum_length: property.minimum_length,
              maximum_length: property.maximum_length,
              multiple: property.type === 'array',
              'item-value': 'id',
              'item-text': 'name',
              clearable: true,
              text: {
                clearable: true,
                prependIcon: 'mdi-timer',
              },
              folder,
            }
          })
        }
      })

      if (item?.id) {
        schema.col2.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col2.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col2.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col2.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col2.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col2.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          label: 'UpdatedBy',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }
      schema.col3.schema.listing = {
        title: 'Listings',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'ma-2',
        schema: {
          edit_listings: {
            type: 'btn',
            disabled: !canACL('update', 'listings', { id: payload?.project_id }),
            col: { cols: 2, md: 1 },
            class: 'ma-3',
            'x-small': true,
            outlined: true,
            label: 'EditListings',
            iconCenter: 'mdi-format-list-group-plus',
            color: 'primary',
          },
          listings: {
            type: 'table',
            label: 'Listings',
            'item-value': 'id',
            'item-text': 'name',
            col: 12,
            multiple: true,
            readonly: true,
            headers: [
              {
                text: 'Name',
                value: 'name',
              },
              {
                text: 'Type',
                value: 'mode',
              },
            ],
            items: listingsStore.listings,
          },
        },
      }

      return schema
    },

    getRules(item, items, property) {
      const rules = []
      if (property.appearance === 'email') {
        rules.push(emailValidator)
      }
      if (property.appearance === 'url') {
        rules.push(urlValidator)
      }
      if (property.appearance === 'date-picker') {
        rules.push(dateValidator)
      }
      if (property.appearance === 'time-picker') {
        rules.push(timeValidator)
      }
      if (property.appearance === 'date-time-picker') {
        rules.push(datetimeValidator)
      }
      if ((!items || items.length === 0) && property.required) {
        rules.push(required)
      }
      if (property.regex) {
        rules.push(regexValidator(property.regex))
      }
      if (property.minimum_length) {
        rules.push(minLen(property.minimum_length))
      }
      if (property.maximum_length) {
        rules.push(maxLen(property.maximum_length))
      }
      if (property.maximum && property.minimum) {
        rules.push(between(property.minimum, property.maximum))
      }

      return rules
    },

    getExt(property) {
      if (property.type === 'number') {
        return 'number'
      }

      return null
    },

    getType(property) {
      if (property.appearance === 'date-picker') {
        return 'date-picker'
      }
      if (property.appearance === 'color-picker') {
        return 'color-picker'
      }
      if (property.appearance === 'time-picker') {
        return 'time-picker'
      }
      if (property.appearance === 'date-time-picker') {
        return 'date-time-picker'
      }
      if (property.appearance === 'combobox') {
        return 'combobox'
      }
      if (property.appearance === 'textarea') {
        return 'textarea'
      }
      if (property.appearance === 'dropdown') {
        return 'combobox'
      }
      if (property.appearance === 'rating') {
        return 'rating'
      }
      if (property.appearance === 'slider') {
        return 'slider'
      }
      if (property.appearance === 'file') {
        return 'file-small'
      }
      if (property.appearance === 'phone') {
        return 'phone'
      }

      return 'text'
    },

    async onAction(action, payload) {
      return payload?.item
    },

    formatItem(item) {
      const propertiesStore = usePropertiesStore()
      const listingsStore = useListingsStore()

      Object.keys(item).forEach(key => {
        if (item[key] instanceof Object) {
          if ((key === 'properties' || key === 'properties_crypted')) {
            Object.keys(item[key])?.forEach(subkey => {
            // eslint-disable-next-line prefer-template
              item[key + '->>' + subkey] = propertiesStore.getPropertyItem(subkey, item[key][subkey], true)
            })
            delete item[key]
          } else if (key === 'listings') {
            const listings = []
            Object.keys(item[key])?.forEach(subkey => {
              const listing = listingsStore.getListingItem(subkey.length > 5 ? subkey : item[key][subkey])
              if (listing) {
                listings.push(listing)
              }
            })
            item[key] = listings
          } else if (!(item[key] instanceof Array)) {
            Object.keys(item[key])?.forEach(subkey => {
            // eslint-disable-next-line prefer-template
              item[key + '->>' + subkey] = item[key][subkey]
            })
          }
        }
      })
      delete item.properties_text

      return item
    },

    getItems(property) {
      const items = []
      property?.properties_items?.forEach(it => {
        items.push(it)
      })

      return sortBy(items, ['sort_index'])
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useObjectsStore, import.meta.hot))
}
