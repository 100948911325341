<template>
  <v-row>
    <v-snackbar
      v-model="isAlertVisible"
      color="error"
      outlined
      text
      shaped
      top
      right
    >
      {{ errors }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="error"
          v-bind="attrs"
          @click="isAlertVisible = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-col cols="12">
      <v-row v-if="firstLoad === false"
             class="mt-8"
      >
        <v-col
          v-for="repo in repositories"
          :key="repo.id"
          md="4"
          cols="12"
        >
          <v-card :disabled="!releases[repo.id]">
            <v-img
              height="210"
              :src="repo.image"
            ></v-img>
            <v-card-title
              class="font-weight-bold text-truncate mr-5 py-2"
            >
              <h5 class="font-weight-bold text-uppercase">
                {{ repo.name }}
              </h5>
              <v-spacer></v-spacer>
              <v-btn
                icon
                color="primary"
                class="pa-0 ma-0"
                :disabled="!$canACL('read', 'repositories', organization)"
                @click="onIssues(repo)"
              >
                <v-icon>mdi-bug</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-timeline
                align-top
                dense
                class="activity-timeline timeline-custom-dense timeline-custom-dots"
              >
                <v-timeline-item
                  v-for="item in releases[repo.id]"
                  :key="item.id"
                  color="primary"
                  small
                >
                  <div class="d-flex align-start justify-space-between flex-wrap">
                    <h4 class="font-weight-semibold me-1">
                      {{ item.tag_name }}
                    </h4>
                    <small class="text-no-wrap">{{ $moment(item.created_at).format("LLL") }}</small>
                  </div>
                  <span></span>
                  <div class="d-flex align-center mt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :loading="loading === item.id"
                      small
                      :disabled="loading === item.id || !$canACL('read', 'repositories', organization)"
                      @click="download(repo.id, item)"
                    >
                      {{ $t('Download') }}
                    </v-btn>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else
             class="mt-8"
      >
        <v-col

          md="4"
          cols="12"
        >
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col

          md="4"
          cols="12"
        >
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="isDialogVisible"
      fullscreen
    >
      <v-card class="pa-sm-6 pa-3">
        <v-card-title>
          <span class="justify-center text-h5">{{ $t("Issues") }}</span>
          <v-spacer></v-spacer>
          <v-btn icon
                 @click="isDialogVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="8">
              <v-card outlined>
                <v-skeleton-loader v-if="loadingDialog"
                                   type="card"
                ></v-skeleton-loader>
                <v-simple-table v-else
                                fixed-header
                                height="500px"
                >
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t('Name') }}
                        </th>
                        <th class="text-left">
                          {{ $t('State') }}
                        </th>
                        <th
                          class="text-left"
                        >
                          {{ $t('Labels') }}
                        </th>
                        <th v-show="false"
                            class="text-left"
                        >
                          {{ $t('Comments') }}
                        </th>
                        <th class="text-left">
                          {{ $t('UpdatedAt') }}
                        </th>
                        <th>
                          <v-btn icon
                                 color="primary"
                                 @click="onIssues(repository)"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in issues"
                        :key="item.id"
                      >
                        <td>{{ item.title }}</td>
                        <td>
                          <table-cell-item
                            :filter="false"
                            :source="{
                              type: 'chip-type',
                              value: item.state,
                              text: item.state,
                            }"
                          >
                          </table-cell-item>
                        </td>
                        <td>
                          <v-chip v-for="label in item.labels"
                                  :key="label.id"
                                  dark
                                  small
                                  class="me-1"
                                  :style="`background:#${label.color};`"
                          >
                            {{ label.name }}
                          </v-chip>
                        </td>
                        <td v-show="false">
                          {{ item.comments }} 💬
                        </td>
                        <td>
                          <table-cell-item
                            :filter="false"
                            :source="{
                              type: 'datetimeago',
                              value: item.updated_at,
                              text: item.updated_at,
                            }"
                          >
                          </table-cell-item>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-form ref="formIssue"
                      @submit.prevent="createIssue"
              >
                <v-card outlined
                        class="pa-sm-6 pa-3"
                >
                  <v-card-title class="justify-center text-h5">
                    {{ $t("NewIssue") }}
                  </v-card-title>
                  <v-card-text class="pt-3">
                    <v-row class="mt-1">
                      <v-col
                        cols="12"
                        class="py-1"
                      >
                        <v-text-field
                          v-model="issue.title"
                          prepend-inner-icon="mdi-bug"
                          outlined
                          dense
                          hide-details
                          :rules="[validators.required]"
                          :label="$t('Name')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        class="py-1"
                      >
                        <v-textarea
                          v-model="issue.body"
                          outlined
                          dense
                          hide-details
                          :rules="[validators.required]"
                          :label="$t('Description')"
                        ></v-textarea>
                      </v-col>
                      <v-col
                        cols="12"
                        class="py-1"
                      >
                        <v-select
                          v-model="issue.labels"
                          class="ma-0 pa-0"
                          outlined
                          dense
                          multiple
                          :label="$t('Labels')"
                          :rules="[validators.required]"
                          :items="[{ text: 'Bug', value: 'bug'}, { text: 'Improvements or additions to documentation', value: 'documentation'}, { text: 'Further information is requested', value: 'question'} , { text: 'New feature or request', value: 'enhancement'}]"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-alert
                    v-if="errors"
                    border="left"
                    colored-border
                    text
                    color="error"
                    class="subtitle-2"
                  >
                    {{ errors }}
                  </v-alert>
                  <v-card-actions>
                    <v-btn large
                           type="submit"
                           block
                           color="primary"
                           :loading="loadingIssue"
                           :disabled="loadingIssue"
                    >
                      {{ $t('Send') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref, toRef, onMounted } from 'vue'
import { useUserStore } from '@/stores/user.store'
import TableCellItem from '@/views/table/TableCellItem.vue'
import { required } from '@core/utils/validation'
import axios from '@axios'

export default {
  components: {
    TableCellItem,
  },
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const userStore = useUserStore()
    const isAlertVisible = ref(false)
    const isDialogVisible = ref(false)
    const repository = ref(null)
    const errors = ref(null)
    const loading = ref(null)
    const loadingDialog = ref(null)
    const loadingIssue = ref(null)
    const firstLoad = ref(null)
    const releases = ref({})
    const issues = ref([])
    const issue = ref({})
    const formIssue = ref(null)
    const repositories = ref([
      {
        id: 'micleo',
        name: 'App - Front End',
        image: require('@/assets/images/contacts-large-light.png'),
      },
      {
        id: 'micleo-functions',
        name: 'Edge Function  - Back End',
        image: require('@/assets/images/back-end.jpg'),
      },
    ])

    const organization = toRef(props, 'organization')

    onMounted(async () => {
      firstLoad.value = true
      releases.value = {}

      await Promise.all(
        repositories.value.map(async repo => {
          try {
            const data = await axios.post('get-releases', { record: { repository: repo.id } })
            releases.value[repo.id] = data.data
          } catch (error) {
            isAlertVisible.value = true
            errors.value = error.response.data
            firstLoad.value = false

            return Promise.reject(error.response.data)
          }

          return true
        }),
      )
      firstLoad.value = false
    })

    const download = (id, it) => {
      loading.value = it?.id
      errors.value = null
      axios.post('download-repo', { record: { repository: id, ref: it.tag_name } })
        .then(response => {
          loading.value = null
          window.location.href = response.data.url
        }).catch(error => {
          loading.value = null
          isAlertVisible.value = true
          errors.value = error.message
        })
    }

    const onIssues = repo => {
      loadingDialog.value = true
      isDialogVisible.value = true
      errors.value = null
      issue.value = {
        title: '',
        body: '',
      }
      if (formIssue.value) formIssue.value.resetValidation()
      repository.value = repo
      axios.post('get-issues', { record: { repository: repo.id } })
        .then(async response => {
          loadingDialog.value = false
          issues.value = response.data
        }).catch(error => {
          loadingDialog.value = false
          isAlertVisible.value = true
          errors.value = error.message
        })
    }

    const createIssue = () => {
      const isFormValid = formIssue.value.validate()
      if (!isFormValid) return
      loadingIssue.value = true
      errors.value = null
      axios.post('create-issue', { record: { issue: issue.value, repository: repository.value.id } })
        .then(() => {
          loadingIssue.value = false
          onIssues(repository.value)
        }).catch(error => {
          loadingIssue.value = false
          isAlertVisible.value = true
          errors.value = error.message
        })
    }

    return {
      organization,
      isAlertVisible,
      isDialogVisible,
      errors,
      loading,
      userStore,
      download,
      releases,
      loadingDialog,
      firstLoad,
      repositories,
      repository,
      onIssues,
      issues,
      issue,
      createIssue,
      formIssue,
      validators: {
        required,
      },
      loadingIssue,
    }
  },
}
</script>
