<template>
  <div
    v-if="organizationsStore.organization"
    id="user-view"
  >
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      class="user-tabs"
      style="position: relative; z-index: 0;"
      show-arrows
    >
      <v-tab
        v-for="(t, index) in tabs"
        v-show="$canACL('view', t.resource, organizationsStore.organization)"
        :key="t.icon"
        :disabled="!$canACL(t.action, t.resource, organizationsStore.organization)"
        @click="onTabEvent(index)"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ t.icon }}
        </v-icon>
        <span>{{ $t(t.title) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items
      id="user-tabs-content"
      v-model="tab"
    >
      <v-tab-item>
        <organization-details
          :organization="organizationsStore.organization"
        ></organization-details>
      </v-tab-item>
      <v-tab-item>
        <organization-plan
          :organization="organizationsStore.organization"
          :prices="organizationsStore.prices"
        ></organization-plan>
      </v-tab-item>
      <v-tab-item>
        <organization-invoices
          :organization="organizationsStore.organization"
        ></organization-invoices>
      </v-tab-item>
      <v-tab-item>
        <organization-members
          :organization="organizationsStore.organization"
        ></organization-members>
      </v-tab-item>
      <v-tab-item>
        <organization-roles
          :organization="organizationsStore.organization"
        ></organization-roles>
      </v-tab-item>
      <v-tab-item>
        <organization-integrations
          :organization="organizationsStore.organization"
        ></organization-integrations>
      </v-tab-item>
      <v-tab-item>
        <organization-projects
          :organization="organizationsStore.organization"
        ></organization-projects>
      </v-tab-item>
      <v-tab-item>
        <organization-repositories
          :organization="organizationsStore.organization"
        ></organization-repositories>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { useOrganizationsStore } from '@/stores/organizations.store'
import { useUserStore } from '@/stores/user.store'
import OrganizationDetails from '@/views/organizations/settings/OrganizationDetails.vue'
import OrganizationIntegrations from '@/views/organizations/settings/OrganizationIntegrations.vue'
import OrganizationInvoices from '@/views/organizations/settings/OrganizationInvoices.vue'
import OrganizationMembers from '@/views/organizations/settings/OrganizationMembers.vue'
import OrganizationPlan from '@/views/organizations/settings/OrganizationPlan.vue'
import OrganizationProjects from '@/views/organizations/settings/OrganizationProjects.vue'
import OrganizationRepositories from '@/views/organizations/settings/OrganizationRepositories.vue'
import OrganizationRoles from '@/views/organizations/settings/OrganizationRoles.vue'
import { appConfig } from '@/plugins/micleoConfig'
import {
  getCurrentInstance,
  onMounted, onUnmounted,
  ref,
} from 'vue'

export default {
  metaInfo: {
    title: 'Organization',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    OrganizationDetails,
    OrganizationMembers,
    OrganizationRoles,
    OrganizationProjects,
    OrganizationInvoices,
    OrganizationPlan,
    OrganizationIntegrations,
    OrganizationRepositories,
  },
  setup() {
    const tab = ref(0)
    const subscription = ref(null)
    const vm = getCurrentInstance().proxy

    const organizationsStore = useOrganizationsStore()
    const userStore = useUserStore()

    // tabs
    const tabs = [
      {
        title: 'General', icon: 'mdi-application', action: 'read', resource: 'organizations',
      },
      {
        title: 'BillingPlan', icon: 'mdi-calendar-clock', action: 'read', resource: 'subscriptions',
      },
      {
        title: 'Invoices', icon: 'mdi-text-box-multiple-outline', action: 'read', resource: 'invoices',
      },
      {
        title: 'Members', icon: 'mdi-shield-account', action: 'read', resource: 'members',
      },
      {
        title: 'RolesPermissions', icon: 'mdi-security', action: 'read', resource: 'roles',
      },
      {
        title: 'Integrations', icon: 'mdi-store', action: 'read', resource: 'integrations',
      },
      {
        title: 'Projects', icon: 'mdi-folder-table', action: 'read', resource: 'projects',
      },
      {
        title: 'Repositories', icon: 'mdi-github', action: 'view', resource: 'repositories',
      },
    ]

    if (vm.$router.currentRoute.params?.tab) {
      tab.value = parseInt(vm.$router.currentRoute.params.tab, 10)
    }

    onMounted(async () => {
      subscription.value = await organizationsStore.listenOrganization(vm.$router.currentRoute.params.id)
      await organizationsStore.getPrices()
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    const onTabEvent = t => {
      vm.$router.push({ name: 'edit-organization', params: { tab: t, id: vm.$router.currentRoute.params.id } })
    }

    return {
      tab,
      tabs,
      onTabEvent,
      organizationsStore,
    }
  },
  data() {
    return {
      subscription: null,
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
