<template>
  <v-row
    class="pa-3 mt-2"
  >
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.project"
    ></alert-error>
    <v-snackbar
      v-model="isSaved"
      color="primary"
      text
      outlined
      shaped
      top
      right
    >
      {{ $t('ProjectSavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <confirm-dialog-delete
      :message="$t('DeleteProjectDetails')"
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingDelete"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>
    <v-col
      cols="12"
      lg="9"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("General") }}
          </h5>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            class="multi-col-validation mt-3"
            @submit.prevent="save"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-img
                      v-if="project.image"
                      :src="project.image"
                      size="180"
                      class="ml-3"
                    ></v-img>
                    <v-avatar
                      v-else
                      color="primary"
                      class="v-avatar-light-bg primary--text me-3"
                      rounded
                      size="180"
                    >
                      <span
                        class="font-weight-semibold text-5xl"
                      >{{ avatarText(project.name) }}</span>
                    </v-avatar>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <div>
                      <v-btn
                        small
                        color="primary"
                        class="me-3 mt-5"
                        :loading="loadingUpload"
                        :disabled="loadingUpload || !$canACL('update', 'projects', { id : project.organization_id })"
                        @click="uploadClick"
                      >
                        <v-icon class="d-sm-none">
                          mdi-cloud-upload-outline
                        </v-icon>
                        <span class="d-none d-sm-block"> {{ $t("UploadPicture") }}</span>
                      </v-btn>

                      <input
                        ref="refInputEl"
                        type="file"
                        accept=".jpeg,.png,.jpg,GIF"
                        :hidden="true"
                        @change="handleFileSelected"
                      />

                      <v-btn
                        small
                        color="error"
                        outlined
                        class="mt-5"
                        :disabled="!$canACL('update', 'projects', { id : project.organization_id })"
                        @click.prevent="resetImage"
                      >
                        {{ $t("Reset") }}
                      </v-btn>
                      <p class="text-sm mt-5">
                        {{ $t("AllowedImageFormats") }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="py-2"
                  >
                    <v-text-field
                      v-model="project.name"
                      :disabled="!$canACL('update', 'projects', { id : project.organization_id })"
                      :label="$t('Name')"
                      outlined
                      dense
                      hide-details="auto"
                      prepend-inner-icon="mdi-folder-table"
                      :rules="[validators.required, v => (v || '' ).length <= 25 || $t('FieldMaxLength', {length: 25})]"
                      :placeholder="$t('Name')"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-2"
                  >
                    <v-textarea
                      v-model="project.description"
                      :disabled="!$canACL('update', 'projects', { id : project.organization_id })"
                      :rules="[v => (v || '' ).length <= 120 || $t('FieldMaxLength', {length: 120})]"
                      :label="$t('Description')"
                      rows="2"
                      outlined
                      clearable
                      dense
                      hide-details="auto"
                      :placeholder="$t('Description')"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-2"
                  >
                    <v-select
                      v-model="project.organization_id"
                      :label="$t('Organization')"
                      prepend-inner-icon="mdi-home-city"
                      :items="organizations"
                      item-text="name"
                      item-value="id"
                      outlined
                      disabled
                      dense
                      hide-details
                      :rules="[validators.required]"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-2"
                  >
                    <v-select
                      v-model="project.locales"
                      multiple
                      :rules="[validators.required]"
                      :disabled="!$canACL('update', 'projects', { id : project.organization_id })"
                      chips
                      :label="$t('Locales')"
                      prepend-inner-icon="mdi-translate"
                      :items="locales"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-2"
                  >
                    <v-autocomplete
                      v-model="project.timezone"
                      :rules="[validators.required]"
                      :disabled="!$canACL('update', 'projects', { id : project.organization_id })"
                      :label="$t('TimeZone')"
                      item-text="text"
                      item-value="text"
                      prepend-inner-icon="mdi-clock-outline"
                      :items="timezones"
                      outlined
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-btn
                  :loading="loading"
                  :disabled="loading || !$canACL('update', 'projects', { id : project.organization_id })"
                  color="primary"
                  class="me-3 mt-4"
                  type="submit"
                >
                  {{ $t("Save") }}
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-4"
                  type="reset"
                  :disabled="!$canACL('update', 'projects', { id : project.organization_id })"
                  @click.prevent="resetForm"
                >
                  {{ $t("Cancel") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      lg="3"
    >
      <v-alert
        border="left"
        colored-border
        text
        color="error"
      >
        <h5 class="font-weight-bold text-uppercase mb-1">
          {{ $t('DangerZone') }}
        </h5>
        <p class="text-sm mb-0">
          {{ $t("DeleteProjectDetails") }}
        </p>
        <v-btn
          color="error"
          class="mt-3 mb-1"
          small
          outlined
          target="_blank"
          :disabled="!$canACL('delete', 'projects', { id : project.organization_id })"
          @click="isDeleteDialogVisible = true"
        >
          {{ $t("DeleteProject") }}
        </v-btn>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref, onMounted, getCurrentInstance, toRef,
} from 'vue'
import { avatarText } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import AlertError from '@/components/AlertError.vue'
import { useProjectsStore } from '@/stores/projects.store'
import timezones from '@/views/projects/settings/timezones.json'

export default {
  components: {
    ConfirmDialogDelete,
    AlertError,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    organizations: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const loading = ref(false)
    const loadingUpload = ref(false)
    const loadingDelete = ref(false)
    const isSaved = ref(false)
    const form = ref(null)
    const errors = ref({})
    const isAlertVisible = ref(false)
    const isDeleteDialogVisible = ref(false)
    const locales = ref(['fr', 'en', 'it', 'es', 'de'])
    const project = toRef(props, 'project')
    locales.value.sort()
    const vm = getCurrentInstance().proxy
    const projectsStore = useProjectsStore()
    onMounted(async () => {
      vm.$refs.refInputEl = vm.$el.querySelector('input[type=file]')
      vm.$refs.refInputEl.style.display = 'none'
    })

    const save = () => {
      errors.value = {}
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      loading.value = true
      projectsStore.saveProject(project.value)
        .then(() => {
          loading.value = false
          isSaved.value = true
        })
        .catch(error => {
          isAlertVisible.value = true
          loading.value = false
          errors.value = {
            project: error.message,
          }
          if (error.response) {
            errors.value = {
              project: error.response.data?.error?.message || error.response.data?.message,
            }
          }
        })
    }

    const resetForm = () => {
      errors.value = {}
      projectsStore.getProject(project.value.id)
        .then(data => {
          projectsStore.project = data
        })
    }

    const resetImage = () => {
      project.value.image = ''
    }

    const uploadClick = () => {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      vm.$refs.refInputEl.dispatchEvent(event)
    }

    const handleFileSelected = async e => {
      loadingUpload.value = true
      if (
        e.target.files.length > 0
        && (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png')
      ) {
        project.value.image = ''

        projectsStore.uploadProjectCover(projectsStore.project.id, e.target.files[0])
          .then(async () => {
            const url = await projectsStore.getProjectCover(projectsStore.project.id, e.target.files[0])
            project.value.image = url.publicUrl
            loadingUpload.value = false
          })
          .catch(async () => {
            const url = await projectsStore.getProjectCover(projectsStore.project.id, e.target.files[0])
            project.value.image = url.publicUrl
            loadingUpload.value = false
          })
      } else {
        isAlertVisible.value = true
        errors.value = {
          image: 'Allowed JPG, GIF or PNG.',
        }
      }
    }

    const confirmDelete = () => {
      loadingDelete.value = true
      projectsStore.deleteProject(project.value).then(() => {
        loadingDelete.value = false
        isDeleteDialogVisible.value = false
        vm.$router.push({ name: 'projects' })
      }).catch(error => {
        loadingDelete.value = false
        isAlertVisible.value = true
        errors.value = {
          project: error,
        }
      })
    }

    return {
      project,
      form,
      save,
      resetForm,
      resetImage,
      uploadClick,
      handleFileSelected,
      confirmDelete,
      isSaved,
      loading,
      loadingUpload,
      loadingDelete,
      errors,
      isAlertVisible,
      isDeleteDialogVisible,
      validators: {
        required,
      },
      avatarText,
      locales,
      timezones,
    }
  },
}
</script>
