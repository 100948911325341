<template>
  <div class="d-flex">
    <div
      class="v-avatar-group"
    >
      <avatar-member
        v-for="member in members.slice(0, 5)"
        :key="member.details.id"
        :member="member.details"
        xsmall
      ></avatar-member>
    </div>
    <span v-if="members.length - 5 >= 0"
          class="ma-0 py-1 font-weight-bold text-uppercase text-xs"
    >
      +{{ members.length - 5 }}
    </span>
  </div>
</template>

<script>
import AvatarMember from '@/components/AvatarMember.vue'

export default {
  components: {
    AvatarMember,
  },
  props: {
    members: {
      type: Array,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    xxsmall: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style>

</style>
