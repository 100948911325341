<template>
  <v-row>
    <v-col
      cols="12"
      md="7"
    >
      <project-statistics-card></project-statistics-card>
    </v-col>
    <v-col
      cols="12"
      md="5"
    >
      <organization-statistics-card v-if="$canACL('view', 'organizations', projectsStore.GET_PROJECT?.organization)"></organization-statistics-card>
    </v-col>
  </v-row>
</template>

<script>
import { useProjectsStore } from '@/stores/projects.store'
import { useUserStore } from '@/stores/user.store'
import OrganizationStatisticsCard from '@/views/projects/dashboard/OrganizationStatisticsCard.vue'
import ProjectStatisticsCard from '@/views/projects/dashboard/ProjectStatisticsCard.vue'
import { appConfig } from '@/plugins/micleoConfig'
import {
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
} from 'vue'

export default {
  metaInfo: {
    title: 'Dashboard',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    ProjectStatisticsCard,
    OrganizationStatisticsCard,
  },
  setup() {
    const projectsStore = useProjectsStore()
    const userStore = useUserStore()

    const vm = getCurrentInstance().proxy
    const subscription = ref(null)

    onMounted(async () => {
      subscription.value = await projectsStore.listenProject(vm.$router.currentRoute.params.id)
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    return {
      projectsStore,
    }
  },
}
</script>
