<template>
  <v-row class="pa-3 mt-2">
    <v-snackbar
      v-model="isSaved"
      color="primary"
      outlined
      text
      shaped
      top
      right
    >
      {{ $t('ProfileSavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-col
      cols="12"
      lg="9"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("Profile") }}
          </h5>
        </v-card-title>
        <v-card-text class="d-flex">
          <v-avatar
            :color="user.avatar ? '' : 'primary'"
            :class="user.avatar ? '' : 'v-avatar-light-bg primary--text'"
            rounded
            size="120"
            class="me-6"
          >
            <v-img
              v-if="user.avatar"
              :src="user.avatar"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(user.fullname) }}</span>
          </v-avatar>

          <!-- upload photo -->
          <div>
            <v-btn
              small
              color="primary"
              class="me-3 mt-5"
              :loading="loadingUpload"
              :disabled="loadingUpload"
              @click="uploadClick"
            >
              <v-icon class="d-sm-none">
                mdi-cloud-upload-outline
              </v-icon>
              <span class="d-none d-sm-block">{{ $t('UploadPhoto') }}</span>
            </v-btn>

            <input
              ref="refInputEl"
              type="file"
              accept=".jpeg,.png,.jpg,GIF"
              :hidden="true"
              @change="handleFileSelected"
            />

            <v-btn
              small
              color="error"
              outlined
              class="mt-5"
              @click.prevent="resetAvatar"
            >
              {{ $t('Reset') }}
            </v-btn>
            <p class="text-sm mt-5">
              {{ $t('AllowedImageFormats') }}
            </p>
          </div>
        </v-card-text>

        <v-card-text>
          <v-form
            ref="form"
            class="multi-col-validation mt-6"
            @submit.prevent="save"
          >
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="user.fullname"
                  :rules="[validators.required]"
                  :label="$t('FullName')"
                  prepend-inner-icon="mdi-account-outline"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="user.email"
                  disabled
                  prepend-inner-icon="mdi-email-outline"
                  :error-messages="errors.email != '' ? $t(errors.email) : errors.email"
                  :rules="[validators.required, validators.emailValidator]"
                  :label="$t('Email')"
                  dense
                  outlined
                  @click:append-outer="resetForm"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-alert
                  v-model="isAlertVisible"
                  border="left"
                  colored-border
                  text
                  color="primary"
                  icon="mdi-email-open-outline"
                >
                  <p class="text-base font-weight-medium mb-1">
                    {{ $t('ConfirmYourEmailSignup') }}
                  </p>
                  <p
                    v-if="errors.email"
                    class="caption mt-3 mb-1"
                  >
                    {{ errors.email }}
                  </p>
                </v-alert>
                <v-btn
                  v-if="!isAlertVisible"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  class="me-3 mt-4"
                  type="submit"
                >
                  {{ $t('Save') }}
                </v-btn>
                <v-btn
                  v-if="!isAlertVisible"
                  color="secondary"
                  outlined
                  class="mt-4"
                  type="reset"
                  @click.prevent="resetForm"
                >
                  {{ $t('Cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
      lg="3"
    >
      <v-card>
        <v-card-text>
          <div class="d-flex align-center">
            <h5 class="font-weight-bold text-uppercase">
              {{ $t("Language") }}
            </h5>
            <v-spacer></v-spacer>
            <app-bar-i18n class="ml-4"></app-bar-i18n>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-5">
        <v-card-text>
          <div class="d-flex align-center">
            <h5 class="font-weight-bold text-uppercase">
              {{ $t("Appearance") }}
            </h5>
            <v-spacer></v-spacer>
            <app-bar-theme-switcher></app-bar-theme-switcher>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-5">
        <v-card-text>
          <div class="d-flex align-center">
            <h5 class="font-weight-bold text-uppercase">
              {{ $t("Menu") }}
            </h5>
            <v-spacer></v-spacer>
            <v-select
              v-model="appContentLayoutNav"
              class="ma-0 pa-0"
              :disabled="$vuetify.breakpoint.xs"
              style="width:80px; height: 40px;"
              outlined
              dense
              :items="[{ text: 'Horizontal', value: 'horizontal'}, { text: 'Vertical', value: 'vertical'}]"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref, getCurrentInstance, onMounted, toRef,
} from 'vue'
import useAppConfig from '@/stores/appconfig'
import { avatarText } from '@core/utils/filter'
import { required, emailValidator } from '@core/utils/validation'
import { useUserStore } from '@/stores/user.store'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'

export default {
  components: {
    AppBarI18n,
    AppBarThemeSwitcher,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const loading = ref(false)
    const loadingUpload = ref(false)
    const isSaved = ref(false)
    const form = ref(null)
    const errors = ref({})
    const vm = getCurrentInstance().proxy
    const user = toRef(props, 'user')
    const isAlertVisible = ref(false)

    const userStore = useUserStore()
    const {
      appContentLayoutNav,
    } = useAppConfig()

    onMounted(() => {
      vm.$refs.refInputEl = vm.$el.querySelector('input[type=file]')
      vm.$refs.refInputEl.style.display = 'none'
    })

    const save = () => {
      loading.value = true
      userStore.setUser(user.value)
        .then(data => {
          if (data !== null) {
            if (data.emailChanged) {
              isAlertVisible.value = true
            }
            loading.value = false
            isSaved.value = true
          }
        })
        .catch(() => {
          loading.value = false
        })
    }

    const uploadClick = () => {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      vm.$refs.refInputEl.dispatchEvent(event)
    }

    const resetForm = async () => {
      errors.value = {}
      userStore.getUser(user.value).then(async data => {
        userStore.user = data
      })
    }

    const handleFileSelected = async e => {
      loadingUpload.value = true
      if (
        e.target.files.length > 0
        && (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png')
      ) {
        userStore.uploadAdminAvatar(e.target.files[0]).then(async () => {
          const url = await userStore.getAdminImgUrl(e.target.files[0])
          user.value.avatar = url.publicUrl
          loadingUpload.value = false
        })
          .catch(async () => {
            const url = await userStore.getAdminImgUrl(e.target.files[0])
            user.value.avatar = url.publicUrl
            loadingUpload.value = false
          })
      } else {
        errors.value = {
          image: 'Allowed JPG, GIF or PNG.',
        }
      }
    }

    // reset avatar image
    const resetAvatar = () => {
      user.value.avatar = ''
    }

    return {
      user,
      form,
      isSaved,
      loading,
      loadingUpload,
      avatarText,
      validators: {
        required,
        emailValidator,
      },
      errors,
      save,
      handleFileSelected,
      resetForm,
      resetAvatar,
      uploadClick,
      isAlertVisible,
      appContentLayoutNav,
    }
  },
}
</script>
