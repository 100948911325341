<template>
  <v-row v-observe-visibility="visibilityChanged"
         class="pa-3 mt-2"
  >
    <confirm-dialog-delete
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingAction"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>
    <v-col
      cols="12"
      lg="9"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("Members") }}
          </h5>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            :disabled="!$canACL('create', 'members', organization)"
            @click="openDialog"
          >
            <v-icon
              :left="!$vuetify.breakpoint.xs"
              color=""
            >
              mdi-account-plus
            </v-icon>
            <span class="d-none d-sm-block">{{ $t("AddMember") }}</span>
          </v-btn>
        </v-card-title>
        <v-divider class="mx-5"></v-divider>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex flex-grow-1 align-center pb-3">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              :placeholder="$t('SearchMember')"
              class="me-3 search-table"
              clearable
              prepend-inner-icon="mdi-account-search"
              clear-icon="mdi-close"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-data-table
          v-model="selectedTableData"
          :headers="tableColumns"
          :items="listTable"
          :options.sync="options"
          :server-items-length="totalListTable"
          :loading="loading"
          disable-sort
          class="text-no-wrap"
        >
          <template
            #[`item.admin.fullname`]="{item}"
          >
            <div
              v-if="item.admin"
              class="d-flex align-center"
            >
              <v-avatar
                size="30"
                color="primary"
                class="v-avatar-light-bg primary--text"
              >
                <v-img
                  v-if="item.admin.avatar"
                  :src="item.admin.avatar"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(item.admin.fullname) }}</span>
              </v-avatar>

              <div
                class="d-flex flex-column ms-3"
              >
                <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.admin.fullname }}</span>
                <span class="text-xs">{{ item.admin.email }}</span>
              </div>
            </div>
          </template>

          <template #[`item.role.name`]="{item}">
            <v-chip
              color="primary"
              :class="`text-uppercase v-chip-light-bg primary--text font-weight-semibold`"
            >
              {{ $t(item.role.name) }}
            </v-chip>
          </template>
          <template #[`item.created_at`]="{item}">
            {{ $moment(item.created_at).format("lll") }}
          </template>
          <template #[`item.updated_at`]="{item}">
            {{ $moment(item.updated_at).format("lll") }}
          </template>

          <template #[`item.actions`]="{item}">
            <div class="d-flex align-center justify-center">
              <v-btn
                outlined
                x-small
                class="me-3"
                color="primary"
                :disabled="!$canACL('update', 'members', organization)"
                @click="editDialog(item)"
              >
                <v-icon>
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
              <v-btn
                x-small
                outlined
                color="error"
                :disabled="!$canACL('delete', 'members', organization) || item.admin.id === userStore?.GET_USER.id"
                @click="openDialogDelete(item)"
              >
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog
      v-model="isAddDialogVisible"
      max-width="450"
    >
      <v-card class="pa-sm-6 pa-3">
        <v-card-title
          v-if="member.edit"
          class="justify-center text-h5"
        >
          {{ $t("EditMember") }}
        </v-card-title>
        <v-card-title
          v-else
          class="justify-center text-h5"
        >
          {{ $t("AddMember") }}
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form
            ref="form"
            @submit.prevent="upsertMember"
          >
            <v-text-field
              v-model="member.email"
              :rules="[validators.required, validators.emailValidator]"
              :label="$t('Email')"
              required
              outlined
              dense
              prepend-inner-icon="mdi-email-outline"
              hide-details="auto"
              class="mb-4"
            ></v-text-field>

            <v-select
              v-model="member.role_id"
              :rules="[validators.required]"
              :items="roles.data"
              :label="$t('Role')"
              item-text="name"
              item-value="id"
              required
              hide-details="auto"
              prepend-inner-icon="mdi-security"
              class="mb-4"
              outlined
              dense
            >
              <template #selection="data">
                <v-chip
                  color="primary"
                  :class="`text-uppercase v-chip-light-bg primary--text font-weight-semibold`"
                >
                  {{ $t(data.item.name) }}
                </v-chip>
              </template>
              <template #item="data">
                <v-chip
                  color="primary"
                  :class="`text-uppercase v-chip-light-bg primary--text font-weight-semibold`"
                >
                  {{ $t(data.item.name) }}
                </v-chip>
              </template>
            </v-select>

            <v-alert
              v-if="errors.member"
              border="left"
              colored-border
              text
              color="error"
              class="subtitle-2"
            >
              {{ errors.member?.message }}
            </v-alert>
            <div class="mt-6 d-flex justify-center">
              <v-btn
                :loading="loadingAction"
                :disabled="loadingAction"
                color="primary"
                class="me-3"
                type="submit"
              >
                {{ $t('Submit') }}
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click="isAddDialogVisible = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref, onBeforeMount, getCurrentInstance } from 'vue'
import {
  required, emailValidator,
} from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import { useOrganizationsStore } from '@/stores/organizations.store'
import { useUserStore } from '@/stores/user.store'
import useMemberList from './useMemberList'

export default {
  components: {
    ConfirmDialogDelete,
  },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const errors = ref({})
    const member = ref({ email: '', role: '', edit: false })
    const roles = ref({})
    const loadingAction = ref(false)
    const form = ref(null)
    const vm = getCurrentInstance().proxy
    const organizationsStore = useOrganizationsStore()
    const userStore = useUserStore()

    const {
      listTable, searchQuery, tableColumns, options, totalListTable, loading, selectedTableData, isAddDialogVisible, isDeleteDialogVisible, fetchDatas,
    } = useMemberList(vm.$router.currentRoute.params.id)

    const visibilityChanged = isVisible => {
      if (isVisible) {
        fetchDatas()
      }
    }

    onBeforeMount(async () => {
      roles.value = await organizationsStore.getRoles({ organizationId: organizationsStore.organization.id })
      tableColumns.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
    })

    const confirmDelete = () => {
      if (!member.value) { return }
      loadingAction.value = true
      organizationsStore.deleteMember(member.value).then(() => {
        isDeleteDialogVisible.value = false
        isAddDialogVisible.value = false
        loadingAction.value = false
      })
        .catch(() => {
          loadingAction.value = false
        })
    }

    const openDialogDelete = _member => {
      isDeleteDialogVisible.value = true
      member.value = _member
    }

    const editDialog = _member => {
      isAddDialogVisible.value = true
      errors.value = {}
      member.value = { email: _member.admin.email, role_id: _member.role_id, edit: true }
    }

    const upsertMember = () => {
      errors.value = {}

      if (!form.value.validate()) { return }

      loadingAction.value = true
      organizationsStore.upsertMember(member.value, props.organization.id).then(() => {
        loadingAction.value = false
        isAddDialogVisible.value = false
      }).catch(error => {
        loadingAction.value = false
        errors.value = {
          member: error,
        }
      })
    }

    const openDialog = () => {
      if (form.value) { form.value.reset() }
      isAddDialogVisible.value = true
      member.value = {}
      errors.value = {}
    }

    return {
      tableColumns,
      searchQuery,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,
      validators: {
        required,
        emailValidator,
      },
      isAddDialogVisible,
      member,
      roles,
      loadingAction,
      errors,
      form,
      avatarText,
      isDeleteDialogVisible,
      confirmDelete,
      openDialogDelete,
      upsertMember,
      openDialog,
      editDialog,
      userStore,
      visibilityChanged,
    }
  },
}
</script>
