const organizations = [
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/pages/organizations/Organizations.vue'),
    meta: {
      layout: 'content',
      resource: 'organizations',
      action: 'view',
    },
  },
  {
    path: '/create/organization',
    name: 'create-organization',
    component: () => import('@/pages/organizations/CreateOrganization.vue'),
    meta: {
      layout: 'content',
      resource: 'organizations',
      action: 'create',
    },
  },
  {
    path: '/edit/:tab/organization/:id',
    name: 'edit-organization',
    component: () => import('@/pages/organizations/Organization.vue'),
    meta: {
      layout: 'content',
      resource: 'organizations',
      action: 'view',
    },
  },

]

export default organizations
