/* eslint-disable no-template-curly-in-string */

const AG_GRID_LOCALE_FR = {
  page: 'Page',
  more: 'Plus',
  to: '-',
  of: 'de',
  next: 'Suivant',
  last: 'Dernier',
  first: 'Premier',
  previous: 'Précédent',
  loadingOoo: 'Chargement...',
  selectAll: 'Tout sélectionner',
  searchOoo: 'Rechercher...',
  blanks: 'Espaces',
  filterOoo: 'Filtre...',
  applyFilter: 'Appliquer',
  resetFilter: 'Effacer',
  clearFilter: 'Effacer',
  cancelFilter: 'Annuler',
  equals: 'égal',
  notEquals: 'différent',
  lessThan: 'plus petit que',
  greaterThan: 'plus grand que',
  lessThanOrEqual: 'plus petit ou égal',
  greaterThanOrEqual: 'plus grand ou égal',
  inRange: 'dans la plage',
  contains: 'contient',
  notContains: 'ne contient pas',
  startsWith: 'commence par',
  endsWith: 'finit par',
  andCondition: 'ET',
  orCondition: 'OU',
  group: 'Groupe',
  columns: 'Colonne',
  filters: 'Filtres',
  rowGroupColumns: 'Colonne-pivot',
  rowGroupColumnsEmptyMessage: 'Glisser-deposer ici pour grouper',
  valueColumns: 'Colonnes valeurs',
  pivotMode: 'Mode pivot',
  groups: 'Groupes',
  values: 'Valeurs',
  pivots: 'Pivots',
  valueColumnsEmptyMessage: 'Glisser-déposer les lignes ici pour les agréger',
  pivotColumnsEmptyMessage: 'Déposer ici pour pivoter',
  toolPanelButton: 'Outils',
  noRowsToShow: 'Pas de données',
  pinColumn: 'Fixer la colonne',
  valueAggregation: 'Agréger valeurs',
  autosizeThiscolumn: 'Largeur automatique',
  autosizeAllColumns: 'Toutes les colonnes en largeur automatique',
  groupBy: 'Grouper par',
  ungroupBy: 'Supprimer grouper par',
  resetColumns: 'Réinitialiser colonne',
  expandAll: 'Etendre tout',
  collapseAll: 'Tout fermer',
  toolPanel: 'Outils',
  export: 'Exporter',
  csvExport: 'Exporter CSV',
  excelExport: 'Exporter Excel (.xlsx)',
  excelXmlExport: 'Exporter xml (.xml)',
  pinLeft: 'Fixer <<',
  pinRight: 'Fixer >>',
  noPin: 'Ne pas fixer <>',
  sum: 'Somme',
  min: 'Minimum',
  max: 'Maximum',
  none: 'Aucune',
  count: 'Nombre',
  average: 'Moyenne',
  filteredRows: 'Filtrer',
  selectedRows: 'Selectionner',
  totalRows: 'Total',
  totalAndFilteredRows: 'Ligne',
  copy: 'Copier',
  copyWithHeaders: 'Copier avec titre',
  ctrlC: 'Ctrl-C',
  paste: 'Insérer',
  ctrlV: 'Ctrl-V',
}

export default AG_GRID_LOCALE_FR
