<template>
  <layout-content-horizontal-nav :nav-menu-items="menuItems">
    <!-- Default Slot -->
    <slot></slot>

    <!-- Navbar -->
    <template #navbar>
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown"
          ></v-app-bar-nav-icon>
          <router-link
            to="/"
            class="d-flex align-center text-decoration-none"
          >
            <v-img
              v-if="$vuetify.theme.isDark && appLogoDark || !$vuetify.theme.isDark && appLogo"
              :src="$vuetify.theme.isDark ? appLogoDark: appLogo"
              max-height="30px"
              max-width="30px"
              :alt="appName"
              contain
            ></v-img>
            <h2 class="ml-1 font-weight-semibold appName">
              {{ appName }}
            </h2>
          </router-link>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center">
          <app-bar-project></app-bar-project>
          <app-bar-i18n></app-bar-i18n>
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <app-bar-notification
            :notifications="chatsStore.notifications"
            :user="userStore.user"
            :contacts="chatsStore.contacts"
          ></app-bar-notification>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between ">
        <span v-if="footerName"
              class="d-none d-md-inline"
        >&copy; {{ new Date().getFullYear() }} <a
          href="#"
          class="text-decoration-none"
        >{{ footerName }}</a><span class="d-none d-md-inline">, {{ $t('AllrightsReserved') }}</span></span>
      </div>
    </template>
  </layout-content-horizontal-nav>
</template>

<script>
import {
  onBeforeMount, ref, watch, getCurrentInstance,
} from 'vue'

import LayoutContentHorizontalNav from '@core/layouts/content/LayoutContentHorizontalNav.vue'

// App Bar Component
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import AppBarProject from '@core/layouts/components/app-bar/AppBarProject.vue'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

import { useChatsStore } from '@/stores/chats.store'
import { useUserStore } from '@/stores/user.store'
import { useProjectsStore } from '@/stores/projects.store'
import navMenuItems from '@/navigation'
import useAppConfig from '@/stores/appconfig'

export default {
  components: {
    LayoutContentHorizontalNav,

    // App Bar Components
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarNotification,
    AppBarProject,
  },
  setup() {
    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const menuItems = ref([])
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    const vm = getCurrentInstance().proxy

    const projectsStore = useProjectsStore()
    const chatsStore = useChatsStore()
    const userStore = useUserStore()
    const {
      appName,
      footerName,
      appLogo,
      appLogoDark,
    } = useAppConfig()

    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })
    const createMenu = projectId => {
      menuItems.value = JSON.parse(JSON.stringify(navMenuItems.filter(m => !m.subheader)))
      menuItems.value.forEach(itemMenu => {
        if (itemMenu.to && itemMenu.to.params && itemMenu.parent === 'projects') {
          // eslint-disable-next-line no-param-reassign
          itemMenu.to.params.id = projectId || '0'
        }
        if (itemMenu.to?.params?.folder) itemMenu.to.params.folder = localStorage.getItem('folder')
        if (itemMenu.parent === 'projects') {
          itemMenu.children?.forEach(item => {
            if (item.to && item.to.params) {
            // eslint-disable-next-line no-param-reassign
              item.to.params.id = projectId || '0'
            }
            if (item.to?.params?.folder) item.to.params.folder = localStorage.getItem('folder')
          })
        }
      })
    }
    watch(() => userStore.user, () => createMenu(projectsStore.project?.id))
    watch(() => projectsStore.project, val => createMenu(val?.id))
    onBeforeMount(() => {
      if (vm.$router.currentRoute.params.id !== '0') { createMenu(vm.$router.currentRoute.params.id) }
      chatsStore.fetchChatsAndContacts()
    })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    return {
      menuItems,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      // App Config
      appName,
      footerName,
      appLogo,
      appLogoDark,
      chatsStore,
      userStore,

    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
