<template>
  <div class="v-form multi-col-validation"
       :class="obj && obj.schema['hide-details'] ? 'phone-number' : 'phone-number-details'"
  >
    <vue-tel-input-vuetify
      v-model="newPhone"
      v-bind="obj?.schema || {}"
      :label="$t('Phone')"
      placeholder=""
      outlined
      dense
      clearable
      mode="international"
      messages=" "
      :error-messages="messages"
      :preferred-countries="preferredCountries"
      :rules="rules"
      :disabled="disabled"
      @input="formatPhone"
    ></vue-tel-input-vuetify>
  </div>
</template>
<script>
import { ref, getCurrentInstance } from 'vue'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue'

export default {
  components: {
    VueTelInputVuetify,
  },
  props: {
    phone: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    obj: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const messages = ref('')
    const newPhone = ref('')
    const preferredCountries = ref(['FR', 'BE', 'GB', 'US'])
    const vm = getCurrentInstance().proxy

    newPhone.value = props.phone

    const formatPhone = (input, data) => {
      messages.value = ''
      emit('input', data.number.e164)
      if (!data.possible) {
        messages.value = vm.$t(data.possibility.replace(new RegExp('unknown', 'g'), ''))
      }
    }

    return {
      messages,
      newPhone,
      formatPhone,
      preferredCountries,
    }
  },
}
</script>

<style>
.phone-number .country-code {
    width: 46px !important;
    height: 46px !important;
    top: 3px !important;
    position: relative;
    margin-right: 5px;
}

.phone-number-details .country-code {
    width: 46px !important;
    height: 46px !important;
    top: -6px;
    position: relative;
    margin-right: 5px;
}
</style>
