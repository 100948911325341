import { ref, watch } from 'vue'
import { useOrganizationsStore } from '@/stores/organizations.store'

export default function useInvoicesList(organizationId) {
  const listTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    { text: 'Total', value: 'total' },
    { text: 'Date', value: 'created_at' },
    { text: 'Status', value: 'status' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['created_at'],
    sortDesc: [false],
  })
  const totalListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])
  const organizationsStore = useOrganizationsStore()

  const fetchDatas = () => {
    organizationsStore
      .getInvoices({
        organizationId,
        status: statusFilter.value,
        search: searchQuery.value,
        options: options.value,
      })
      .then(data => {
        listTable.value = data.data
        totalListTable.value = data.count
        loading.value = false
      })
      .catch(() => {
        listTable.value = []
        totalListTable.value = 0
        loading.value = false
      })
  }

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    fetchDatas()
  })

  return {
    tableColumns,
    searchQuery,
    options,
    listTable,
    statusFilter,
    totalListTable,
    loading,
    selectedTableData,
    fetchDatas,
  }
}
