import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase } from '@/plugins/supabase'
import { useFoldersStore } from '@/stores/folders.store'
import { useChatsStore } from '@/stores/chats.store'
import { required } from '@core/utils/validation'
import { canACL } from '@/plugins/acl/checkaccess'
import { TABLE_LISTINGS, displayMode } from '@core/utils'

// eslint-disable-next-line import/prefer-default-export
export const useListingsStore = defineStore({
  id: TABLE_LISTINGS,
  state: () => ({
    listings: [],
    isListings: false,
  }),
  actions: {
    getHeader(payload, item) {
      return {
        title: 'Listings',
        icon: 'mdi-format-list-group',
        name: ['name', 'properties->>firstname', 'properties->>lastname'],
        folder: payload?.folder,
        display: displayMode(TABLE_LISTINGS, 'table'),
        newData: 'NewListing',
        actionData: 'EditListings',
        editData: 'EditListing',
        search: 'SearchListing',
        searchIcon: 'mdi-text-search',
        add: {
          text: 'AddListing',
          icon: 'mdi-format-list-group-plus',
        },
        actions: [{
          title: 'Save', icon: 'mdi-content-save-outline', action: 'save', loading: false, acl: 'create,update',
        }, {
          title: 'Duplicate',
          icon: 'mdi-content-duplicate',
          action: 'duplicate',
          acl: 'create',
          disabled: !item?.created_at,
        },
        {
          title: 'Delete',
          icon: 'mdi-delete-outline-outline',
          action: 'delete',
          acl: 'delete',
          color: 'error',
          disabled: !item?.created_at,
        }],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['updated_at'],
        sortDesc: [true],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: 12,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
          search: true,
        }, {
          text: 'Type',
          value: 'type',
          type: 'font-weight-semibold text-button translate',
        },
        {
          text: 'Contacts',
          value: 'objects',
          type: 'font-weight-semibold text-button',
        },
        {
          text: 'LastSyncAt',
          value: 'last_sync_at',
          type: 'ago',
        },
        {
          text: 'UpdatedAt',
          value: 'updated_at',
          type: 'date',
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        // general
        name: null,
        type: 'auto',
        folder_id: payload?.folder_id,
        project_id: payload?.project_id,
        filters: null,
      }
    },

    getSchema(item, items, payload) {
      const foldersStore = useFoldersStore()
      const chatsStore = useChatsStore()

      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 8 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }

      schema.col1.schema.general = {
        title: 'General',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'px-3 ma-2',
        schema: {
          name: {
            type: 'text',
            label: 'Name',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-database',
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
          },
          type: {
            type: 'select',
            label: 'Type',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-turbine',
            items: [
              { text: 'Auto', value: 'auto' },
              { text: 'Manual', value: 'manual' },
              { text: 'Fixed', value: 'fixed' },
            ],
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
            disabled: !!item?.created_at,
          },
          folder_id: {
            type: 'autocomplete',
            subtype: 'folders',
            label: 'Folders',
            class: 'mx-1 mb-2',
            col: 12,
            clearable: true,
            complex: true,
            prependInnerIcon: 'mdi-folder-open',
            dense: true,
            items: foldersStore.folders,
            rules: items.length > 0 ? [] : [required],
          },
        },
      }
      if (!canACL('view', 'folders', { id: payload?.project_id })) {
        delete schema.col1.schema.general.schema.folder_id
      }
      schema.col1.schema.contacts = {
        title: 'Contacts',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'pa-3 ma-2',
        hidden: items.length > 0,
        schema: {
          view__contacts: {
            disabled: !canACL('view', 'contacts', { id: payload?.project_id }),
            type: 'btn',
            label: 'View',
            outlined: true,
            small: true,
            class: 'mb-3',
            iconLeft: 'mdi-table-search',
            color: 'primary',
            offset: 3,
            col: 6,
          },
        },
      }

      schema.col2.schema = {
        filter: {
          title: 'Filters',
          disabled: !canACL('view', 'properties', { id: payload?.project_id }),
          type: 'wrap',
          col: 12,
          outlined: true,
          shaped: true,
          ripple: false,
          class: 'pa-3 ma-2 listing_filters',
          hidden: item?.type !== 'auto',
          schema: {
            group: {
              type: 'select',
              subtype: 'translate',
              col: { cols: 8, md: 6 },
              label: 'Condition',
              class: 'mx-1 mb-2 listing-group',
              'item-text': 'name',
              items: [
                { name: 'AND', value: 'AND' },
                { name: 'OR', value: 'OR' },
              ],
            },
            add_filter: {
              type: 'btn',
              iconLeft: 'mdi-plus',
              label: 'AddFilter',
              outlined: true,
              small: true,
              class: 'mb-2 mt-1 mx-1',
              color: 'primary',
              col: { cols: 8, md: 6 },
            },
            filters: {
              type: 'array',
              col: 12,
              schema: {
                group: {
                  type: 'select',
                  subtype: 'translate',
                  col: { cols: 8, md: 2 },
                  label: 'Condition',
                  class: 'mx-1 mb-2',
                  'item-text': 'name',
                  hidden: true,
                  items: [
                    { name: 'AND', value: 'AND' },
                    { name: 'OR', value: 'OR' },
                  ],
                  disabled: true,
                },
                add_condition: {
                  type: 'btn',
                  col: { cols: 2, md: 1 },
                  offset: 1,
                  'x-small': true,
                  outlined: true,
                  iconCenter: 'mdi-plus',
                  class: 'mb-3',
                  tooltip: 'AddCondition',
                  color: 'primary',
                },
                delete_filter: {
                  type: 'btn',
                  col: { cols: 2, md: 1 },
                  class: 'mb-3',
                  'x-small': true,
                  outlined: true,
                  iconCenter: 'mdi-delete-outline',
                  color: 'error',
                },
                conditions: {
                  type: 'array',
                  offset: 1,
                  col: 11,
                  schema: {
                    condition: {
                      type: 'select',
                      subtype: 'translate',
                      col: { cols: 12, md: 2 },
                      label: 'Condition',
                      class: 'mx-1 mb-2',
                      'item-text': 'name',
                      'item-value': 'value',
                      items: [
                        { name: 'AND', value: 'AND' },
                        { name: 'OR', value: 'OR' },
                      ],
                    },
                    property: {
                      type: 'select',
                      label: 'Property',
                      class: 'mx-1 mb-2',
                      items: payload?.properties,
                      'item-text': 'name',
                      'item-value': 'slug',
                      rules: [required],
                      col: { cols: 12, md: 3 },
                    },
                    operation: {
                      type: 'select',
                      col: { cols: 12, md: 3 },
                      label: 'Operation',
                      class: 'mx-1 mb-2',
                      'item-text': 'name',
                      items: [
                        { name: 'Starts with', value: 'starts' },
                        { name: 'Contains', value: 'contains' },
                        { name: 'Not contains', value: 'not-contains' },
                        { name: 'Equals', value: 'equals' },
                        { name: 'Not equals', value: 'not-equals' },
                        { name: 'Is NULL', value: 'is-null' },
                        { name: 'Not NULL', value: 'not-null' },
                      ],
                    },
                    value: {
                      label: 'Value',
                      type: 'text',
                      col: { cols: 10, md: 3 },
                      class: 'mx-1 mb-2',
                    },
                    remove_condition: {
                      type: 'btn',
                      col: { cols: 2, md: 1 },
                      class: 'mt-2',
                      'x-small': true,
                      outlined: true,
                      iconCenter: 'mdi-delete-outline',
                      color: 'error',
                    },
                  },
                },
              },
            },
          },
        },
      }

      if (item?.id) {
        schema.col1.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col1.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col1.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col1.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col1.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col1.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          label: 'UpdatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }

      return schema
    },

    async onAction(action, payload) {
      return payload?.item
    },

    async getListings(projectId) {
      const { data, error } = await supabase.from(TABLE_LISTINGS).select('*').eq('project_id', projectId)
      if (error) {
        return Promise.reject(error)
      }
      this.listings = data

      return this.listings
    },

    async upsert(listings) {
      const { data, error } = await supabase.from(TABLE_LISTINGS.replace('_view', '')).upsert(listings).select()
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    getListingItem(id) {
      return this.listings.find(item => item.id === id)
    },

    onChangeGroup(item) {
      if (item && !item.group) {
        item.group = 'OR'
        if (item?.filters instanceof Array) {
          item.filters.forEach(f => {
            item.group = f.group
          })
        }
      }
      if (item?.filters instanceof Array) {
        item.filters.forEach(f => {
          f.group = item.group
        })
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useListingsStore, import.meta.hot))
}
