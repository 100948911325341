import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase } from '@/plugins/supabase'
import { TABLE_INTEGRATIONS } from '@core/utils'
import partners from '@/views/organizations/settings/integrations.json'
import axios from '@axios'

// eslint-disable-next-line import/prefer-default-export
export const useIntegrationsStore = defineStore({
  id: TABLE_INTEGRATIONS,
  state: () => ({
    integrations: [],
  }),
  actions: {
    async getIntegrations(organizationId, search, tags) {
      const { data, error } = await supabase
        .from(`decrypted_${TABLE_INTEGRATIONS}`)
        .select(
          '*',
        ).eq('organization_id', organizationId)

      let marketplaces = partners
      if (search) marketplaces = partners.filter(m => m.slug.indexOf(search.toLowerCase().replace(' ', '')) >= 0)
      if (tags?.length) marketplaces = marketplaces.filter(m => m.type.includes(tags))
      const integrations = []
      marketplaces.forEach(d => {
        const enabled = data.find(it => it.slug === d.slug)
        if (enabled) {
          enabled.enabled = true
          enabled.settings_crypted = enabled.decrypted_settings_crypted ? JSON.parse(enabled.decrypted_settings_crypted) : d.settings_crypted
          delete enabled.decrypted_settings_crypted
          integrations.push(enabled)
        } else { integrations.push(d) }
      })
      if (error) {
        throw error
      }
      this.integrations = integrations

      return integrations
    },

    async insert(integration) {
      const { data, error } = await supabase.from(TABLE_INTEGRATIONS.replace('_view', '')).insert(integration).select()
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async update(integration) {
      const { data, error } = await supabase.from(TABLE_INTEGRATIONS.replace('_view', '')).update({ settings_crypted: integration.settings_crypted, enabled: integration.enabled }).eq('id', integration.id)
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async delete(integration) {
      const { data, error } = await supabase.from(TABLE_INTEGRATIONS.replace('_view', '')).delete().eq('id', integration.id)
      if (error) {
        return Promise.reject(error)
      }

      return data
    },
    async getWebhooks(integration, organization) {
      try {
        const data = await axios.post('get-webhooks', { integration_id: integration.id, organization_id: organization.id })

        return data.data
      } catch (error) {
        return Promise.reject(error.response.data)
      }
    },
    async deleteWebhook(integration, webhookid, organization) {
      try {
        const data = await axios.post('delete-webhook', { integration_id: integration.id, webhook_id: webhookid, organization_id: organization.id })

        return data.data
      } catch (error) {
        return Promise.reject(error.response.data)
      }
    },
    async addWebhook(integration, organization) {
      try {
        const data = await axios.post('add-webhook', { integration_id: integration.id, organization_id: organization.id })

        return data.data
      } catch (error) {
        return Promise.reject(error.response.data)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIntegrationsStore, import.meta.hot))
}
