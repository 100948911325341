<template>
  <div id="header">
    <v-card-text
      v-if="config.header"
      class="d-flex pa-2"
      style="height:55px"
    >
      <v-btn
        v-if="!isLeftSidebarOpen && $canACL('view', 'folders',{id: $router.currentRoute.params.id})"
        icon
        class="mr-3 pt-1 d-inline-block"
        @click="isLeftSidebarOpen = true"
      >
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <div>
        <div v-if="$vuetify.breakpoint.xs && menu.length > 0"
             class="text-h6"
             style="top: 2px;position: relative;"
        >
          <v-icon v-if="menu[0].homeIcon"
                  class="ml-1 mr-2"
                  size="25"
          >
            {{ menu[0].homeIcon }}
          </v-icon>
          <span class="font-weight-semibold text-uppercase">{{ menu[0].title }}</span>

          <v-icon
            v-if="menu[0].icon"
            class="mx-1"
            color="primary"
          >
            {{ menu[0].icon }}
          </v-icon>
          <v-icon
            v-if="isDelete"
            class="mx-1"
            color="error"
          >
            mdi-delete-outline
          </v-icon>
        </div>
        <v-breadcrumbs
          v-else
          style="margin-right: 30px !important;   overflow: hidden;
  text-overflow: ellipsis;"
          class="pa-0 ma-0 pt-1"
          large
          dense
        >
          <template
            v-for="(menuitem, index) in menu"
          >
            <v-breadcrumbs-item
              :key="index"
              :class="menuitem.to && !menuitem.disabled ? 'cursor-pointer' :''"
              :disabled="menuitem.disabled"
              @click="menuitem.to? $emit(menuitem.to, index): null"
            >
              <span
                :class="menuitem.home? 'text-h6':'text-h7'"
              >  <v-icon v-if="menuitem.homeIcon"
                         class="ml-1 mr-2 pb-1"
                         size="25"
                         :disabled="menuitem.disabled"
                 >
                   {{ menuitem.homeIcon }}
                 </v-icon>
                <span :class="menuitem.bold? 'font-weight-semibold text-uppercase':'text-uppercase'">{{ menuitem.title }}</span>

                <v-icon
                  v-if="menuitem.icon"
                  class="mx-1"
                  color="primary"
                >
                  {{ menuitem.icon }}
                </v-icon>
                <v-icon
                  v-if="isDelete && index === menu.length -1"
                  class="mx-1"
                  color="error"
                >mdi-delete-outline</v-icon>

              </span>
              <v-chip
                v-if="menuitem.folder"
                close
                outlined
                class="text-overline ml-2 primary--text text-uppercase"
                @click:close="clearFolder"
              >
                / <v-icon
                  class="mr-1"
                  color="primary"
                >
                  mdi-folder-open
                </v-icon>{{ menuitem.folder.name }}
              </v-chip>
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider
              v-if="index !== menu.length -1"
              :key="'divider-'+index"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-breadcrumbs-divider>
          </template>
        </v-breadcrumbs>
      </div>
      <v-spacer></v-spacer>

      <div v-if="!isExport && !isImport">
        <template v-if="(!item && !config.currentTable && config.header.add)">
          <v-tooltip
            open-delay="500"
            top
          >
            <template #activator="{ on: onTooltip }">
              <v-btn
                color="primary"
                class="mt-1 mx-1"
                :disabled="loading || !$canACL('create', config.currentTable,{id: $router.currentRoute.params.id})"
                :small="!$vuetify.breakpoint.xs"
                :x-small="$vuetify.breakpoint.xs"
                @click="$emit('on-add')"
                v-on="{ ...onTooltip }"
              >
                <v-icon
                  :left="!$vuetify.breakpoint.xs"
                >
                  {{ config.header.add.icon }}
                </v-icon>
                <span class="d-none d-sm-block">{{ $t(config.header.add.text) }}</span>
              </v-btn>
            </template>
            <span>{{ $t(config.header.add.tooltip) }}</span>
          </v-tooltip>
        </template>

        <template v-if="( !item && config.currentTable && config.subheader.add)">
          <v-tooltip
            open-delay="500"
            top
          >
            <template #activator="{ on: onTooltip }">
              <v-btn
                color="primary"
                class="mt-1 mx-1"
                :disabled="loading || !$canACL('create', config.currentTable,{id: $router.currentRoute.params.id})"
                :small="!$vuetify.breakpoint.xs"
                :x-small="$vuetify.breakpoint.xs"
                @click="$emit('on-add')"
                v-on="{ ...onTooltip }"
              >
                <v-icon
                  :left="!$vuetify.breakpoint.xs"
                >
                  {{ config.subheader.add.icon }}
                </v-icon>
                <span class="d-none d-sm-block">{{ $t(config.subheader.add.text) }}</span>
              </v-btn>
            </template>
            <span>{{ $t(config.subheader.add.tooltip) }}</span>
          </v-tooltip>
        </template>

        <!--CUSTOM-->
        <properties-button v-if=" $canACL('create', config.currentTable,{id: $router.currentRoute.params.id}) && (!item && config.header?.buttons?.includes('properties'))"></properties-button>

        <files-button v-if=" $canACL('create', config.currentTable,{id: $router.currentRoute.params.id}) && (!item && config.header?.buttons?.includes('files'))"
                      :config="config"
        ></files-button>
      </div>
      <v-btn
        v-if="item && !directory[directory.length-1]?.editor"
        style="position: absolute; right:51px; top:11px;"
        icon
        color="primary"
        :small="!$vuetify.breakpoint.xs"
        :x-small="$vuetify.breakpoint.xs"
        @click="$emit('on-init', true)"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
      <v-btn
        v-if="item || isExport || isImport"
        style="position: absolute; right:11px; top:11px;"
        icon
        :small="!$vuetify.breakpoint.xs"
        :x-small="$vuetify.breakpoint.xs"
        @click="$emit('on-previous', undefined, currentTableDirectEdit && !directory[directory.length-1]?.editor)"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-text
      v-if="!isExport && !isImport"
      :class="(!item || config.currentTable && !item)?'d-flex align-center flex-wrap pa-2':'d-none'"
    >
      <div
        v-if="config.header.search || config.currentTable && config.subheader.search"
        class="d-flex flex-grow-1 pa-0 ma-0"
      >
        <v-text-field
          :value="searchQuery"
          single-line
          dense
          outlined
          hide-details
          :placeholder="config.currentTable && config.subheader.search ? $t(config.subheader.search) : $t(config.header.search)"
          class="mx-1 search-table"
          clearable
          :prepend-inner-icon="config.currentTable && config.subheader.search ? config.subheader.searchIcon:config.header.searchIcon || 'mdi-magnify'"
          clear-icon="mdi-close"
          @input="debounceInput"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <div

        class="d-flex align-center pb-1 "
      >
        <div
          class="text-right d-flex align-center justify-space-between flex-wrap"
        >
          <v-btn
            icon
            x-small
            class="d-none d-sm-block me-1"
            :disabled="loading"
            color="primary"
            @click="toggleGrid"
          >
            <v-icon v-if="config.header.display === 'grid'">
              mdi-view-grid
            </v-icon>
            <v-icon v-if="config.header.display === 'table'">
              mdi-table
            </v-icon>
            <v-icon v-if="config.header.display === 'timeline'">
              mdi-timeline-outline
            </v-icon>
          </v-btn>
          <v-btn
            icon
            x-small
            class="me-1"
            :disabled="loading"
            color="primary"
            @click="$emit('on-refresh')"
          >
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
          <v-btn
            v-if="items.length"
            icon
            x-small
            class="me-1"
            :disabled="loading"
            color="primary"
            @click="onSelectAll"
          >
            <v-icon v-if="!selectAll">
              mdi-select-group
            </v-icon>
            <v-icon v-else>
              mdi-select-off
            </v-icon>
          </v-btn>
          <span class="text-caption d-none mx-2 primary--text">{{ total }} {{ $t('items') }}</span>
        </div>
        <v-menu
          bottom
          offset-y
        >
          <template #activator="{ on: onMenu }">
            <v-tooltip
              open-delay="500"
              top
            >
              <template #activator="{ on: onTooltip }">
                <v-badge
                  top
                  left
                  overlap
                  color="primary"
                  :value="(itemActions.length >0)"
                  :content="itemActions?.length"
                >
                  <v-btn
                    outlined
                    class="me-1 ml-2"
                    :small="!$vuetify.breakpoint.xs"
                    :x-small="$vuetify.breakpoint.xs"
                    :disabled="loading"
                    color="primary"
                    v-on="{ ...onMenu, ...onTooltip }"
                  >
                    <v-icon
                      :left="!$vuetify.breakpoint.xs"
                    >
                      mdi-grid
                    </v-icon>
                    <span class="d-none d-sm-block">{{ $t("Actions") }}</span>
                  </v-btn>
                </v-badge>
              </template>
              <span>{{ $t("ActionsTooltip") }}</span>
            </v-tooltip>
          </template>

          <v-list>
            <v-list-item
              :disabled="!itemActions.length || !$canACL('update', config.currentTable,{id: $router.currentRoute.params.id})"
              @click="$emit('on-edit')"
            >
              <v-list-item-icon>
                <v-icon class="me-5"
                        :disabled="!itemActions.length || !$canACL('update', config.currentTable,{id: $router.currentRoute.params.id})"
                >
                  mdi-arrange-bring-to-front
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="overline">
                {{ $t('Edit') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="$emit('on-export')">
              <v-list-item-icon>
                <v-icon class="me-2">
                  mdi-database-export-outline
                </v-icon>
                <v-list-item-title class="overline">
                  {{ $t('Export') }}
                </v-list-item-title>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              :disabled="!$canACL('update', config.currentTable,{id: $router.currentRoute.params.id})"
              @click="$emit('on-import')"
            >
              <v-list-item-icon>
                <v-icon class="me-2"
                        :disabled="!$canACL('update', config.currentTable,{id: $router.currentRoute.params.id})"
                >
                  mdi-database-import-outline
                </v-icon>
                <v-list-item-title class="overline">
                  {{ $t('Import') }}
                </v-list-item-title>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              :disabled="!itemActions.length || !$canACL('delete', config.currentTable,{id: $router.currentRoute.params.id})"
              @click="$emit('on-delete')"
            >
              <v-list-item-icon>
                <v-icon class="me-5"
                        :disabled="!itemActions.length || !$canACL('delete', config.currentTable,{id: $router.currentRoute.params.id})"
                >
                  mdi-delete-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="overline">
                {{ $t('Delete') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="isLocal()"></v-divider>
            <v-list-item
              v-if="isLocal()"
              :disabled="!$canACL('update', config.currentTable,{id: $router.currentRoute.params.id})"
              @click="$emit('on-test')"
            >
              <v-list-item-icon>
                <v-icon class="me-5"
                        :disabled="!$canACL('update', config.currentTable,{id: $router.currentRoute.params.id})"
                >
                  mdi-ab-testing
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="overline">
                {{ $t('Test') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card-text>

    <v-row v-if="!item">
      <v-col id="user-view"
             cols="12"
      >
        <v-tabs v-if="tabs.length && !isImport && !isExport"
                v-model="currentTab"
                show-arrows
                class="user-tabs ma-0"
                style="position: relative; z-index: 0;"
        >
          <v-tab
            v-for="(tab, index) in tabs"
            :key="tab.id"
            @click="onTabEvent(index)"
          >
            <v-icon
              size="15"
              class="me-2 pt-1"
            >
              {{ tab.icon }}
            </v-icon>
            <span class="text-caption pt-1"
                  style="font-size: 0.65rem !important;"
            >{{ $t(tab.title) }}</span>
            <span v-if="tab.count >=0"
                  class="text-caption pt-1"
            >({{ tab.count }})</span>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref, computed,
} from 'vue'
import { useVModel } from '@vueuse/core'
import debounce from 'lodash/debounce'
import { useFoldersStore } from '@/stores/folders.store'
import PropertiesButton from '@/views/projects/settings/PropertiesButton.vue'
import FilesButton from '@/views/projects/settings/FilesButton.vue'
import { useTableStore } from '@/stores/table.store'
import {
  updateDirectory,
} from '@core/utils'

export default {
  components: {
    PropertiesButton,
    FilesButton,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    directory: {
      type: Array,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
    isExport: {
      type: Boolean,
      default: false,
    },
    isImport: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    item: {
      default: null,
    },
    itemActions: {
      type: Array,
      default: null,
    },
    tabs: {
      type: Array,
      default: null,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isLeftSidebarOpen: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      required: true,
    },
    searchQuery: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    listing: {
      type: Object,
      default: null,
    },
    currentTableDirectEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const foldersStore = useFoldersStore()
    const tableStore = useTableStore()
    const searchQuery = useVModel(props, 'searchQuery', emit)
    const item = useVModel(props, 'item', emit)
    const items = useVModel(props, 'items', emit)
    const config = useVModel(props, 'config', emit)
    const directory = useVModel(props, 'directory', emit)
    const isLeftSidebarOpen = useVModel(props, 'isLeftSidebarOpen', emit)
    const currentTab = ref(null)
    const loading = useVModel(props, 'loading', emit)
    const selectAll = ref(false)
    const updatedAt = ref(null)

    const tabs = computed(() => {
      const data = [
      ]

      const step = directory.value.length - 1 > 0 ? directory.value.length - 1 : 0
      const currentDirectory = directory.value[step]

      let children = []

      // folder
      if (!currentDirectory?.folder?.table) {
        children = JSON.parse(JSON.stringify(foldersStore.allFolders[currentDirectory?.folder?.id]?.children || null))
        children?.splice(0, 0, {
          title: ' ', id: currentDirectory?.folder.id, icon: 'mdi-folder-open', updated_at: updatedAt.value,
        })
      } else {
        children = JSON.parse(JSON.stringify(foldersStore.allFolders[currentDirectory?.folder.parent_id]?.children || null))
        children?.splice(0, 0, {
          title: ' ', id: currentDirectory?.folder.parent_id, icon: 'mdi-folder-open', updated_at: updatedAt.value,
        })
      }

      children?.forEach(it => {
        if (it.title) {
          data
            .push({
              title: it.title, id: it.id, icon: it.icon, count: it.count, updated_at: updatedAt.value,
            })
        }
      })

      // eslint-disable-next-line vue/no-async-in-computed-properties
      setTimeout(() => {
        currentTab.value = 0
        if (data && currentDirectory?.folder?.table) {
          currentTab.value = data.findIndex(l => l.id === currentDirectory?.folder?.id)
        }
      }, 20)

      return data
    })

    const debounceInput = debounce(e => {
      searchQuery.value = e
    }, 250)

    const onTabEvent = index => {
      updateDirectory(directory.value, directory.value.length - 1, { folder: foldersStore.allFolders[tabs.value[index].id] })
    }

    const clearFolder = () => {
      updateDirectory(directory.value, 0, { folder: null })
    }

    const onSelectAll = () => {
      if (selectAll.value) {
        items.value = []
        selectAll.value = false

        return
      }
      loading.value = true
      tableStore.getExport().then(data => {
        items.value = data
        loading.value = false
        selectAll.value = true
      }).catch(() => {
        loading.value = false
      })
    }

    const isLocal = () => window.location.hostname === 'localhost'

    const toggleGrid = () => {
      if (config.value.header.display === 'table') config.value.header.display = 'grid'
      else if (config.value.header.display === 'grid') config.value.header.display = 'timeline'
      else if (config.value.header.display === 'timeline') config.value.header.display = 'table'
      localStorage.setItem(`display-${config.value.currentTable}`, config.value.header.display)
    }

    const refreshTabs = () => {
      updatedAt.value = new Date()
    }

    return {
      config,
      searchQuery,
      debounceInput,
      item,
      items,
      isLeftSidebarOpen,
      tabs,
      currentTab,
      loading,
      selectAll,
      onTabEvent,
      onSelectAll,
      isLocal,
      clearFolder,
      toggleGrid,
      refreshTabs,
    }
  },
}
</script>
<style>
.v-application.theme--light .user-tabs.v-tabs:not(.v-tabs--vertical) {
    border-bottom: 0 !important;
}
.v-tab {
  min-width: 50px;
  max-width: 360px;
  padding: 0px 10px;
}
.v-tabs-bar {
    height: 38px;
}
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
