export default {
  AllProjects: 'Tous les projets',
  Dashboard: 'Dashboard',
  Olympics: 'Olympiques',
  Admins: 'Administrateurs',
  Settings: 'Paramètres',
  Organizations: 'Organisations',
  Projects: 'Projets',
  Project: 'Projet',
  AccountSettings: 'Paramètres',
  CreateProject: 'Créer un projet',
  StartProject: 'Voir le projet',
  ActiveProject: 'Activer le projet',
  ActivePlan: 'Activer un plan',
  Complete: 'Terminer',
  Cancel: 'Annuler',
  Next: 'Suivant',
  Previous: 'Précédent',
  Reset: 'Effacer',
  CreateOrganization: 'Créer une organisation',
  AllowedImageFormats: 'JPG, GIF ou PNG autorisés.',
  UploadPicture: 'Choisir une image',
  Name: 'Nom',
  Organization: 'Organisation',
  Description: 'Description',
  ProjectDetails: 'Détails du projet',
  SetupProjectDetails: 'Configurez votre projet',
  OrganizationPlan: 'Tarifs',
  SetupOrganizationPlan: 'Choisissez un abonnement',
  ReviewProjectInformations: 'Confirmez vos informations',
  SubmitProject: 'Validation',
  Monthly: 'Mensuel',
  Annually: 'Annuel',
  PricingPlans: 'Tarifs',
  PricingDetails: 'Commencer gratuitement, puis ajouter un plan payant.',
  Popular: 'Populaire',
  ChoosePlan: 'Choisir ce plan',
  ChooseYourPlan: 'Choisir votre plan',
  CurrentPlan: 'Votre abonnement',
  CurrentPlanIs: 'Votre abonnement actuel est',
  month: 'mois',
  year: 'an',
  Edit: 'Éditer',
  Delete: 'Supprimer',
  Submit: 'Valider',
  Save: 'Sauvegarder',
  BillingAddress: 'Adresse de facturation',
  Address: 'Adresse',
  EditAddress: "Modifier l'adresse",
  AddBillingAddress: 'Ajoutez votre adresse de facturation.',
  PaymentMethods: 'Moyens de paiement',
  AddCard: 'Ajouter une carte',
  Company: 'Société',
  BillingEmail: 'Email',
  VATNumber: 'Numéro de TVA',
  Country: 'Pays',
  ZipCode: 'Code postal',
  CardExpiresAt: 'La carte expire le',
  CardNumber: 'Numéro de carte',
  CardHolder: 'Titulaire',
  CardStatus: 'Statut',
  General: 'Général',
  BillingPlan: 'Abonnement',
  BillingPayment: 'Facturation',
  Members: 'Membres',
  Invoices: 'Factures',
  CreateInvoice: 'Créer une facture',
  CancelSubscription: "Annuler l'Abonnement",
  PayInvoice: 'Payer la facture',
  SeeInvoice: 'Voir la facture',
  Upgrade: 'Mettre à niveau',
  Active: 'Activer',
  UpgradePlan: 'Mettre à niveau',
  ChooseBestPlan: 'Choisissez le meilleur plan pour votre projet.',
  PaymentInterval: 'Intervalle de paiement',
  CreatedAt: 'Créé le',
  UpdatedAt: 'Modifié le',
  UpdatedBy: 'Modifié par',
  CreatedBy: 'Créé par',
  EndAt: 'Termine le',
  StartAt: 'Commence le',
  SubscriptionStatus: "État de l'abonnement",
  Days: 'Jours',
  Profile: 'Profil',
  Logout: 'Se déconnecter',
  MM: 'MM',
  YYYY: 'YYYY',
  CVV: 'CVV',
  Primary: 'Principal',
  Secondary: 'Secondaire',
  Expired: 'Expiré',
  SubscriptionIncomplete: "Votre abonnement est incomplet. Veuillez payer la facture pour l'activer.",
  NeedAttention: 'Nous avons besoin de votre attention !',
  AllOrganizations: 'Toutes les organisations',
  View: 'Voir',
  Website: 'Site web',
  UploadPhoto: 'Télécharger une photo',
  FullName: 'Prénom nom',
  OrganizationCreatedSuccessfully: 'Organisation créée avec succès',
  OrganizationSavedSuccessfully: 'Organisation enregistrée avec succès',
  ProjectSavedSuccessfully: 'Projet enregistré avec succès',
  ProjectCreatedSuccessfully: 'Projet créé avec succès.',
  ProfileSavedSuccessfully: 'Profil enregistré avec succès.',
  PaswwordSavedSuccessfully: 'Mot de passe enregistré avec succès.',
  WorkspaceCreatedSuccessfully: 'Workspace créée avec succès',
  SavedSuccessfully: 'Opération réussie avec succès.',
  OrganizationDetails: "Détails de l'organisation",
  SetupOrganizationDetails: 'Configurez les informations de votre organisation',
  OrganizationBilling: "Facturation de l'organisation",
  SetupOrganizationBilling: 'Définissez vos méthodes de paiement',
  Create: 'Créer',
  Finish: 'Terminer',
  ViewInvoice: 'Voir la facture',
  SearchInvoice: 'Rechercher une facture',
  SelectStatus: 'Choisir le statut',
  Phone: 'Téléphone',
  SearchMember: 'Rechercher un membre',
  AddMember: 'Ajouter un membre',
  EditMember: 'Éditer un membre',
  Role: 'Rôle',
  Email: 'Email',
  InviteMember: "Inviter un membre à l'organisation",
  MemberNeedBeRegistered: 'Les membres que vous souhaitez inviter doivent déjà être inscrits',
  AreYouSure: 'Êtes-vous sûr ?',
  DeletingMemberPermanent: "La suppression d'un membre est définitive",
  PermissionDeniedProject: 'Vous devez être un membre propriétaire pour créer un projet.',
  DangerZone: 'Zone de danger',
  DeleteOrganizationDetails:
    "La suppression de cette organisation supprimera également tous les projets affiliés. Assurez-vous d'avoir fait une sauvegarde si vous souhaitez conserver vos données.",
  DeleteOrganization: "Supprimer l'organisation",
  DeleteProjectDetails:
    "La suppression de ce projet supprimera également toutes les bases de données affiliées. Assurez-vous d'avoir fait une sauvegarde si vous souhaitez conserver vos données.",
  DeleteProject: 'Supprimer le projet',
  Password: 'Mot de passe',
  ForgotPassword: 'Mot de passe oublié ?',
  WelcomeSignin: 'Bienvenue ! 👋🏻',
  WelcomeSigninDetails: "Connectez-vous et commencez l'avanture",
  Login: 'Connexion',
  NewOnPlatform: 'Nouveau sur notre plateforme ?',
  CreateAnAccount: 'Créer un compte',
  SigninTermsPolicy:
    "En vous connectant, vous acceptez les conditions d'utilisation et la politique de confidentialité de Micleo.",
  Or: 'Ou',
  OR: 'OU',
  AND: 'ET',
  WelcomeSignup: "L'aventure commence ici 🚀",
  WelcomeSignupDetails: "Veuillez vous inscrire pour commencer l'aventure",
  ConfirmYourEmailSignup: 'Veuillez cliquez sur le lien envoyé par e-mail pour confirmer votre inscription.',
  ConfirmYourEmailUpdated: 'Veuillez cliquez sur le lien envoyé par e-mail pour confirmer votre modification.',
  ResendConfirmation: 'Renvoyer la confirmation',
  SignUp: 'Inscription',
  SignupTermsPolicy:
    "En vous inscrivant, vous acceptez les conditions d'utilisation et la politique de confidentialité de Micleo.",
  AlreadyHaveAccount: 'Vous avez déjà un compte?',
  SignInInstead: 'Se connecter',
  PrivacyPolicy: 'Politique de confidentialité',
  Close: 'Fermer',
  ForgotPasswordDetails: 'Entrez votre email afin de recevoir un lien pour réinitialiser votre mot de passe',
  ClickResetLink: "Veuillez cliquer sur le lien de réinitialisation dans l'email.",
  SendResetLink: 'Envoyer le lien',
  BackToLogin: 'Retour connexion',
  NotAuthorized: "Vous n'êtes pas autorisé !",
  NotAuthorizedDetails: "Vous n'êtes pas autorisé à accéder à cette page.",
  BackToHome: 'Retour',
  PageNotFound: 'Page non trouvé',
  PageNotFoundDetails: "Nous n'avons pas pu trouver la page que vous recherchez.",
  ErrorOccured: 'Une erreur est survenue',
  ErrorOccuredDetails: "Nous n'avons pas pu trouver la page que vous recherchez.",
  UnderMaintenance: 'En maintenance ! 🚧',
  UnderMaintenanceDetails: 'Désolé pour la gêne occasionnée, mais nous effectuons actuellement une maintenance',
  SearchProject: 'Rechercher un projet',
  TypeDelete: 'Saisissez “Supprimer”',
  PermissionDeniedOrganization: 'Vous devez créer une adresse de facturation pour continuer.',
  Member: 'Membre',
  Actions: 'Actions',
  Action: 'Action',
  Total: 'Total',
  Date: 'Date',
  Status: 'Statut',
  paid: 'Payé',
  open: 'Ouvert',
  draft: 'Brouillon',
  processing: 'En traitement',
  void: 'Terminé',
  uncollectible: 'Irrécouvrable',
  ID: '#ID',
  Slug: '#Slug',
  Contacts: 'Contacts',
  Export: 'Exporter',
  SearchContact: 'Rechercher un contact',
  EditContacts: 'Éditer les contacts',
  EditContact: 'Éditer le contact',
  sheets: "fiche(s) d'informations",
  DeleteContacts: 'Supprimer des contacts',
  EditLists: 'Éditer les listes / vues',
  EditDatas: 'Éditer les données',
  GroupedAction: 'Action groupée',
  ImportContacts: 'Importer des contacts',
  AddContact: 'Ajouter un contact',
  AllContacts: 'Tous les contacts',
  DeleteContactsAlert: 'Supprimer définitivement ce(s) contact(s) ?',
  tooShort: 'Trop court',
  French: 'Français',
  English: 'Anglais',
  AllrightsReserved: 'Tous les droits sont réservés',
  FieldRequired: 'Ce champ est requis',
  RequiredError: '%{field} est un champs requis',
  FieldEmailError: "Format de l'email invalide",
  FieldPasswordError:
    'Le champ doit contenir au moins une majuscule, une minuscule, un caractère spécial et un chiffre avec au moins 8 caractères',
  FieldConfirmPasswordError: 'Le mot de passe et le champ confirmation ne correspondent pas',
  FieldIntegerError: 'Ce champ doit être un entier',
  FieldRegexError: "Le format du champ n'est pas valide",
  FieldInvalidFormatError: "Le format du champ n'est pas valide",
  InvalidFormatError: "'%{value}' n'a pas un format valide pour le champs %{field}",
  FieldAlphaError: 'Ce champ ne peut contenir que des caractères alphabétiques',
  FieldURLError: 'URL invalide',
  FieldIntegerBetweenError: 'Entrer un nombre entre %{min} and %{max}',
  FieldMinLengthError: 'Ce champ doit contenir au minimum %{length} caractères',
  FieldMaxLengthError: 'Ce champ doit contenir au maximum %{length} caractères',
  MinLengthError: '%{field} doit contenir au minimum %{length} caractères',
  MaxLengthError: '%{field} doit contenir au maximum %{length} caractères',
  UniqueError: "'%{value}' existe déjà, %{field} doit être unique",
  'Invalid login credentials': 'Authentification invalide',
  'Password should be at least 6 characters': 'Le mot de passe doit comporter au moins 6 caractères',
  'too-short': 'Trop court',
  'too-long': 'Trop long',
  'invalid-country-code': 'Code pays invalide',
  reader: 'lecteur',
  owner: 'propriétaire',
  editor: 'éditeur',
  resource_missing: 'Veuillez définir un moyen de paiement par défaut pour continuer.',
  SeeMyPortail: 'Voir mon portail client',
  CancelSubscriptionDetails:
    "Votre plan sera annulé, mais il restera disponible jusqu'à la fin de votre période de facturation. Si vous changez d'avis, vous pouvez renouveler votre abonnement.",
  ActionsTooltip: 'Effectuer des actions groupées sur le tableau',
  SettinsTooltip: 'Importer, paramètrer la fiche des contacts',
  AddListingTootltip: 'Créer une liste / une vue',
  SearchOrganization: 'Rechercher une organisation',
  ChangePassword: 'Changer de mot de passe',
  CurrentPassword: 'Mot de passe actuel',
  NewPassword: 'Nouveau mot de passe',
  PasswordRequirements: 'Recommendations mot de passe :',
  PasswordMinimum8: 'Minimum 8 caractères',
  Password1LowerCase: 'Au moins un caractère minuscule',
  Password1Symbol: "Au moins un chiffre, un symbole ou un caractère d'espacement",
  ConfirmPassword: 'Confirmez votre mot de passe',
  RecentDevices: 'Appareils récents',
  Browser: 'Navigateur',
  Device: 'Appareil',
  Location: 'Lieu',
  RecentActivity: 'Activité récente',
  MFA: 'Authentification multifacteur (MFA)',
  MFAEnabled: 'Authentification multifacteur est activée.',
  MFADisabled: "Authentification multifacteur par n'est pas encore activée.",
  MFAInfo:
    "L'authentification multifacteur par TOTP ajoute une couche de sécurité supplémentaire à votre compte en exigeant plus qu'un simple mot de passe pour vous connecter.",
  EnableMFA: 'Activer MFA',
  DisableMFA: "Désactiver L'Authentification multifacteur",
  FieldMaxLength: 'Le champ doit contenir {length} caractères ou moins',
  ConnectedAccounts: 'Moyens de connexions',
  unverified: 'Non vérifié',
  verified: 'Vérifié',
  EnterCodeMFA: 'Saisissez votre code TOTP',
  InvalidTOTP: 'Code TOTP invalide',
  ScanQRCodeTOTP: 'Scannez le QR Code avec votre application TOTP',
  Language: 'Langue',
  Appearance: 'Apparence',
  Applications: 'Applications',
  Integrations: 'Intégrations',
  Campaigns: 'Campagnes',
  Folders: 'Dossiers',
  Listings: 'Listes / Vues',
  Activities: 'Activités',
  Properties: 'Propriétés',
  AdvancedSettings: 'Paramètres avancés',
  Marketing: 'Marketing',
  Workflows: 'Workflows',
  YourPassword: 'Votre mot de passe',
  PasswordInfo: 'Assurez-vous de modifier votre mot de passe régulièrement pour améliorer la sécurité de votre compte.',
  Security: 'Sécurité',
  AddProperty: 'Ajouter une propriété',
  SearchProperty: 'Rechercher une propriété',
  CreditCard: 'Carte de crédit',
  ResetPassword: 'Réinitialiser votre mot de passe',
  DefaultValue: 'Valeur par défaut',
  Validation: 'Validation',
  Type: 'Type',
  Category: 'Catégorie',
  Required: 'Requis',
  Minimum: 'Minimum',
  Maximum: 'Maximum',
  Regex: 'Regex',
  Unique: 'Unique',
  text: 'Text',
  textarea: 'Textarea',
  number: 'Nombre',
  date: 'Date',
  time: 'Temps',
  array: 'Tableau',
  phone: 'Téléphone',
  email: 'Email',
  password: 'Mot de passe',
  url: 'URL',
  color: 'Couleur',
  file: 'Fichier',
  boolean: 'Boolean',
  singleline: 'Champs texte',
  dropdown: 'Liste déroulante',
  switch: 'Switch',
  slider: 'Slider',
  rating: 'Rating',
  radioboxes: 'Radioboxes',
  checkboxes: 'Checkboxes',
  combobox: 'Combobox',
  slug: 'Slug',
  MinimumLength: 'Longueur minimum',
  MaximumLength: 'Longueur maximum',
  'date-picker': 'Calendrier',
  'time-picker': 'Horloge',
  'date-time-picker': 'Date & Heure',
  EditProperty: 'Éditer la propriété',
  TimeZone: 'Time Zone',
  NewProperty: 'Nouvelle propriété',
  ModelsProperties: 'Modèles de propriétés',
  AddPropertyTootltip: 'Ajouter une propriété pour les données de vos contacts',
  ContactsProperties: 'Propriétés des contacts',
  Notifications: 'Notifications',
  ReadAllNotifications: 'Marquer comme tout lu',
  Chat: 'Chat',
  Chats: 'Chats',
  StartConversation: 'Démarrer une conversation',
  Search: 'Rechercher',
  CreateChat: 'Créer une nouvelle conversation',
  Send: 'Envoyer',
  TypeYourMessage: 'Saisissez votre message',
  Header: 'Entête',
  EditProperties: 'Éditer les propriétés',
  Visibility: 'Visible',
  Hidden: 'Masqué',
  Details: 'Détails',
  Import: 'Importer',
  Table: 'Tableau',
  NewItem: 'Ajouter un élément',
  EditItems: 'Éditer les éléments',
  SearchItem: 'Rechercher un élément',
  NewFolder: 'Ajouter un dossier',
  EditFolders: 'Éditer les dossiers',
  EditFolder: 'Éditer le dossier',
  Path: 'Chemin',
  Folder: 'Dossier',
  SearchFolder: 'Rechercher un dossier',
  FolderError: 'Ce dossier possède des sous-dossiers',
  '#': '#',
  ToggleAll: 'Tout sélectionner',
  ClearAll: 'Tout éffacer',
  true: 'Vrai',
  false: 'Faux',
  items: 'élément(s)',
  noresultsmatching: 'Aucun résultat. Appuyez sur <kbd>entrée</kbd> pour en créer un.',
  Add: 'Ajouter',
  Options: 'Options',
  Value: 'Valeur',
  NewOption: 'Ajouter une option',
  EditOption: "Éditer l'option",
  EditOptions: 'Éditer les options',
  SearchOption: 'Rechercher une option',
  Lastname: 'Nom',
  Firstname: 'Prénom',
  'Job Title': 'Fonction',
  ImportData: 'Importer vos données',
  ImportDataDetails: 'Importez un ficher ou copier/coller vos données',
  ImportFile: 'Importer un fichier',
  CopyPaste: 'Copier / Coller',
  AllowedImportFormats: 'CSV ou XLS autorisés',
  MappingImport: 'Affilier vos données',
  MappingImportDetails: "Correspondez votre modèle et l'import",
  replace: 'Remplacer',
  merge: 'Fusionner',
  Model: 'Modèle',
  DragDrop: 'Glissez et déposez',
  ConfirmImport: 'Confirmation',
  ConfirmImportDetails: 'Validez avant import',
  Conflict: 'Conflit',
  ItemsImportedDetails: '%{inserts} élément(s) ajouté(s) et %{updates} modifié(s) !',
  ItemsImported: 'Elément(s) importé(s) avec succès !',
  Items: 'Eléments',
  ExportColumns: 'Choisir vos colonnes',
  ExportColumnsDetails: 'Définissez les colonnes de votre export',
  ConfirmExport: 'Confirmation',
  ConfirmExportDetails: 'Validation avant export',
  Error: 'Erreur',
  errors: 'erreur(s)',
  corrects: 'correct(s)',
  Test: 'Test',
  'color-picker': 'Sélecteur de couleurs',
  Encrypt: 'Encrypter',
  Color: 'Couleur',
  ReadOnly: 'Lecture seule',
  NewListing: 'Nouvelle Liste / Vue',
  SearchListing: 'Rechercher une Liste / Vue',
  EditListings: 'Éditer les Listes / Vues',
  EditListing: 'Éditer la Liste / Vue',
  AddListing: 'Ajouter une Liste / Vue',
  Property: 'Propriété',
  Condition: 'Condition',
  Filters: 'Filtres',
  Filter: 'Filtre',
  AddCondition: 'Ajouter une condition',
  AddFilter: 'Ajouter un filtre',
  Operation: 'Opération',
  LastSyncAt: 'Dernière synchronisation',
  NewCampaign: 'Nouvelle Campagne',
  SearchCampaign: 'Rechercher une Campagne',
  EditCampaigns: 'Éditer les Campagnes',
  EditCampaign: 'Éditer la Campagne',
  AddCampaign: 'Ajouter une Campagne',
  NewTemplate: 'Nouveau Template',
  SearchTemplate: 'Rechercher un Template',
  EditTemplates: 'Éditer les Templates',
  EditTemplate: 'Éditer le Template',
  AddTemplate: 'Ajouter un Template',
  scheduled: 'Programmé',
  archived: 'Archivé',
  active: 'En cours',
  sent: 'Envoyé',
  campaign: 'Campagne',
  Campaign: 'Campagne',
  transactional: 'Transactionnelle',
  Editor: 'Éditeur',
  auto: 'Dynamique',
  manual: 'Manuel',
  Subject: 'Objet',
  SenderEmail: "Email de l'expéditeur",
  SenderName: "Nom de l'expéditeur",
  ReplyTo: 'Reply To',
  ScheduledAt: 'Date programmé',
  DeliveredAt: 'Délivré',
  Recipients: 'Destinataires',
  EditRecipients: 'Éditer les destinataires',
  Statistics: 'Statistiques',
  Models: 'Modèles / Objets',
  NewModel: 'Nouveau Modèle',
  SearchModel: 'Rechercher un Modèle',
  EditModels: 'Éditer les Modèles',
  EditModel: 'Éditer le Modèle',
  AddModel: 'Ajouter un Modèle',
  Confirm: 'Confirmer',
  NewIntegration: 'Nouvelle Intégration',
  SearchIntegration: 'Rechercher une Intégration',
  EditIntegrations: "Éditer l'Intégration",
  AddIntegration: 'Ajouter une Intégration',
  Enable: 'Activer',
  Disable: 'Désactiver',
  Enabled: 'Activé',
  Disabled: 'Désactivé',
  payments: 'Paiements',
  events: 'Evénementiels',
  sms: 'SMS',
  SelectTags: 'Choisir une catégorie',
  api_key: 'API Key',
  api_secret: 'API Secret',
  SendCampaign: "Envoyer la campagne '%{campaign}' à %{recipients} destinataire(s).",
  AddWebhook: 'Ajouter un Webhook',
  TestCampaign: 'Tester la campagne',
  ViewStats: 'Voir les statistiques',
  ViewLogs: 'Voir les logs',
  SearchActivity: 'Rechercher une activité',
  Logs: 'Logs',
  SearchLog: 'Rechercher un log',
  created: 'Créé',
  done: 'Terminé',
  TaskID: 'Task ID',
  TaskDetails: 'Task Details',
  error: 'Erreur',
  NewWorkflow: 'Nouveau Workflow',
  SearchWorkflow: 'Rechercher un Workflow',
  EditWorkflows: 'Éditer les Workflows',
  EditWorkflow: 'Éditer le Workflow',
  AddWorkflow: 'Ajouter un Workflow ',
  Input: 'Entrée',
  Output: 'Sortie',
  NewObjectInListing: 'Nouveau contact dans le Listing',
  NewActivity: 'Nouvelle activité',
  SendCampaignAction: 'Envoyer une campagne',
  AddToListing: 'Ajouter au Listing',
  SetProperties: 'Enregistrer des propriétés',
  Delay: 'Délai',
  Delays: 'Délais',
  Times: 'Temps',
  Triggers: 'Triggers',
  Conditions: 'Conditions',
  domain: 'Domaine',
  Recipient: 'Destinataire',
  account_sid: 'Account SID',
  auth_token: 'Auth token',
  token_id: 'Token ID',
  token_secret: 'Token secret',
  Default: 'Par défaut',
  Data: 'Données',
  NewData: 'Nouvelle Donnée',
  SearchData: 'Rechercher une Donnée',
  EditData: 'Éditer les Données',
  AddData: 'Ajouter une Donnée',
  Templates: 'Templates',
  trialdays: "jours d'essais",
  CancelAt: 'Votre abonnement sera annulé le',
  NewForm: 'Nouveau Formulaire',
  SearchForm: 'Rechercher un Formulaire',
  EditForms: 'Éditer les Formulaires',
  EditForm: 'Éditer le Formulaire',
  AddForm: 'Ajouter un Formulaire ',
  fm: {
    components: {
      fields: {
        input: 'Input',
        textarea: 'Textarea',
        number: 'Number',
        radio: 'Radio',
        checkbox: 'Checkbox',
        time: 'Time',
        date: 'Date',
        rate: 'Rate',
        color: 'Color',
        select: 'Select',
        switch: 'Switch',
        slider: 'Slider',
        text: 'Text',
        blank: 'Custom',
        fileupload: 'File',
        imgupload: 'Image',
        editor: 'Editor',
        cascader: 'Cascader',
        table: 'Sub-table',
        grid: 'Grid',
        tabs: 'Tabs',
        divider: 'Divider',
      },
      basic: {
        title: 'Composant de Base',
      },
      advance: {
        title: 'Composant Avancé',
      },
      layout: {
        title: 'Page',
      },
    },
    description: {
      containerEmpty: "Glissez et déposez l'élément depuis la gauche pour ajouter des champs.",
      configEmpty: 'Please add a component',
      tableEmpty: "Glissez et déposez l'élément depuis la gauche pour ajouter des champs.",
      uploadJsonInfo: 'There is the format of JSON below，you can overwrite it with you own JSON code',
    },
    message: {
      copySuccess: 'Copie réussie',
      validError: 'La validation des données du formulaire a échoué',
    },
    actions: {
      import: 'Import JSON',
      clear: 'Effacer',
      preview: 'Preview',
      json: 'Generate JSON',
      code: 'Generate Code',
      getData: 'Get Data',
      reset: 'Réinitialiser',
      copyData: 'Copy Data',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      addOption: 'Ajouter une Option',
      addColumn: 'Ajouter une Colonne',
      addTab: 'Ajouter un Onglet',
      upload: 'Upload',
      add: 'Ajouter',
    },
    config: {
      form: {
        title: 'Formulaire',
        labelPosition: {
          title: 'Position du Label',
          left: 'Gauche',
          right: 'Droite',
          top: 'Haut',
        },
        labelWidth: 'Largeur du Label',
        size: 'Taille',
        customClass: 'Custom Class',
      },
      widget: {
        title: 'Composant',
        model: 'ID',
        name: 'Nom',
        width: 'Largeur',
        height: 'Hauteur',
        size: 'Taille',
        labelWidth: 'Largeur du Label',
        custom: 'Custom',
        placeholder: 'Placeholder',
        layout: 'Layout',
        block: 'Block',
        inline: 'Inline',
        contentPosition: 'Content Position',
        left: 'Gauche',
        right: 'Droite',
        center: 'Centrer',
        showInput: 'Display Input Box',
        min: 'Minimum',
        max: 'Maximum',
        step: 'Step',
        multiple: 'Multiple',
        filterable: 'Searchable',
        allowHalf: 'Allow Half',
        showAlpha: 'Support transparency options',
        showLabel: 'Show lable',
        option: 'Option',
        staticData: 'Static Data',
        remoteData: 'Remote Date',
        remoteFunc: 'Remote Function',
        value: 'Value',
        label: 'Label',
        childrenOption: 'Sub-Option',
        defaultValue: 'Valeur par défaut',
        showType: 'Display type',
        isRange: 'Range Time',
        isTimestamp: 'Get time stamp',
        startPlaceholder: 'Placeholder of start time',
        endPlaceholder: 'Placeholder of end time',
        format: 'Format',
        limit: 'Maximum Upload Count',
        isQiniu: 'Upload with Qiniu Cloud',
        tokenFunc: 'A funchtin to get Qiniu Uptoken',
        imageAction: 'Picture upload address',
        tip: 'Text Prompt',
        action: 'Upload Address',
        defaultType: 'Data Type',
        string: 'String',
        object: 'Object',
        array: 'Array',
        number: 'Number',
        boolean: 'Boolean',
        integer: 'Integer',
        float: 'Float',
        url: 'URL',
        email: 'E-mail',
        hex: 'Hexadecimal',
        gutter: 'Grid Spacing',
        columnOption: 'Column Configuration',
        span: 'Grid spans',
        justify: 'Horizontal Arrangement',
        justifyStart: 'Start',
        justifyEnd: 'End',
        justifyCenter: 'Center',
        justifySpaceAround: 'Space Around',
        justifySpaceBetween: 'Space Between',
        align: 'Vertical Arrangement',
        alignTop: 'Top',
        alignMiddle: 'Middle',
        alignBottom: 'Bottom',
        type: 'Type',
        default: 'Par défaut',
        card: 'Tabs',
        borderCard: 'Border-Card',
        tabPosition: 'Tab Position',
        top: 'Haut',
        bottom: 'Bas',
        tabOption: 'Label Configuration',
        tabName: 'Tab Name',
        customClass: 'Custom Class',
        attribute: 'Action',
        dataBind: 'Data Binding',
        hidden: 'Hidden',
        readonly: 'Lecture seule',
        disabled: 'Désactivé',
        editable: 'Text box is editable',
        clearable: 'Display Clear Button',
        arrowControl: 'Use the arrow for time selection',
        isDelete: 'Supprimable',
        isEdit: 'Modifiable',
        showPassword: 'Afficher le mot de passe',
        validate: 'Validation',
        required: 'Requis',
        patternPlaceholder: 'Fill in the regular expressions',
        newOption: 'Nouvelle Option',
        tab: 'Tab',
        validatorRequired: 'Requis',
        validatorType: 'Invaild format',
        validatorPattern: 'Unmatched pattern',
        showWordLimit: 'Afficher la limite de mots',
        maxlength: 'Longueur maximale',
      },
    },
    upload: {
      preview: 'preview',
      edit: 'replace',
      delete: 'delete',
    },
  },
  Form: 'Formulaire',
  others: 'Autres',
  button: 'Bouton',
  Left: 'Gauche',
  Right: 'Droite',
  Top: 'Haut',
  Bottom: 'Bas',
  Margin: 'Marge extérieure',
  Padding: 'Marge intérieure',
  TextAlign: 'Alignement du texte',
  Columns: 'Colonnes',
  Size: 'Taille',
  BlocAlignHorizontal: 'Alignement Bloc Horizontal',
  BlocAlignVertical: 'Alignement Bloc Vertical',
  Forms: 'Formulaires',
  Dark: 'Mode nuit',
  Outlined: 'Souligné',
  FontFamily: 'Police',
  trialing: "Période d'essai",
  incomplete: 'Non activé',
  UsageSummary: "Résumé d'utilisation",
  ViewActivities: 'Voir les activités',
  RolesPermissions: 'Droits & Permissions',
  Administrator: 'Administrateur',
  Reader: 'Lecteur',
  AddRole: 'Ajouter un droit',
  SearchRole: 'Rechercher un droit',
  read: 'Lecture',
  create: 'Créer',
  update: 'Modifier',
  delete: 'Supprimer',
  Permissions: 'Permissions',
  SelectAll: 'Tout sélectionner',
  EditRole: 'Modifier le droit',
  Roles: 'Droits & Permissions',
  Objects: 'Objets',
  Subscriptions: 'Abonnement',
  Files: 'Documents',
  NewFile: 'Nouveau Document',
  SearchFile: 'Rechercher un Document',
  EditFiles: 'Éditer les Documents',
  EditFile: 'Éditer le Document',
  AddFile: 'Ajouter un Document ',
  File: 'Document',
  UploadFile: 'Télécharger un document',
  Upload: 'Télécharger',
  Thumbnail: 'Miniature',
  Viewer: 'Diaporama',
  Extension: 'Extension',
  click: 'click',
  total: 'total',
  opened: 'opened',
  unique_opened: 'unique_opened',
  request: 'request',
  delivred: 'delivred',
  Download: 'Télécharger',
  QuickstartTemplates: 'Quickstart templates',
  view: 'Visible',
  State: 'Statut',
  Comments: 'Commentaires',
  Repositories: 'Licences',
  NewIssue: 'Nouveau Bug',
  SortBy: 'Trier par',
  EmbedCode: 'Code à intégrer',
  CopyCode: 'Copier le code',
  Tokens: 'Tokens',
  AddToken: 'Ajouter un Token',
  EditToken: 'Editer le token',
  SearchToken: 'Rechercher un Token',
  CreateSupabaseAccount: 'Compte Supabase',
  CreateSupabaseAccountDetails: "Connectez-vous à votre compte Supabase et autorisez l'application Micleo.",
  SupabaseIntegration: 'Intégration Supabase',
  SupabaseAPIToken: 'Supabase API Token',
  SupabaseAPITokenDetails: 'Supabase API Token',
  SupabaseProjects: 'Projet Supabase',
  SupabaseProjectsDetails: 'Importez votre nouveau projet Supabase et démarrez la configuration.',
  NoProjects: 'Aucun projet trouvé',
  AddProject: 'Ajouter le Projet',
  RemoveProject: 'Supprimer le Projet',
  DatabaseVersion: 'Database Version',
  DatabaseHost: 'Database Host',
  Region: 'Région',
  ProjectAPIKeys: 'Clés',
  SearchAPIKey: 'Rechercher une API Key',
  ProjectSettings: 'Paramètres du projet',
  Health: 'Health',
  APIsettings: "Paramètres de l'API",
  DatabaseSettings: 'Paramètres de base de données',
  Database: 'Base de données',
  SMTPSettings: 'Paramètres SMTP',
  ImportProject: 'Importer le Projet',
  db_schema: 'Exposed schemas',
  db_extra_search_path: 'Extra search path',
  max_rows: 'Nb. maximum de lignes',
  jwt_secret: 'JWT secret',
  pool_mode: 'Pool Mode',
  ignore_startup_parameters: 'Ignorer les paramètres de démarrage',
  max_client_conn: 'Nb. Connexions client maximales',
  connection_string: 'Connexion URL',
  smtp_admin_email: 'Admin Email / Sender Email',
  smtp_host: 'Hôte',
  smtp_port: 'Numéro de port',
  smtp_user: 'Username',
  smtp_pass: 'Mot de passe',
  smtp_max_frequency: 'Intervalle minimum entre les e-mails envoyés',
  smtp_sender_name: 'Admin Name / Sender Name',
  rate_limit_email_sent: "Limite de débit Envoi d'e-mail",
  ProjectUrl: 'URL projet',
  AllWorkspaces: 'Tous les Workspaces',
  CreateWorkspace: 'Créer un Workspace',
  DeleteWorkspace: 'Supprimer le Workspace',
  DeleteWorkspaceDetails:
  "La suppression de ce workspace supprimera également toutes les bases de données affiliées. Assurez-vous d'avoir fait une sauvegarde si vous souhaitez conserver vos données.",
  WorkspaceSavedSuccessfully: 'Workspace enregistré avec succès',
  AppCustomizer: 'App Config.',
  AppName: "Nom de l'App",
  FooterName: 'Nom/Copyright du pied de page',
  AppMetaDescription: 'App Meta Description',
  Theming: 'Thème',
  Skin: 'Template',
  Mode: 'Mode',
  MenuLayout: 'Disposition du menu',
  AppBarType: 'Header Bar position',
  FooterType: 'Pied de page position',
  RouterTransition: 'Page Transition',
  Colors: 'Couleurs',
  Light: 'Mode Jour',
  Success: 'Success',
  Info: 'Info',
  Warning: 'Warning',
  Logo: 'Logo',
  LogoDark: 'Logo mode nuit',
  Gtag: 'Gtag',
  ViewApp: "Voir l'application",
  TriggerUpdate: 'Mise à jour',
  UploadLogo: 'Télécharger un Logo',
  SupabaseProject: 'Projet Supabase',
}
