<template>
  <app-card-actions v-if="projectsStore.GET_PROJECT!=null"
                    action-refresh
                    @refresh="onRefresh"
  >
    <template slot="title">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text me-1"
          rounded
          size="34"
        >
          <v-img
            v-if="projectsStore.GET_PROJECT.organization.image"
            :src="projectsStore.GET_PROJECT.organization.image"
          ></v-img>
          <span
            v-else
            class="font-weight-semibold"
          >{{ avatarText(projectsStore.GET_PROJECT.organization.name) }}</span>
        </v-avatar><span class="font-weight-semibold text-button">
          {{ projectsStore.GET_PROJECT.organization.name }} /
        </span>
        <v-icon>mdi-home-city-outline</v-icon>
      </div>
    </template>
    <v-card-text>
      <v-row>
        <v-col v-for="data in statisticsData?.filter(d => $canACL(d.action, d.resource, projectsStore.GET_PROJECT.organization))"
               :key="data.title"
               cols="6"
               md="4"
               class="d-flex align-center"
        >
          <v-avatar size="44"
                    :color="data.color"
                    rounded
                    class="elevation-1"
          >
            <v-icon dark
                    color="white"
                    size="30"
                    @click="goTo(data)"
            >
              {{ data.icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs text-uppercase mb-0"
               style="font-size: 0.6rem !important;"
            >
              {{ $t(data.title) }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </app-card-actions>
</template>

<script>
import { onMounted, getCurrentInstance, ref } from 'vue'
import { useProjectsStore } from '@/stores/projects.store'
import AppCardActions from '@/components/AppCardActions.vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    AppCardActions,
  },
  setup() {
    const projectsStore = useProjectsStore()
    const vm = getCurrentInstance().proxy
    const statisticsData = ref(null)

    const goTo = item => {
      vm.$router.push({
        name: item.to,
        params: {
          id: projectsStore.GET_PROJECT.organization.id,
          tab: item.tab,
        },
      })
    }

    const onRefresh = hideOverlay => {
      projectsStore.getProject(vm.$router.currentRoute.params.id).then(data => {
        statisticsData.value = [
          {
            title: 'Members',
            to: 'edit-organization',
            tab: 3,
            total: data.organization.members?.length,
            icon: 'mdi-account-group',
            color: 'primary',
            action: 'view',
            resource: 'members',
          },
          {
            title: 'Integrations',
            to: 'edit-organization',
            tab: 5,
            total: data.organization.integrations?.length,
            icon: 'mdi-store',
            color: 'primary',
            action: 'view',
            resource: 'integrations',
          },
          {
            title: 'RolesPermissions',
            to: 'edit-organization',
            tab: 4,
            total: data.organization.roles?.length,
            icon: 'mdi-security',
            color: 'primary',
            action: 'view',
            resource: 'roles',
          },
        ]
        if (hideOverlay) { hideOverlay() }
      })
    }

    onMounted(async () => {
      onRefresh()
    })

    return {
      projectsStore,
      statisticsData,
      goTo,
      onRefresh,
      avatarText,
    }
  },
}
</script>
