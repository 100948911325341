import {
  TABLE_CONTACTS, TABLE_FOLDERS, TABLE_MODELS, TABLE_LISTINGS, TABLE_TEMPLATES, TABLE_CAMPAIGNS, TABLE_APPS, TABLE_ACTIVITIES, TABLE_LOGS, TABLE_FORMS, TABLE_WORKFLOWS, TABLE_FILES,
} from '@core/utils'

export default [
  {
    subheader: 'Projects',
    resource: 'home',
    action: 'view',
  },
  {
    title: 'AllProjects',
    icon: 'mdi-folder-table',
    to: 'projects',
    resource: 'projects',
    action: 'view',
  },
  {
    title: 'AllWorkspaces',
    icon: 'mdi-folder-table',
    to: 'workspaces',
    resource: 'workspaces',
    action: 'view',
  },
  {
    subheader: 'Project',
    resource: 'dashboard',
    to: { name: 'dashboard', params: { id: 0 } },
    action: 'view',
    parent: 'projects',
  },
  {
    title: 'Dashboard',
    icon: 'mdi-monitor-dashboard',
    to: { name: 'dashboard', params: { id: 0 } },
    resource: 'dashboard',
    action: 'view',
    parent: 'projects',
  },
  {
    title: 'Contacts',
    icon: 'mdi-account-group',
    to: {
      name: TABLE_CONTACTS,
      params: {
        id: 0,
        path: btoa(JSON.stringify([{ t: TABLE_CONTACTS }])),
        mode: 'view',
        folder: 0,
      },
    },
    parent: 'projects',
    children: [
      {
        title: 'Contacts',
        icon: 'mdi-account-group',
        to: {
          name: TABLE_CONTACTS,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_CONTACTS }])),
            mode: 'view',
            folder: 0,
          },
        },
        resource: 'contacts',
        action: 'view',
        parent: 'projects',
      },
      {
        title: 'Listings',
        icon: 'mdi-format-list-group',
        to: {
          name: TABLE_LISTINGS,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_LISTINGS }])),
            mode: 'view',
            folder: 0,
          },
        },
        resource: 'listings',
        action: 'view',
      },
      {
        title: 'Models',
        icon: 'mdi-shape',
        to: {
          name: TABLE_MODELS,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_MODELS }])),
            mode: 'view',
          },
        },
        resource: 'models',
        action: 'view',
      },
      {
        title: 'Forms',
        icon: 'mdi-list-box-outline',
        to: {
          name: TABLE_FORMS,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_FORMS }])),
            mode: 'view',
            folder: 0,
          },
        },
        resource: 'forms',
        action: 'view',
      },
    ],

  },
  {
    title: 'Marketing',
    icon: 'mdi-bullseye-arrow',
    parent: 'projects',
    children: [
      {
        title: 'Campaigns',
        icon: 'mdi-email-fast-outline',
        to: {
          name: TABLE_CAMPAIGNS,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_CAMPAIGNS }])),
            mode: 'view',
            folder: 0,
          },
        },
        resource: 'campaigns',
        disabled: true,
        action: 'view',
      }, {
        title: 'Templates',
        icon: 'mdi-newspaper-variant',
        to: {
          name: TABLE_TEMPLATES,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_TEMPLATES }])),
            mode: 'view',
            folder: 0,
          },
        },
        resource: 'templates',
        action: 'view',
      },
      {
        title: 'Activities',
        icon: 'mdi-timeline-check-outline',
        to: {
          name: TABLE_ACTIVITIES,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_ACTIVITIES }])),
            mode: 'view',
          },
        },
        resource: 'activities',
        action: 'view',
      },

      {
        title: 'Workflows',
        icon: 'mdi-sitemap-outline',
        to: {
          name: TABLE_WORKFLOWS,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_WORKFLOWS }])),
            mode: 'view',
            folder: 0,
          },
        },
        resource: 'workflows',
        action: 'view',
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'mdi-cog-outline',
    parent: 'projects',
    to: { name: 'settings', params: { id: 0, tab: 0 } },
    children: [
      {
        title: 'Folders',
        icon: 'mdi-folder-multiple',
        to: {
          name: TABLE_FOLDERS,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_FOLDERS }])),
            mode: 'view',
            folder: 0,
          },
        },
        resource: 'folders',
        action: 'view',
      },
      {
        title: 'Files',
        icon: 'mdi-file-document',
        to: {
          name: TABLE_FILES,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_FILES }])),
            mode: 'view',
            folder: 0,
          },
        },
        resource: 'files',
        action: 'view',
      },
      {
        title: 'Applications',
        icon: 'mdi-apps',
        to: { name: TABLE_APPS, params: { id: 0 } },
        resource: 'apps',
        disabled: true,
        action: 'view',
      },
      {
        title: 'Logs',
        icon: 'mdi-alert-box',
        to: {
          name: TABLE_LOGS,
          params: {
            id: 0,
            path: btoa(JSON.stringify([{ t: TABLE_LOGS }])),
            mode: 'view',
          },
        },
        resource: 'logs',
        action: 'view',
      },
      {
        title: 'Settings',
        icon: 'mdi-cogs',
        to: { name: 'settings', params: { id: 0, tab: 0 } },
        resource: 'settings',
        action: 'view',
      },
    ],
  },
  {
    subheader: 'Settings',
    resource: 'organizations',
    action: 'view',
  },

  /*
 {
    title: 'Access Tokens',
    icon: 'mdi-key',
    to: 'tokens',
    resource: 'tokens',
    action: 'view',
    parent: 'tokens',
  },
  */
  {
    title: 'Chat',
    icon: 'mdi-message-text',
    to: { name: 'chat' },
    resource: 'chats',
    action: 'view',
  },
  {
    title: 'Organizations',
    icon: 'mdi-home-city',
    to: 'organizations',
    resource: 'organizations',
    action: 'view',
  },
]
