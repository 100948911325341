/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-await */
import { defineStore, acceptHMRUpdate } from 'pinia'
import chunk from 'lodash/chunk'
import sortBy from 'lodash/sortBy'
import { supabase, supabaseDomain } from '@/plugins/supabase'
import { useFoldersStore } from '@/stores/folders.store'
import { usePropertiesStore } from '@/stores/properties.store'
import { usePropertiesItemsStore } from '@/stores/properties-items.store'
import { useContactsStore } from '@/stores/contacts.store'
import { useListingsStore } from '@/stores/listings.store'
import { useCampaignsStore } from '@/stores/campaigns.store'
import { useTemplatesStore } from '@/stores/templates.store'
import { useObjectsStore } from '@/stores/objects.store'
import { useProjectsStore } from '@/stores/projects.store'
import { getParent } from '@core/utils/folders'
import axios from '@axios'
import {
  getObjectKeys,
  TABLE_CONTACTS,
  TABLE_PROPERTIES_ITEMS,
  TABLE_FOLDERS,
  TABLE_PROPERTIES,
  TABLE_MODELS,
  TABLE_LISTINGS,
  TABLE_TEMPLATES,
  TABLE_CAMPAIGNS,
  TABLE_ACTIVITIES,
  TABLE_LOGS,
  TABLE_TASKS,
  TABLE_WORKFLOWS,
  TABLE_OBJECTS,
  TABLE_FORMS,
  TABLE_FILES,
} from '@core/utils'
import { useLogsStore } from '@/stores/logs.store'
import { useModelsStore } from './models.store'
import { useActivitiesStore } from './activities.store'
import { useWorkflowsStore } from './workflows.store'
import { useFormsStore } from './forms.store'
import { useFilesStore } from './files.store'

// eslint-disable-next-line import/prefer-default-export
export const useTableStore = defineStore({
  id: 'table',
  state: () => ({
    config: null,
    route: null,
    history: null,
    abortController: null,
  }),
  actions: {
    async getItems(rangeFrom, rangeTo, head) {
      console.time('getItems')
      if (!this.abortController) this.abortController = new AbortController()
      if (!this.config) {
        this.abortController.abort()

        return null
      }

      const from = this.config.tableOptions
        ? (this.config.tableOptions.page - 1) * this.config.tableOptions.itemsPerPage
        : 0
      const to = this.config.tableOptions ? from + this.config.tableOptions.itemsPerPage - 1 : 0

      let query = supabase
        .from(this.config.currentTable)
        .select(this.config.selectQuery || '*', { count: 'exact', head }) // estimated, planed

      if (!rangeFrom && !rangeTo) {
        if (this.config.tableOptions && this.config.tableOptions.itemsPerPage !== -1) query = query.range(from, to)
      } else query = query.range(rangeFrom, rangeTo)

      const foreignTables = []

      if (this.config.tableOptions?.sortBy && this.config.tableOptions?.sortDesc) {
        let i = 0
        this.config.tableOptions.sortBy?.forEach(item => {
          if (item.indexOf('fkey_') >= 0) {
            foreignTables.push(item.split('->>')[0].replace('fkey_', ''))
            item = item.split('->>')[1]
          }

          query.order(item, { foreignTable: foreignTables.join(', '), ascending: !this.config.tableOptions.sortDesc[i] })
          i += 1
        })
      }

      query = query.eq('project_id', this.route.params.id)

      if (this.config.matchQuery?.length > 0) {
        this.config.matchQuery.forEach(q => {
          if (q.column) { query.filter(q.column, q.operator, q.value) }
        })
      }
      if (
        this.config.tableOptions?.filters
        && Object.keys(this.config.tableOptions.filters).length > 0
      ) {
        await Promise.all(
          Object.keys(this.config.tableOptions.filters).map(async key => {
            if (this.config.tableOptions.filters[key]?.length) {
              const filtersCopy = JSON.parse(JSON.stringify(this.config.tableOptions.filters[key]))
              const valuesIn = []
              const valuesLike = []
              const sql = []

              const sch = this.getCurrentSchema(key)

              if (key.indexOf('fkey_') >= 0) {
                foreignTables.push(key.split('->>')[0].replace('fkey_', ''))
                key = key.split('->>')[1]
              }

              filtersCopy.forEach(item => {
                // valeur en base
                if (item.type) {
                  if (item.value === 'NULL') sql.push(`${key}.is.null`)
                  else if (item.value !== 'NULL' && !sch?.multiple) valuesIn.push(item.value || item)
                  else valuesLike.push(item.value || item)
                } else if (item.value === 'NULL') sql.push(`${key}.is.null`)
                else valuesLike.push(item.value || item)
              })

              if (valuesIn.length > 0) sql.push(`${key}.in.(${valuesIn})`)

              valuesLike.forEach(item => {
                sql.push(`${key}.ilike."%${item}%"`)
              })
              if (sql.length > 0) {
                query = query.or(sql.join(','), { foreignTable: foreignTables.join(', ') })
              }
            }
          }),
        )
      }

      if (this.config.searchQuery && this.config.tableColumns) {
        const sql2 = []

        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(this.config.searchQuery.trim())) {
          sql2.push(`id.eq.${this.config.searchQuery.trim()}`)
        } else if (this.config.currentTable !== TABLE_CONTACTS) {
          this.config.tableColumns.forEach(item => {
            if (item.search) {
              sql2.push(`${item.value}.ilike."%${this.config.searchQuery.trim()}%"`)
            }
          })
        } else {
          sql2.push(`properties_text.ilike."%${this.config.searchQuery.trim()}%"`)
        }

        if (sql2.length) query = query.or(sql2.join(','))
      }

      const { data: allItems, count, error } = await query.abortSignal(this.abortController.signal)

      if (error) {
        return Promise.reject(error)
      }
      const items = this.formatItems(this.config.currentTable, allItems)
      console.timeEnd('getItems')

      return { data: items, count: count || items?.length }
    },

    async getExport(dir) {
      if (dir && dir.config) {
        this.config.tableOptions = dir.config.tableOptions
        this.config.searchQuery = dir.config.searchQuery
      }

      const { count, error: errorCount } = await this.getItems(0, 1, true)

      if (errorCount) {
        return Promise.reject(errorCount)
      }

      const queries = []

      let total = 0
      do {
        queries.push(this.getItems(total, total + 4999))
        total += 5000
      } while (total < count)

      const allItems = []
      await Promise.all(
        queries.map(async query => {
          const { data, error } = await query
          if (error) {
            return Promise.reject(error)
          }

          if (data && data.success === false) {
            return Promise.reject(data)
          }
          allItems.push(...data)

          return true
        }),
      )

      return allItems
    },

    async getItem(id, table) {
      const query = supabase.from(table || this.config.currentTable)

      const { data: item, error } = await query.select(this.config.selectQuery).match({ id }).single()

      if (error) {
        return Promise.reject(error)
      }

      return this.formatItem(this.config.currentTable || table, item)
    },

    async upsert(items) {
      const modelsStore = useModelsStore()
      const allItems = JSON.parse(JSON.stringify(items))
      allItems?.forEach(it => {
        Object.keys(it).forEach(key => {
          const [mainNode, subNode] = key.split('->>')
          if (subNode) {
            if (!it[mainNode] || it[mainNode] === '{}') it[mainNode] = {}
            it[mainNode][subNode] = it[key]
            delete it[key]
          }
          if (!Object.keys(this.config.model).includes(mainNode) && mainNode !== 'id') {
            it[mainNode] = null
            delete it[mainNode]
          } else {
            if (it[key] instanceof Array && key === 'listings') {
              const listingsIds = []
              it[key].forEach(i => listingsIds.push(i.id))
              it[key] = listingsIds
            }

            if (it[key] === '') {
              it[key] = null
            }
          }
        })
      })

      let chunked = chunk(allItems, 10000)
      let messages = { inserts: 0, updates: 0 }
      let response = true
      if (this.config.currentTable === TABLE_CONTACTS || this.config.currentTable === TABLE_OBJECTS) {
        if (this.config.isImport) {
          try {
            const data = await axios.post('upsert-objects', {
              objects: allItems,
              project_id: this.config.project_id,
              model_id: this.config.model.model_id || modelsStore.model?.id,
              errors: this.config.isImport,
            })
            if (data && data.success === false) {
              return Promise.reject(data)
            }
          } catch (error) {
            console.log(error.response.data)

            return Promise.reject(error.response.data)
          }
        }
        chunked = chunk(allItems, 1000)
        // eslint-disable-next-line no-restricted-syntax
        for (const tab of chunked) {
          try {
          // eslint-disable-next-line no-await-in-loop
            const data = await axios.post('upsert-objects', {
              objects: tab,
              project_id: this.config.project_id,
              model_id: this.config.model.model_id || modelsStore.model?.id,
            })
            if (data && data.success === false) {
              return Promise.reject(data)
            }
            messages = { inserts: messages.inserts + data.inserts, updates: messages.updates + data.updates }
          } catch (error) {
            console.log(error.response.data)

            return Promise.reject(error.response.data)
          }
        }
      } else {
        const queries = []
        chunked.forEach(tab => {
          if (tab.length > 0) {
            queries.push(supabase.from(this.config.currentTable.replace('_view', '')).upsert(tab).select())
          } else {
            queries.push(supabase.from(this.config.currentTable.replace('_view', '')).upsert(tab))
          }
        })
        response = await Promise.all(
          queries.map(async query => {
            const { data, error } = await query
            if (error) {
              return Promise.reject(error)
            }

            if (data && data.success === false) {
              return Promise.reject(data)
            }

            return data
          }),
        )
      }

      return this.config.currentTable === TABLE_CONTACTS ? messages : response
    },

    async deleteItem(object) {
      if (this.config.currentTable === TABLE_FOLDERS) {
        const foldersStore = useFoldersStore()

        return foldersStore.deleteFolders(object)
      }

      if (this.config.currentTable === TABLE_FILES) {
        const filesStore = useFilesStore()

        return filesStore.deleteFiles(object)
      }

      const queries = []
      let ids = []
      if (object.id) {
        queries.push(supabase.from(this.config.currentTable.replace('_view', '').replace(TABLE_CONTACTS, TABLE_OBJECTS)).delete().match({ id: object.id }))
      } else {
        const chunked = chunk(object, 100)
        chunked.forEach(tab => {
          const chunkedIds = []
          tab.forEach(item => {
            if (ids !== null && item.id) {
              ids.push(item.id)
            } else {
              ids = null
            }
            chunkedIds.push(item.id)
          })
          queries.push(supabase.from(this.config.currentTable.replace('_view', '').replace(TABLE_CONTACTS, TABLE_OBJECTS)).delete().in('id', chunkedIds))
        })
      }

      await Promise.all(
        queries.map(async query => {
          const { error } = await query
          if (error) {
            return Promise.reject(error)
          }

          return true
        }),
      )

      if (this.config.currentTable === TABLE_CONTACTS) {
        await supabase.from(TABLE_TASKS).insert({
          sql: false,
          project_id: this.config.project_id,
          job: JSON.stringify({
            function: 'listings',
            params: {
              ids: ids && ids.length <= 500 ? ids : null,
              listing_id: null,
              is_all: null,
            },
          }),
        })
      }

      return true
    },

    async getDistincts(projectid, col) {
      let params = {
        tname: this.config.currentTable,
        cname: col.value,
        projectid,
      }

      const splitted = col.value.split('->>')

      if (splitted.length === 2) {
        if (splitted[0].indexOf('fkey_') >= 0) {
          params = {
            tname: splitted[0].replace('fkey_', ''),
            cname: splitted[1],
            projectid,
          }
        } else {
          params = {
            tname: this.config.currentTable.replace('contacts', 'objects_view'),
            cname: splitted[0],
            projectid,
            filterid: splitted[1],
          }
        }
      }

      const { data, error } = await supabase.rpc('get_distinct', params)

      if (error) {
        return Promise.reject(error)
      }

      const values = []
      data.forEach(item => values.push({
        text: `${item.distinct_values}`,
        value: item.distinct_values,
        isSelected: null,
        isFiltred: false,
        type: col.type,
        appearance: col.appearance,
      }))

      values.push({
        text: '',
        value: 'NULL',
        isSelected: null,
        isFiltred: false,
      })

      const propertiesStore = usePropertiesStore()
      const foldersStore = useFoldersStore()
      const templatesStore = useTemplatesStore()

      let distincts = {}
      values.forEach(it => {
        let prop = {}
        if (col.value === 'folder_id') {
          prop = foldersStore.allFolders[it.value]
          if (prop) prop.name = getParent(it.value, foldersStore.folders)
        } else if (col.value === 'template_id') {
          prop = templatesStore.templates.find(t => t.id === it.value)
        } else {
          prop = propertiesStore.getPropertyItem(params.filterid, it.value)
        }
        if (prop?.name) {
          it.text = prop.name
          distincts[it.value] = it
        } else if (prop instanceof Array) {
          prop.forEach(p => {
            distincts[p.id] = {
              text: p.name,
              value: p.id,
              isSelected: null,
              isFiltred: false,
              type: col.type,
              appearance: col.appearance,
            }
          })
        } else distincts[it.value] = it
      })
      distincts = sortBy(Object.values(distincts), ['text'])

      return distincts
    },

    async getKeys(projectid, col) {
      let params = {
        tname: this.config.currentTable,
        cname: col,
        projectid,
      }

      if (params.tname === TABLE_CONTACTS) {
        const splitted = col.split('->>')
        params = {
          tname: this.config.currentTable.replace('contacts', 'objects_view'),
          cname: splitted[0],
          filterid: splitted[1],
          projectid,
        }
      }
      const { data, error } = await supabase.rpc('get_keys', params)
      if (error) {
        return Promise.reject(error)
      }

      return data
    },
    async listenItems() {
      if (supabaseDomain.indexOf('localhost') < 0) {
        let table = this.config.currentTable || this.config.path
        table = table.replace('_view', '')

        return supabase
          .channel(`listenItems-${this.route.params.id}`)
          .on(
            'postgres_changes',
            {
              event: '*',
              schema: 'public',
              table,
              filter: `project_id=eq.${this.route.params.id}`,
            },
            async payload => {
              this.$patch({ eventType: payload.eventType, item: payload.new.id ? payload.new : payload.old })
            },
          )
          .subscribe()
      }

      return null
    },

    getSelectQuery(type) {
      if (type === TABLE_PROPERTIES) {
        return '*, properties_items(*)'
      }
      if (type === TABLE_ACTIVITIES) {
        return '*, fkey_campaigns:campaigns!inner(*), fkey_objects:objects!inner(*)'
      }
      if (type === TABLE_CAMPAIGNS) {
        return '*, fkey_templates:templates!inner(*)'
      }
      if (type === TABLE_FOLDERS) {
        return '*, properties(id), folders(id), listings(id),  campaigns(id),  templates(id),  workflows(id), files(id)'
      }

      return '*'
    },

    getTableColumnId() {
      return 'project_id'
    },

    getMatchQuery(subTable, currentTable, payload) {
      const data = [JSON.parse(JSON.stringify(payload.query))]

      if (subTable === TABLE_LISTINGS && currentTable === TABLE_CONTACTS && payload.item !== '0') {
        data.push({ column: `listings->>${payload.item}`, operator: 'neq', value: 'null' })
      }
      if (subTable === TABLE_PROPERTIES && currentTable === TABLE_PROPERTIES_ITEMS && payload.item !== '0') {
        data.push({ column: 'property_id', operator: 'eq', value: payload.item })
      }
      if (subTable === TABLE_MODELS && currentTable === TABLE_PROPERTIES && payload.item !== '0') {
        data.push({ column: 'model_id', operator: 'eq', value: payload.item })
      }
      if (subTable === TABLE_MODELS && currentTable === TABLE_OBJECTS && payload.item !== '0') {
        data.push({ column: 'model_id', operator: 'eq', value: payload.item })
      }
      if (subTable === TABLE_CAMPAIGNS && currentTable === TABLE_ACTIVITIES && payload.item !== '0') {
        data.push({ column: 'campaigns.id', operator: 'eq', value: payload.item })
      }
      if (subTable === TABLE_FORMS && currentTable === TABLE_PROPERTIES && payload.item !== '0') {
        data.push({ column: 'model_id', operator: 'eq', value: payload.item })
      }
      if (subTable === TABLE_CAMPAIGNS && currentTable === TABLE_LOGS && payload.item !== '0') {
        data.push({ column: 'payload->params->>campaign_id', operator: 'eq', value: payload.item })
      }
      if (subTable === TABLE_CONTACTS && currentTable === TABLE_ACTIVITIES && payload.item !== '0') {
        data.push({ column: 'object_id', operator: 'eq', value: payload.item })
      }

      return data
    },

    getHeader(type, payload, item) {
      const propertiesStore = usePropertiesStore()
      const foldersStore = useFoldersStore()
      const contactsStore = useContactsStore()
      const propertiesItemsStore = usePropertiesItemsStore()
      const listingsStore = useListingsStore()
      const campaignsStore = useCampaignsStore()
      const templatesStore = useTemplatesStore()
      const modelsStore = useModelsStore()
      const activitiesStore = useActivitiesStore()
      const logsStore = useLogsStore()
      const workflowsStore = useWorkflowsStore()
      const objectsStore = useObjectsStore()
      const formsStore = useFormsStore()
      const filesStore = useFilesStore()

      if (type === TABLE_PROPERTIES) {
        return propertiesStore.getHeader(payload, item)
      }
      if (type === TABLE_FOLDERS) {
        return foldersStore.getHeader(payload, item)
      }
      if (type === TABLE_CONTACTS) {
        return contactsStore.getHeader(payload, item)
      }
      if (type === TABLE_PROPERTIES_ITEMS) {
        return propertiesItemsStore.getHeader(payload, item)
      }
      if (type === TABLE_LISTINGS) {
        return listingsStore.getHeader(payload, item)
      }
      if (type === TABLE_CAMPAIGNS) {
        return campaignsStore.getHeader(payload, item)
      }
      if (type === TABLE_TEMPLATES) {
        return templatesStore.getHeader(payload, item)
      }
      if (type === TABLE_MODELS) {
        return modelsStore.getHeader(payload, item)
      }
      if (type === TABLE_ACTIVITIES) {
        return activitiesStore.getHeader(payload, item)
      }
      if (type === TABLE_LOGS) {
        return logsStore.getHeader(payload, item)
      }
      if (type === TABLE_WORKFLOWS) {
        return workflowsStore.getHeader(payload, item)
      }
      if (type === TABLE_OBJECTS) {
        return objectsStore.getHeader(payload, item)
      }
      if (type === TABLE_FORMS) {
        return formsStore.getHeader(payload, item)
      }
      if (type === TABLE_FILES) {
        return filesStore.getHeader(payload, item)
      }

      return {
        title: payload?.folder?.title || 'Table',
        folder: payload?.folder,
        newData: 'NewItem',
        actionData: 'EditItems',
        search: 'SearchItem',
      }
    },

    getTableOptions(type) {
      const propertiesStore = usePropertiesStore()
      const foldersStore = useFoldersStore()
      const contactsStore = useContactsStore()
      const propertiesItemsStore = usePropertiesItemsStore()
      const listingsStore = useListingsStore()
      const campaignsStore = useCampaignsStore()
      const templatesStore = useTemplatesStore()
      const modelsStore = useModelsStore()
      const activitiesStore = useActivitiesStore()
      const logsStore = useLogsStore()
      const workflowsStore = useWorkflowsStore()
      const objectsStore = useObjectsStore()
      const formsStore = useFormsStore()
      const filesStore = useFilesStore()

      let options = {
        sortBy: [],
        sortDesc: [],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: 10,
        fixedHeader: true,
        page: 1,
        filters: {},
      }

      if (type === TABLE_PROPERTIES) {
        options = propertiesStore.getTableOptions()
      } else if (type === TABLE_FOLDERS) {
        options = foldersStore.getTableOptions()
      } else if (type === TABLE_CONTACTS) {
        options = contactsStore.getTableOptions()
      } else if (type === TABLE_PROPERTIES_ITEMS) {
        options = propertiesItemsStore.getTableOptions()
      } else if (type === TABLE_LISTINGS) {
        options = listingsStore.getTableOptions()
      } else if (type === TABLE_CAMPAIGNS) {
        options = campaignsStore.getTableOptions()
      } else if (type === TABLE_TEMPLATES) {
        options = templatesStore.getTableOptions()
      } else if (type === TABLE_MODELS) {
        options = modelsStore.getTableOptions()
      } else if (type === TABLE_ACTIVITIES) {
        options = activitiesStore.getTableOptions()
      } else if (type === TABLE_LOGS) {
        options = logsStore.getTableOptions()
      } else if (type === TABLE_WORKFLOWS) {
        options = workflowsStore.getTableOptions()
      } else if (type === TABLE_OBJECTS) {
        options = objectsStore.getTableOptions()
      } else if (type === TABLE_FORMS) {
        options = formsStore.getTableOptions()
      } else if (type === TABLE_FILES) {
        options = filesStore.getTableOptions()
      }

      return options
    },

    getTableColumns(type, payload) {
      const propertiesStore = usePropertiesStore()
      const foldersStore = useFoldersStore()
      const contactsStore = useContactsStore()
      const propertiesItemsStore = usePropertiesItemsStore()
      const listingsStore = useListingsStore()
      const campaignsStore = useCampaignsStore()
      const templatesStore = useTemplatesStore()
      const modelsStore = useModelsStore()
      const activitiesStore = useActivitiesStore()
      const logsStore = useLogsStore()
      const workflowsStore = useWorkflowsStore()
      const objectsStore = useObjectsStore()
      const formsStore = useFormsStore()
      const filesStore = useFilesStore()

      if (type === TABLE_PROPERTIES) {
        return propertiesStore.getTableColumns()
      }
      if (type === TABLE_FOLDERS) {
        return foldersStore.getTableColumns()
      }
      if (type === TABLE_CONTACTS) {
        return contactsStore.getTableColumns(payload)
      }
      if (type === TABLE_PROPERTIES_ITEMS) {
        return propertiesItemsStore.getTableColumns()
      }
      if (type === TABLE_LISTINGS) {
        return listingsStore.getTableColumns()
      }
      if (type === TABLE_CAMPAIGNS) {
        return campaignsStore.getTableColumns()
      }
      if (type === TABLE_TEMPLATES) {
        return templatesStore.getTableColumns()
      }
      if (type === TABLE_MODELS) {
        return modelsStore.getTableColumns()
      }
      if (type === TABLE_ACTIVITIES) {
        return activitiesStore.getTableColumns()
      }
      if (type === TABLE_LOGS) {
        return logsStore.getTableColumns()
      }
      if (type === TABLE_WORKFLOWS) {
        return workflowsStore.getTableColumns()
      }
      if (type === TABLE_OBJECTS) {
        return objectsStore.getTableColumns(payload)
      }
      if (type === TABLE_FORMS) {
        return formsStore.getTableColumns()
      }
      if (type === TABLE_FILES) {
        return filesStore.getTableColumns()
      }

      return [
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(type, payload) {
      const propertiesStore = usePropertiesStore()
      const foldersStore = useFoldersStore()
      const contactsStore = useContactsStore()
      const propertiesItemsStore = usePropertiesItemsStore()
      const listingsStore = useListingsStore()
      const campaignsStore = useCampaignsStore()
      const templatesStore = useTemplatesStore()
      const modelsStore = useModelsStore()
      const activitiesStore = useActivitiesStore()
      const logsStore = useLogsStore()
      const workflowsStore = useWorkflowsStore()
      const objectsStore = useObjectsStore()
      const formsStore = useFormsStore()
      const filesStore = useFilesStore()

      let model = {}

      if (type === TABLE_PROPERTIES) {
        model = propertiesStore.getModel(payload)
      }
      if (type === TABLE_FOLDERS) {
        model = foldersStore.getModel(payload)
      }
      if (type === TABLE_CONTACTS) {
        model = contactsStore.getModel(payload)
      }
      if (type === TABLE_PROPERTIES_ITEMS) {
        model = propertiesItemsStore.getModel(payload)
      }
      if (type === TABLE_LISTINGS) {
        model = listingsStore.getModel(payload)
      }
      if (type === TABLE_CAMPAIGNS) {
        model = campaignsStore.getModel(payload)
      }
      if (type === TABLE_TEMPLATES) {
        model = templatesStore.getModel(payload)
      }
      if (type === TABLE_MODELS) {
        return modelsStore.getModel(payload)
      }
      if (type === TABLE_ACTIVITIES) {
        return activitiesStore.getModel(payload)
      }
      if (type === TABLE_LOGS) {
        return logsStore.getModel(payload)
      }
      if (type === TABLE_WORKFLOWS) {
        return workflowsStore.getModel(payload)
      }
      if (type === TABLE_OBJECTS) {
        return objectsStore.getModel(payload)
      }
      if (type === TABLE_FORMS) {
        return formsStore.getModel(payload)
      }
      if (type === TABLE_FILES) {
        return filesStore.getModel(payload)
      }

      Object.keys(model).forEach(key => {
        if (model[key] instanceof Object) {
          Object.keys(model[key])?.forEach(subkey => {
            // eslint-disable-next-line prefer-template
            model[key + '->>' + subkey] = model[key][subkey]
          })
        }
      })

      return model
    },

    getSchema(type, items, item, payload) {
      const propertiesStore = usePropertiesStore()
      const foldersStore = useFoldersStore()
      const contactsStore = useContactsStore()
      const propertiesItemsStore = usePropertiesItemsStore()
      const listingsStore = useListingsStore()
      const campaignsStore = useCampaignsStore()
      const templatesStore = useTemplatesStore()
      const modelsStore = useModelsStore()
      const activitiesStore = useActivitiesStore()
      const logsStore = useLogsStore()
      const workflowsStore = useWorkflowsStore()
      const objectsStore = useObjectsStore()
      const formsStore = useFormsStore()
      const filesStore = useFilesStore()

      if (type === TABLE_PROPERTIES) {
        return propertiesStore.getSchema(item, items, payload)
      }
      if (type === TABLE_FOLDERS) {
        return foldersStore.getSchema(item, items, payload)
      }
      if (type === TABLE_CONTACTS) {
        return contactsStore.getSchema(item, items, payload)
      }
      if (type === TABLE_PROPERTIES_ITEMS) {
        return propertiesItemsStore.getSchema(items, payload)
      }
      if (type === TABLE_LISTINGS) {
        return listingsStore.getSchema(item, items, payload)
      }
      if (type === TABLE_CAMPAIGNS) {
        return campaignsStore.getSchema(item, items, payload)
      }
      if (type === TABLE_TEMPLATES) {
        return templatesStore.getSchema(item, items, payload)
      }
      if (type === TABLE_MODELS) {
        return modelsStore.getSchema(item, items, payload)
      }
      if (type === TABLE_ACTIVITIES) {
        return activitiesStore.getSchema(item, items, payload)
      }
      if (type === TABLE_LOGS) {
        return logsStore.getSchema(item, items, payload)
      }
      if (type === TABLE_WORKFLOWS) {
        return workflowsStore.getSchema(item, items, payload)
      }
      if (type === TABLE_OBJECTS) {
        return objectsStore.getSchema(item, items, payload)
      }
      if (type === TABLE_FORMS) {
        return formsStore.getSchema(item, items, payload)
      }
      if (type === TABLE_FILES) {
        return filesStore.getSchema(item, items, payload)
      }

      return {}
    },

    getCurrentSchema(col) {
      const currentPath = getObjectKeys(this.config.schema).filter(path => path.indexOf(`schema.${col}`) >= 0)[0]
      if (!currentPath) return null

      return JSON.parse(JSON.stringify(currentPath?.split('.').reduce((o, k) => o && o[k], this.config.schema)))
    },

    getPositionSchema(col) {
      const currentPath = getObjectKeys(this.config.schema)
      if (!currentPath) return null

      return currentPath.findIndex(it => it.indexOf(`schema.${col}`) >= 0)
    },

    async test(type, payload) {
      const contactsStore = useContactsStore()

      if (type === TABLE_CONTACTS) {
        return await contactsStore.test(payload?.project_id)
      }

      return true
    },

    async onAction(action, payload) {
      const propertiesStore = usePropertiesStore()
      const foldersStore = useFoldersStore()
      const contactsStore = useContactsStore()
      const propertiesItemsStore = usePropertiesItemsStore()
      const listingsStore = useListingsStore()
      const campaignsStore = useCampaignsStore()
      const templatesStore = useTemplatesStore()
      const modelsStore = useModelsStore()
      const objectsStore = useObjectsStore()
      const formsStore = useFormsStore()
      const filesStore = useFilesStore()

      if (this.config.currentTable === TABLE_PROPERTIES) {
        payload.item = await propertiesStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_FOLDERS) {
        payload.item = await foldersStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_CONTACTS) {
        payload.item = await contactsStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_PROPERTIES_ITEMS) {
        payload.item = await propertiesItemsStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_LISTINGS) {
        payload.item = await listingsStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_CAMPAIGNS) {
        payload.item = await campaignsStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_TEMPLATES) {
        payload.item = await templatesStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_MODELS) {
        payload.item = await modelsStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_OBJECTS) {
        payload.item = await objectsStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_FORMS) {
        payload.item = await formsStore.onAction(action, payload)
      }
      if (this.config.currentTable === TABLE_FILES) {
        payload.item = await filesStore.onAction(action, payload)
      }

      if (action === 'duplicate') {
        Object.keys(payload?.item).forEach(key => {
          const [mainNode, subNode] = key.split('->>')
          if (subNode) {
            if (!payload.item[mainNode] || payload.item[mainNode] === '{}' || JSON.stringify(payload.item[mainNode]) === '{}') {
              delete payload.item[key]
            }
          }
          if (!Object.keys(this.config.model).includes(mainNode) && mainNode !== 'id') {
            payload.item[mainNode] = null
            delete payload.item[mainNode]
          }
        })
      }

      return payload.item
    },

    formatItem(type, item) {
      const objectsStore = useObjectsStore()
      const campaignsStore = useCampaignsStore()
      if (type === TABLE_OBJECTS || type === TABLE_CONTACTS) {
        return objectsStore.formatItem(item)
      } if (type === TABLE_CAMPAIGNS) {
        return campaignsStore.formatItem(item)
      }

      Object.keys(item).forEach(key => {
        if (item[key] instanceof Object) {
          if (!(item[key] instanceof Array)) {
            Object.keys(item[key])?.forEach(subkey => {
            // eslint-disable-next-line prefer-template
              item[key + '->>' + subkey] = item[key][subkey]
            })
          }
        }
      })

      return item
    },

    formatItems(type, items) {
      const foldersStore = useFoldersStore()

      if (type === TABLE_FOLDERS) {
        return foldersStore.formatItems(items, this.config)
      }

      return items?.map(item => this.formatItem(type, item))
    },

    async uploadFile(folder, slug, name, file, table) {
      const projectsStore = useProjectsStore()
      const { data, error } = await supabase.storage.from(this.config?.currentTable || table).upload(`${projectsStore.project?.id}/${folder}/${slug}/${name}`, file,
        {
          cacheControl: '3600',
          upsert: false,
        })
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async getFileUrl(folder, slug, name) {
      slug = slug.replace('->>', '_')
      const projectsStore = useProjectsStore()
      const { data } = await supabase.storage.from(this.config.currentTable).getPublicUrl(`${projectsStore.project?.id}/${folder}/${slug}/${name}`)

      return data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTableStore, import.meta.hot))
}
