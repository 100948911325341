<template>
  <div
    v-if="userStore.user!==null"
    id="user-view"
  >
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      class="user-tabs"
      show-arrows
    >
      <v-tab
        v-for="(t, index) in tabs"
        :key="t.icon"
        @click="onTabEvent(index)"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ t.icon }}
        </v-icon>
        <span>{{ $t(t.title) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items
      id="user-tabs-content"
      v-model="tab"
    >
      <v-tab-item>
        <account-details :user="userStore.user"></account-details>
      </v-tab-item>
      <v-tab-item>
        <account-security :user="userStore.user"></account-security>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { useProjectsStore } from '@/stores/projects.store'
import { useUserStore } from '@/stores/user.store'
import AccountDetails from '@/views/account/AccountDetails.vue'
import AccountSecurity from '@/views/account/AccountSecurity.vue'
import { getCurrentInstance, onMounted, ref } from 'vue'
import { appConfig } from '@/plugins/micleoConfig'

export default {
  metaInfo: {
    title: 'Account',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.name },
    ],
  },
  components: {
    AccountDetails,
    AccountSecurity,
  },
  setup() {
    const tab = ref('')
    const tabs = [
      { title: 'Profile', icon: 'mdi-account' },
      { title: 'Security', icon: 'mdi-lock' },
    ]
    const vm = getCurrentInstance().proxy
    const userStore = useUserStore()
    const projectsStore = useProjectsStore()

    if (vm.$router.currentRoute.params?.tab) {
      tab.value = parseInt(vm.$router.currentRoute.params.tab, 10)
    }

    onMounted(async () => {
      projectsStore.project = null
    })

    const onTabEvent = t => {
      vm.$router.push({ name: 'account-settings', params: { tab: t } })
    }

    return {
      userStore,
      tab,
      tabs,
      onTabEvent,
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
