const workspaces = [
  {
    path: '/create/workspace',
    name: 'create-workspace',
    component: () => import('@/pages/workspaces/CreateWorkspace.vue'),
    meta: {
      layout: 'content',
      resource: 'workspaces',
      action: 'create',
    },
  },
  {
    path: '/workspaces',
    name: 'workspaces',
    component: () => import('@/pages/workspaces/Workspaces.vue'),
    meta: {
      layout: 'content',
      resource: 'workspaces',
      action: 'view',
    },
  },
  {
    path: '/edit/:tab/workspace/:id',
    name: 'edit-workspace',
    component: () => import('@/pages/workspaces/Workspace.vue'),
    meta: {
      layout: 'content',
      resource: 'workspaces',
      action: 'view',
    },
  },
]

export default workspaces
