<template>
  <div>
    <v-snackbar v-model="isSaved"
                outlined
                text
                color="primary"
                shaped
                top
                right
    >
      {{ $t('SavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn icon
               color="primary"
               v-bind="attrs"
               @click="isSaved = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="isDialogVisible"
      max-width="450"
      persistent
    >
      <v-card class="pa-sm-6 pa-3">
        <v-card-title
          class="justify-center text-h5"
        >
          {{ $t("TestCampaign") }}
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form
            ref="form"
            @submit.prevent="sendTest"
          >
            <v-autocomplete
              v-model="contacts"
              :label="$t('Contacts')"
              :loading="loading"
              :items="items"
              item-value="id"
              item-text="name"
              :search-input.sync="search"
              cache-items
              chips
              deletable-chips
              multiple
              small-chips
              prepend-inner-icon="mdi-account"
              hide-details="auto"
              class="mb-4"
              :rules="[]"
            ></v-autocomplete>

            <v-alert
              v-if="errors.test"
              border="left"
              colored-border
              text
              color="error"
              class="subtitle-2"
            >
              {{ errors.test }}
            </v-alert>
            <div class="mt-6 d-flex justify-center">
              <v-btn
                :loading="loadingAction"
                :disabled="loadingAction"
                color="primary"
                class="me-3"
                type="submit"
              >
                {{ $t('Submit') }}
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click="isDialogVisible = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch, getCurrentInstance } from 'vue'
import { useVModel } from '@vueuse/core'
import { required, emailValidator, alphaValidator } from '@core/utils/validation'
import { useUserStore } from '@/stores/user.store'
import { useContactsStore } from '@/stores/contacts.store'
import { useCampaignsStore } from '@/stores/campaigns.store'
import { usePropertiesStore } from '@/stores/properties.store'

export default {
  components: {
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const loadingAction = ref(false)
    const form = ref(null)
    const errors = ref({})
    const isDialogVisible = useVModel(props, 'isDialogVisible', emit)
    const item = useVModel(props, 'item', emit)
    const contacts = ref(null)
    const userStore = useUserStore()
    const contactsStore = useContactsStore()
    const campaignsStore = useCampaignsStore()
    const propertiesStore = usePropertiesStore()

    const loading = ref(false)
    const search = ref(null)
    const items = ref([])
    const isSaved = ref(false)
    const params = ref([])
    const vm = getCurrentInstance().proxy

    watch(isDialogVisible, async () => {
      if (form.value) form.value.resetValidation()
      if (isDialogVisible.value) {
        loadingAction.value = false
        search.value = userStore.user.email
        contacts.value = []
        params.value = []
      }
    })

    const sendTest = () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return
      loadingAction.value = true

      campaignsStore.sendTest(item.value, contacts.value).then(() => {
        isSaved.value = true
        loadingAction.value = false
        isDialogVisible.value = false
      }).catch(error => {
        loadingAction.value = false
        errors.value = {
          test: error,
        }
      })
    }

    const querySelections = () => {
      loading.value = true
      contactsStore.getContacts(item.value.project_id, params.value.join(','), search.value).then(data => {
        loading.value = false
        items.value = data
      })
    }

    watch(search, () => {
      if (!params.value.length) {
        propertiesStore.getProperties(vm.$router.currentRoute.params.id, 'contacts').then(properties => {
          params.value = []
          properties.forEach(p => {
            if (p.slug === 'firstname' || p.slug === 'lastname' || p.slug === 'email' || p.slug === 'phone') { params.value.push(!p.encrypt ? `properties->>${p.slug}` : `properties_crypted->>${p.slug}`) }
          })
          querySelections()
        })
      } else {
        querySelections()
      }
    })

    return {
      form,
      contacts,
      isDialogVisible,
      loadingAction,
      item,
      errors,
      validators: {
        required,
        emailValidator,
        alphaValidator,
      },
      sendTest,
      loading,
      search,
      items,
      querySelections,
      isSaved,
    }
  },
}
</script>
<style>
.unlayer-editor {
  height: 85%;
  min-height: 85% ! important;
}
</style>
