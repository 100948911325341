<template>
  <v-row v-observe-visibility="visibilityChanged"
         class="pa-3 mt-2"
  >
    <confirm-dialog-delete
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingDelete"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.integration"
    ></alert-error>
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("Integrations") }}
          </h5>
        </v-card-title>
        <v-divider class="mx-5"></v-divider>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex flex-grow-1 align-center pb-3">
            <v-text-field
              v-model="searchQuery"
              single-line
              class="me-3 search-table"
              dense
              outlined
              hide-details
              :placeholder="$t('SearchIntegration')"
              clearable
              prepend-inner-icon="mdi-store-search"
              clear-icon="mdi-close"
            ></v-text-field>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center pb-3">
            <v-select
              v-model="tagsFilter"
              :items="tags"
              single-line
              chips
              outlined
              dense
              hide-details
              clearable
              :placeholder="$t('SelectTags')"
            >
              <template #selection="data">
                {{ $t(data.item) }}
              </template>
              <template #item="data">
                {{ $t(data.item) }}
              </template>
            </v-select>
          </div>
        </v-card-text>
      </v-card>
      <v-row class="mt-5">
        <v-col
          v-for="integration in listTable"
          :key="integration.slug"
          lg="3"
          md="4"
          sm="4"
          cols="12"
        >
          <v-card
            height="100%"
            min-height="250"
            min-width="280"
          >
            <v-card-text class="justify-center align-center">
              <v-chip
                v-if="integration.enabled"
                style="right:8px; top:8px; position:absolute;"
                color="primary"
                class="text-button"
              >
                {{ $t('Enabled') }}
              </v-chip>
              <div
                style="left:8px; top:8px; position: absolute;"
              >
                <v-chip
                  v-for="type in integration.type"
                  :key="type"
                  class="text-button me-1"
                  outlined
                  color="primary"
                >
                  {{ $t(type) }}
                </v-chip>
              </div>

              <v-img
                v-if="integration && integration.name"
                class="px-10"
                height="200"
                contain
                :src="require(`@/assets/images/integrations/${integration.slug}.png`)"
              ></v-img>
            </v-card-text>

            <v-card-actions
              class="pa-2"
            >
              <v-btn
                v-if="!integration.created_at"
                :disabled="integration.enabled || !$canACL('create', 'integrations', organization)"
                color="primary"
                block
                dark
                large
                @click="actionIntegration(integration, false)"
              >
                {{ $t("Enable") }}
              </v-btn>
              <v-btn
                v-if="integration.created_at && !integration.enabled"
                color="primary"
                block
                dark
                large
                outlined
                :disabled="loadingAction === integration.slug || !$canACL('update', 'integrations', organization)"
                @click="actionIntegration(integration, false)"
              >
                {{ $t("Settings") }} / {{ $t("Enable") }}
              </v-btn>
              <v-btn
                v-if="integration.created_at && integration.enabled && integration.slug !=='micleo' && integration.slug !=='supabase'"
                color="primary"
                block
                dark
                large
                outlined
                :disabled="loadingAction === integration.slug || !$canACL('update', 'integrations', organization)"
                @click="actionIntegration(integration, false)"
              >
                {{ $t("Settings") }}
              </v-btn>
              <v-btn
                v-if="integration.slug ==='micleo'|| integration.slug ==='supabase'"
                color="secondary"
                block
                dark
                large
                outlined
              >
                {{ $t("Default") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-if="currentIntegration"
      v-model="isAddDialogVisible"
      max-width="450"
    >
      <v-card id="user-view"
              class="pa-sm-6 pa-3"
      >
        <v-card-title
          class="justify-center text-h5"
        >
          {{ currentIntegration.name }}
          <div style="position:absolute; top:20px; right:20px;">
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="onDelete">
                  <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card-title>
        <v-tabs v-model="tab"
                class="user-tabs mt-3"
                dense
                style="position: relative; z-index: 0;"
        >
          <v-tab>
            {{ $t('Settings') }}
          </v-tab>
          <v-tab v-if="currentIntegration.slug !== 'supabase'">
            {{ $t('Webhooks') }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-form
              ref="form"
              class="multi-col-validation mt-8"
              @submit.prevent="onSave"
            >
              <v-card-text>
                <v-row :key="keyDiv">
                  <v-col
                    v-for="key in Object.keys(currentIntegration.settings_crypted)"
                    :key="isVisible[key]+'-'+key"
                    cols="12"
                  >
                    <v-text-field
                      v-model="currentIntegration.settings_crypted[key]"
                      :value="currentIntegration.settings_crypted[key]"
                      dense
                      outlined
                      prepend-inner-icon="mdi-form-textbox-password"
                      :rules="[validators.required]"
                      :type="isVisible[key] ? 'text' : 'password'"
                      :append-icon="isVisible[key] ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                      :label="$t(key)"
                      @click:append="toggleVisibility(key)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-switch v-model="currentIntegration.enabled"
                              class="px-2"
                              :label="currentIntegration.enabled ? $t('Enabled') : $t('Disabled')"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <div class="mt-6 d-flex justify-center">
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    class="me-3"
                    type="submit"
                  >
                    {{ $t('Submit') }}
                  </v-btn>

                  <v-btn
                    color="secondary"
                    outlined
                    class="me-3"
                    @click.prevent="resetForm"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                </div>
              </v-card-text>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <v-btn
              color="primary"
              class="mx-2 mt-4"
              outlined
              small
              :loading="loadingAdd"
              :disabled="loadingAdd|| currentIntegration.webhooks?.length > 0 || currentIntegration.slug === 'twilio'"
              @click="addWebhook(currentIntegration)"
            >
              <v-icon class="me-1">
                mdi-web-sync
              </v-icon>
              <span class="d-none d-sm-block">{{ $t("AddWebhook") }}</span>
            </v-btn>
            <v-simple-table class="my-3">
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('ID') }}
                    </th>
                    <th class="text-left">
                      {{ $t('Events') }}
                    </th>
                    <th class="text-left">
                      {{ $t('Action') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="webhook in currentIntegration.webhooks"
                    :key="webhook.id"
                  >
                    <td class="text-button">
                      {{ webhook.id }}
                    </td>
                    <td>{{ webhook.eventtype || webhook.events?.join(", ") }}</td>
                    <td>
                      <v-btn
                        color="error"
                        x-small
                        icon
                        :loading="loadingDeleteWeb === webhook.id"
                        :disabled="loadingDeleteWeb === webhook.id"
                        @click="deleteWebhook(currentIntegration, webhook.id)"
                      >
                        <v-icon>
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref } from 'vue'
import { avatarText } from '@core/utils/filter'
import useIntegrationList from '@/views/organizations/settings/useIntegrationList'
import { useIntegrationsStore } from '@/stores/integrations.store'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import AlertError from '@/components/AlertError.vue'
import {
  required,
} from '@core/utils/validation'
import { uuidv7 } from 'uuidv7'

export default {
  components: {
    ConfirmDialogDelete,
    AlertError,
  },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const errors = ref({})
    const loadingAction = ref(null)
    const form = ref(null)
    const integrationsStore = useIntegrationsStore()
    const currentIntegration = ref(null)
    const isVisible = ref({})
    const isDeleteDialogVisible = ref(false)
    const loadingDelete = ref(false)
    const loadingDeleteWeb = ref(null)
    const loadingAdd = ref(false)
    const isAlertVisible = ref(false)
    const tags = ref(['email', 'sms', 'events', 'payments'])
    const tab = ref(0)
    const keyDiv = ref(uuidv7())

    const {
      listTable, searchQuery, loading, fetchDatas, isAddDialogVisible, tagsFilter,
    } = useIntegrationList(props.organization.id)

    const visibilityChanged = isV => {
      if (isV) {
        fetchDatas()
      }
    }

    const resetForm = () => {
      if (form.value) { form.value.reset() }
      tab.value = 0
      errors.value = {}
      isAddDialogVisible.value = false
    }

    const actionIntegration = (integration, refresh) => {
      if (!refresh) resetForm()
      loadingAction.value = integration.slug
      if (integration.created_at) {
        integrationsStore.getWebhooks(integration, props.organization).then(data => {
          integration = data
          loadingAction.value = null
          isAddDialogVisible.value = true
          currentIntegration.value = JSON.parse(JSON.stringify(integration))
        }).catch(error => {
          loadingAction.value = null
          isAddDialogVisible.value = true
          currentIntegration.value = JSON.parse(JSON.stringify(integration))
          errors.value = {
            integration: error,
          }
        })
      } else {
        integration.organization_id = props.organization.id
        integrationsStore.insert(integration).then(() => {
          loadingAction.value = null
          fetchDatas()
        }).catch(error => {
          isAlertVisible.value = true
          loadingAction.value = null
          errors.value = {
            integration: error?.message || error,
          }
        })
      }
    }

    const onSave = () => {
      const isValid = form.value?.validate()
      if (!isValid) return
      integrationsStore.update(currentIntegration.value).then(() => {
        isAddDialogVisible.value = false
        fetchDatas()
      }).catch(error => {
        isAlertVisible.value = true
        errors.value = {
          integration: error.message,
        }
      })
    }

    const onDelete = () => {
      isDeleteDialogVisible.value = true
      isAddDialogVisible.value = false
    }
    const confirmDelete = () => {
      loadingDelete.value = true
      integrationsStore.delete(currentIntegration.value).then(() => {
        loadingDelete.value = false
        isDeleteDialogVisible.value = false
        isAddDialogVisible.value = false
        fetchDatas()
      }).catch(error => {
        loadingDelete.value = false
        isAlertVisible.value = true
        errors.value = {
          integration: error.message,
        }
      })
    }

    const deleteWebhook = (integration, id) => {
      loadingDeleteWeb.value = id
      integrationsStore.deleteWebhook(integration, id, props.organization).then(() => {
        setTimeout(() => {
          loadingDeleteWeb.value = null
          actionIntegration(integration, true)
        }, 100)
      }).catch(error => {
        loadingDeleteWeb.value = null
        isAlertVisible.value = true
        errors.value = {
          integration: error,
        }
      })
    }

    const addWebhook = integration => {
      loadingAdd.value = true
      integrationsStore.addWebhook(integration, props.organization).then(() => {
        loadingAdd.value = false
        actionIntegration(integration, true)
      }).catch(error => {
        loadingAdd.value = false
        isAlertVisible.value = true
        errors.value = {
          integration: error,
        }
      })
    }

    const toggleVisibility = k => {
      if (isVisible.value[k]) {
        isVisible.value[k] = false
      } else {
        isVisible.value[k] = true
      }
      keyDiv.value = uuidv7()
    }

    return {
      loadingAction,
      errors,
      form,
      avatarText,
      searchQuery,
      listTable,
      loading,
      actionIntegration,
      isAddDialogVisible,
      currentIntegration,
      resetForm,
      validators: {
        required,
      },
      isVisible,
      onSave,
      onDelete,
      isDeleteDialogVisible,
      loadingDelete,
      loadingDeleteWeb,
      confirmDelete,
      tags,
      tagsFilter,
      isAlertVisible,
      tab,
      deleteWebhook,
      addWebhook,
      loadingAdd,
      toggleVisibility,
      keyDiv,
      visibilityChanged,
    }
  },
}
</script>
