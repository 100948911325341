<template>
  <v-col
    :lg="appContentLayoutNav === 'horizontal' ? 3 : 4"
    md="4"
    sm="6"
    cols="12"
  >
    <v-card
      width="360"
      elevation="10"
      @click.prevent="gotToProject"
    >
      <v-img
        v-if="project && project.image"
        height="210"
        :src="project.image"
      ></v-img>

      <v-avatar
        v-else-if="project"
        color="primary"
        class="v-avatar-light-bg primary--text me-3"
        rounded
        width="100%"
        height="210"
      >
        <span
          class="font-weight-semibold text-5xl"
        >{{ avatarText(project.name) }}</span>
      </v-avatar>
      <v-skeleton-loader
        v-else
        type="image"
      ></v-skeleton-loader>
      <v-card-title
        v-if="project"
        class="font-weight-bold text-truncate mr-5 pt-5 pb-2"
      >
        <h5 class="font-weight-bold text-uppercase">
          {{ project.name }}
        </h5>
      </v-card-title>

      <v-skeleton-loader
        v-if="!project"
        class="mt-4 ml-5"
        type="heading"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-if="!project"
        class="mt-4 ml-5 mr-5 mb-7"
        type="text, text"
      ></v-skeleton-loader>
      <v-card-subtitle v-if="project"
                       class="py-4"
      >
        <!-- Mutual Friends -->
        <div class="d-flex justify-space-between align-center">
          <span
            class="text--primary font-weight-medium projects-content text-caption"
          >
            {{ project.description }}
          </span>
          <div>
            <avatar-members
              v-if="project.organization"
              :members="project.organization.members"
              xsmall
            ></avatar-members>
          </div>
        </div>
      </v-card-subtitle>

      <v-card-actions
        class="pa-2 pt-0"
      >
        <v-btn
          v-if="project"
          color="primary"
          block
          dark
          large
        >
          {{ $t("StartProject") }}
        </v-btn>
        <v-skeleton-loader
          v-else
          type="button"
        ></v-skeleton-loader>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { avatarText } from '@core/utils/filter'
import AvatarMembers from '@/components/AvatarMembers.vue'
import { useUserStore } from '@/stores/user.store'
import { useProjectsStore } from '@/stores/projects.store'
import useAppConfig from '@/stores/appconfig'

export default {
  components: {
    AvatarMembers,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const userStore = useUserStore()
    const projectsStore = useProjectsStore()

    const vm = getCurrentInstance().proxy
    const {
      appContentLayoutNav,
    } = useAppConfig()

    const gotToProject = async () => {
      userStore.getUser(props.user).then(async data => {
        userStore.user = data
        projectsStore.project = await projectsStore.getProject(props.project.id)
        vm.$router.push({
          name: 'dashboard',
          params: {
            id: props.project.id,
          },
        })
      })
    }

    return {
      gotToProject,
      avatarText,
      appContentLayoutNav,
    }
  },
}
</script>

<style>
.v-skeleton-loader__button {
  width:400px !important;
}
</style>
