<template>
  <div>
    <confirm-dialog-delete
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingAction"
      :is-confirm="false"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>
    <v-snackbar
      v-model="isAlertVisible"
      color="error"
      outlined
      text
      shaped
      top
      right
    >
      {{ errors.folder }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="error"
          v-bind="attrs"
          @click="isAlertVisible = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="374"
      touchless
      :right="$vuetify.rtl"
      mobile-breakpoint="sm"
      :temporary="$vuetify.breakpoint.xsOnly"
      absolute
    >
      <div class="h-full">
        <div class="d-flex align-center pa-2">
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('SearchFolder')"
            hide-details
            outlined
            dense
            class="chat-search"
            clearable
            clear-icon="mdi-close"
            prepend-inner-icon="mdi-folder-search"
          ></v-text-field>
          <v-icon
            class="d-inline-flex ml-3 mr-1"
            @click="isLeftSidebarOpen = false"
          >
            mdi-close
          </v-icon>
        </div>

        <v-divider></v-divider>
        <div class="d-flex pt-7 pb-0 px-3">
          <p class="font-weight-medium text-xl primary--text ms-3">
            {{ $t('Folders') }} ({{ folders.items.length }})
          </p>
          <v-spacer></v-spacer>
          <v-btn
            x-small
            class="me-1"
            icon
            :color="isRefresh?'primary':''"
            @click="fetchDatas"
          >
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
          <v-btn
            x-small
            class="me-1"
            icon
            @click="expandAll"
          >
            <v-icon>
              mdi-expand-all
            </v-icon>
          </v-btn>
          <v-btn
            x-small
            class="me-1"
            icon
            @click="collapseAll"
          >
            <v-icon>
              mdi-collapse-all
            </v-icon>
          </v-btn>
          <v-tooltip
            open-delay="500"
            top
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="!$canACL('create', 'folders',{id: $router.currentRoute.params.id})"
                color="primary"
                class="ml-3"
                x-small
                icon
                v-bind="attrs"
                v-on="on"
                @click="addFolder"
              >
                <v-icon>
                  mdi-folder-plus
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("NewFolder") }}</span>
          </v-tooltip>
        </div>
        <v-card
          outlined
          style="background-color: rgba(94, 86, 105, 0.02);"
          class="mx-2"
        >
          <v-treeview
            v-if="folders.items"
            :items="folders.items"
            :open.sync="folders.open"
            :active="folders.active"
            :search="searchQuery"
            activatable
            :disabled="isDisabled"
            @update:active="onActive"
          >
            <template #prepend="{ item: node, open, active }">
              <v-icon
                v-if="!node.table"
                color="primary"
              >
                {{ open || active ? 'mdi-folder-open-outline' : 'mdi-folder-outline' }}
              </v-icon>
              <v-icon
                v-else
              >
                {{ node.icon }}
              </v-icon>
            </template>
            <template #label="{ item: node }">
              <span
                v-if="!node.table"
                class="text-caption font-weight-semibold text-uppercase"
                style="font-size:11px !important"
              >{{ node.name }}</span>
              <span
                v-else
                class="text-caption font-weight-semibold text-uppercase"
                style="font-size:11px !important"
              >{{ $t(node.title) }} ({{ node.count }})</span>
            </template>
            <template #append="{ item: node }">
              <template
                v-if="!node.table && node.children != null && node.children.length > 0"
              >
                {{ node.children.length }} <v-icon x-small>
                  mdi-folder
                </v-icon> /
              </template>
              <v-btn
                v-if="!node.table"
                :disabled="!$canACL('update', 'folders',{id: $router.currentRoute.params.id})"
                color="primary"
                x-small
                icon
                @click="editFolder(node)"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                v-if="!node.table"
                :disabled="node.root === true || !$canACL('create', 'folders',{id: $router.currentRoute.params.id})"
                color="primary"
                x-small
                icon
                @click="addFolder(node)"
              >
                <v-icon>
                  mdi-folder-plus-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-if="!node.table"
                :disabled="node.root === true || !$canACL('delete', 'folders',{id: $router.currentRoute.params.id})"
                color="error"
                x-small
                icon
                @click="deleteFolder(node)"
              >
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </template>
          </v-treeview>
        </v-card>
      </div>
    </v-navigation-drawer>
    <dialog-folder
      :folder="folderDialog"
      :folders="foldersStore.folders"
      :is-dialog-visible.sync="isAddDialogVisible"
      @set-folder="setFolder"
    ></dialog-folder>
  </div>
</template>

<script>
import {
  getCurrentInstance, ref, computed, onMounted, onUnmounted, watch,
} from 'vue'
import { useVModel } from '@vueuse/core'
import { required } from '@core/utils/validation'
import { getParentIds } from '@core/utils/folders'
import { useFoldersStore } from '@/stores/folders.store'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import DialogFolder from '@/views/projects/settings/DialogFolder.vue'
import { useUserStore } from '@/stores/user.store'
import {
  TABLE_CONTACTS,
  TABLE_FOLDERS,
  updateDirectory,
} from '@core/utils'

export default {
  components: {
    DialogFolder,
    ConfirmDialogDelete,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    directory: {
      type: Array,
      required: true,
    },
    listing: {
      type: Object,
      default: null,
    },
    isLeftSidebarOpen: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    item: {
      default: null,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const folder = ref(null)
    const listing = useVModel(props, 'listing', emit)
    const folderDialog = ref(null)
    const config = useVModel(props, 'config', emit)
    const directory = useVModel(props, 'directory', emit)
    const item = useVModel(props, 'item', emit)
    const isLeftSidebarOpen = useVModel(props, 'isLeftSidebarOpen', emit)
    const errors = ref({})
    const searchQuery = ref(null)
    const loadingAction = ref(false)
    const isAlertVisible = ref(false)
    const subscription = ref(null)
    const isExpandAll = ref(false)
    const isDeleteDialogVisible = ref(false)
    const isAddDialogVisible = ref(false)
    const isRefresh = ref(false)
    const foldersStore = useFoldersStore()
    const userStore = useUserStore()

    const isDisabled = computed(() => {
      if (item.value) { return true }
      if (!Object.keys(config.value.model).includes('folder_id') && config.value.currentTable !== TABLE_FOLDERS && config.value.currentTable !== TABLE_CONTACTS) {
        return true
      }

      return !!(config.value?.isExport || config.value?.isImport)
    })

    onMounted(async () => {
      subscription.value = await foldersStore.listenFolders(vm.$router.currentRoute.params.id)
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    watch([isAddDialogVisible, isDeleteDialogVisible], () => {
      if (!isAddDialogVisible.value && !isDeleteDialogVisible.value) {
        foldersStore.getFolders(vm.$router.currentRoute.params.id)
      }
    })

    const folders = computed(() => {
      const step = directory.value.length - 1 > 0 ? directory.value.length - 1 : 0
      const currentDirectory = directory.value[step]
      const items = foldersStore.getTree(vm.$router.currentRoute.name, foldersStore.folders)
      let open = []
      if (searchQuery.value) { isExpandAll.value = true }
      if (isExpandAll.value) { open = Object.keys(foldersStore.allFolders) }
      const active = []
      if (currentDirectory?.folder) {
        active.push(currentDirectory?.folder.id || currentDirectory?.folder.parent_id)
        open = getParentIds(currentDirectory?.folder.table ? currentDirectory?.folder.parent_id : currentDirectory?.folder.id, foldersStore.folders)
      }
      if (folderDialog.value) {
        open = open.concat(getParentIds(folderDialog.value.parent_id, foldersStore.folders))
        if (folderDialog.value.parent_id) open.push(folderDialog.value.parent_id)
      }

      return { items, open, active }
    })

    const onActive = ids => {
      const step = directory.value.length - 1 > 0 ? directory.value.length - 1 : 0
      config.value = null
      listing.value = null
      if (ids.length > 0) {
        const id = ids[ids.length - 1]
        const it = foldersStore.allFolders[id]
        if (!it.table) { localStorage.setItem('folder', it.id) } else { localStorage.setItem('folder', it.parent_id) }
        if (it.parent_id) {
          foldersStore.getFolder(vm.$router.currentRoute.params.id, it.parent_id)
          const parent = foldersStore.allFolders[it.parent_id]
          if (parent && parent.children && parent.children.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            listing.value = parent.children.find(i => i.id === id) || parent.children[0]
          }
        } else {
          foldersStore.getFolder(vm.$router.currentRoute.params.id, id)
        }
        updateDirectory(directory.value, step, { folder: it })
      } else {
        updateDirectory(directory.value, step, { folder: null })
        localStorage.removeItem('folder')
      }

      emit('on-refresh')
    }

    const addFolder = it => {
      isAddDialogVisible.value = true
      folderDialog.value = {
        parent_id: it ? it.id : null,
        project_id: vm.$router.currentRoute.params.id,
      }
    }

    const editFolder = it => {
      isAddDialogVisible.value = true
      folderDialog.value = JSON.parse(JSON.stringify(it))
    }

    const deleteFolder = it => {
      folderDialog.value = it
      isDeleteDialogVisible.value = true
    }

    const confirmDelete = () => {
      foldersStore.deleteFolders(folderDialog.value).then(() => {
        isDeleteDialogVisible.value = false
        folder.value = null
        emit('on-refresh')
      }).catch(error => {
        isAlertVisible.value = true
        isDeleteDialogVisible.value = false
        errors.value = {
          folder: vm.$t('FolderError'),
        }
        if (error.response) {
          errors.value = {
            folder: vm.$t('FolderError'),
          }
        }
      })
    }

    const expandAll = () => {
      isExpandAll.value = true
    }

    const collapseAll = () => {
      folder.value = null
      isExpandAll.value = false
    }

    const fetchDatas = () => {
      foldersStore.getFolders(vm.$router.currentRoute.params.id, config.value.path)
    }

    const setFolder = val => {
      folder.value = val
    }

    return {
      config,
      item,
      isLeftSidebarOpen,
      folders,
      searchQuery,
      isAddDialogVisible,
      isDeleteDialogVisible,
      validators: {
        required,
      },
      folder,
      errors,
      loadingAction,
      addFolder,
      deleteFolder,
      confirmDelete,
      editFolder,
      isAlertVisible,
      foldersStore,
      expandAll,
      collapseAll,
      onActive,
      folderDialog,
      fetchDatas,
      isRefresh,
      isDisabled,
      setFolder,
    }
  },
}
</script>
