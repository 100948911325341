<template>
  <v-row v-observe-visibility="visibilityChanged"
         class="pa-3 mt-2"
  >
    <confirm-dialog-delete
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingAction"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>
    <v-col
      cols="12"
      lg="9"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("RolesPermissions") }}
          </h5>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            :disabled="!$canACL('create', 'roles', organization)"
            @click="addRole"
          >
            <v-icon
              :left="!$vuetify.breakpoint.xs"
              color=""
            >
              mdi-plus
            </v-icon>
            <span class="d-none d-sm-block">{{ $t("AddRole") }}</span>
          </v-btn>
        </v-card-title>
        <v-divider class="mx-5"></v-divider>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex flex-grow-1 align-center pb-3">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              :placeholder="$t('SearchRole')"
              class="me-3 search-table"
              clearable
              prepend-inner-icon="mdi-magnify "
              clear-icon="mdi-close"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-data-table
          v-model="selectedTableData"
          :headers="tableColumns"
          :items="listTable"
          :options.sync="options"
          :server-items-length="totalListTable"
          :loading="loading"
          disable-sort
          class="text-no-wrap"
        >
          <template #[`item.name`]="{item}">
            <v-chip
              color="primary"
              :class="`text-uppercase v-chip-light-bg primary--text font-weight-semibold`"
            >
              {{ $t(item.name) }}
            </v-chip>
          </template>
          <template #[`item.members`]="{item}">
            <span class="font-weight-bold">{{ item.members.length }}</span>
          </template>
          <template #[`item.created_at`]="{item}">
            {{ $moment(item.created_at).format("lll") }}
          </template>
          <template #[`item.updated_at`]="{item}">
            {{ $moment(item.updated_at).format("lll") }}
          </template>

          <template #[`item.actions`]="{item}">
            <div class="d-flex align-center justify-center">
              <v-btn
                outlined
                x-small
                class="me-3"
                color="primary"
                :disabled="!$canACL('update', 'roles', organization)"
                @click="editRole(item)"
              >
                <v-icon>
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
              <v-btn
                x-small
                outlined
                color="error"
                :disabled="!$canACL('delete', 'roles', organization)"
                @click="openDialogDelete(item)"
              >
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <dialog-role :role-permissions="role"
                 :is-dialog-visible.sync="isAddDialogVisible"
    ></dialog-role>
  </v-row>
</template>

<script>
import { ref, onBeforeMount, getCurrentInstance } from 'vue'
import {
  required, emailValidator,
} from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import DialogRole from '@/views/organizations/settings/DialogRole.vue'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import { useOrganizationsStore } from '@/stores/organizations.store'
import { uuidv7 } from 'uuidv7'
import useRoleList from './useRoleList'

export default {
  components: {
    ConfirmDialogDelete,
    DialogRole,
  },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const errors = ref({})
    const role = ref({})
    const rolesName = ref(['Administrator', 'Editor', 'Reader'])
    const loadingAction = ref(false)
    const vm = getCurrentInstance().proxy
    const organizationsStore = useOrganizationsStore()
    const permissions = ref(null)

    const {
      listTable, searchQuery, tableColumns, options, totalListTable, loading, selectedTableData, isAddDialogVisible, isDeleteDialogVisible, fetchDatas,
    } = useRoleList(vm.$router.currentRoute.params.id)

    const visibilityChanged = isVisible => {
      if (isVisible) {
        fetchDatas()
      }
    }

    onBeforeMount(async () => {
      tableColumns.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
    })

    const confirmDelete = () => {
      if (!role.value) { return }
      loadingAction.value = true
      organizationsStore.deleteRole(role.value.id).then(() => {
        isDeleteDialogVisible.value = false
        isAddDialogVisible.value = false
        loadingAction.value = false
      })
        .catch(() => {
          loadingAction.value = false
        })
    }

    const openDialogDelete = _role => {
      isDeleteDialogVisible.value = true
      role.value = _role
    }

    const editRole = _role => {
      isAddDialogVisible.value = true
      errors.value = {}
      role.value = _role
    }

    const addRole = () => {
      role.value = { id: uuidv7(), organization_id: vm.$router.currentRoute.params.id }
      isAddDialogVisible.value = true
    }

    return {
      tableColumns,
      searchQuery,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,
      validators: {
        required,
        emailValidator,
      },
      isAddDialogVisible,
      role,
      rolesName,
      loadingAction,
      errors,
      avatarText,
      isDeleteDialogVisible,
      confirmDelete,
      openDialogDelete,
      addRole,
      editRole,
      permissions,
      visibilityChanged,
    }
  },
}
</script>
