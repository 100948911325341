import { ref, watch } from 'vue'
import { useTableStore } from '@/stores/table.store'

export default function useList(config, _isServer) {
  const listTable = ref([])
  const listLocal = ref([])
  const searchQuery = ref('')
  const totalTable = ref(0)
  const loading = ref(false)
  const items = ref([])
  const item = ref(null)
  const subitem = ref(null)
  const isDelete = ref(false)
  const isExport = ref(false)
  const isImport = ref(false)
  const options = ref(config?.tableOptions)
  const isAlertVisible = ref(null)
  const errors = ref({})
  const firstload = ref(true)
  const tableStore = useTableStore()
  const isServer = ref(_isServer)

  const setLocalData = (data, _options) => {
    isServer.value = false
    listTable.value = data
    options.value = _options
    listLocal.value = JSON.parse(JSON.stringify(data))
    loading.value = false
  }
  const fetchLocalDatas = () => {
    if (options.value && options.value.filters && Object.keys(options.value.filters).length > 0) {
      listTable.value = JSON.parse(JSON.stringify(listLocal.value))
      Object.keys(options.value.filters).map(async key => {
        if (options.value.filters[key] && options.value.filters[key].length) {
          listTable.value = listTable.value.filter(
            it => !!options.value.filters[key].find(
              f => f.value === it[key] || it[key]?.toString().toLowerCase().indexOf(f.value) >= 0,
            ),
          )
        }
      })
    } else {
      listTable.value = JSON.parse(JSON.stringify(listLocal.value))
    }
  }

  const fetchDatas = () => {
    if (isServer.value === false) {
      fetchLocalDatas()

      return
    }
    loading.value = true
    tableStore
      .getItems()
      .then(data => {
        listTable.value = data.data
        totalTable.value = data.count
        loading.value = false
      })
      .catch(error => {
        listTable.value = []
        totalTable.value = 0
        loading.value = false
        isAlertVisible.value = true
        if (error.message === 'Requested range not satisfiable') options.value.page = 1
        else {
          errors.value = {
            item: error.message,
          }
          if (error.response) {
            errors.value = {
              item: error.response.data?.error?.message || error.response.data?.message,
            }
          }
        }
      })
  }

  watch(
    [searchQuery, isDelete, options],
    () => {
      tableStore.config.tableOptions = options.value
      tableStore.config.searchQuery = searchQuery.value
      if (!item.value && !isDelete.value && !firstload.value && !loading.value && tableStore.config.currentTable) {
        fetchDatas()
      }
    },
    { deep: true },
  )

  tableStore.$subscribe(async mutation => {
    if (mutation.payload) {
      const index = listTable.value.findIndex(it => it.id === mutation.payload.item.id)
      if (mutation.payload.eventType === 'UPDATE') {
        if (index !== -1) listTable.value.splice(index, 1, mutation.payload.item)
      } else if (mutation.payload.eventType === 'INSERT') {
        if (index === -1) listTable.value.push(mutation.payload.item)
        else listTable.value.splice(index, 1, mutation.payload.item)
      } else if (mutation.payload.eventType === 'DELETE') {
        if (index !== -1) listTable.value.splice(index, 1)
      }
    }
  })

  return {
    searchQuery,
    listTable,
    listLocal,
    setLocalData,
    totalTable,
    loading,
    items,
    fetchDatas,
    item,
    subitem,
    isDelete,
    options,
    isAlertVisible,
    errors,
    isExport,
    isImport,
    firstload,
  }
}
