<template>
  <v-row>
    <confirm-dialog-delete
      :is-delete-dialog-visible.sync="isDelete"
      :is-loading="loadingAction"
      :is-confirm="false"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>
    <v-snackbar v-model="isSaved"
                outlined
                text
                color="primary"
                shaped
                top
                right
    >
      {{ $t('SavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn icon
               color="primary"
               v-bind="attrs"
               @click="isSaved = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.item"
    ></alert-error>
    <v-col cols="12">
      <v-card flat>
        <table-header
          ref="tableheader"
          :menu="menu"
          :config.sync="config"
          :directory.sync="directory"
          :is-export="isExport"
          :is-import="isImport"
          :search-query.sync="searchQuery"
          :item.sync="item"
          :items.sync="items"
          :is-delete.sync="isDelete"
          :listing="listing"
          :item-actions="items"
          :total="totalTable"
          :is-left-sidebar-open.sync="isLeftSidebarOpen"
          :loading.sync="loading"
          :current-table-direct-edit="currentTableDirectEdit"
          @on-add="onAdd"
          @on-edit="onEdit"
          @on-delete="onDelete"
          @on-import="onImport"
          @on-export="onExport"
          @on-previous="onPrevious"
          @on-test="onTest"
          @on-refresh="onRefresh"
          @on-init="onInit"
        ></table-header>
        <div v-if="!isImport && !isExport">
          <table-form
            v-if="item"
            :config.sync="config"
            :item.sync="item"
            :items.sync="items"
            :total="totalTable"
            :firstload="firstload"
            :loading="loading"
            :properties="items.length > 1 ? items : null"
            :current-table-direct-edit="currentTableDirectEdit"
            :directory="directory"
            @on-delete="onDelete"
            @on-previous="onPrevious"
            @on-change="onChange"
            @on-refresh="onRefresh"
            @on-save="onSave"
            @on-copy="onCopy"
            @on-click="onClick"
            @on-sub-table="onSubTable"
          ></table-form>
          <v-skeleton-loader v-if="firstload && !item"
                             type="table"
          >
          </v-skeleton-loader>
          <table-content
            v-if="!firstload && !item"
            :config.sync="config"
            :options.sync="options"
            :directory.sync="directory"
            :list-table.sync="listTable"
            :list-local.sync="listLocal"
            :items.sync="items"
            :total-table.sync="totalTable"
            :is-delete.sync="isDelete"
            :loading.sync="loading"
            :item.sync="item"
            :search-query="searchQuery"
            @on-change="onChange"
            @on-refresh="onRefresh"
            @on-delete="onDelete"
            @on-click="onClick"
          ></table-content>
        </div>
        <table-import
          v-if="isImport"
          :config="config"
          :directory="directory"
          @on-previous="onPrevious"
          @on-refresh="onRefresh"
        ></table-import>
        <table-export
          v-if="isExport"
          :config="config"
          :directory="directory"
          :selected-items.sync="items"
          :count="items.length || totalTable"
          @on-previous="onPrevious"
        ></table-export>
        <dialog-property
          :directory="directory"
          :is-dialog-visible.sync="propertiesStore.isModels"
          @on-add="onAdd"
        ></dialog-property>
        <dialog-listing
          :item.sync="item"
          :items.sync="items"
          :is-dialog-visible.sync="listingsStore.isListings"
          @on-change="onChange"
        ></dialog-listing>
        <dialog-test :item.sync="item"
                     :is-dialog-visible.sync="campaignsStore.isTest"
        ></dialog-test>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  getCurrentInstance, ref, onMounted, onUnmounted, computed, watch,
} from 'vue'
import { useVModel } from '@vueuse/core'
import TableHeader from '@/views/table/TableHeader.vue'
import TableImport from '@/views/table/TableImport.vue'
import TableExport from '@/views/table/TableExport.vue'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import useTable from '@/views/table/useTable'
import { useTableStore } from '@/stores/table.store'
import { usePropertiesStore } from '@/stores/properties.store'
import { useListingsStore } from '@/stores/listings.store'
import { useFoldersStore } from '@/stores/folders.store'
import { useUserStore } from '@/stores/user.store'
import TableContent from '@/views/table/TableContent.vue'
import TableForm from '@/views/table/TableForm.vue'
import AlertError from '@/components/AlertError.vue'
import { useTemplatesStore } from '@/stores/templates.store'
import { useModelsStore } from '@/stores/models.store'
import { useFilesStore } from '@/stores/files.store'
import { useCampaignsStore } from '@/stores/campaigns.store'
import DialogProperty from '@/views/projects/settings/DialogProperty.vue'
import DialogListing from '@/views/projects/settings/DialogListing.vue'
import DialogTest from '@/views/projects/settings/DialogTest.vue'

import {
  TABLE_CONTACTS,
  TABLE_LISTINGS,
  TABLE_PROPERTIES,
  TABLE_FOLDERS,
  TABLE_CAMPAIGNS,
  TABLE_MODELS,
  TABLE_FORMS,
  addDirectory,
  getPathFromDir,
  updateDirectory,
  TABLE_OBJECTS,
  TABLE_FILES,
  TABLE_WORKFLOWS,
} from '@core/utils'

export default {
  components: {
    ConfirmDialogDelete,
    TableHeader,
    TableContent,
    TableForm,
    TableImport,
    TableExport,
    DialogProperty,
    DialogTest,
    DialogListing,
    AlertError,
  },
  model: {
    prop: 'table',
    event: 'changeTable',
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    directory: {
      type: Array,
      required: true,
    },
    isLeftSidebarOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const loadingAction = ref(false)
    const subscription = ref(null)

    const tableStore = useTableStore()
    const propertiesStore = usePropertiesStore()
    const userStore = useUserStore()
    const listingsStore = useListingsStore()
    const foldersStore = useFoldersStore()
    const templatesStore = useTemplatesStore()
    const modelsStore = useModelsStore()
    const campaignsStore = useCampaignsStore()
    const filesStore = useFilesStore()

    const config = useVModel(props, 'config', emit)
    const directory = useVModel(props, 'directory', emit)
    const listing = useVModel(props, 'listing', emit)
    const isLeftSidebarOpen = useVModel(props, 'isLeftSidebarOpen', emit)
    const currentTable = ref(null)
    const isSaved = ref(false)
    const deleteRedirect = ref(false)
    const currentTableDirectEdit = ref(false)
    const subTable = ref(null)
    let steps = 0
    const {
      listTable,
      listLocal,
      searchQuery,
      totalTable,
      loading,
      items,
      item,
      subitem,
      isDelete,
      options,
      isAlertVisible,
      errors,
      isExport,
      isImport,
      firstload,
      fetchDatas,
    } = useTable(config.value || props.config)

    watch(
      directory,
      () => {
        const step = directory.value.length - 1 > 0 ? directory.value.length - 1 : 0
        const currentDirectory = directory.value[step]
        subitem.value = directory.value[step - 1]?.item || null
        item.value = currentDirectory?.item || null
        currentTable.value = currentDirectory?.folder?.table || currentDirectory?.table || vm.$router.currentRoute.name

        subTable.value = directory.value[step - 2]?.table || currentDirectory?.table

        if (currentDirectory?.config && steps > directory.value.length) {
          config.value = currentDirectory?.config
          searchQuery.value = currentDirectory?.config?.searchQuery
          options.value = currentDirectory?.config?.tableOptions
        } else {
          searchQuery.value = null
          if (currentTable.value) {
            options.value = tableStore.getTableOptions(currentTable.value)
          }
        }

        const path = btoa(getPathFromDir(directory.value))
        const folderID = currentDirectory?.folder?.id || '0'

        if (
          steps > 0
          && (props.config.path !== vm.$router.currentRoute.name
            || vm.$router.currentRoute.params.path !== path
            || vm.$router.currentRoute.params.folder !== folderID)
        ) {
          vm.$router.push({
            name: props.config.path,
            params: {
              id: vm.$router.currentRoute.params.id,
              path,
              mode: 'view',
              folder: folderID,
            },
          })
        }
        steps = directory.value.length

        emit('changeTable', {
          item: item.value,
          items: items.value,
          currentTable: currentTable.value,
          isExport: isExport.value,
          isImport: isImport.value,
          subTable: subTable.value,
        })
      },
      { deep: true },
    )

    const menu = computed(() => {
      const data = []
      directory.value.forEach((d, index) => {
        const header = tableStore.getHeader(d.table, { folder: d.folder })
        if (d.editor) {
          data.push({
            title: d.editor.indexOf('editor') >= 0 ? vm.$t('Editor') : vm.$t('Viewer'),
            to: directory.value.length - 1 !== index ? 'on-previous' : undefined,
            disabled: d.disabled,
          })
        } else if (!d.item) {
          data.push({
            title: vm.$t(header.title),
            home: true,
            bold: true,
            homeIcon: header.icon,
            to: 'on-previous',
            folder: d.folder,
            disabled: d.disabled,
          })
        } else if (d.item.id) {
          const titleName = []
          header.name?.forEach(n => {
            if (d.item[n])titleName.push(d.item[n])
          })
          data.push({
            title: titleName.length > 0 ? titleName.join(' ') : vm.$t(header.editData),
            to: directory.value.length - 1 !== index ? 'on-previous' : undefined,
            disabled: d.disabled,
          })
        } else if (!d.item.id && items.value.length === 0) {
          data.push({
            title: vm.$t(header.newData),
            to: directory.value.length - 1 !== index ? 'on-previous' : undefined,
            disabled: d.disabled,
          })
        } else if (items.value.length > 1) {
          data.push({
            title: vm.$t(header.actionData),
            to: directory.value.length - 1 !== index ? 'on-previous' : undefined,
            disabled: d.disabled,
          })
        }
      })

      if (isExport.value && !currentTable.value) {
        data.push({
          title: vm.$t('Export'),
          icon: 'mdi-database-export-outline',
        })
      }

      if (isImport.value && !currentTable.value) {
        data.push({
          title: vm.$t('Import'),
          icon: 'mdi-database-import-outline',
        })
      }

      return data
    })

    foldersStore.$subscribe(async mutation => {
      if (mutation.payload) {
        const index = foldersStore.folders.findIndex(it => it.id === mutation.payload.item.id)
        if (mutation.payload.eventType === 'UPDATE') {
          if (index !== -1) {
            foldersStore.folders.splice(index, 1, await foldersStore.getFolder(vm.$router.currentRoute.params.id, mutation.payload.item.id))
            vm.$refs.tableheader.refreshTabs()
          }
        } else if (mutation.payload.eventType === 'INSERT') {
          if (index === -1) foldersStore.folders.push(await foldersStore.getFolder(vm.$router.currentRoute.params.id, mutation.payload.item.id))
        } else if (mutation.payload.eventType === 'DELETE') {
          if (index !== -1) foldersStore.folders.splice(index, 1)
        }
      }
    })

    const getPath = () => {
      const decodedPath = JSON.parse(atob(vm.$router.currentRoute.params.path))
      const decodedDir = []
      const promises = []
      decodedPath.forEach(async path => {
        if (path.i) {
          if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(path.i)) {
            promises.push(Promise.resolve(tableStore.getItem(path.i, path.t)))
            if (path.t === TABLE_MODELS) {
              promises.push(Promise.resolve(propertiesStore.getPropertiesID(tableStore.route.params.id, path.i)))
            }
            decodedDir.push({ table: path.t, item: path.i, editor: path.e })
          } else {
            // decodedDir.push({ table: path.split(':')[0], item: null })
          }
        } else {
          decodedDir.push({ table: path.t, item: null, editor: path.e })
        }
      })

      Promise.all(promises).then(data => {
        decodedDir.forEach(dir => {
          if (dir.item) dir.item = data.find(d => d.id === dir.item)
        })
        if (vm.$router.currentRoute.params.folder !== '0') {
          const it = foldersStore.allFolders[vm.$router.currentRoute.params.folder]
          updateDirectory(decodedDir, decodedDir.length - 1, { folder: it })
        }
        directory.value = decodedDir
        firstload.value = false
      })

      if (isImport.value || isExport.value) {
        emit('changeTable', {
          item: item.value,
          items: items.value,
          currentTable: currentTable.value,
          isExport: isExport.value,
          isImport: isImport.value,
        })
      }
      firstload.value = false

      return false
    }

    if (vm.$router.currentRoute.params && vm.$router.currentRoute.params.mode === 'import') {
      isImport.value = true
    } else if (vm.$router.currentRoute.params && vm.$router.currentRoute.params.mode === 'export') {
      isExport.value = true
    }

    const onChange = () => {
      firstload.value = false
      if (directory.value[directory.value.length - 1].update) { item.value = { ...item.value, ...directory.value[directory.value.length - 1].update } }
      emit('changeTable', {
        item: item.value,
        items: items.value,
        currentTable: currentTable.value,
        isExport: isExport.value,
        isImport: isImport.value,
        subTable: subTable.value,
      })
    }

    const onRefreshItem = () => {
      if (item.value?.id) {
        tableStore.getItem(item.value?.id).then(data => {
          item.value = data
          onChange()
        })
      } else if (currentTable.value === TABLE_OBJECTS && directory.value.length > 2) {
        propertiesStore
          .getPropertiesID(tableStore.route.params.id, directory.value[directory.value.length - 2].item.id)
          .then(() => {
            onChange()
          })
      } else {
        onChange()
      }
    }

    const onInit = async refresh => {
      firstload.value = true
      if (tableStore.route.name === TABLE_CONTACTS || tableStore.route.name === TABLE_LISTINGS) {
        const promise0 = Promise.resolve(foldersStore.getFolders(tableStore.route.params.id, tableStore.route.name))

        // const promise1 = Promise.resolve(propertiesStore.getCategories(tableStore.route.params.id))
        const promise2 = Promise.resolve(propertiesStore.getProperties(tableStore.route.params.id, 'contacts'))
        const promise3 = Promise.resolve(listingsStore.getListings(tableStore.route.params.id))
        const promise4 = Promise.resolve(modelsStore.getModels(tableStore.route.params.id))
        Promise.all([promise0, promise2, promise3, promise4]).then(() => {
          if (!refresh) getPath()
          else {
            onRefreshItem()
          }
        })
      } else if (tableStore.route.name === TABLE_MODELS) {
        const promise0 = Promise.resolve(foldersStore.getFolders(tableStore.route.params.id, tableStore.route.name))
        const promise2 = Promise.resolve(modelsStore.getModels(tableStore.route.params.id))
        const promise1 = Promise.resolve(listingsStore.getListings(tableStore.route.params.id))
        Promise.all([promise0, promise1, promise2]).then(() => {
          if (!refresh) getPath()
          else {
            onRefreshItem()
          }
        })
      } else if (tableStore.route.name === TABLE_PROPERTIES) {
        const promise0 = Promise.resolve(foldersStore.getFolders(tableStore.route.params.id, tableStore.route.name))
        const promise1 = Promise.resolve(propertiesStore.getCategories(tableStore.route.params.id))
        const promise2 = Promise.resolve(modelsStore.getModels(tableStore.route.params.id))
        Promise.all([promise0, promise1, promise2]).then(() => {
          if (!refresh) getPath()
          else {
            onRefreshItem()
          }
        })
      } else if (tableStore.route.name === TABLE_CAMPAIGNS) {
        const promise0 = Promise.resolve(foldersStore.getFolders(tableStore.route.params.id, tableStore.route.name))
        const promise1 = Promise.resolve(templatesStore.getTemplates(tableStore.route.params.id))
        const promise2 = Promise.resolve(listingsStore.getListings(tableStore.route.params.id))
        Promise.all([promise0, promise1, promise2]).then(() => {
          if (!refresh) getPath()
          else {
            onRefreshItem()
          }
        })
      } else if (tableStore.route.name === TABLE_FORMS) {
        const promise0 = Promise.resolve(propertiesStore.getProperties(tableStore.route.params.id, 'contacts'))
        const promise1 = Promise.resolve(foldersStore.getFolders(tableStore.route.params.id, tableStore.route.name))
        Promise.all([promise0, promise1]).then(() => {
          if (!refresh) getPath()
          else {
            onRefreshItem()
          }
        })
      } else if (tableStore.route.name === TABLE_WORKFLOWS) {
        const promise0 = Promise.resolve(listingsStore.getListings(tableStore.route.params.id))
        const promise1 = Promise.resolve(foldersStore.getFolders(tableStore.route.params.id, tableStore.route.name))
        const promise2 = Promise.resolve(campaignsStore.getCampaigns(tableStore.route.params.id))
        const promise3 = Promise.resolve(propertiesStore.getProperties(tableStore.route.params.id, 'contacts'))
        Promise.all([promise0, promise1, promise2, promise3]).then(() => {
          if (!refresh) getPath()
          else {
            onRefreshItem()
          }
        })
      } else {
        const promise0 = Promise.resolve(foldersStore.getFolders(tableStore.route.params.id, tableStore.route.name))
        Promise.all([promise0]).then(() => {
          if (!refresh) getPath()
          else {
            onRefreshItem()
          }
        })
      }
      if (tableStore.route.name !== TABLE_CONTACTS && tableStore.route.name !== TABLE_FOLDERS) {
        subscription.value = await tableStore.listenItems(vm.$router.currentRoute.params.id)
      }
    }

    onMounted(() => {
      onInit()
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    const onPrevious = (goStep, refresh) => {
      if (goStep === undefined && !isImport.value && !isExport.value) {
        goStep = directory.value.length - 2 > 0 ? directory.value.length - 2 : 0
      }
      if (refresh) {
        goStep -= 1
        if (directory.value.length - 3 >= 0 && refresh.id) {
          const dir = directory.value[directory.value.length - 3]
          const currentDir = directory.value[directory.value.length - 1]
          dir.update = {}
          dir.update[`${currentDir.table.slice(0, -1)}_id`] = refresh.id
        }
      }
      isImport.value = false
      isExport.value = false
      if (goStep !== undefined) {
        directory.value.splice(goStep + 1, Infinity)
      }
      if (refresh) {
        onInit(true)
      }
      items.value = []
      currentTableDirectEdit.value = false
    }

    const confirmDelete = () => {
      loadingAction.value = true
      tableStore
        .deleteItem(deleteRedirect.value || items.value)
        .then(() => {
          item.value = null
          items.value = []
          isDelete.value = false
          loadingAction.value = false
          isSaved.value = true
          onChange()
          if (deleteRedirect.value) {
            onPrevious(undefined)
          }
          deleteRedirect.value = null
        })
        .catch(error => {
          isAlertVisible.value = true
          loadingAction.value = false
          errors.value = {
            item: error.message,
          }
          if (error.response) {
            errors.value = {
              item: error.response.data?.error?.message || error.response.data?.message,
            }
          }
        })
    }

    const onAdd = val => {
      addDirectory(
        directory.value,
        config.value.currentTable,
        JSON.parse(JSON.stringify(val || props.config.model)),
        config.value,
      )
      items.value = []
      errors.value = {}
    }

    const onCopy = val => {
      updateDirectory(directory.value, directory.value.length - 1, { item: val })
      items.value = []
      errors.value = {}
    }

    const onExport = async () => {
      if (config.value.currentTable === TABLE_FILES) {
        firstload.value = true
        await filesStore.download(items.value)
        firstload.value = false
        items.value = []

        return
      }
      isExport.value = true
      updateDirectory(directory.value, directory.value.length - 1, { config: props.config })
      emit('changeTable', {
        item: item.value,
        items: items.value,
        currentTable: currentTable.value,
        isExport: isExport.value,
        isImport: isImport.value,
        subTable: subTable.value,
      })
    }

    const onImport = () => {
      if (currentTable.value === TABLE_FILES) {
        filesStore.isAdd = true

        return
      }
      isImport.value = true
      updateDirectory(directory.value, directory.value.length - 1, { config: props.config })
      emit('changeTable', {
        item: item.value,
        items: items.value,
        currentTable: currentTable.value,
        isExport: isExport.value,
        isImport: isImport.value,
        subTable: subTable.value,
      })
    }

    const onEdit = async d => {
      if (d) {
        addDirectory(directory.value, config.value.currentTable, d, config.value)
      } else if (items.value && items.value.length === 1) {
        addDirectory(directory.value, config.value.currentTable, items.value[0], config.value)
        tableStore
          .getItem(items.value[0].id)
          .then(data => {
            updateDirectory(directory.value, directory.value.length - 1, { item: data })
            items.value = []
          })
          .catch(() => {})
      } else {
        addDirectory(directory.value, config.value.currentTable, {}, config.value)
      }
    }

    const onDelete = val => {
      deleteRedirect.value = val ? JSON.parse(JSON.stringify(val)) : null
      item.value = null
      isDelete.value = true
    }

    const onRefresh = () => {
      fetchDatas()
    }

    const onSubTable = async (path, redirect) => {
      firstload.value = true
      const [table, id, subtable, subId] = path.split('/')
      currentTableDirectEdit.value = false
      items.value = []
      addDirectory(directory.value, table, null, config.value, { disabled: true })
      if (id === 'new') {
        firstload.value = true
        setTimeout(() => {
          currentTableDirectEdit.value = redirect
          addDirectory(directory.value, table, JSON.parse(JSON.stringify(config.value.model)), config.value, { disabled: true })
          onInit(true)
        }, 450)
      } else if (id) {
        if (subtable && subId) {
          firstload.value = true
          setTimeout(async () => {
            currentTableDirectEdit.value = redirect
            addDirectory(directory.value, table, await tableStore.getItem(id), config.value, { disabled: true })
            addDirectory(directory.value, subtable, null, config.value, { disabled: true })
            setTimeout(async () => {
              addDirectory(directory.value, subtable, await tableStore.getItem(subId), config.value)
              onInit(true)
            }, 200)
          }, 200)
        } else if (subtable && !subId) {
          firstload.value = true
          setTimeout(async () => {
            currentTableDirectEdit.value = redirect
            addDirectory(directory.value, table, await tableStore.getItem(id), config.value, { disabled: true })
            addDirectory(directory.value, subtable, null, config.value, { disabled: true })
            onInit(true)
          }, 200)
        } else {
          firstload.value = true
          setTimeout(async () => {
            currentTableDirectEdit.value = redirect
            addDirectory(directory.value, table, await tableStore.getItem(id), config.value, { disabled: true })
            firstload.value = false
          }, 200)
        }
      } else {
        onInit(true)
      }
    }

    const onSave = () => {
      isSaved.value = true
    }

    const onTest = () => {
      loading.value = true
      loadingAction.value = true
      tableStore
        .test(config.value.path, { project_id: vm.$router.currentRoute.params.id })
        .then(() => {
          isSaved.value = true
          loading.value = false
          loadingAction.value = false
        })
        .catch(error => {
          isAlertVisible.value = true
          loadingAction.value = false
          loading.value = false
          if (error?.response) {
            errors.value = {
              item: vm.$t(error.response.data.message, error.response.data.params) || 'error',
            }
          } else {
            errors.value = {
              item: vm.$t(error?.message, error?.params) || 'error',
            }
          }
        })
    }

    // LISTINGS METHODS
    const getCondition = () => ({
      condition: 'OR',
      property: '',
      operation: 'equals',
      value: '',
    })

    const addCondition = index => {
      item.value.filters[index[1]].conditions.push(getCondition())
    }

    const removeFilter = index => {
      item.value.filters.splice(index[1], 1)
    }

    const removeCondition = index => {
      item.value.filters[index[1]].conditions.splice(index[2], 1)
      if (item.value.filters[index[1]].conditions.length === 0) {
        removeFilter(index[1])
      }
    }

    const addFilter = () => {
      item.value.filters.push({
        group: 'AND',
        conditions: [getCondition()],
      })
    }

    const onClick = val => {
      const {
        index, key, params,
      } = val
      if (key === 'add_filter') {
        vm.$nextTick(addFilter())
      } else if (key === 'add_condition') {
        addCondition(index)
      } else if (key === 'remove_condition') {
        removeCondition(index)
      } else if (key === 'delete_filter') {
        removeFilter(index)
      } else if (key === 'edit_listings') {
        listingsStore.isListings = true
      } else if (key === 'template_editor') {
        addDirectory(directory.value, config.value.currentTable, item.value, null, { editor: 'template-editor' })
      } else if (key === 'workflow_editor') {
        addDirectory(directory.value, config.value.currentTable, item.value, null, { editor: 'workflow-editor' })
      } else if (key === 'form_editor') {
        addDirectory(directory.value, config.value.currentTable, item.value, null, { editor: 'form-editor' })
      } else if (key === 'file_viewer') {
        addDirectory(directory.value, config.value.currentTable, item.value, null, { editor: 'file-viewer' })
      } else if (
        key.indexOf('view__') >= 0
        || key.indexOf('edit__') >= 0
        || key.indexOf('add__') >= 0
        || key.indexOf('refresh__') >= 0
      ) {
        const [action, table, itemID] = key.split('__')
        if (action === 'view') {
          onSubTable(table, true)
        } else if (action === 'edit') {
          if (itemID) {
            onSubTable(`${table}/${itemID}`, true)
          } else {
            onSubTable(
              item.value[`${table.slice(0, -1)}_id`] ? `${table}/${item.value[`${table.slice(0, -1)}_id`]}` : table, true,
            )
          }
        } else if (action === 'add') {
          onSubTable(`${table}/new`, false)
        } else if (action === 'refresh') {
          onInit(true)
        }
      } else {
        tableStore.onAction(key, { item: item.value })
      }

      if (params
        && params.tag
        && (params.tag.indexOf('view__') >= 0
          || params.tag.indexOf('edit__') >= 0
          || params.tag.indexOf('add__') >= 0
          || params.tag.indexOf('refresh__') >= 0)
      ) {
        const [action, table] = params.tag.split('__')
        if (action === 'view') {
          onSubTable(table, true)
        } else if (action === 'edit') {
          onSubTable(
            item.value[`${table.slice(0, -1)}_id`] ? `${table}/${item.value[`${table.slice(0, -1)}_id`]}` : table, true,
          )
        } else if (action === 'add') {
          onSubTable(`${table}/new`, true)
        } else if (action === 'refresh') {
          onInit(true)
        }
      }
    }

    return {
      listTable,
      listLocal,
      searchQuery,
      totalTable,
      loading,
      items,
      item,
      listing,
      isExport,
      isImport,
      isDelete,
      loadingAction,
      confirmDelete,
      errors,
      isAlertVisible,
      isSaved,
      isLeftSidebarOpen,
      options,
      menu,
      onAdd,
      onEdit,
      onDelete,
      onExport,
      onImport,
      onChange,
      onRefresh,
      onSubTable,
      onPrevious,
      onSave,
      onTest,
      onInit,
      onCopy,
      onClick,
      currentTable,
      currentTableDirectEdit,
      fetchDatas,
      firstload,
      directory,
      propertiesStore,
      campaignsStore,
      listingsStore,
    }
  },
}
</script>
