import { defineStore, acceptHMRUpdate } from 'pinia'
import sortBy from 'lodash/sortBy'
import { supabase } from '@/plugins/supabase'
import { canACL } from '@/plugins/acl/checkaccess'
import types from '@/views/projects/settings/types.json'
import { useFoldersStore } from '@/stores/folders.store'
import { useChatsStore } from '@/stores/chats.store'
import appearances from '@/views/projects/settings/appearances.json'
import { required } from '@core/utils/validation'
import { isJSON, TABLE_PROPERTIES, displayMode } from '@core/utils'
import { useModelsStore } from './models.store'

const slugify = require('slugify')
// eslint-disable-next-line import/prefer-default-export
export const usePropertiesStore = defineStore({
  id: TABLE_PROPERTIES,
  state: () => ({
    isModels: false,
    isItems: false,
    ids: [],
    properties: [],
    categories: [],
    folders: [],
  }),
  actions: {
    getHeader(payload, item) {
      return {
        title: 'Properties',
        icon: 'mdi-database',
        folder: payload?.folder,
        display: displayMode(TABLE_PROPERTIES, 'table'),
        name: ['name'],
        newData: 'NewProperty',
        actionData: 'EditProperties',
        editData: 'EditProperty',
        search: 'SearchProperty',
        searchIcon: 'mdi-database-search',
        buttons: ['properties'],
        actions: [
          { title: 'Save', icon: 'mdi-content-save-outline', action: 'save' },
          {
            title: 'Duplicate',
            icon: 'mdi-content-duplicate',
            action: 'duplicate',
            disabled: !item?.created_at,
          },
          {
            title: 'Delete',
            icon: 'mdi-delete-outline',
            action: 'delete',
            acl: 'delete',
            color: 'error',
            disabled: !item?.created_at,
          },
        ],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['sort_index'],
        sortDesc: [false],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: -1,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        { text: '#', value: 'sort_index', type: 'drag' },
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
          search: true,
        },
        {
          text: 'Type',
          value: 'type',
          type: 'chip-type-properties',
          filterable: true,
        },
        {
          text: 'Appearance',
          value: 'appearance',
          type: 'chip-appearance-properties',
          filterable: true,
        },

        /* {
          text: 'Category',
          value: 'category',
          type: 'text-overline',
          filterable: true,
        }, */
        /*  {
          text: 'Folder',
          value: 'folder_id',
          type: 'folder',
          filterable: true,
        }, */
        {
          text: 'Required',
          value: 'required',
          type: 'simple-checkbox',
          filterable: true,
        },
        {
          text: 'Unique',
          value: 'single',
          type: 'simple-checkbox',
          filterable: true,
        },
        {
          text: 'Hidden',
          value: 'hidden',
          type: 'simple-checkbox',
          filterable: true,
        },
        {
          text: 'Header',
          value: 'tableheader',
          type: 'simple-checkbox',
          filterable: true,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        // general
        name: null,
        type: 'text',
        model_id: payload?.model_id,
        appearance: 'singleline',
        category: 'General',
        slug: null,
        required: false,
        single: false,
        hidden: false,
        readonly: false,
        regex: null,
        tableheader: true,
        encrypt: false,
        minimum: 0,
        maximum: 0,
        minimum_length: 0,
        maximum_length: 0,
        sort_index: null,

        // default value
        default_value: null,
        folder_id: payload?.folder_id,
        project_id: payload?.project_id,
      }
    },

    getSchema(item, items, payload) {
      const foldersStore = useFoldersStore()
      const chatsStore = useChatsStore()
      const modelsStore = useModelsStore()

      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }

      schema.col1.schema.general = {
        title: 'General',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'pa-3 ma-2',
        schema: {
          name: {
            type: 'text',
            label: 'Name',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-database',
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
          },
          slug: {
            type: 'text',
            label: 'ID',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-key-outline',
            disabled: true,
            hidden: !!items.length,
          },
          model_id: {
            type: 'select',
            subtype: 'models',
            col: 12,
            label: 'Model',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-toy-brick',
            clearable: true,
            items: modelsStore.models,
            disabled: true,
            rules: items.length > 0 ? [] : [required],
          },
          type: {
            type: 'select',
            subtype: 'types',
            label: 'Type',
            class: 'mx-1 mb-2',
            complex: true,
            clearable: true,
            items: types,
            rules: items.length > 0 ? [] : [required],
          },
          appearance: {
            type: 'select',
            subtype: 'appearances',
            label: 'Appearance',
            class: 'mx-1 mb-2',
            dense: true,
            clearable: true,
            items: appearances,
            rules: items.length > 0 ? [] : [required],
          },
          category: {
            type: 'select',
            label: 'Category',
            class: 'mx-1 mb-2',
            clearable: true,
            prependInnerIcon: 'mdi-format-list-group',
            dense: true,
            items: payload?.categories,
            rules: items.length > 0 ? [] : [required],
            hidden: true,
          },
          sort_index: {
            type: 'number',
            label: 'Index',
            class: 'mx-1 mb-2',
            clearable: true,
            prependInnerIcon: 'mdi-format-list-numbered',
            disabled: true,
            dense: true,
            hidden: true,
          },
          default_value: {
            hidden: true,
            type: this.getTypeInput(item),
            label: 'DefaultValue',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-format-text',
          },
          folder_id: {
            type: 'autocomplete',
            subtype: 'folders',
            label: 'Folders',
            class: 'mx-1 mb-2',
            col: 12,
            clearable: true,
            complex: true,
            prependInnerIcon: 'mdi-folder-open',
            dense: true,
            items: foldersStore.folders,
            rules: items.length > 0 ? [] : [required],
          },
        },
      }

      if (!canACL('view', 'folders', { id: payload?.project_id })) {
        delete schema.col1.schema.general.schema.folder_id
      }

      schema.col2.schema.validation = {
        title: 'Validation',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'pa-3 ma-2',
        schema: {
          required: {
            type: 'checkbox',
            label: 'Required',
            indeterminate: item && item.required === undefined,
            col: { cols: 6, md: 4 },
          },
          single: {
            type: 'checkbox',
            label: 'Unique',
            indeterminate: item && item.single === undefined,
            col: { cols: 6, md: 4 },
          },
          readonly: {
            type: 'checkbox',
            label: 'ReadOnly',
            indeterminate: item && item.single === undefined,
            col: { cols: 6, md: 4 },
          },
          tableheader: {
            type: 'checkbox',
            label: 'Header',
            indeterminate: item && item.tableheader === undefined,
            col: { cols: 6, md: 4 },
          },
          hidden: {
            type: 'checkbox',
            label: 'Hidden',
            indeterminate: item && item.hidden === undefined,
            col: { cols: 6, md: 4 },
          },
          encrypt: {
            type: 'checkbox',
            label: 'Encrypt',
            class: 'mx-1 mb-2',
            indeterminate: item && item.encrypt === undefined,
            disabled: item?.created_at && item.encrypt && items.length === 0,
            col: { cols: 6, md: 4 },
          },
          regex: {
            hidden: item?.type !== 'text',
            type: 'text',
            label: 'Regex',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-regex',
            col: 12,
          },
          minimum: {
            type: 'number',
            label: 'Minimum',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-minus-thick',
            hidden: item && item.type !== 'number',
          },
          maximum: {
            type: 'number',
            label: 'Maximum',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-plus-thick',
            hidden: item && item.type !== 'number',
          },
          minimum_length: {
            type: 'number',
            label: 'MinimumLength',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-minus-thick',
            hidden: item && item.type !== 'text',
          },
          maximum_length: {
            type: 'number',
            label: 'MaximumLength',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-plus-thick',
            hidden: item && item.type !== 'text',
          },
        },
      }
      if (item?.type === 'array' || item?.appearance === 'dropdown' || item?.appearance === 'radioboxes') {
        schema.col2.schema.properties_items = {
          title: 'Options',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {
            view__properties_items: {
              disabled: !item?.id || !canACL('view', 'properties', { id: payload?.project_id }),
              type: 'btn',
              small: true,
              label: 'EditOptions',
              class: 'mb-3',
              iconLeft: 'mdi-pencil',
              color: 'primary',
              offset: 3,
              col: 6,
            },
          },
        }
      }

      if (item?.id) {
        schema.col3.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col3.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col3.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          label: 'UpdatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }

      return schema
    },

    getPropertyItem(propertyid, value, isTable) {
      const property = this.properties.find(p => p.id === propertyid || p.slug === propertyid)
      if (property && property.properties_items?.length > 0) {
        const array = []
        if (isJSON(value)) {
          const obj = JSON.parse(value)
          obj?.forEach(row => {
            const subitem = property.properties_items.find(item => item.id === row || item.id === row.id)
            if (subitem) array.push(subitem)
          })

          return sortBy(array, ['sort_index'])
        }
        if (value instanceof Array) {
          value.forEach(row => {
            const subitem = property.properties_items.find(item => item.id === row || item.id === row?.id)
            if (subitem) array.push(subitem)
          })
          const items = array.length > 0 ? sortBy(array, ['sort_index']) : null
          if (items && property.appearance === 'checkboxes' && !isTable) {
            const ids = []
            items.forEach(it => ids.push(it.id))

            return ids
          }

          return items
        }
        let subitem = property.properties_items.find(item => item.id === value)
        if (property.appearance === 'radioboxes' && !isTable) {
          subitem = subitem?.id
        }

        return subitem || value
      }

      return value
    },

    async onAction(action, payload) {
      return payload?.item
    },

    async getProperties(projectId, slug) {
      const { data, error } = await supabase
        .from('properties')
        .select('*, properties_items(*), models!inner(*)')
        .filter('project_id', 'eq', projectId)
        .filter('models.slug', 'eq', slug)
        .order('sort_index', { ascending: true })
      if (error) {
        return Promise.reject(error)
      }
      this.properties = data
      this.folders = {}
      data.forEach(p => {
        this.folders[p.folder_id] = true
      })
      this.folders = Object.keys(this.folders)

      return this.properties
    },

    async getPropertiesID(projectId, id) {
      const { data, error } = await supabase
        .from('properties')
        .select('*, properties_items(*)')
        .filter('project_id', 'eq', projectId)
        .filter('model_id', 'eq', id)
        .order('sort_index', { ascending: true })
      if (error) {
        return Promise.reject(error)
      }
      this.properties = data
      this.folders = {}
      data.forEach(p => {
        this.folders[p.folder_id] = true
      })
      this.folders = Object.keys(this.folders)

      return this.properties
    },

    async insertProperty(properties) {
      const { error } = await supabase.from('properties').upsert(properties)
      if (error) {
        return Promise.reject(error)
      }

      return true
    },

    async getCategories(projectId) {
      const { data, error } = await supabase.from('properties').select('category').eq('project_id', projectId)
      if (error) {
        return Promise.reject(error)
      }
      const categories = ['General', 'Details', 'Settings']
      data.forEach(item => {
        if (!categories.includes(item.category)) categories.push(item.category)
      })
      this.categories = categories.sort()

      return this.categories
    },

    async getIds(projectId) {
      const { data, error } = await supabase.from('properties').select('id').eq('project_id', projectId)
      if (error) {
        return Promise.reject(error)
      }
      const ids = []
      data.forEach(item => {
        ids.push(item.id)
      })
      this.ids = ids

      return ids
    },

    async showModelsDialog(projectId) {
      await this.getIds(projectId)
      this.isModels = true
    },

    isDisabledAppearance(list, item) {
      if (!item) return false
      if (
        item.type === 'text'
        && ['singleline', 'textarea', 'email', 'password', 'phone', 'url', 'slug', 'radioboxes', 'dropdown'].includes(
          list.value,
        )
      ) {
        return false
      }
      if (item.type === 'array' && ['checkboxes', 'combobox'].includes(list.value)) {
        return false
      }
      if (item.type === 'number' && ['slider', 'rating', 'number'].includes(list.value)) {
        return false
      }
      if (item.type === 'file' && ['file'].includes(list.value)) {
        return false
      }
      if (item.type === 'date' && ['date-picker', 'time-picker', 'date-time-picker'].includes(list.value)) {
        return false
      }
      if (item.type === 'color' && ['color-picker'].includes(list.value)) {
        return false
      }

      return !(item.type === 'boolean' && ['switch', 'simple-checkbox'].includes(list.value))
    },

    getTypeInput(item) {
      if (!item) return 'text'

      if (item.type === 'text') {
        if (item.appearance === 'email') {
          return 'email'
        }
        if (item.appearance === 'password') {
          return 'password'
        }

        return 'text'
      }
      if (item.type === 'number') {
        return 'number'
      }
      if (item.type === 'boolean') {
        return 'checkbox'
      }

      return item.appearance || 'text'
    },

    onChangeType(type, item) {
      if (!item) return false
      if (
        type === 'text'
        && !['singleline', 'textarea', 'email', 'password', 'phone', 'url', 'slug', 'radioboxes', 'dropdown'].includes(
          item.appearance,
        )
      ) {
        item.appearance = 'singleline'

        return false
      }
      if (type === 'array' && !['checkboxes', 'combobox'].includes(item.appearance)) {
        item.appearance = 'combobox'

        return false
      }
      if (type === 'number' && !['slider', 'rating', 'number'].includes(item.appearance)) {
        item.appearance = 'slider'

        return false
      }
      if (type === 'file' && !['file'].includes(item.appearance)) {
        item.appearance = 'file'

        return false
      }
      if (type === 'date' && !['date-picker', 'time-picker', 'date-time-picker'].includes(item.appearance)) {
        item.appearance = 'date-picker'

        return false
      }
      if (type === 'color' && !['color-picker'].includes(item.appearance)) {
        item.appearance = 'color-picker'

        return false
      }
      if (type === 'boolean' && !['switch', 'simple-checkbox'].includes(item.appearance)) {
        item.appearance = 'switch'

        return false
      }

      return true
    },

    onChangeName(name, item) {
      if (!item?.created_at) {
        if (name) {
          item.slug = slugify(name, {
            replacement: '-', // replace spaces with replacement character, defaults to `-`
            remove: /[^a-zA-Z0-9 ]/g, // remove characters that match regex, defaults to `undefined`
            lower: true, // convert to lower case, defaults to `false`
            strict: false, // strip special characters except replacement, defaults to `false`
            trim: true, // trim leading and trailing replacement chars, defaults to `true`
          })
        } else {
          // table.value.item.id = ''
        }
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePropertiesStore, import.meta.hot))
}
