<template>
  <v-app>
    <v-main>
      <slot></slot>
    </v-main>
    <div
      class="d-flex"
      style="float:right; right:0px ; height:0; top:30px; position: absolute;"
    >
      <app-bar-i18n></app-bar-i18n>
      <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
    </div>
  </v-app>
</template>

<script>
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'

export default {
  components: {
    AppBarI18n,
    AppBarThemeSwitcher,
  },
}
</script>

<style scoped>
</style>
