import isNumber from 'lodash/isNumber'
import { i18n } from '@/plugins/i18n'
import { isEmpty } from './index'

export const required = value => (value && !isEmpty(value) ? true : i18n.t('FieldRequired'))
export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || i18n.t('FieldEmailError')
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || i18n.t('FieldPasswordError')
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || i18n.t('FieldConfirmPasswordError')

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || i18n.t('FieldIntegerBetweenError', min, max)
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || i18n.t('FieldIntegerError')
}

export const minLen = length => v => (v && v.length >= length) || i18n.t('FieldMinLengthError', { length })

export const maxLen = length => v => (v && v.length <= length) || i18n.t('FieldMaxLengthError', { length })

export const regexValidator = regex => value => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || i18n.t('FieldRegexError')
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  return /^[A-Z]*$/i.test(String(value)) || i18n.t('FieldAlphaError')
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || i18n.t('FieldURLError')
}

export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || i18n.t('FieldAlphaError')
}

export const dateValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  return /^[0-9]{4}[\-]{1}[0-9]{2}[\-]{1}[0-9]{2}$/.test(String(value)) || i18n.t('FieldRegexError')
}

export const timeValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(String(value)) || i18n.t('FieldRegexError')
}

export const datetimeValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  return (
    /^[0-9]{4}[\-]{1}[0-9]{2}[\-]{1}[0-9]{2} ([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(String(value))
    || i18n.t('FieldRegexError')
  )
}

export const sanatize = (type, value) => {
  if (type === 'boolean' || type === 'checkbox' || type === 'simple-checkbox') {
    value = value === 'VRAI' || value === 'TRUE' || value === 'vrai' || value === 'true' || value === '1' || value === true
  } else if (type === 'number') {
    if (isNumber(value)) value = +value
  } else if (type === 'email') {
    if (emailValidator(value) !== true) return null
  }

  return value
}
