/* eslint-disable */
// prettier-ignore
export default [
  { header: 'Pages' },

  // dashboard
  { TOGGLE_CONTENT_OVERLAY: 'Dashboard', to: { name: 'dashboard' }, icon: 'mdi-home-outline' },
  { title: 'Profile', to: { name: 'account-settings' }, icon: 'mdi-account-outline'},
  { title: 'Projects', to: { name: 'projects' }, icon: 'mdi-folder-table' },
  { title: 'Organizations', to: { name: 'organizations' }, icon: 'mdi-home-city' },
]
