<template>
  <v-row v-observe-visibility="visibilityChanged"
         class="pa-3 mt-2"
  >
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.organization"
    ></alert-error>
    <confirm-dialog-delete
      :message="$t('DeleteProjectDetails')"
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingDelete"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>
    <v-col
      cols="12"
      lg="9"
    >
      <v-card>
        <v-card-title>
          <div>
            <h5 class="font-weight-bold text-uppercase">
              {{ $t("Projects") }}
            </h5>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            :disabled="!$canACL('create', 'projects', organization)"
            @click="$router.push({ name: 'create-project' })"
          >
            <v-icon
              :left="!$vuetify.breakpoint.xs"
            >
              mdi-folder-plus
            </v-icon>
            <span class="d-none d-sm-block">{{ $t("CreateProject") }}</span>
          </v-btn>
        </v-card-title>
        <v-divider class="mx-5"></v-divider>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex flex-grow-1  align-center pb-3">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              :placeholder="$t('SearchProject')"
              class="me-3 search-table"
              clearable
              prepend-inner-icon="mdi-folder-search"
              clear-icon="mdi-close"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-data-table
          v-model="selectedTableData"
          :headers="tableColumns"
          :items="listTable"
          :options.sync="options"
          :server-items-length="totalListTable"
          :loading="loading"
          class="text-no-wrap"
        >
          <template
            #[`item.name`]="{item}"
          >
            <div
              v-if="item.name"
              class="d-flex align-center"
            >
              <v-avatar
                size="30"
                color="primary"
                class="v-avatar-light-bg primary--text"
              >
                <v-img
                  v-if="item.image"
                  :src="item.image"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(item.name) }}</span>
              </v-avatar>

              <div
                class="d-flex flex-column ms-3"
              >
                <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.name }}</span>
                <span class="text-xs">{{ item.description }}</span>
              </div>
            </div>
          </template>

          <template #[`item.created_at`]="{item}">
            {{ $moment(item.created_at).format("lll") }}
          </template>
          <template #[`item.updated_at`]="{item}">
            {{ $moment(item.updated_at).format("lll") }}
          </template>

          <template #[`item.actions`]="{item}">
            <div class="d-flex align-center justify-center">
              <v-btn
                v-if="false"
                x-small
                outlined
                class="me-3"
                color="primary"
                :disabled="!$canACL('update', 'projects', organization)"
                @click="editDialog(item)"
              >
                <v-icon>
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
              <v-btn
                x-small
                outlined
                color="error"
                :disabled="!$canACL('delete', 'projects', organization)"
                @click="openDialogDelete(item)"
              >
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, getCurrentInstance, onBeforeMount } from 'vue'
import {
  required, emailValidator,
} from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import { setAbilities } from '@/plugins/acl/ability'
import AlertError from '@/components/AlertError.vue'
import { useProjectsStore } from '@/stores/projects.store'
import { useUserStore } from '@/stores/user.store'
import { useOrganizationsStore } from '@/stores/organizations.store'
import useProjectList from './useProjectList'

export default {
  components: {
    ConfirmDialogDelete,
    AlertError,
  },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const errors = ref({})
    const loadingDelete = ref(false)
    const form = ref(null)
    const item = ref(null)
    const isAlertVisible = ref(false)
    const vm = getCurrentInstance().proxy
    const projectsStore = useProjectsStore()
    const userStore = useUserStore()
    const organizationStore = useOrganizationsStore()
    const {
      listTable, searchQuery, tableColumns, options, totalListTable, loading, selectedTableData, isAddDialogVisible, isDeleteDialogVisible, fetchDatas,
    } = useProjectList(vm.$router.currentRoute.params.id)

    const visibilityChanged = isVisible => {
      if (isVisible) {
        fetchDatas()
      }
    }

    onBeforeMount(() => {
      tableColumns.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
    })

    const openDialogDelete = _item => {
      isDeleteDialogVisible.value = true
      item.value = _item
    }
    const editDialog = async _item => {
      userStore.getUser(userStore.user).then(async data => {
        userStore.user = data
        projectsStore.project = await projectsStore.getProject(_item.id)
        vm.$ability.update(setAbilities(_item.id))
        vm.$router.push({ name: 'settings', params: { id: _item.id, tab: 0 } })
      })
    }

    const confirmDelete = () => {
      if (!item.value) { return }
      loadingDelete.value = true
      projectsStore.deleteProject(item.value).then(() => {
        isDeleteDialogVisible.value = false
        loadingDelete.value = false
      }).catch(error => {
        loadingDelete.value = false
        isAlertVisible.value = true
        errors.value = {
          organization: error,
        }
      })
    }

    return {
      tableColumns,
      searchQuery,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,
      validators: {
        required,
        emailValidator,
      },
      isAddDialogVisible,
      loadingDelete,
      errors,
      isAlertVisible,
      form,
      avatarText,
      isDeleteDialogVisible,
      openDialogDelete,
      confirmDelete,
      editDialog,
      organizationStore,
      visibilityChanged,
    }
  },
}
</script>
