import { ref, watch } from 'vue'
import { useProjectsStore } from '@/stores/projects.store'

export default function useList() {
  const listTable = ref([])

  const searchQuery = ref('')
  const options = ref({
    page: ref(1),
    itemsPerPage: 5,
  })
  const totalListTable = ref(0)
  const loading = ref(true)
  const nbPages = ref(0)
  const page = ref(1)
  const projectsStore = useProjectsStore()

  const fetchDatas = () => {
    projectsStore
      .getProjects({ search: searchQuery.value, options: { page: page.value, itemsPerPage: 9 } })
      .then(data => {
        listTable.value = data.data
        totalListTable.value = data.count
        nbPages.value = Math.ceil(totalListTable.value / options.value.itemsPerPage)
        loading.value = false
      })
      .catch(() => {
        listTable.value = []
        totalListTable.value = 0
        nbPages.value = 0
        loading.value = false
      })
  }

  fetchDatas()
  watch([searchQuery, page], () => {
    loading.value = true
    fetchDatas()
  })

  projectsStore.$subscribe(async mutation => {
    if (mutation.payload) {
      const index = listTable.value.findIndex(item => item.id === mutation.payload.item.id)
      if (mutation.payload.eventType === 'UPDATE') {
        if (index !== -1) listTable.value.splice(index, 1, await projectsStore.getProject(mutation.payload.item.id))
      } else if (mutation.payload.eventType === 'INSERT') {
        if (index === -1) listTable.value.push(await projectsStore.getProject(mutation.payload.item.id))
      } else if (mutation.payload.eventType === 'DELETE') {
        if (index !== -1) listTable.value.splice(index, 1)
      }
    }
  })

  return {
    searchQuery,
    page,
    listTable,
    totalListTable,
    loading,
    fetchDatas,
    nbPages,
  }
}
