<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/valid-v-for -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-resize="onResize">
    <div ref="table">
      <v-data-table
        v-if="config.header.display === 'table'"
        v-model="items"
        :loading="loading"
        :headers="config.tableColumns.filter(c => !c.hidden)"
        :items="listTable"
        :options.sync="options"
        :server-items-length="totalTable ? totalTable : -1"
        show-select
        :item-key="config.tableOptions.itemKey || 'id'"
        :dense="config.tableOptions.dense"
        :multi-sort="config.tableOptions.multiSort || true"
        :disable-sort="config.tableOptions.disableSort || false"
        :items-per-page="config.tableOptions.itemsPerPage || 10"
        :height="height"
        :footer-props="{
          'items-per-page-options': [config.tableOptions.itemsPerPage, 10, 12, 20, 100, 1000, -1],
        }"
        checkbox-color="primary"
        class="text-no-wrap custom-table"
        :mobile-breakpoint="mobileBreakPoint"
        style="position: relative; z-index: 0"
        :fixed-header="config.tableOptions.fixedHeader || true"
      >
        <template #header.data-table-select="{ on, props }">
          <v-simple-checkbox v-bind="props"
                             v-on="on"
          />
        </template>
        <template v-for="col in config.tableColumns.filter(c => !c.hidden)"
                  #[`header.${col.value}`]="{ header }"
        >
          <div :key="'header-' + col.value"
               class="text-uppercase"
               style="display: inline-block; padding: 16px 0"
          >
            {{ $t(header.text) }}
            <v-icon v-if="header.search"
                    size="10"
                    color="primary"
                    class="pb-2"
            >
              mdi-magnify
            </v-icon>
          </div>

          <v-menu
            v-if="header.value === 'actions'"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="20"
          >
            <template #activator="{ on: onF, attrs: attrsF }">
              <v-btn
                icon
                small
                :color="isFiltred() ? 'primary': 'secondary'"
                v-bind="attrsF"
                style="float: right; margin: 0; padding-top: 17px"
                v-on="onF"
              >
                <v-icon> mdi-filter-outline </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('Filters') }}</v-list-item-title>
                    <v-list-item-subtitle></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      v-if="isFiltred()"
                      icon
                      color="primary"
                      @click="clearAll"
                    >
                      <v-icon> mdi-filter-off-outline </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <template v-for="col in config.tableColumns.filter(col => col.value !== 'actions')">
                  <v-list-item
                    :disabled="!col.filterable"
                  >
                    <v-list-item-title class="text-button cursor-pointer"
                                       @click="dialogFilter(col)"
                    >
                      {{ col.text }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-btn v-if="options.filters[col.value]?.length"
                             color="indigo"
                             icon
                             :disabled="!col.filterable"
                             @click="clearFilter(col)"
                      >
                        <v-icon
                          small
                          color="primary"
                        >
                          mdi-filter-off-outline
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-menu>
          <div
            v-if="header.filterable"
            :key="'filter-' + col.key"
            style="float: right; margin: 0; padding-top: 4px; position: absolute; top: 2px; left: -12px"
          >
            <v-menu
              :close-on-content-click="false"
              offset-y
              dense
              allow-overflow
              transition="slide-y-transition"
              content-class="table-filters"
            >
              <template #activator="{ on, attrs }">
                <v-btn color="indigo"
                       icon
                       v-bind="attrs"
                       v-on="on"
                       @click="initFilter(header)"
                >
                  <v-icon small
                          :color="options.filters[header.value]?.length ? 'primary' : 'secondary'"
                  >
                    mdi-filter-outline
                  </v-icon>
                </v-btn>
              </template>

              <v-progress-linear v-if="loadingFilters"
                                 indeterminate
              ></v-progress-linear>

              <v-card v-else
                      @on-change-filter="onChangeFilter"
              >
                <v-list-item>
                  <v-list-item-action>
                    <v-simple-checkbox v-model="selectAll"
                                       color="primary"
                    ></v-simple-checkbox>
                  </v-list-item-action>
                  <v-list-item-title class="d-flex">
                    <v-form @submit.prevent="addFilter">
                      <v-text-field
                        :value="searchFilters"
                        single-line
                        clearable
                        dense
                        class="mx-2"
                        style="width: 170px"
                        hide-details
                        :placeholder="$t('Search')"
                        @input="debounceInput"
                      >
                        <template #append-outer>
                          <v-icon v-if="searchFilters"
                                  class="cursor-pointer"
                                  color="primary"
                                  @click="addFilter"
                          >
                            mdi-plus-circle
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-form>
                    <v-chip
                      v-if="countSelected > 0"
                      style="position: absolute; right: 10px"
                      small
                      outlined
                      close
                      color="primary"
                      class="ml-1 mt-1"
                      @click:close="removeFilter()"
                    >
                      {{ countSelected }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <virtual-list
                  style="height: 200px; width: 320px; overflow-y: auto"
                  make
                  list
                  :data-key="'value'"
                  :data-sources="getItems()"
                  :data-component="filterItem"
                  @on-change-filter="onChangeFilter"
                />
              </v-card>
            </v-menu>
          </div>
        </template>

        <template v-for="header in config.tableColumns"
                  #[`item.${header.value}`]="{ item }"
        >
          <div :key="item.value"
               :class="totalTable ? 'cursor-pointer' : ''"
               @click="onEdit(item)"
          >
            <span v-if="header.type === 'drag'">
              {{ item[header.value] }}
              <v-icon
                v-if="!$vuetify.breakpoint.xs"
                :color="isDraggable() ? 'primary' : 'secondary'"
                small
                class="page__grab-icon"
              >
                mdi-arrow-all
              </v-icon>
            </span>
            <div v-else-if="header.type === 'actions'"
                 class="d-flex align-center justify-center pa-0 ma-0"
            >
              <v-btn
                v-if="!!totalTable"
                x-small
                :outlined="!config.tableOptions.dense"
                :icon="config.tableOptions.dense"
                class="me-2"
                color="primary"
                @click="onEdit(item)"
              >
                <v-icon v-if="$canACL('update', config.currentTable, { id: $router.currentRoute.params.id })">
                  mdi-pencil-outline
                </v-icon>
                <v-icon v-else>
                  mdi-magnify
                </v-icon>
              </v-btn>
              <v-btn
                v-if="!!totalTable"
                :disabled="
                  !canDelete(item) || !$canACL('delete', config.currentTable, { id: $router.currentRoute.params.id })
                "
                x-small
                :outlined="!config.tableOptions.dense"
                :icon="config.tableOptions.dense"
                color="error"
                @click="$emit('on-delete', item)"
              >
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>

              <v-btn
                v-if="!totalTable"
                :disabled="!$canACL('delete', config.currentTable, { id: $router.currentRoute.params.id })"
                x-small
                :outlined="!config.tableOptions.dense"
                :icon="config.tableOptions.dense"
                color="error"
                @click="onDeleteNoDatabase(item)"
              >
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>
            </div>
            <table-cell-item
              v-else
              :header="header"
              :item="item"
              :filter="false"
              :source="{
                type: header.type,
                value: item[header.value]?.name || item[header.value],
                text: item[header.value]?.name || item[header.value],
              }"
              @on-click="onClick"
            >
            </table-cell-item>
          </div>
        </template>
        <template v-if="isDraggable()"
                  #body="props"
        >
          <draggable :disabled="!isDraggable()"
                     :list="props.items"
                     tag="tbody"
                     @change="sortUpdated"
          >
            <tr
              v-for="(item, index) in props.items"
              :key="index"
              :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-table-row' : 'cursor-pointer'"
              @click="onEdit(item)"
            >
              <td style="border-bottom: 0px">
                <v-simple-checkbox
                  color="primary"
                  :value="
                    !!items.find(
                      it => it[config.tableOptions.itemKey || 'id'] === item[config.tableOptions.itemKey || 'id'],
                    )
                  "
                  @click="toggleSelect(item)"
                ></v-simple-checkbox>
              </td>
              <td
                v-for="header in config.tableColumns"
                :key="header.value"
                :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row' : ''"
              >
                <div v-if="$vuetify.breakpoint.xs"
                     class="v-data-table__mobile-row__header ml-4"
                >
                  {{ $t(header.text) }}
                </div>
                <div :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row__cell mr-4' : ''">
                  <span v-if="header.type === 'drag'">
                    {{ item[header.value] }}
                    <v-icon
                      v-if="!$vuetify.breakpoint.xs"
                      :color="isDraggable() ? 'primary' : 'secondary'"
                      small
                      class="page__grab-icon"
                    >
                      mdi-arrow-all
                    </v-icon>
                  </span>
                  <div v-else-if="header.type === 'actions'"
                       class="d-flex align-center justify-center"
                  >
                    <v-btn
                      v-if="!!totalTable"
                      x-small
                      :outlined="!config.tableOptions.dense"
                      :icon="config.tableOptions.dense"
                      class="me-2"
                      color="primary"
                      @click="onEdit(item)"
                    >
                      <v-icon v-if="$canACL('update', config.currentTable, { id: $router.currentRoute.params.id })">
                        mdi-pencil-outline
                      </v-icon>
                      <v-icon v-else>
                        mdi-magnify
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!!totalTable"
                      :disabled="
                        !canDelete(item) ||
                          !$canACL('delete', config.currentTable, { id: $router.currentRoute.params.id })
                      "
                      x-small
                      :outlined="!config.tableOptions.dense"
                      :icon="config.tableOptions.dense"
                      color="error"
                      @click="$emit('on-delete', item)"
                    >
                      <v-icon> mdi-delete-outline </v-icon>
                    </v-btn>

                    <v-btn
                      v-if="!totalTable"
                      :disabled="!$canACL('delete', config.currentTable, { id: $router.currentRoute.params.id })"
                      x-small
                      :outlined="!config.tableOptions.dense"
                      :icon="config.tableOptions.dense"
                      color="error"
                      @click="onDeleteNoDatabase(item)"
                    >
                      <v-icon> mdi-delete-outline </v-icon>
                    </v-btn>
                  </div>
                  <table-cell-item
                    v-else
                    :header="header"
                    :item="item"
                    :filter="false"
                    :source="{
                      type: header.type,
                      value: item[header.value]?.name || item[header.value],
                      text: item[header.value],
                    }"
                    @on-click="onClick"
                  >
                  </table-cell-item>
                </div>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
      <table-grid v-if="config.header.display === 'grid'"
                  :height="height"
                  :config.sync="config"
                  :options.sync="options"
                  :list-table.sync="listTable"
                  :loading.sync="loading"
                  :total-table.sync="totalTable"
                  :items.sync="items"
                  @on-edit="onEdit"
                  @on-click="onClick"
                  @toggle-select="toggleSelect"
      ></table-grid>
      <table-timeline v-if="config.header.display === 'timeline'"
                      :height="height"
                      :config.sync="config"
                      :options.sync="options"
                      :list-table.sync="listTable"
                      :loading.sync="loading"
                      :total-table.sync="totalTable"
                      :items.sync="items"
                      @on-edit="onEdit"
                      @on-click="onClick"
                      @toggle-select="toggleSelect"
      ></table-timeline>
    </div>
    <v-dialog v-model="isFilter"
              width="320"
              content-class="table-filters"
    >
      <v-progress-linear v-if="loadingFilters"
                         indeterminate
      ></v-progress-linear>

      <v-card v-else
              content-class="table-filters"
              @on-change-filter="onChangeFilter"
      >
        <v-list-item>
          <v-list-item-action>
            <v-simple-checkbox v-model="selectAll"
                               color="primary"
            ></v-simple-checkbox>
          </v-list-item-action>
          <v-list-item-title class="d-flex">
            <v-form @submit.prevent="addFilter">
              <v-text-field
                :value="searchFilters"
                single-line
                clearable
                dense
                class="mx-2"
                style="width: 170px"
                hide-details
                :placeholder="$t('Search')"
                @input="debounceInput"
              >
                <template #append-outer>
                  <v-icon v-if="searchFilters"
                          class="cursor-pointer"
                          color="primary"
                          @click="addFilter"
                  >
                    mdi-plus-circle
                  </v-icon>
                </template>
              </v-text-field>
            </v-form>
            <v-chip
              v-if="countSelected > 0"
              style="position: absolute; right: 10px"
              small
              outlined
              close
              color="primary"
              class="ml-1 mt-1"
              @click:close="removeFilter()"
            >
              {{ countSelected }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <virtual-list
          style="height: 200px; width: 320px; overflow-y: auto"
          make
          list
          :data-key="'value'"
          :data-sources="getItems()"
          :data-component="filterItem"
          @on-change-filter="onChangeFilter"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { useVModel } from '@vueuse/core'
import {
  getCurrentInstance, ref, watch, computed, onMounted,
} from 'vue'
import VirtualList from 'vue-virtual-scroll-list'
import debounce from 'lodash/debounce'
import { useTableStore } from '@/stores/table.store'
import { useFoldersStore } from '@/stores/folders.store'
import TableCellItem from '@/views/table/TableCellItem.vue'
import { useTemplatesStore } from '@/stores/templates.store'
import {
  TABLE_PROPERTIES, TABLE_FOLDERS, addDirectory, TABLE_MODELS, updateDirectory,
} from '@core/utils'
import TableFilterItem from '@/views/table/TableFilterItem.vue'
import TableGrid from '@/views/table/TableGrid.vue'
import TableTimeline from '@/views/table/TableTimeline.vue'
import { useFilesStore } from '@/stores/files.store'

export default {
  components: {
    Draggable,
    VirtualList,
    TableCellItem,
    TableGrid,
    TableTimeline,
  },
  props: {
    config: {
      type: Object,
      default: null,
      required: true,
    },
    options: {
      type: Object,
      default: null,
      required: true,
    },
    directory: {
      type: Array,
      default: () => [],
    },
    listTable: {
      type: Array,
      default: null,
    },
    listLocal: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    totalTable: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    searchQuery: {
      type: String,
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    item: {
      default: null,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const itemActions = ref(null)
    const tableStore = useTableStore()
    const foldersStore = useFoldersStore()
    const templatesStore = useTemplatesStore()
    const filesStore = useFilesStore()
    const item = useVModel(props, 'item', emit)
    const config = useVModel(props, 'config', emit)
    const options = useVModel(props, 'options', emit)
    const directory = useVModel(props, 'directory', emit)
    const isDelete = useVModel(props, 'isDelete', emit)
    const listTable = useVModel(props, 'listTable', emit)
    const listLocal = useVModel(props, 'listLocal', emit)
    const items = useVModel(props, 'items', emit)
    const loading = useVModel(props, 'loading', emit)
    const tableHeight = ref(0)
    const filterItems = ref([])
    const currentCol = ref(null)
    const loadingFilters = ref(false)
    const selectAll = ref(false)
    const searchFilters = ref(null)
    const mobileBreakPoint = ref(0)
    const isFilter = ref(false)

    let isFiltering = false

    onMounted(() => {
      // emit('on-refresh')
    })

    const onChangeFilter = () => {
      options.value.filters[currentCol.value] = filterItems.value.filter(it => it.isSelected)
      if (options.value.filters[currentCol.value].length === filterItems.value.length) {
        delete options.value.filters[currentCol.value]
      }
      config.value.tableOptions = options.value
      tableStore.config.tableOptions = options.value
      emit('on-refresh')
    }

    watch(selectAll, () => {
      if (selectAll.value !== null) {
        filterItems.value.forEach(it => {
          if (!it.isFiltred) {
            it.isSelected = selectAll.value
          }
        })
      }
    })

    watch(searchFilters, () => {
      isFiltering = true
      filterItems.value?.forEach(it => {
        if (searchFilters.value) {
          if (
            it.text.toLowerCase().indexOf(searchFilters.value.toLowerCase()) >= 0

          /* || vm.$t(it.text).toLowerCase().indexOf(searchFilters.value.toLowerCase()) >= 0 */
          ) {
            it.isFiltred = false
          } else it.isFiltred = true
        } else it.isFiltred = false
      })
      selectAll.value = null
      setTimeout(() => {
        isFiltering = false
      }, 50)
    })

    watch(
      filterItems,
      () => {
        if (!loadingFilters.value && !isFiltering) {
          onChangeFilter()
        }
        if (filterItems.value.find(it => it.isSelected === false && it.isFiltred === false)) {
          selectAll.value = null
        }
      },
      { deep: true },
    )

    const onEdit = async _item => {
      item.value = _item
      if (!props.totalTable) return

      if (config.value.currentTable === TABLE_FOLDERS) {
        updateDirectory(directory.value, directory.value.length - 1, { folder: _item })
      } else {
        addDirectory(directory.value, config.value.currentTable, _item, config.value, null)
        tableStore
          .getItem(_item?.id)
          .then(data => {
            updateDirectory(directory.value, directory.value.length - 1, { item: data })
            items.value = []
          })
          .catch(() => {})
      }
    }

    const onDeleteNoDatabase = _item => {
      let index = listTable.value.findIndex(i => i.id === _item.id)
      if (index > -1) {
        listTable.value.splice(index, 1)
      }

      index = listLocal.value.findIndex(i => i.id === _item.id)
      if (index > -1) {
        listLocal.value.splice(index, 1)
      }
    }

    const arrayMove = (arr, fromIndex, toIndex) => {
      const element = arr[fromIndex]
      arr.splice(fromIndex, 1)
      arr.splice(toIndex, 0, element)
      arr.forEach((it, index) => {
        // eslint-disable-next-line no-param-reassign
        it.sort_index = index
      })

      return arr
    }

    const sortUpdated = evt => {
      listTable.value = arrayMove(listTable.value, evt.moved.oldIndex, evt.moved.newIndex)
      if (!listLocal.value.length) {
        tableStore.upsert(listTable.value)
      }
    }
    const toggleSelect = it => {
      if (items.value && items.value.indexOf(it) > -1) {
        items.value.splice(items.value.indexOf(it), 1)
      } else {
        items.value.push(it)
      }
    }

    const isFiltred = () => {
      let b = false
      Object.keys(options.value.filters).forEach(i => {
        if (options.value.filters[i]?.length > 0) {
          b = true
        }
      })

      return b
    }

    const isDraggable = () => {
      const column = config.value.tableColumns.find(col => col.type === 'drag')
      if (
        !column
        || props.searchQuery
        || isFiltred()
        || (vm.$vuetify.breakpoint.xs && mobileBreakPoint.value !== '0')
        || Object.keys(config.value.matchQuery).length === 1
      ) {
        return false
      }

      return !!(
        config.value.tableOptions?.sortBy.length === 1
        && config.value.tableOptions?.sortBy.includes(column.value)
        && config.value.tableOptions?.sortDesc.includes(false)
      )
    }

    const canDelete = _it => {
      if (config.value.currentTable === TABLE_PROPERTIES) {
        return !!_it.custom
      }
      if (config.value.currentTable === TABLE_FOLDERS) {
        return !_it.root
      }
      if (config.value.currentTable === TABLE_MODELS) {
        return _it.slug !== 'contacts'
      }

      return true
    }

    /// ///////////////////
    // CUSTOM
    /// //////////////////

    const onResize = () => {
      if (config.value.isImport || config.value.isExport) {
        tableHeight.value = window.innerHeight - 450
      } else tableHeight.value = window.innerHeight - 325
    }

    const initFilter = async col => {
      isFiltering = true
      currentCol.value = col.value
      loadingFilters.value = true
      searchFilters.value = null
      selectAll.value = null
      filterItems.value = []
      if (props.totalTable) {
        filterItems.value = await tableStore.getDistincts(vm.$router.currentRoute.params.id, col)
      } else {
        const distincts = {}
        listLocal.value?.forEach(it => {
          distincts[it[col.value]] = true
        })
        Object.keys(distincts).forEach(k => {
          filterItems.value.push({
            text: `${k}`,
            value: k,
            type: col.type,
            isSelected: null,
            isFiltred: false,
          })
        })
      }
      options.value?.filters[col.value]?.forEach(filter => {
        let isHere = false
        filterItems.value.forEach(it => {
          if (it.value === filter.value) {
            it.isSelected = true
            isHere = true
          }
        })
        if (!isHere) filterItems.value.splice(0, 0, filter)
      })
      loadingFilters.value = false
      setTimeout(() => {
        isFiltering = false
      }, 50)
    }
    const dialogFilter = col => {
      isFilter.value = true
      initFilter(col)
    }

    const clearAll = () => {
      Object.keys(options.value.filters).forEach(i => {
        options.value.filters[i] = undefined
      })
      config.value.tableOptions = options.value
      tableStore.config.tableOptions = options.value
      emit('on-refresh')
    }
    const clearFilter = col => {
      options.value.filters[col.value] = undefined
      config.value.tableOptions = options.value
      tableStore.config.tableOptions = options.value
      emit('on-refresh')
    }
    const removeFilter = () => {
      filterItems.value.forEach(it => {
        it.isSelected = null
      })
      selectAll.value = null
    }
    const addFilter = () => {
      if (filterItems.value.find(it => it.value === searchFilters.value)) {
        return
      }
      filterItems.value.splice(0, 0, {
        text: searchFilters.value,
        value: searchFilters.value,
        isSelected: true,
        isFiltred: false,
      })
      searchFilters.value = null
      onChangeFilter()
    }

    const height = computed(() => {
      if (config.value.tableOptions.height >= 0) {
        if (config.value.tableOptions.height === 0) {
          return tableHeight.value
        }

        return config.value.tableOptions.height
      }

      return 0
    })
    const countSelected = computed(() => filterItems.value.filter(it => it.isSelected === true).length)

    const debounceInput = debounce(e => {
      searchFilters.value = e
    }, 250)

    const getItems = () => filterItems.value.filter(it => it.isFiltred === false)

    const getImageCol = () => config.value.tableColumns.find(h => h.type.indexOf('file') >= 0)?.value

    const onClick = async (val, _item) => {
      addDirectory(directory.value, config.value.currentTable, _item, config.value, null)
      emit('on-click', val, _item)
    }

    return {
      item,
      itemActions,
      tableHeight,
      onEdit,
      canDelete,
      onDeleteNoDatabase,
      onResize,
      options,

      isDelete,
      listTable,
      items,
      sortUpdated,
      toggleSelect,
      isDraggable,

      foldersStore,
      templatesStore,

      clearAll,
      onChangeFilter,
      isFiltred,
      initFilter,
      loadingFilters,
      loading,
      height,
      filterItems,
      selectAll,
      searchFilters,
      countSelected,
      removeFilter,
      addFilter,
      debounceInput,
      getItems,
      mobileBreakPoint,
      getImageCol,
      filesStore,
      onClick,
      isFilter,
      dialogFilter,
      clearFilter,
    }
  },
  data() {
    return {
      filterItem: TableFilterItem,
    }
  },
}
</script>
