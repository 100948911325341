<template>
  <v-row v-observe-visibility="visibilityChanged"
         class="pa-3 mt-2"
  >
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.token"
    ></alert-error>
    <v-col cols="12"
           md="10"
    >
      <v-card>
        <v-card-title>
          <div>
            <h5 class="font-weight-bold text-uppercase">
              {{ $t('Logs') }}
            </h5>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon
                 color="primary"
                 class="me-3"
                 :loading="loading"
                 :disabled="loading"
                 @click="fetchDatas()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mx-5"></v-divider>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex flex-grow-1 align-center pb-3">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              :placeholder="$t('Search')"
              class="me-3 search-table"
              clearable
              prepend-inner-icon="mdi-magnify"
              clear-icon="mdi-close"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-data-table
          v-model="selectedTableData"
          :headers="tableColumns"
          :items="listTable"
          :options.sync="options"
          :server-items-length="totalListTable"
          :loading="loading"
          :single-select="true"
          class="text-no-wrap"
        >
          <template #[`item.name`]="{ item }">
            <span class="d-block primary--text text-uppercase font-weight-semibold text-truncate">{{ item.name }}</span>
          </template>
          <template #[`item.message`]="{ item }">
            <vue-json-pretty :data="item.message" />
          </template>
          <template #[`item.status`]="{ item }">
            <table-cell-item
              :filter="false"
              :source="{
                type: 'chip-type',
                value: item.status,
                text: item.status,
              }"
            >
            </table-cell-item>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ $moment(item.created_at).format('lll') }}
          </template>
          <template #[`item.updated_at`]="{ item }">
            {{ $moment(item.updated_at).format('lll') }}
          </template>

          <template #[`item.actions`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-btn x-small
                     outlined
                     class="me-3"
                     color="primary"
                     @click="editDialog(item)"
              >
                <v-icon> mdi-magnify </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog v-model="isAddDialogVisible"
              max-width="450"
    >
      <v-card v-if="migration"
              class="pa-sm-6 pa-3"
      >
        <v-card-title class="justify-center text-h5 text-uppercase">
          {{ migration.name }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-form ref="form">
            <vue-json-pretty v-if="migration.message"
                             :data="migration.message"
            />
            <div class="mt-6 d-flex justify-center">
              <v-btn v-if="false"
                     :loading="loadingAction"
                     :disabled="loadingAction"
                     color="primary"
                     class="me-3"
                     type="submit"
              >
                {{ $t('Submit') }}
              </v-btn>
              <v-btn outlined
                     color="secondary"
                     @click="isAddDialogVisible = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref, getCurrentInstance, onBeforeMount } from 'vue'
import { required, emailValidator, sanatize } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import AlertError from '@/components/AlertError.vue'
import useMigrationList from '@/views/workspaces/settings/useMigrationList'
import VueJsonPretty from 'vue-json-pretty'
import TableCellItem from '@/views/table/TableCellItem.vue'
import { useWorkspacesMigrationsStore } from '@/stores/workspaces_migrations.store'
import { useUserStore } from '@/stores/user.store'

export default {
  components: {
    AlertError,
    VueJsonPretty,
    TableCellItem,
  },
  props: {},

  setup() {
    const errors = ref({})
    const loadingAction = ref(false)
    const form = ref(null)
    const migration = ref(null)
    const isAlertVisible = ref(false)
    const subscription = ref(null)
    const vm = getCurrentInstance().proxy
    const migrationsStore = useWorkspacesMigrationsStore()
    const userStore = useUserStore()

    const {
      listTable,
      searchQuery,
      tableColumns,
      options,
      totalListTable,
      loading,
      selectedTableData,
      isAddDialogVisible,
      fetchDatas,
    } = useMigrationList(vm.$router.currentRoute.params.id)

    const visibilityChanged = async isVisible => {
      if (isVisible) {
        fetchDatas()
        subscription.value = await migrationsStore.listenMigrations(vm.$router.currentRoute.params.id)
      } else {
        userStore.removeChannel(subscription.value)
      }
    }

    onBeforeMount(() => {
      tableColumns.forEach(element => {
        if (element.text) {
          // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
    })

    const editDialog = async _item => {
      isAddDialogVisible.value = true
      migration.value = _item
    }

    const parseBoolean = val => sanatize('boolean', val)

    return {
      fetchDatas,
      tableColumns,
      searchQuery,
      options,
      totalListTable,
      listTable,
      loading,
      loadingAction,
      selectedTableData,
      validators: {
        required,
        emailValidator,
      },
      isAddDialogVisible,
      errors,
      isAlertVisible,
      form,
      avatarText,
      migration,
      editDialog,
      visibilityChanged,
      parseBoolean,
    }
  },
}
</script>
