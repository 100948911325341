// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch } from 'vue'

export const TABLE_CONTACTS = 'contacts'
export const TABLE_PROPERTIES = 'properties'
export const TABLE_PROPERTIES_ITEMS = 'properties_items'
export const TABLE_MODELS = 'models'
export const TABLE_LISTINGS = 'listings'
export const TABLE_FOLDERS = 'folders'
export const TABLE_CAMPAIGNS = 'campaigns'
export const TABLE_TEMPLATES = 'templates'
export const TABLE_APPS = 'apps'
export const TABLE_INTEGRATIONS = 'integrations'
export const TABLE_ACTIVITIES = 'activities'
export const TABLE_LOGS = 'logs'
export const TABLE_TASKS = 'tasks'
export const TABLE_WORKFLOWS = 'workflows'
export const TABLE_OBJECTS = 'objects'
export const TABLE_FORMS = 'forms'
export const TABLE_FILES = 'files'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true
  }

  return false
}

export const getVuetify = () => {
  const ins = getCurrentInstance()?.proxy

  return ins && ins.$vuetify ? ins.$vuetify : null
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy

  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const isEmpty = value => {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

// ——— Get Initial Text from name ——————— //

export const getInitialName = fullName =>
  // eslint-disable-next-line implicit-arrow-linebreak
  fullName
    .split(' ')
    .map(n => n[0])
    .join('')

// ——— Add Alpha To color ——————— //
export const addAlpha = (color, opacity) => {
  const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return color + opacityLocal.toString(16).toUpperCase()
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //
export const psScrollToBottom = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = scrollEl.scrollHeight
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //
export const psScrollToTop = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = 0
}

// ————————————————————————————————————
//* ——— Color Manipulations
// ————————————————————————————————————

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const rgbToHex = (r, g, b) => {
  const componentToHex = c => {
    const hex = c.toString(16)

    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  /* eslint-disable indent */
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
  /* eslint-enable */
}
export const getObjectKeys = (obj, prefix = '') => Object.entries(obj).reduce((collector, [key, val]) => {
  const newKeys = [...collector, prefix ? `${prefix}.${key}` : key]
  if (Object.prototype.toString.call(val) === '[object Object]') {
    const newPrefix = prefix ? `${prefix}.${key}` : key
    const otherKeys = getObjectKeys(val, newPrefix)

    return [...newKeys, ...otherKeys]
  }

  return newKeys
}, [])

export const isJSON = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }

  return true
}
export const addDirectory = (dir, table, item, config, payload) => {
  if (!dir.find(it => it.item && it.item.id === item?.id && it.table === table && it.editor === payload?.editor)) {
    if (dir.length - 1 >= 0) {
      dir[dir.length - 1].config = config ? JSON.parse(JSON.stringify(config)) : config
    }
    dir.push({
      table, item, editor: payload?.editor, disabled: payload?.disabled,
    })
  }
}
export const updateDirectory = (dir, index, payload) => {
  if (index !== -1 && dir.length - 1 >= index) {
    const data = JSON.parse(JSON.stringify(dir[index]))
    Object.keys(payload).forEach(key => { data[key] = payload[key] })
    dir.splice(index, 1, data)
  }
}
export const getPathFromDir = dir => {
  const path = []
  dir.forEach(it => {
    path.push({ t: it.table, e: it.editor || '', i: it.item?.id })
  })

  return JSON.stringify(path)
}
export const getLastItemID = dir => {
  let item = null
  dir.forEach((it, index) => {
    if (it.item && index !== (dir.length - 1)) item = it.item
  })

  return item?.id || item?.model_id
}

export const getLastFolderID = dir => {
  let folder = null
  dir.forEach(it => {
    if (it.folder) folder = it.folder
  })

  return folder?.id
}

export const displayMode = (table, defaultValue) => {
  if (localStorage.getItem(`display-${table}`) === null) { localStorage.setItem(`display-${table}`, defaultValue) }

  return localStorage.getItem(`display-${table}`)
}
