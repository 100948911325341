import { defineStore, acceptHMRUpdate } from 'pinia'
import { faker } from '@faker-js/faker'
import sortBy from 'lodash/sortBy'
import chunk from 'lodash/chunk'
import { hcaptcha } from '@/plugins/hcaptcha'
import { supabase } from '@/plugins/supabase'
import { useChatsStore } from '@/stores/chats.store'
import { useListingsStore } from '@/stores/listings.store'
import { useFoldersStore } from '@/stores/folders.store'
import { getParent } from '@core/utils/folders'
import {
  required,
  emailValidator,
  urlValidator,
  regexValidator,
  minLen,
  maxLen,
  between,
  dateValidator,
  timeValidator,
  datetimeValidator,
} from '@core/utils/validation'
import { canACL } from '@/plugins/acl/checkaccess'
import { TABLE_CONTACTS, displayMode } from '@core/utils'
import axios from '@axios'
import { useModelsStore } from './models.store'
import { usePropertiesStore } from './properties.store'

// eslint-disable-next-line import/prefer-default-export
export const useContactsStore = defineStore({
  id: TABLE_CONTACTS,
  state: () => ({}),
  actions: {
    getHeader(payload, item) {
      return {
        title: 'Contacts',
        icon: 'mdi-account-group',
        name: ['properties->>firstname', 'properties->>lastname'],
        folder: payload?.folder,
        display: displayMode(TABLE_CONTACTS, 'table'),
        newData: 'AddContact',
        actionData: 'EditContacts',
        editData: 'EditContact',
        search: 'SearchContact',
        searchIcon: 'mdi-account-search',
        add: {
          text: 'AddContact',
          icon: 'mdi-account-plus',
        },
        actions: [
          {
            title: 'Save',
            icon: 'mdi-content-save-outline',
            action: 'save',
            loading: false,
            acl: 'create,update',
          },
          {
            title: 'Duplicate',
            icon: 'mdi-content-duplicate',
            action: 'duplicate',
            acl: 'create',
            disabled: !item?.created_at,
          },
          {
            title: 'Delete',
            icon: 'mdi-delete-outline',
            action: 'delete',
            acl: 'delete',
            color: 'error',
            disabled: !item?.created_at,
          },
        ],
        menu: [
          {
            title: 'Activities',
            icon: 'mdi-timeline-check-outline',
            action: 'activities',
            loading: false,
          },
        ],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['updated_at'],
        sortDesc: [true],
        dense: false,
        multiSort: false,
        disableSort: false,
        itemsPerPage: 10,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        height: 0,
        page: 1,
        filters: {},
      }
    },

    getTableColumns(payload) {
      const columns = []
      payload?.properties.forEach(property => {
        columns.push({
          text: property.name,
          // eslint-disable-next-line prefer-template
          value: !property.encrypt ? 'properties->>' + property.slug : 'properties_crypted->>' + property.slug,
          filterable: true,
          type: property.type,
          search: !property.encrypt && property.type === 'text',
          appearance: property.appearance,
          minimum: property.minimum,
          maximum: property.maximum,
          hidden: property.hidden || !property.tableheader,
        })
      })

      columns.push({
        text: 'UpdatedAt',
        value: 'updated_at',
        type: 'date',
      })

      columns.push({
        text: 'Action',
        value: 'actions',
        align: 'center',
        type: 'actions',
        sortable: false,
      })

      return columns
    },

    getModel(payload) {
      const model = {}
      model.project_id = payload?.project_id
      model.folder_id = payload?.folder_id
      model.listings = []
      model.properties = {}
      model.properties_crypted = {}

      payload?.properties?.forEach(property => {
        if (property.encrypt) {
          model.properties_crypted[property.slug] = null
        } else model.properties[property.slug] = null
      })

      return model
    },

    getSchemaItem(item, items, payload, property, folder) {
      return {
        disabled: !canACL('update', 'contacts', { id: payload?.project_id }),
        type: this.getType(property),
        ext: this.getExt(property),
        label: `${property.name || ''}`,
        col: 12,
        class:
          property.appearance !== 'phone'
          && property.appearance !== 'color-picker'
          && property.appearance !== 'checkboxes'
          && property.appearance !== 'radioboxes'
            ? 'mx-1 mb-2'
            : 'mx-1',
        rules: this.getRules(items, property),
        hidden: !!items.length && property.single,
        items: this.getItems(property),
        options: this.getOptions(property),
        min: property.minimum,
        max: property.maximum > 0 ? property.maximum : 5,
        minimum_length: property.minimum_length,
        maximum_length: property.maximum_length,
        row: !(property.properties_items.length <= 3 || property.name?.length > 20),
        multiple: property.type === 'array',
        'item-value': 'id',
        'item-text': 'name',
        clearable: true,
        text: {
          clearable: true,
          prependIcon: 'mdi-timer',
        },
        folder,
      }
    },

    getSchemaProperty(property, isBuilder) {
      if (!property) {
        return null
      }

      const propertiesStore = usePropertiesStore()
      const item = propertiesStore.properties?.find(p => p.id === property.id)
      const schema = {
        type: this.getType(item || property),
        ext: this.getExt(item || property),
        placeholder: property.placeholder || item?.placeholder,
        label: `${property.name || item?.name || ''}`,
        col: 12,
        color: property.color || item?.color,
        outlined: property.outlined || item?.outlined,
        dark: property.dark || item?.dark,
        'hide-details': false,
        class: property.class || item?.class,
        items: this.getItems(item || property),
        options: this.getOptions(item || property),
        min: property.minimum || item?.minimum,
        max: property.maximum > 0 ? property.maximum : 5,
        minimum_length: property.minimum_length || item?.minimum_length,
        maximum_length: property.maximum_length || item?.maximum_length,
        multiple: property.type === 'array' || item?.type === 'array',
        'item-value': 'id',
        'item-text': 'name',
        rules: !isBuilder ? this.getRules(undefined, property) : [],
        sitekey: !isBuilder && property.appearance === 'captcha' ? hcaptcha : null,
      }

      return schema
    },

    getSchema(item, items, payload) {
      const chatsStore = useChatsStore()
      const listingsStore = useListingsStore()
      const foldersStore = useFoldersStore()
      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }
      payload?.folders.forEach((folder, index) => {
        const group = payload?.properties.filter(p => p.folder_id === folder)

        // eslint-disable-next-line no-mixed-operators, prefer-template
        const id = 'col' + ((index % 2) + 1)

        if (group.length) {
          schema[id].schema[folder] = {
            title: getParent(folder, foldersStore.folders),
            type: 'wrap',
            outlined: true,
            shaped: true,
            col: 12,
            ripple: false,
            class: 'pa-3 ma-2',
            schema: {},
          }
          group.forEach(property => {
            schema[id].schema[folder].schema[
              // eslint-disable-next-line prefer-template
              !property.encrypt ? 'properties->>' + property.slug : 'properties_crypted->>' + property.slug
            ] = this.getSchemaItem(item, items, payload, property, folder)
          })
        }
      })

      if (item?.id) {
        schema.col3.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col3.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col3.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          label: 'UpdatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }

      if (canACL('view', 'activities', { id: payload?.project_id })) {
        schema.col3.schema.activities = {
          title: 'Activities',
          type: 'wrap',
          col: 12,
          outlined: true,
          shaped: true,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {
            view__activities: {
              disabled: !canACL('view', 'activities', { id: payload?.project_id }),
              type: 'btn',
              small: true,
              label: 'ViewActivities',
              class: 'mb-3 mx-2',
              outlined: true,
              iconLeft: 'mdi-timeline-check-outline',
              color: 'primary',
              col: 6,
            },
          },
        }
      }
      if (canACL('view', 'listings', { id: payload?.project_id })) {
        schema.col2.schema.listing = {
          title: 'Listings',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'ma-2',
          schema: {
            edit_listings: {
              type: 'btn',
              disabled: !canACL('update', 'listings', { id: payload?.project_id }),
              col: { cols: 2, md: 1 },
              class: 'ma-3',
              'x-small': true,
              outlined: true,
              label: 'EditListings',
              iconCenter: 'mdi-format-list-group-plus',
              color: 'primary',
            },
            listings: {
              type: 'table',
              label: 'Listings',
              'item-value': 'id',
              'item-text': 'name',
              col: 12,
              multiple: true,
              readonly: true,
              headers: [
                {
                  text: 'Name',
                  value: 'name',
                },
                {
                  text: 'Type',
                  value: 'mode',
                },
              ],
              items: listingsStore.listings,
            },
          },
        }
      }

      return schema
    },

    getRules(items, property) {
      const rules = []
      if (property.appearance === 'email') {
        rules.push(emailValidator)
      }
      if (property.appearance === 'url') {
        rules.push(urlValidator)
      }
      if (property.appearance === 'date-picker') {
        rules.push(dateValidator)
      }
      if (property.appearance === 'time-picker') {
        rules.push(timeValidator)
      }
      if (property.appearance === 'date-time-picker') {
        rules.push(datetimeValidator)
      }
      if ((!items || items.length === 0) && property.required) {
        rules.push(required)
      }
      if (property.regex) {
        rules.push(regexValidator(property.regex))
      }
      if (property.minimum_length) {
        rules.push(minLen(property.minimum_length))
      }
      if (property.maximum_length) {
        rules.push(maxLen(property.maximum_length))
      }
      if (property.maximum && property.minimum) {
        rules.push(between(property.minimum, property.maximum))
      }

      return rules
    },

    getExt(property) {
      if (property.type === 'number') {
        return 'number'
      }

      return null
    },

    getType(property) {
      if (property.appearance === 'date-picker') {
        return 'date-picker'
      }
      if (property.appearance === 'color-picker') {
        return 'color-picker'
      }
      if (property.appearance === 'time-picker') {
        return 'time-picker'
      }
      if (property.appearance === 'date-time-picker') {
        return 'date-time-picker'
      }
      if (property.appearance === 'combobox') {
        return 'combobox'
      }
      if (property.appearance === 'textarea') {
        return 'textarea'
      }
      if (property.appearance === 'dropdown') {
        return 'combobox'
      }
      if (property.appearance === 'rating') {
        return 'rating'
      }
      if (property.appearance === 'slider') {
        return 'slider'
      }
      if (property.appearance === 'file') {
        return 'file'
      }
      if (property.appearance === 'phone') {
        return 'phone'
      }
      if (property.appearance === 'radioboxes') {
        return 'radio'
      }
      if (property.appearance === 'checkboxes') {
        return 'checks'
      }
      if (property.appearance === 'button') {
        return 'btn'
      }

      return property.appearance
    },

    getItems(property) {
      const items = []
      property?.properties_items?.forEach(it => {
        items.push(it)
      })

      return sortBy(items, ['sort_index'])
    },

    getOptions(property) {
      const options = []
      property?.properties_items?.forEach(it => {
        options.push({ value: it.id, label: it.name })
      })
      if (property.appearance === 'radioboxes' && !property?.properties_items.find(p => p.id === '')) {
        options.push({ value: '', label: 'N/A' })
      }

      return options
    },
    async onAction(action, payload) {
      return payload?.item
    },

    async test(id) {
      const modelsStore = useModelsStore()
      try {
        const newContacts = []
        for (let i = 1; i <= 5000; i += 1) {
          const c = {
            properties: {
              firstname: faker.name.firstName(),
              lastname: faker.name.lastName(),
              phone: faker.phone.phoneNumber('+33 6 ## ## ## ##'),
              company: faker.company.companyName(),
              'job-title': faker.name.jobTitle(),

              // photo: i < 90 ? `https://randomuser.me/api/portraits/${i % 2 === 0 ? 'men' : 'women'}/${i}.jpg` : null,
            },
          }
          c.properties.email = faker.internet
            .email(c.properties.firstname, c.properties.lastname)
            .toLowerCase().replace('@', `+${i}@`)
          newContacts.push(c)
        }
        const chunked = chunk(newContacts, 1000)
        let messages = { inserts: 0, updates: 0 }

        // eslint-disable-next-line no-restricted-syntax
        for (const tab of chunked) {
          try {
          // eslint-disable-next-line no-await-in-loop
            const data = await axios.post('upsert-objects', {
              objects: tab,
              project_id: id,
              model_id: modelsStore?.model?.id,
            })
            if (data && data.success === false) {
              return Promise.reject(data)
            }
            messages = { inserts: messages.inserts + data.inserts, updates: messages.updates + data.updates }
          } catch (error) {
            return Promise.reject(error.response.data)
          }
        }

        return messages
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getContacts(projectId, fields, search) {
      const query = supabase.from('contacts').select('*').eq('project_id', projectId)

      const filters = []
      fields.split(',').forEach(field => {
        filters.push(`${field}.ilike."%${search}%"`)
        if (search) {
          search.split(' ').forEach(s => {
            filters.push(`${field}.ilike."%${s}%"`)
          })
        }
      })
      query.or(filters.join(','))

      const { data, error } = await query

      if (error) {
        return Promise.reject(error)
      }
      data.forEach(d => {
        const vals = []
        fields.split(',').forEach(field => {
          const splitted = field.split('->>')
          vals.push(d[splitted[0]][splitted[1]])
        })
        d.name = vals.join(' ')
      })

      return data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContactsStore, import.meta.hot))
}
