// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
  VDatePicker,
  VTimePicker,
  VMenu,
  VCard,
  VAutocomplete,
  VColorPicker,
  VCombobox,
  VSimpleCheckbox,
  VSwitch,
  VTextarea,
  VRadio,
  VRating,
  VRadioGroup,
  VDataTable,
} from 'vuetify/lib'
import {
  Ripple, Intersect, Touch, Resize,
} from 'vuetify/lib/directives'
import fr from 'vuetify/lib/locale/fr'
import en from 'vuetify/lib/locale/en'
import preset from '@/@core/preset/preset'

Vue.component('PhoneNumber', () => import('@/components/PhoneNumber.vue'))
Vue.component('DatePicker', () => import('@/components/DatePicker.vue'))
Vue.component('TimePicker', () => import('@/components/TimePicker.vue'))
Vue.component('DateTimePicker', () => import('@/components/DateTimePicker.vue'))
Vue.component('ColorPicker', () => import('@/components/ColorPicker.vue'))
Vue.component('Rating', () => import('@/components/Rating.vue'))

Vue.use(Vuetify, {
  components: {
    VRow,
    VTooltip,
    VCol,
    VTextField,
    VCheckbox,
    VSelect,
    VDatePicker,
    VTimePicker,
    VMenu,
    VCard,
    VAutocomplete,
    VColorPicker,
    VCombobox,
    VSimpleCheckbox,
    VSwitch,
    VTextarea,
    VRadio,
    VRating,
    VRadioGroup,
    VDataTable,
  },
  directives: {
    Ripple,
    Intersect,
    Touch,
    Resize,
  },
})

export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: localStorage.getItem('locale') || 'en',
  },
  preset,
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
