<template>
  <div>
    <div>
      <v-snackbar
        v-model="isFormSubmittedSnackbarVisible"
        shaped
        outlined
        text
        color="primary"
        top
        right
      >
        {{ $t('ProjectCreatedSuccessfully') }}

        <template #action="{ attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            @click="isFormSubmittedSnackbarVisible = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-stepper
      v-model="activeStep"
      class="custom-header"
    >
      <!-- Header -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
          :rules="[() => stepValidationResult[1]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3"
              :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
            >01</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
              >{{ $t('ProjectDetails') }}</span>
              <span
                class="text-xs"
                :class="stepValidationResult[1] ? 'text--secondary' : 'error--text'"
              >{{
                $t('SetupProjectDetails')
              }}</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <v-form
            ref="step1Form"
            class="pb-6 pt-2"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-img
                      v-if="projectData.project.image"
                      :src="projectData.project.image"
                      size="180"
                      class="me-3"
                    ></v-img>
                    <v-avatar
                      v-else
                      color="primary"
                      class="v-avatar-light-bg primary--text me-3"
                      rounded
                      size="180"
                    >
                      <span class="font-weight-semibold text-5xl">{{ avatarText(projectData.project.name) }}</span>
                    </v-avatar>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <div>
                      <v-btn
                        small
                        color="primary"
                        class="me-3 mt-5"
                        :loading="loadingUpload"
                        :disabled="loadingUpload"
                        @click="uploadClick"
                      >
                        <v-icon class="d-sm-none">
                          mdi-cloud-upload-outline
                        </v-icon>
                        <span class="d-none d-sm-block">{{ $t('UploadPicture') }}</span>
                      </v-btn>

                      <input
                        ref="refInputEl"
                        type="file"
                        accept=".jpeg,.png,.jpg,GIF"
                        :hidden="true"
                        @change="handleFileSelected"
                      />

                      <v-btn
                        small
                        color="error"
                        outlined
                        class="mt-5"
                        @click.prevent="resetImage"
                      >
                        {{ $t('Reset') }}
                      </v-btn>
                      <p class="text-sm mt-5">
                        {{ $t('AllowedImageFormats') }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="py-2"
                  >
                    <v-text-field
                      v-model="projectData.project.name"
                      :label="$t('Name')"
                      outlined
                      dense
                      hide-details="auto"
                      prepend-inner-icon="mdi-folder-table"
                      :rules="[validators.required, v => (v || '' ).length <= 25 || $t('FieldMaxLength', {length: 25})]"
                      :placeholder="$t('Name')"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-2"
                  >
                    <v-textarea
                      v-model="projectData.project.description"
                      :rules="[v => (v || '' ).length <= 120 || $t('FieldMaxLength', {length: 120})]"
                      :label="$t('Description')"
                      rows="2"
                      outlined
                      clearable
                      dense
                      hide-details="auto"
                      :placeholder="$t('Description')"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-2"
                  >
                    <v-select
                      v-model="projectData.project.organization"
                      :label="$t('Organization')"
                      :placeholder="$t('Organization')"
                      prepend-inner-icon="mdi-home-city"
                      :items="organizationsStore.organizations"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      hide-details
                      :rules="[validators.required]"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-1"
                  >
                    <v-select
                      v-model="projectData.project.locales"
                      :label="$t('Locales')"
                      chips
                      multiple
                      :placeholder="$t('Locales')"
                      prepend-inner-icon="mdi-translate"
                      :items="locales"
                      outlined
                      dense
                      hide-details
                      :rules="[validators.required]"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-1"
                  >
                    <v-autocomplete
                      v-model="projectData.project.timezone"
                      :label="$t('TimeZone')"
                      item-text="text"
                      item-value="text"
                      prepend-inner-icon="mdi-clock-outline"
                      :items="timezones"
                      outlined
                      dense
                      :rules="[validators.required]"
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-1"
                  >
                    <v-switch
                      v-model="projectData.project.quickstart"
                      :label="$t('QuickstartTemplates')"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      v-if="!organizationsStore.organizations || organizationsStore.organizations.length === 0"
                      color="primary"
                      text
                      class="my-0 mt-3"
                      @click="$router.push({ name: 'create-organization' })"
                    >
                      {{ $t('CreateOrganization') }}
                    </v-btn>
                    <v-alert
                      v-if="
                        projectData.project.organization &&
                          !$canACL('create', 'projects', { id: projectData.project.organization })
                      "
                      border="left"
                      colored-border
                      text
                      color="primary"
                      class="subtitle-2 mt-3"
                    >
                      {{ $t('PermissionDeniedProject') }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-alert
                  v-if="errors.project"
                  border="left"
                  colored-border
                  text
                  color="error"
                  class="subtitle-2"
                >
                  {{ errors.project }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-space-between">
            <v-btn
              outlined
              @click="$router.push('/projects')"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              :disabled="!$canACL('create', 'organizations', { id: projectData.project.organization }) || loadingStepOne"
              color="primary"
              class="me-2"
              :loading="loadingStepOne"
              @click="handleStepValidation(1, true, true)"
            >
              {{ $t('Complete') }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { useOrganizationsStore } from '@/stores/organizations.store'
import { useProjectsStore } from '@/stores/projects.store'
import { useUserStore } from '@/stores/user.store'
import timezones from '@/views/projects/settings/timezones.json'
import { avatarText } from '@core/utils/filter'
import {
  alphaDashValidator,
  alphaValidator,
  confirmedValidator,
  required,
  urlValidator,
} from '@core/utils/validation'
import { uuidv7 } from 'uuidv7'
import { appConfig } from '@/plugins/micleoConfig'
import {
  getCurrentInstance,
  onMounted, onUnmounted,
  ref,
} from 'vue'

export default {
  metaInfo: {
    title: 'Create Project',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
  },
  setup() {
    const activeStep = ref(1)
    const loadingUpload = ref(false)
    const loadingStepOne = ref(false)
    const loadingCreateProject = ref(false)
    const subscription = ref(null)
    const errors = ref({})
    const locales = ref(['fr', 'en', 'it', 'es', 'de'])
    locales.value.sort()

    const vm = getCurrentInstance().proxy

    const isFormSubmittedSnackbarVisible = ref(false)

    const step1Form = ref(null)

    const stepValidationResult = ref({
      1: true,
    })

    const userStore = useUserStore()
    const projectsStore = useProjectsStore()
    const organizationsStore = useOrganizationsStore()

    const getDefaultOrganization = () => {
      if (organizationsStore.organizations) {
        const tab = organizationsStore.organizations.filter(organization => vm.$canACL('create', 'projects', organization))
        if (tab.length) {
          return tab[0].id
        }
      }

      return null
    }

    const projectData = ref({
      project: {
        id: uuidv7(),
        name: '',
        description: '',
        organization: getDefaultOrganization(),
        image: '',
        quickstart: true,
        subscription: {
          price: {
            product: {},
          },
        },
        interval: 'month',
      },
    })

    const uploadClick = () => {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      vm.$refs.refInputEl.dispatchEvent(event)
    }

    const handleFileSelected = async e => {
      loadingUpload.value = true
      if (
        e.target.files.length > 0
        && (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png')
      ) {
        projectData.value.project.image = ''
        projectsStore.uploadProjectCover(projectData.value.project.id, e.target.files[0])
          .then(async () => {
            const url = await projectsStore.getProjectCover(projectData.value.project.id, e.target.files[0])
            projectData.value.project.image = url.publicUrl
            loadingUpload.value = false
          })
          .catch(async () => {
            const url = await projectsStore.getProjectCover(projectData.value.project.id, e.target.files[0])
            projectData.value.project.image = url.publicUrl
            loadingUpload.value = false
          })
      } else {
        errors.value = {
          image: 'Allowed JPG, GIF or PNG',
        }
      }
    }

    const resetImage = () => {
      projectData.value.project.image = ''
    }

    // Step Validation
    const handleStepValidation = async (step, navigateNext = true, isFinalStep = false) => {
      // Get Form templateRef of Step from argument
      const templateRef = (() => {
        if (step === 1) return step1Form.value

        return null
      })()

      // If no validate step templateRef is found just return
      if (!templateRef) return

      // If step is valid proceed to the next step
      const isStepValid = templateRef.validate()

      if (projectData.value.project.organization) {
        subscription.value = await organizationsStore.listenOrganization(projectData.value.project.organization)
      }

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid

      if (isStepValid) {
        if (navigateNext && !isFinalStep) activeStep.value += 1
        else if (!navigateNext) activeStep.value -= 1
      }

      if (isFinalStep && isStepValid) {
        errors.value = {}
        loadingStepOne.value = true
        projectsStore.createProject(projectData.value.project)
          .then(() => {
            isFormSubmittedSnackbarVisible.value = true
            setTimeout(() => vm.$router.push({ name: 'projects' }), 500)
          })
          .catch(error => {
            loadingStepOne.value = false
            errors.value = {
              project: error.message,
            }
            if (error.response && error.response.data.error.code) {
              errors.value = {
                project: error.response.data.error.code,
              }
            }
          })
      }
    }

    onMounted(async () => {
      vm.$refs.refInputEl = vm.$el.querySelector('input[type=file]')
      vm.$refs.refInputEl.style.display = 'none'
      organizationsStore.getOrganizations()
      organizationsStore.getPrices()
      projectData.value.project.organization = getDefaultOrganization()
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    return {
      userStore,
      projectsStore,
      organizationsStore,
      projectData,
      getDefaultOrganization,
      handleFileSelected,
      handleStepValidation,
      resetImage,
      uploadClick,
      activeStep,
      loadingUpload,
      loadingStepOne,
      loadingCreateProject,
      errors,
      avatarText,

      // Field Validators
      validators: {
        required,
        alphaDashValidator,
        confirmedValidator,
        alphaValidator,
        urlValidator,
      },

      // Step Validations
      stepValidationResult,

      // Template Refs
      step1Form,

      // Snackbar
      isFormSubmittedSnackbarVisible,
      locales,
      timezones,
    }
  },
}
</script>
