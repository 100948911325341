<template>
  <layout-content-vertical-nav
    :nav-menu-items="menuItems"
  >
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            mdi-menu
          </v-icon>
          <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-project></app-bar-project>
          <app-bar-i18n></app-bar-i18n>
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <app-bar-notification
            :notifications="chatsStore.notifications"
            :user="userStore.user"
            :contacts="chatsStore.contacts"
          ></app-bar-notification>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between ">
        <span v-if="footerName"
              class="d-none d-md-inline"
        >&copy; {{ new Date().getFullYear() }} <a
          href="#"
          class="text-decoration-none"
        >{{ footerName }}</a><span class="d-none d-md-inline">, {{ $t('AllrightsReserved') }}</span></span>
      </div>
    </template>

    <template #v-app-content>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import {
  onBeforeMount, ref, watch, getCurrentInstance,
} from 'vue'

// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import AppBarProject from '@core/layouts/components/app-bar/AppBarProject.vue'
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'

import { getVuetify } from '@core/utils'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'
import navMenuItems from '@/navigation'
import LayoutContentVerticalNav from '@/@core/layouts/content/LayoutContentVerticalNav.vue'
import { useProjectsStore } from '@/stores/projects.store'
import useAppConfig from '@/stores/appconfig'

import { useChatsStore } from '@/stores/chats.store'
import { useUserStore } from '@/stores/user.store'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarProject,
    AppBarSearch,
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarNotification,
  },
  setup() {
    const $vuetify = getVuetify()
    const vm = getCurrentInstance().proxy
    const {
      footerName,
    } = useAppConfig()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const menuItems = ref([])
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })

    const projectsStore = useProjectsStore()
    const chatsStore = useChatsStore()
    const userStore = useUserStore()

    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    const createMenu = projectId => {
      menuItems.value = JSON.parse(JSON.stringify(navMenuItems))
      menuItems.value.forEach(itemMenu => {
        if (itemMenu.to && itemMenu.to.params && itemMenu.parent === 'projects') {
          // eslint-disable-next-line no-param-reassign
          itemMenu.to.params.id = projectId || '0'
        }
        if (itemMenu.to?.params?.folder) itemMenu.to.params.folder = localStorage.getItem('folder')
        if (itemMenu.parent === 'projects') {
          itemMenu.children?.forEach(item => {
            if (item.to && item.to.params) {
            // eslint-disable-next-line no-param-reassign
              item.to.params.id = projectId || '0'
            }
            if (item.to?.params?.folder) item.to.params.folder = localStorage.getItem('folder')
          })
        }
      })
    }

    watch(() => projectsStore.project, val => {
      createMenu(val?.id)
    })
    watch(() => userStore.user, () => createMenu(projectsStore.project?.id))

    onBeforeMount(() => {
      if (vm.$router.currentRoute.params.id !== '0') { createMenu(vm.$router.currentRoute.params.id) }
      chatsStore.fetchChatsAndContacts()
    })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      menuItems,
      navMenuItems,
      handleShallShowFullSearchUpdate,
      createMenu,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      chatsStore,
      userStore,
      footerName,
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
