<template>
  <v-row class="pa-3 mt-2">
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.token"
    ></alert-error>
    <confirm-dialog-delete
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingDelete"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>
    <v-col
      cols="12"
      :md="isEmbed ? 12 : 10"
    >
      <v-card :flat="isEmbed"
              :outlined="isEmbed"
      >
        <v-card-title>
          <div>
            <h5 class="font-weight-bold text-uppercase">
              {{ $t("Tokens") }}
            </h5>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            :disabled="!$canACL('create', 'tokens')"
            @click="openDialog"
          >
            <v-icon
              :left="!$vuetify.breakpoint.xs"
            >
              mdi-key-plus
            </v-icon>
            <span class="d-none d-sm-block">{{ $t("AddToken") }}</span>
          </v-btn>
        </v-card-title>
        <v-divider class="mx-5"></v-divider>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex flex-grow-1  align-center pb-3">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              :placeholder="$t('SearchToken')"
              class="me-3 search-table"
              clearable
              prepend-inner-icon="mdi-magnify"
              clear-icon="mdi-close"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-data-table
          v-model="selected"
          :headers="tableColumns"
          :items="listTable"
          :options.sync="options"
          :server-items-length="totalListTable"
          :loading="loading"
          :show-select="isEmbed"
          :single-select="true"
          class="text-no-wrap"
        >
          <template
            #[`item.name`]="{item}"
          >
            <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.name }}</span>
          </template>
          <template
            #[`item.description`]="{item}"
          >
            <span class="text-caption">{{ item.description }}</span>
          </template>

          <template #[`item.created_at`]="{item}">
            {{ $moment(item.created_at).format("lll") }}
          </template>
          <template #[`item.updated_at`]="{item}">
            {{ $moment(item.updated_at).format("lll") }}
          </template>

          <template #[`item.actions`]="{item}">
            <div class="d-flex align-center justify-center">
              <v-btn
                x-small
                outlined
                class="me-3"
                color="primary"
                :disabled="!$canACL('update', 'tokens')"
                @click="editDialog(item)"
              >
                <v-icon>
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
              <v-btn
                x-small
                outlined
                color="error"
                :disabled="!$canACL('delete', 'tokens')"
                @click="openDialogDelete(item)"
              >
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog
      v-model="isAddDialogVisible"
      max-width="450"
    >
      <v-card v-if="token"
              class="pa-sm-6 pa-3"
      >
        <v-card-title
          v-if="token.created_at"
          class="justify-center text-h5"
        >
          {{ $t("EditToken") }}
        </v-card-title>
        <v-card-title
          v-else
          class="justify-center text-h5"
        >
          {{ $t("AddToken") }}
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form
            ref="form"
            @submit.prevent="upsertToken"
          >
            <v-text-field
              v-model="token.name"
              :rules="[validators.required]"
              :label="$t('Name')"
              required
              outlined
              dense
              prepend-inner-icon="mdi-format-text"
              hide-details="auto"
              class="mb-4"
            ></v-text-field>
            <v-text-field
              v-model="token.secret"
              :rules="[validators.required]"
              :label="$t('Token')"
              required
              outlined
              dense
              prepend-inner-icon="mdi-key"
              hide-details="auto"
              class="mb-4"
            ></v-text-field>

            <v-textarea
              v-if="false"
              v-model="token.description"
              :label="$t('Description')"
              required
              outlined
              dense
              prepend-inner-icon="mdi-format-text"
              hide-details="auto"
              class="mb-4"
            ></v-textarea>

            <div class="mt-6 d-flex justify-center">
              <v-btn
                :loading="loadingAction"
                :disabled="loadingAction"
                color="primary"
                class="me-3"
                type="submit"
              >
                {{ $t('Submit') }}
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click="isAddDialogVisible = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref, getCurrentInstance, onBeforeMount } from 'vue'
import {
  required, emailValidator,
} from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import AlertError from '@/components/AlertError.vue'
import { useTokensStore } from '@/stores/tokens.store'
import useTokenList from '@/pages/tokens/useTokenList'
import { useVModel } from '@vueuse/core'

export default {
  components: {
    ConfirmDialogDelete,
    AlertError,
  },
  props: {
    isEmbed: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const errors = ref({})
    const loadingDelete = ref(false)
    const loadingAction = ref(false)
    const form = ref(null)
    const item = ref(null)
    const token = ref(null)
    const isAlertVisible = ref(false)
    const selected = useVModel(props, 'selected', emit)
    const vm = getCurrentInstance().proxy
    const tokensStore = useTokensStore()

    const {
      listTable, searchQuery, tableColumns, options, totalListTable, loading, selectedTableData, isAddDialogVisible, isDeleteDialogVisible,
    } = useTokenList()

    onBeforeMount(() => {
      tableColumns.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
    })

    const openDialog = () => {
      if (form.value) { form.value.reset() }
      isAddDialogVisible.value = true
      token.value = {}
      errors.value = {}
    }

    const openDialogDelete = _item => {
      isDeleteDialogVisible.value = true
      item.value = _item
    }
    const editDialog = async _item => {
      isAddDialogVisible.value = true
      tokensStore.getToken(_item.id).then(data => {
        token.value = data
      })
    }

    const confirmDelete = () => {
      if (!item.value) { return }
      loadingDelete.value = true
      tokensStore.deleteToken(item.value).then(() => {
        isDeleteDialogVisible.value = false
        loadingDelete.value = false
      }).catch(error => {
        loadingDelete.value = false
        isAlertVisible.value = true
        errors.value = {
          token: error,
        }
      })
    }

    const upsertToken = () => {
      errors.value = {}

      if (!form.value.validate()) { return }

      if (token.value.created_at) {
        tokensStore.saveToken(token.value).then(() => {
          loadingAction.value = false
          isAddDialogVisible.value = false
        }).catch(error => {
          loadingAction.value = false
          isAlertVisible.value = true
          errors.value = {
            token: error,
          }
        })
      } else {
        tokensStore.createToken(token.value).then(() => {
          loadingAction.value = false
          isAddDialogVisible.value = false
        }).catch(error => {
          loadingAction.value = false
          isAlertVisible.value = true
          errors.value = {
            token: error,
          }
        })
      }
    }

    return {
      tableColumns,
      searchQuery,
      options,
      token,
      totalListTable,
      listTable,
      loading,
      loadingAction,
      selectedTableData,
      validators: {
        required,
        emailValidator,
      },
      isAddDialogVisible,
      loadingDelete,
      errors,
      isAlertVisible,
      form,
      avatarText,
      isDeleteDialogVisible,
      openDialogDelete,
      confirmDelete,
      editDialog,
      openDialog,
      upsertToken,
      selected,
    }
  },
}
</script>
