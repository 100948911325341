<template>
  <div>
    <v-snackbar
      v-model="isSaved"
      outlined
      text
      color="primary"
      shaped
      top
      right
    >
      {{ $t('SavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.permissions"
    ></alert-error>
    <v-dialog
      v-model="isDialogVisible"
      max-width="450"
    >
      <v-card class="pa-sm-6 pa-3">
        <v-card-title class="justify-center text-h5">
          {{ $t(rolePermissions.created_at ? 'EditRole' : 'AddRole') }}
        </v-card-title>
        <v-card-text class="mt-6">
          <!-- 👉 Form -->
          <v-form
            ref="form"
            class="multi-col-validation"
            @submit.prevent="onSubmit"
          >
            <v-text-field
              v-model="rolePermissions.name"
              :rules="[validators.required]"
              :label="$t('Role')"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-4"
            ></v-text-field>

            <div id="user-view">
              <!-- tabs -->
              <v-tabs
                v-model="tabs"
                class="user-tabs"
                style="position: relative; z-index: 0;"
                show-arrows
              >
                <v-tab>
                  <v-icon
                    size="20"
                    class="me-3"
                  >
                    mdi-folder-table
                  </v-icon>
                  <span>{{ $t('Project') }}</span>
                </v-tab>
                <v-tab>
                  <v-icon
                    size="20"
                    class="me-3"
                  >
                    mdi-home-city
                  </v-icon>
                  <span>{{ $t('Organization') }}</span>
                </v-tab>
              </v-tabs>

              <v-tabs-items
                id="user-tabs-content"
                v-model="tabs"
              >
                <v-tab-item>
                  <v-checkbox
                    v-model="isSelectAllProject"
                    :indeterminate="isProjectIndeterminate"
                    :label="$t('SelectAll')"
                    dense
                    class="mt-4 mx-3"
                  />
                  <v-expansion-panels class="pa-2"
                                      multiple
                  >
                    <v-expansion-panel
                      v-for="permission in permissions?.filter(p => p.parent === 'projects')"
                      :key="permission.resource"
                    >
                      <v-expansion-panel-header class="text-button py-0">
                        {{ $t(capitalizeFirstLetter(permission.resource)) }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.view"
                              :label="$t('view')"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.read"
                              :label="$t('read')"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.create"
                              :label="$t('create')"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.update"
                              :label="$t('update')"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.delete"
                              :label="$t('delete')"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
                <v-tab-item>
                  <v-checkbox
                    v-model="isSelectAllOrga"
                    :indeterminate="isOrgaIndeterminate"
                    :label="$t('SelectAll')"
                    dense
                    class="mt-4 mx-3"
                  />
                  <v-expansion-panels class="pa-2"
                                      multiple
                  >
                    <v-expansion-panel
                      v-for="permission in permissions?.filter(p => p.parent === 'organizations')"
                      :key="permission.resource"
                    >
                      <v-expansion-panel-header class="text-button py-0">
                        {{ $t(capitalizeFirstLetter(permission.resource)) }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.view"
                              :label="$t('view')"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.read"
                              :label="$t('read')"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.create"
                              :label="$t('create')"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.update"
                              :label="$t('update')"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="permission.delete"
                              :label="$t('delete')"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
              </v-tabs-items>
            </div>

            <div class="mt-6 d-flex justify-center">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                class="me-3"
                type="submit"
              >
                {{ $t('Submit') }}
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click="isDialogVisible = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref, watch, computed, getCurrentInstance,
} from 'vue'
import { useVModel } from '@vueuse/core'
import { useOrganizationsStore } from '@/stores/organizations.store'
import AlertError from '@/components/AlertError.vue'
import {
  required,
} from '@core/utils/validation'

export default {
  components: {
    AlertError,
  },
  props: {
    rolePermissions: {
      type: Object,
      required: false,
      default: () => ({
        name: '',
        permissions: [],
      }),
    },
    isDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    // 👉 Permission List
    const permissions = ref(null)
    const form = ref(null)
    const isSelectAllProject = ref(false)
    const isSelectAllOrga = ref(false)
    const role = ref('')
    const errors = ref({})
    const isAlertVisible = ref(false)
    const refPermissionForm = ref()
    const allPermissions = ref([])
    const tabs = ref(null)
    const loading = ref(null)
    const isSaved = ref(null)
    const organizationsStore = useOrganizationsStore()
    const vm = getCurrentInstance().proxy
    const isDialogVisible = useVModel(props, 'isDialogVisible', emit)
    const rolePermissions = useVModel(props, 'rolePermissions', emit)

    const checkedProjectCount = computed(() => {
      let counter = 0
      permissions.value?.forEach(permission => {
        if (permission.parent === 'projects') {
          Object.entries(permission).forEach(([key, value]) => {
            if (key !== 'resource' && key !== 'parent' && value) { counter += 1 }
          })
        }
      })

      return counter
    })

    const checkedOrgaCount = computed(() => {
      let counter = 0
      permissions.value?.forEach(permission => {
        if (permission.parent === 'organizations') {
          Object.entries(permission).forEach(([key, value]) => {
            if (key !== 'resource' && key !== 'parent' && value) { counter += 1 }
          })
        }
      })

      return counter
    })

    const isProjectIndeterminate = computed(() => checkedProjectCount.value > 0 && checkedProjectCount.value < allPermissions.value.filter(p => p.parent === 'projects').length)
    const isOrgaIndeterminate = computed(() => checkedOrgaCount.value > 0 && checkedOrgaCount.value < allPermissions.value.filter(p => p.parent === 'organizations').length)

    watch(isSelectAllProject, val => {
      permissions.value = permissions.value.map(permission => {
        if (permission.parent === 'projects') {
          return {
            resource: permission.resource,
            parent: permission.parent,
            view: val,
            read: val,
            delete: val,
            update: val,
            create: val,
          }
        }

        return permission
      })
    })

    watch(isSelectAllOrga, val => {
      permissions.value = permissions.value.map(permission => {
        if (permission.parent === 'organizations') {
          return {
            resource: permission.resource,
            parent: permission.parent,
            view: val,
            read: val,
            delete: val,
            update: val,
            create: val,
          }
        }

        return permission
      })
    })

    const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

    // if Indeterminate is false, then set isSelectAll to false
    watch(isDialogVisible, async () => {
      tabs.value = 0
      if (form.value) form.value.resetValidation()

      allPermissions.value = await organizationsStore.getPermissions()
      const tab = {}
      allPermissions.value.forEach(p => {
        tab[p.resource] = {
          resource: p.resource,
          view: false,
          read: false,
          update: false,
          create: false,
          delete: false,
          parent: p.parent,
        }
      })

      permissions.value = Object.values(tab)
      if (rolePermissions.value.id) { rolePermissions.value = await organizationsStore.getRole(rolePermissions.value.id) }

      if (rolePermissions.value && rolePermissions.value.permissions?.length) {
        role.value = rolePermissions.value.name
        permissions.value = permissions.value.map(permission => ({
          resource: permission.resource,
          parent: permission.parent,
          view: !!rolePermissions.value?.permissions.find(item => item.resource === permission.resource && item.type === 'VIEW'),
          read: !!rolePermissions.value?.permissions.find(item => item.resource === permission.resource && item.type === 'SELECT'),
          update: !!rolePermissions.value?.permissions.find(item => item.resource === permission.resource && item.type === 'UPDATE'),
          delete: !!rolePermissions.value?.permissions.find(item => item.resource === permission.resource && item.type === 'DELETE'),
          create: !!rolePermissions.value?.permissions.find(item => item.resource === permission.resource && item.type === 'INSERT'),
        }))
      }
      permissions.value = permissions.value.sort((a, b) => vm.$t(capitalizeFirstLetter(a.resource)).localeCompare(vm.$t(capitalizeFirstLetter(b.resource))))
    })

    /*
    watch(isProjectIndeterminate, () => {
      if (!isProjectIndeterminate.value) { isSelectAllProject.value = false }
    })
    watch(isOrgaIndeterminate, () => {
      if (!isOrgaIndeterminate.value) { isSelectAllOrga.value = false }
    })

    */

    watch(permissions, () => {
      if (checkedProjectCount.value === allPermissions.value.filter(p => p.parent === 'projects').length) { isSelectAllProject.value = true }
      if (checkedOrgaCount.value === allPermissions.value.filter(p => p.parent === 'organizations').length) { isSelectAllOrga.value = true }
    }, { deep: true })

    const onSubmit = async () => {
      refPermissionForm.value?.reset()
      loading.value = true
      organizationsStore.updatePermissions(rolePermissions.value, permissions.value, organizationsStore.organization).then(() => {
        loading.value = false
        isDialogVisible.value = false
        isSaved.value = true
      }).catch(error => {
        loading.value = false
        isAlertVisible.value = true
        errors.value = {
          permissions: error,
        }
      })
    }

    return {
      tabs,
      isDialogVisible,
      form,
      isSelectAllProject,
      isSelectAllOrga,
      isOrgaIndeterminate,
      isProjectIndeterminate,
      role,
      refPermissionForm,
      permissions,
      onSubmit,
      validators: {
        required,
      },
      capitalizeFirstLetter,
      rolePermissions,
      errors,
      isAlertVisible,
      loading,
      isSaved,
    }
  },

}

</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.permission-table {
  td {
    .v-label {
      white-space: nowrap;
      font-size: x-small;
    }
  }
}
</style>
