/* eslint-disable import/no-mutable-exports */
import { createClient } from '@supabase/supabase-js'
import { appConfig } from './micleoConfig'

const supabaseUrl = appConfig.url
const supabaseAnonKey = appConfig.tokens.SUPABASE_ANON

export const redirectTo = window.location.origin
export const version = appConfig.theme.app.version || '1.0.0'
export const supabase = createClient(supabaseUrl, supabaseAnonKey)
export const supabaseDomain = `sb-${(appConfig?.url || supabaseUrl)
  .replace('.supabase.co', '')
  .replace('.micleo.com', '')
  .replace('https://', '')
  .replace('http://', '')
  .replace(':8000', '')
  .replace(':54321', '')
  .replace('/', '')}-auth-token`

// Create a single supabase client for interacting with your database
