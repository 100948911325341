import { defineStore, acceptHMRUpdate } from 'pinia'
import { required } from '@core/utils/validation'
import { TABLE_ACTIVITIES, displayMode } from '@core/utils'

// eslint-disable-next-line import/prefer-default-export
export const useActivitiesStore = defineStore({
  id: TABLE_ACTIVITIES,
  state: () => ({

  }),
  actions: {
    getHeader(payload) {
      return {
        title: 'Activities',
        icon: 'mdi-timeline-check-outline',
        name: ['id'],
        folder: payload?.folder,
        display: displayMode(TABLE_ACTIVITIES, 'table'),
      }
    },

    getTableOptions() {
      return {
        sortBy: ['date'],
        sortDesc: [true],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: -1,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        {
          text: 'Campaign',
          value: 'fkey_campaigns->>name',
          filterable: true,
          search: true,
          type: 'font-weight-semibold text-button primary--text',
        },
        {
          text: 'Recipient',
          value: 'payload->>recipient',
          filterable: true,
          search: true,
          type: 'font-weight-semibold',
        },
        {
          text: 'Status',
          value: 'payload->>event',
          filterable: true,
          search: true,
          type: 'chip-type',
        },
        {
          text: 'CreatedAt',
          value: 'date',
          type: 'datetimeago',
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        project_id: payload?.project_id,
      }
    },

    getSchema(item, items, payload) {
      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }

      schema.col1.schema.general = {
        title: 'General',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'pa-3 ma-2',
        schema: {
          id: {
            type: 'text',
            label: 'ID',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-key-outline',
            disabled: true,
            hidden: !!items.length,
            col: 12,
          },
          'fkey_campaigns->>name': {
            type: 'text',
            label: 'Campaign',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-email',
            disabled: true,
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
            col: 12,
          },
          'payload->>recipient': {
            type: 'text',
            label: 'Recipient',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-account',
            disabled: true,
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
            col: 12,
          },
          'payload->>event': {
            type: 'text',
            label: 'Status',
            class: 'mx-1 mb-2',
            chips: true,
            prependInnerIcon: 'mdi-tag',
            disabled: true,
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
            col: 12,
          },
        },
      }
      schema.col2.schema.settings = {
        title: 'Details',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'pa-3 ma-2',
        schema: {
          payload: {
            type: 'json',
            disabled: true,
            hidden: !!items.length,
            col: 12,
          },
        },
      }

      return schema
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useActivitiesStore, import.meta.hot))
}
