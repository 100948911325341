import { defineStore, acceptHMRUpdate } from 'pinia'

import { supabase } from '@/plugins/supabase'
import { useFoldersStore } from '@/stores/folders.store'
import { useChatsStore } from '@/stores/chats.store'
import { useTemplatesStore } from '@/stores/templates.store'
import { useListingsStore } from '@/stores/listings.store'
import { required, datetimeValidator } from '@core/utils/validation'
import { canACL } from '@/plugins/acl/checkaccess'
import { TABLE_CAMPAIGNS, TABLE_TASKS, displayMode } from '@core/utils'
// eslint-disable-next-line import/prefer-default-export
export const useCampaignsStore = defineStore({
  id: TABLE_CAMPAIGNS,
  state: () => ({
    campaigns: [],
    isTest: false,
  }),
  actions: {
    getHeader(payload, item) {
      return {
        title: 'Campaigns',
        icon: 'mdi-email-fast-outline',
        name: ['name'],
        folder: payload?.folder,
        display: displayMode(TABLE_CAMPAIGNS, 'table'),
        newData: 'NewCampaign',
        actionData: 'EditCampaigns',
        editData: 'EditCampaign',
        search: 'SearchCampaign',
        searchIcon: 'mdi-email-search',
        add: {
          text: 'AddCampaign',
          icon: 'mdi-email-plus',
        },
        actions: [
          {
            title: 'Save',
            icon: 'mdi-content-save-outline',
            action: 'save',
            loading: false,
            acl: 'create,update',
          },
          {
            title: 'Duplicate',
            icon: 'mdi-content-duplicate',
            action: 'duplicate',
            acl: 'create',
            disabled: !item?.created_at,
          },
          {
            title: 'Send',
            icon: 'mdi-email-fast',
            action: 'send',
            confirm: true,
            message: 'SendCampaign',
            disabled: item?.status !== 'draft' || !item?.created_at,
            acl: 'create,update',
          },
          {
            title: 'Test',
            icon: 'mdi-test-tube',
            action: 'test',
            disabled: !item?.created_at,
            acl: 'create,update',
          },
          {
            title: 'Cancel',
            icon: 'mdi-cancel',
            action: 'cancel',
            confirm: true,
            disabled: item?.status !== 'processing' && item?.status !== 'sent',
            acl: 'update',
          },
          {
            title: 'Delete',
            icon: 'mdi-delete-outline',
            action: 'delete',
            acl: 'delete',
            color: 'error',
            disabled: !item?.created_at,
          },
        ],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['updated_at'],
        sortDesc: [true],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: -1,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
          search: true,
        },
        {
          text: 'Status',
          value: 'status',
          type: 'chip-type',
          filterable: true,
        },
        {
          text: 'Type',
          value: 'fkey_templates->>type',
          filterable: true,
          type: 'chip-type',
        },
        {
          text: 'Template',
          value: 'fkey_templates->>thumbnail',
          type: 'file-small',
          icon: 'mdi-newspaper-variant',
          filterable: true,
        },
        {
          text: 'Statistics',
          value: 'counters',
          type: 'json-beautifier',
          filterable: false,
          sortable: false,
        },
        {
          text: 'UpdatedAt',
          value: 'updated_at',
          type: 'date',
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        // general
        name: null,
        type: 'campaign',
        status: 'draft',
        listings: [],
        description: null,
        template_id: null,
        scheduled_at: null,
        delivered_at: null,
        counters: {},
        integration_id: payload?.integration_id,
        folder_id: payload?.folder_id,
        project_id: payload?.project_id,
      }
    },

    getSchema(item, items, payload) {
      const foldersStore = useFoldersStore()
      const chatsStore = useChatsStore()
      const templatesStore = useTemplatesStore()
      const listingsStore = useListingsStore()

      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }

      schema.col1.schema.general = {
        title: 'General',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'px-3 ma-2',
        schema: {
          name: {
            type: 'text',
            label: 'Name',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-format-header-1',
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
            disabled: item?.status !== 'draft',
          },
          type: {
            type: 'select',
            subtype: 'translate',
            label: 'Type',
            class: 'mx-1 mb-2',
            col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 12 },
            items: ['campaign', 'transactional'],
            rules: items.length > 0 ? [] : [required],
            hidden: true,
            disabled: item?.status !== 'draft',
          },
          status: {
            type: 'select',
            subtype: 'translate',
            label: 'Status',
            class: 'mx-1 mb-2',
            col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 12 },
            items: ['draft', 'processing', 'scheduled', 'archived', 'active', 'sent'],
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
            disabled: true,
          },
          scheduled_at: {
            type: 'date-time-picker',
            label: 'ScheduledAt',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-clock',
            rules: items.length > 0 ? [] : [datetimeValidator],
            disabled: item?.status !== 'draft',
          },
          description: {
            type: 'textarea',
            label: 'Description',
            class: 'mx-1 mb-2',
            rules: [],
            hidden: !!items.length,
            disabled: item?.status !== 'draft',
          },
          template_id: {
            type: 'autocomplete',
            subtype: 'templates',
            label: 'Template',
            class: 'mx-1 mb-2',
            'content-class': 'table-filters',
            col: 12,
            clearable: true,
            complex: true,
            prependInnerIcon: 'mdi-file-search',
            dense: true,
            items: templatesStore.templates,
            rules: items.length > 0 ? [] : [required],
            disabled: item?.status !== 'draft' || !canACL('view', 'templates', { id: payload?.project_id }),
          },
          folder_id: {
            type: 'autocomplete',
            subtype: 'folders',
            label: 'Folders',
            class: 'mx-1 mb-2',
            col: 12,
            clearable: true,
            complex: true,
            prependInnerIcon: 'mdi-folder-open',
            dense: true,
            items: foldersStore.folders,
            rules: items.length > 0 ? [] : [required],
          },
        },
      }

      if (!canACL('view', 'folders', { id: payload?.project_id })) {
        delete schema.col1.schema.general.schema.folder_id
      }

      schema.col2.schema.listing = {
        title: 'Recipients',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'ma-2',
        schema: {
          edit_listings: {
            type: 'btn',
            disabled: item?.status !== 'draft' || !canACL('view', 'listings', { id: payload?.project_id }),
            col: 12,
            class: 'mx-3 my-1',
            'x-small': true,
            outlined: true,
            label: 'EditRecipients',
            iconCenter: 'mdi-pencil',
            color: 'primary',
          },
          view__listings: {
            type: 'btn',
            label: 'Listings',
            'x-small': true,
            outlined: true,
            col: 12,
            iconCenter: 'mdi-format-list-group',
            color: 'secondary',
            class: 'mx-3 my-1',
            disabled: item?.status !== 'draft' || !canACL('view', 'listings', { id: payload?.project_id }),
          },
          recipients: {
            type: 'number',
            label: 'Recipients',
            class: 'ma-3',
            dense: true,
            flat: true,
            prependInnerIcon: 'mdi-account-group',
            hidden: !!items.length,
            disabled: true,
            col: 12,
          },
          listings: {
            type: 'table',
            label: 'Listings',
            'item-value': 'id',
            'item-text': 'name',
            col: 12,
            multiple: true,
            readonly: true,
            headers: [
              {
                text: 'Name',
                value: 'name',
              },
              {
                text: 'Contacts',
                value: 'objects',
              },
            ],
            items: listingsStore.listings,
          },
        },
      }

      schema.col2.schema.activities = {
        title: 'Statistics',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'pa-3 ma-2',
        hidden: !item?.created_at,
        schema: {
          view__activities: {
            disabled: !canACL('view', 'activities', { id: payload?.project_id }),
            type: 'btn',
            small: true,
            label: 'ViewStats',
            class: 'mb-3 mx-2',
            outlined: true,
            iconLeft: 'mdi-table-search',
            color: 'primary',
            col: 8,
          },
          actions: {
            type: 'wrap',
            col: { cols: 12, md: 4 },
            flat: true,
            schema: {
              refresh__activities: {
                type: 'btn',
                small: true,
                class: 'mb-3 mx-2',
                outlined: true,
                iconCenter: 'mdi-refresh',
                color: 'primary',
                col: 4,
              },
            },
          },

          'counters->>total': {
            type: 'number',
            label: 'TOTAL',
            class: 'mx-1 mb-2',
            dense: true,
            flat: true,
            prependInnerIcon: 'mdi-chart-areaspline',
            hidden: !!items.length,
            disabled: true,
            col: 12,
          },
        },
      }
      if (item?.counters) {
        Object.keys(item.counters).forEach(key => {
          if (!schema.col2.schema.activities.schema[`counters->>${key}`]) {
            schema.col2.schema.activities.schema[`counters->>${key}`] = {
              type: 'number',
              label: key,
              dense: true,
              flat: true,
              class: 'text-uppercase mx-1 mb-2',
              prependInnerIcon: 'mdi-chart-areaspline',
              hidden: !!items.length,
              disabled: true,
              col: 6,
            }
          }
        })
      }

      if (item?.id) {
        schema.col3.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col3.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col3.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          label: 'UpdatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }

      if (canACL('view', 'logs', { id: payload?.project_id })) {
        schema.col3.schema.logs = {
          title: 'Logs',
          type: 'wrap',
          col: 12,
          outlined: true,
          shaped: true,
          ripple: false,
          class: 'pa-3 ma-2',
          hidden: !item?.created_at,
          schema: {
            view__logs: {
              disabled: !canACL('view', 'logs', { id: payload?.project_id }),
              type: 'btn',
              small: true,
              label: 'ViewLogs',
              class: 'mb-3 mx-2',
              outlined: true,
              iconLeft: 'mdi-alert-box',
              color: 'primary',
              col: 6,
            },
          },
        }
      }

      return schema
    },

    async onAction(action, payload) {
      if (action === 'send') {
        // eslint-disable-next-line no-return-await
        return await this.update(payload.item, { status: 'processing' })
      } if (action === 'cancel') {
        // eslint-disable-next-line no-return-await
        return await this.cancel(payload.item)
      } if (action === 'test') {
        // eslint-disable-next-line no-return-await
        this.isTest = true
      }
      if (action === 'duplicate') {
        payload.item.status = 'draft'
        payload.item.counters = {}

        return payload.item
      }

      return payload?.item
    },

    formatItem(item) {
      const listingsStore = useListingsStore()

      Object.keys(item).forEach(key => {
        if (item[key] instanceof Object) {
          if (key === 'listings') {
            const listings = []
            Object.keys(item[key])?.forEach(subkey => {
              const listing = listingsStore.getListingItem(subkey.length > 5 ? subkey : item[key][subkey])
              if (listing) {
                listings.push(listing)
              }
            })
            item[key] = listings
          } else if (!(item[key] instanceof Array)) {
            Object.keys(item[key])?.forEach(subkey => {
            // eslint-disable-next-line prefer-template
              item[key + '->>' + subkey] = item[key][subkey]
            })
          }
        }
      })
      delete item.properties_text

      return item
    },

    async getCampaigns(projectId) {
      const { data, count, error } = await supabase
        .from(TABLE_CAMPAIGNS)
        .select('*', { count: 'exact' })
        .eq('project_id', projectId)
        .order('updated_at', { ascending: false })
      if (error) {
        return Promise.reject(error)
      }
      this.campaigns = data

      return { data, count }
    },

    onRecipients(item) {
      if (item?.listings) {
        const recipients = {}
        if (item.listings instanceof Array) {
          item.listings.forEach(l => {
            l.objects_ids?.forEach(it => {
              recipients[it] = true
            })
          })
        }
        item.recipients = Object.keys(recipients).length
      }
    },

    async update(campaign, payload) {
      const { data, error } = await supabase
        .from(TABLE_CAMPAIGNS)
        .update(payload)
        .eq('id', campaign.id)
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async cancel(campaign) {
      const { error } = await supabase.rpc('cancel_campaign', { campaignid: campaign.id })
      if (error) {
        return Promise.reject(error)
      }

      return true
    },

    async sendTest(campaign, contacts) {
      const { error } = await supabase
        .from(TABLE_TASKS).insert({ sql: false, project_id: campaign.project_id, job: { function: 'test-campaign', params: { campaign_id: campaign.id, test_ids: contacts } } })
      if (error) {
        return Promise.reject(error)
      }

      return true
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCampaignsStore, import.meta.hot))
}
