<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="template"
       v-html="html"
  >
  </div>
</template>

<script>
import {
  getCurrentInstance, onMounted, ref,
} from 'vue'

import axios from '@axios'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const html = ref(null)
    onMounted(async () => {
      const data = await axios.post('template', {
        template_id: vm.$router.currentRoute.params.id,
      })
      html.value = data?.email?.html
    })

    return {
      html,
    }
  },
}
</script>
<style scoped>
.template {
  background-color: white;
  margin: 0;
  padding: 0;
  height: 100%;
}
</style>
