<template>
  <div class="hidden-sm-and-down">
    <v-chip
      v-if="projectsStore.GET_PROJECT!=null"
      class="d-flex align-center mx-5"
      close
      @click:close="goToProjects"
    >
      <v-avatar
        color="primary"
        class="v-avatar-light-bg primary--text me-3"
        rounded
        size="34"
      >
        <v-img
          v-if="projectsStore.GET_PROJECT.image"
          :src="projectsStore.GET_PROJECT.image"
        ></v-img>
        <span
          v-else
          class="font-weight-semibold"
        >{{ avatarText(projectsStore.GET_PROJECT.name) }}</span>
      </v-avatar><span class="font-weight-semibold text-overline">
        {{ projectsStore.GET_PROJECT.name }}
      </span>
    </v-chip>
  </div>
</template>

<script>
import {
  getCurrentInstance,
} from 'vue'
import { avatarText } from '@core/utils/filter'
import { useProjectsStore } from '@/stores/projects.store'

export default {

  setup() {
    const projectsStore = useProjectsStore()
    const vm = getCurrentInstance().proxy

    const goToProjects = () => {
      vm.$router.push({ name: 'projects' })
    }

    return {
      projectsStore,
      avatarText,
      goToProjects,
    }
  },
}
</script>
