import { defineStore, acceptHMRUpdate } from 'pinia'
import { useChatsStore } from '@/stores/chats.store'
import { useFoldersStore } from '@/stores/folders.store'
import { canACL } from '@/plugins/acl/checkaccess'
import { required } from '@core/utils/validation'
import { supabase } from '@/plugins/supabase'
import { appConfig } from '@/plugins/micleoConfig'
import { TABLE_FILES, displayMode } from '@/@core/utils'
import { useProjectsStore } from './projects.store'

const appAPI = process.env.VUE_APP_API
const supabaseURL = appConfig?.url

const FileSaver = require('file-saver')

// eslint-disable-next-line import/prefer-default-export
export const useFilesStore = defineStore({
  id: TABLE_FILES,
  state: () => ({
    files: [],
    isAdd: false,
  }),
  actions: {

    getHeader(payload) {
      return {
        title: 'Files',
        icon: 'mdi-file-document',
        name: ['name'],
        folder: payload?.folder,
        display: displayMode(TABLE_FILES, 'grid'),
        newData: 'NewFile',
        actionData: 'EditFiles',
        editData: 'EditFile',
        search: 'SearchFile',
        searchIcon: 'mdi-file-search',
        buttons: ['files'],
        actions: [{
          title: 'Save', icon: 'mdi-content-save-outline', action: 'save', loading: false, acl: 'create,update',
        },
        {
          title: 'Delete',
          icon: 'mdi-delete-outline',
          color: 'error',
          action: 'delete',
          acl: 'delete',
        }],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['updated_at'],
        sortDesc: [true],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: 12,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold primary--text',
          filterable: true,
          search: true,
        },
        {
          text: 'Path',
          value: 'folder_id',
          type: 'folder',
          filterable: true,
        },
        {
          text: 'Extension',
          value: 'metadata->>mimetype',
          type: 'mimetype',
          filterable: true,
        },
        {
          text: 'Type',
          value: 'bucket_id',
          type: 'text-button',
          filterable: true,
        },
        {
          text: 'Thumbnail',
          value: 'thumbnail',
          type: 'files',
        },
        {
          text: 'UpdatedAt',
          value: 'updated_at',
          type: 'date',
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        // general
        name: null,
        description: null,
        folder_id: payload?.folder_id,
        project_id: payload?.project_id,
      }
    },

    getSchema(item, items, payload) {
      const chatsStore = useChatsStore()
      const foldersStore = useFoldersStore()

      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }

      schema.col1.schema.general = {
        title: 'General',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'px-3 ma-2',
        schema: {
          name: {
            type: 'text',
            label: 'Name',
            disabled: true,
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-format-header-1',
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
          },
          folder_id: {
            type: 'autocomplete',
            subtype: 'folders',
            disabled: true,
            label: 'Folders',
            class: 'mx-1 mb-2',
            col: 12,
            clearable: true,
            complex: true,
            prependInnerIcon: 'mdi-folder-open',
            dense: true,
            items: foldersStore.folders,
            rules: items.length > 0 ? [] : [required],
          },
          description: {
            type: 'textarea',
            label: 'Description',
            class: 'mx-1 mb-2',
            rules: [],
            hidden: true,
          },
        },
      }

      schema.col1.schema.contacts = {
        title: 'Action',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'pa-3 ma-2',
        hidden: items.length > 0,
        schema: {
          download_file: {
            disabled: !canACL('view', 'contacts', { id: payload?.project_id }),
            type: 'btn',
            label: 'Download',
            outlined: true,
            small: true,
            class: 'mb-3',
            iconLeft: 'mdi-download',
            color: 'primary',
            offset: 3,
            col: 6,
          },
        },
      }

      if (!canACL('view', 'folders', { id: payload?.project_id })) {
        delete schema.col1.schema.general.schema.folder_id
      }

      schema.col2.schema.file = {
        title: 'File',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'text-center',
        hidden: items.length > 0,
        schema: {
          file_viewer: {
            type: 'image',
            src: item?.property_slug ? this.getThumbnail(`${item?.bucket_id}/${item?.project_id}/${item?.folder_id}/${item?.property_slug}/${item?.name}`, item?.metadata?.mimetype) : this.getThumbnail(`${item?.bucket_id}/${item?.project_id}/${item?.folder_id}/${item?.name}`, item?.metadata?.mimetype),
            width: '280',
            height: '250',
            style: 'border: 1px solid #ccc;',
            col: 12,
            class: 'mb-5',
            hidden: !!items.length,
          },
        },
      }

      if (item?.id) {
        schema.col3.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col3.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col3.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          label: 'UpdatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }

      return schema
    },
    async onAction(action, payload) {
      if (action === 'download_file') {
        await this.download([payload.item])
      }

      return payload?.item
    },

    async download(items) {
      await Promise.all(
        items.map(async item => {
          const path = item.property_slug ? `${item.project_id}/${item.folder_id}/${item.property_slug}/${item.name}` : `${item.project_id}/${item.folder_id}/${item.name}`
          const { data: blob, error } = await supabase
            .storage
            .from(item.bucket_id)
            .download(path)
          if (error) {
            return Promise.reject(error)
          }
          FileSaver.saveAs(blob, item.name)

          return true
        }),
      )

      return true
    },

    getUrl(item) {
      if (item?.property_slug) return `${supabaseURL}/storage/v1/object/public/${item.bucket_id}/${item.project_id}/${item.folder_id}/${item.property_slug}/${item.name}`
      if (item && !item.property_slug) return `${supabaseURL}/storage/v1/object/public/${item.bucket_id}/${item.project_id}/${item.folder_id}/${item.name}`

      return `${appAPI}/placeholder?w=260&h=300&t=`
    },

    getThumbnail(path, type) {
      if (!path && !type) {
        return `${appAPI}/placeholder?w=260&h=350&t=`
      }
      const extension = path?.split(/[#?]/)[0].split('.').pop().trim() || ''
      if (!type) {
        if (['png', 'jpg', 'png', 'jpeg', 'gif'].includes(extension)) {
          return path.indexOf('https') >= 0 ? path : `${supabaseURL}/storage/v1/object/public/${path}`
        }

        return `${appAPI}/placeholder?w=260&h=350&t=${extension?.toUpperCase()}`
      }
      if (type.indexOf('image') >= 0) return path.indexOf('https') >= 0 ? path : `${supabaseURL}/storage/v1/object/public/${path}`

      return `${appAPI}/placeholder?w=260&h=350&t=${extension?.toUpperCase()}`
    },

    getFileName(path) {
      return path?.split(/[#?]/)[0].split('/').pop().trim()
    },

    async uploadFile(folder, fileName, file) {
      const projectsStore = useProjectsStore()
      const { data, error } = await supabase.storage.from(TABLE_FILES).upload(`${projectsStore.project?.id}/${folder}/${fileName}`, file)
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async getFileUrl(folder, fileName) {
      const projectsStore = useProjectsStore()
      const { data } = await supabase.storage.from(TABLE_FILES).getPublicUrl(`${projectsStore.project?.id}/${folder}/${fileName}`)

      return data
    },

    async getFile(path) {
      const splittedPath = path.split('/')
      const { data, error } = await supabase.from(TABLE_FILES).select('*').match({ project_id: splittedPath[0], folder_id: splittedPath[1], name: splittedPath[2] }).single()
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async deleteFiles(object) {
      if (object.id) {
        object = [object]
      }
      const tabs = {}
      object.forEach(async item => {
        if (!tabs[item.bucket_id]) tabs[item.bucket_id] = []
        if (item.property_slug) { tabs[item.bucket_id].push(`${item.project_id}/${item.folder_id}/${item.property_slug}/${item.name}`) } else { tabs[item.bucket_id].push(`${item.project_id}/${item.folder_id}/${item.name}`) }
      })
      const queries = []

      Object.keys(tabs).forEach(bucketid => {
        queries.push(supabase
          .storage
          .from(bucketid)
          .remove(tabs[bucketid]))
      })

      await Promise.all(
        queries.map(async query => {
          const { error } = await query
          if (error) {
            return Promise.reject(error)
          }

          return true
        }),
      )

      return true
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFilesStore, import.meta.hot))
}
