<template>
  <v-tooltip
    open-delay="500"
    top
  >
    <template #activator="{ on, attrs }">
      <v-badge
        :value="member.online===true && !(xxsmall)"
        bottom
        overlap
        dot
        bordered
        v-bind="attrs"
        :color="member.online?'success':'secondary'"
        offset-x="11"
        offset-y="11"
        style="box-shadow: none; -moz-box-shadow: none; -webkit-box-shadow: none;"
        class="me-1 cursor-pointer"
        v-on="on"
      >
        <v-avatar
          :size="xxsmall? '20':xsmall?'28':small?'34':'40'"
          color="primary"
          class="v-avatar-light-bg primary--text"
        >
          <v-img
            v-if="member.avatar"
            :src="member.avatar"
          ></v-img>
          <span
            v-else
            :class="xxsmall? 'xxmsall_text_avatar':xsmall? 'xmsall_text_avatar':small? 'small_text_avatar':''"
            class="font-weight-semibold small_text_avatar"
          >{{ avatarText(member.fullname) }}
          </span>
        </v-avatar>
      </v-badge>
    </template>

    <span>{{ member.fullname }}</span>
  </v-tooltip>
</template>

<script>
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    xxsmall: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.small_text_avatar {
font-size: 14px;
}
.xmsall_text_avatar {
font-size: 12px;
}

.xxmsall_text_avatar {
font-size: 10px;
}

</style>
