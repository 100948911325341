import { defineStore, acceptHMRUpdate } from 'pinia'
import { required } from '@core/utils/validation'
import { TABLE_LOGS, displayMode } from '@core/utils'

// eslint-disable-next-line import/prefer-default-export
export const useLogsStore = defineStore({
  id: 'logs',
  state: () => ({

  }),
  actions: {

    getHeader(payload) {
      return {
        title: 'Logs',
        icon: 'mdi-alert-box',
        name: ['id'],
        folder: payload?.folder,
        display: displayMode(TABLE_LOGS, 'table'),
      }
    },

    getTableOptions() {
      return {
        sortBy: ['id'],
        sortDesc: [true],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: 10,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        {
          text: 'ID',
          value: 'id',
          filterable: true,
          type: 'number',
        },
        {
          text: 'Status',
          value: 'status',
          type: 'chip-type',
          filterable: true,
        },
        {
          text: 'Task ID',
          value: 'payload->>id',
          type: 'number',
          filterable: true,
          sortable: true,
        },
        {
          text: 'CreatedAt',
          value: 'created_at',
          type: 'ago',
        },
        {
          text: 'Task Details',
          value: 'payload',
          type: 'json',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        project_id: payload?.project_id,
      }
    },

    getSchema(item, items, payload) {
      const schema = {
        general: {
          title: 'General',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {
            id: {
              type: 'text',
              label: 'ID',
              class: 'mx-1 mb-2',
              prependInnerIcon: 'mdi-key-outline',
              disabled: true,
              hidden: !!items.length,
              col: 12,
            },
            status: {
              type: 'select',
              subtype: 'translate',
              label: 'Status',
              class: 'mx-1 mb-2',
              items: ['created', 'processing', 'error', 'done'],
              prependInnerIcon: 'mdi-tag',
              disabled: true,
              chips: true,
              rules: items.length > 0 ? [] : [required],
              hidden: !!items.length,
              col: 12,
            },
            'payload->>id': {
              type: 'text',
              label: 'Task ID',
              class: 'mx-1 mb-2',
              prependInnerIcon: 'mdi-identifier',
              disabled: true,
              hidden: !!items.length,
              col: 12,
            },
            payload: {
              type: 'json',
              label: 'Task Details',
              class: 'mx-1 mb-2',
              disabled: true,
              hidden: !!items.length,
              col: 12,
            },
          },
        },
      }

      return schema
    },
  },

})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLogsStore, import.meta.hot))
}
