<template>
  <div
    :style="{visibility: isReady ? 'visible' : 'hidden'}"
    class="chat-log pa-5"
  >
    <div
      class="d-flex justify-center pb-3"
    >
      <v-btn
        v-if="(page!== nbPages)"
        small
        outlined
        icon
        @click="(page = page+1)"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </div>
    <div>
      <div
        v-for="(msgGrp, index) in formattedChatData.formattedChatLog"

        :key="msgGrp.sender_id+String(index)"
        class="chat-group d-flex align-start"
        :class="[{'flex-row-reverse': msgGrp.sender_id === user.id}, { 'mb-8': formattedChatData.formattedChatLog.length-1 !== index }]"
        @click="clickChat"
      >
        <div
          class="chat-avatar"
        >
          <v-avatar
            v-if="msgGrp.contact"
            size="38"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="msgGrp.contact.avatar"
              :src="msgGrp.contact.avatar"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold"
            >{{ avatarText(msgGrp.contact.fullname) }}</span>
          </v-avatar>
        </div>
        <div
          class="chat-body d-inline-flex flex-column px-2"
          :class="msgGrp.sender_id === user.id ? 'align-end' : 'align-start'"
        >
          <p
            v-for="(msgData, msgIndex) in msgGrp.messages"
            :key="msgData.id"
            class="chat-content py-3 px-4 elevation-1"
            :class="[
              msgGrp.sender_id === user.id ? 'bg-card chat-left' : 'primary white--text chat-right',
              msgGrp.messages.length-1 !== msgIndex ? 'mb-2' : 'mb-1'
            ]"
          >
            <v-img
              v-if="msgData.attachment"
              height="150"
              width="150"
              contain
              alt="image"
              :src="msgData.attachment"
            ></v-img>
            <span>{{ msgData.msg }}</span>
          </p>
          <span class="text-xs me-1 text--disabled">{{ $moment(msgGrp.messages[msgGrp.messages.length -1].updated_at).format("lll") }}
            <div class="mt-1"><avatar-member
              v-for="admin in getAdmins(msgGrp.messages)"
              :key="admin.id"
              :member="admin"
              xxsmall
            ></avatar-member></div>
          </span>
        </div>
      </div>
      <div class="d-flex justify-center pb-3">
        <v-btn
          v-if="(page!== 1)"
          small
          outlined
          icon
          @click="(page = page-1)"
        >
          <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from 'vue'
import AvatarMember from '@/components/AvatarMember.vue'
import { avatarText } from '@core/utils/filter'
import { useChatsStore } from '@/stores/chats.store'
import useChatMessages from '@/views/chats/useChatMessages'

export default {
  components: {
    AvatarMember,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const chatsStore = useChatsStore()
    const lastRequest = ref(null)
    const isReady = ref(false)
    const {
      searchQuery, page, nbPages, loading, fetchDatas,
    } = useChatMessages()

    lastRequest.value = new Date()
    watch(loading, () => {
      if (!loading.value) {
        isReady.value = false
        setTimeout(() => {
          emit('scroll-chat')
          isReady.value = true
        }, 200)
      }
    })
    chatsStore.$subscribe(async mutation => {
      if (mutation.payload) {
        if (mutation.payload.eventType === 'UPDATE') {
          if (mutation.payload.item.created_by) {
            await chatsStore.updateSeen()
          }

          if (mutation.payload.item.admin_id === props.user.id) {
            fetchDatas()
          }
        }
      }
    })
    const clickChat = () => {
      chatsStore.updateSeen()
    }

    const formattedChatData = computed(() => {
      let chatLog = []
      if (chatsStore.messages) {
        chatLog = chatsStore.messages
      }
      const { members } = props.chatData

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].sender_id : undefined
      let msgGroup = {
        sender_id: chatMessageSenderId,
        messages: [],
        contact: chatMessageSenderId === props.user.id ? props.user : props.contacts.find(item => item.id === chatMessageSenderId),
      }
      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.sender_id) {
          msgGroup.messages.push({
            msg: msg.message,
            updated_at: msg.updated_at,
            sender_id: msg.sender_id,
            attachment: msg.attachment,
          })
        } else {
          chatMessageSenderId = msg.sender_id
          formattedChatLog.push(msgGroup)
          msgGroup = {
            contact: chatMessageSenderId === props.user.id ? props.user : props.contacts.find(item => item.id === chatMessageSenderId),
            sender_id: msg.sender_id,
            messages: [
              {
                msg: msg.message,
                updated_at: msg.updated_at,
                sender_id: msg.sender_id,
                attachment: msg.attachment,
              },
            ],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        members,
      }
    })

    // Feedback icon
    const getAdmins = msgs => {
      const admins = []
      if (!msgs && msgs.length) { return admins }
      props.chatData.members.forEach(member => {
        if (member.last_sign_in_at >= msgs[msgs.length - 1].updated_at) {
          if (member.admin_id !== msgs[msgs.length - 1].sender_id) {
            const admin = props.contacts.find(item => item.id === member.admin_id)
            if (admin) { admins.push(admin) }
          }
        }
      })

      return admins
    }

    return {
      formattedChatData,
      avatarText,
      getAdmins,
      clickChat,
      searchQuery,
      page,
      nbPages,
      loading,
      fetchDatas,
      isReady,

    }
  },
  watch: {
    chatData: {
      handler() {
        this.fetchDatas()
      },
    },
  },
}
</script>
