import orderBy from 'lodash/orderBy'
import {
  TABLE_CONTACTS, TABLE_FOLDERS, TABLE_PROPERTIES, TABLE_LISTINGS, TABLE_TEMPLATES, TABLE_CAMPAIGNS, TABLE_WORKFLOWS, TABLE_FILES,
} from '@core/utils'

export const getParent = (parentId, list) => {
  let path = ''
  const parent = list.find(f => f.id === parentId)
  if (parent) {
    if (parent.parent_id) {
      path = `${path}${getParent(parent.parent_id, list)}/${parent.name}`
    } else {
      path = `${path}${parent.name}`
    }
  }

  return path
}

export const getParentIds = (parentId, list) => {
  const ids = []
  const parent = list.find(f => f.id === parentId)
  if (parent && parent.id) {
    const tmp = getParentIds(parent.parent_id, list)
    ids.push(parent.id)
    tmp.forEach(id => ids.push(id))
  } else if (parentId) ids.push(parentId)

  return ids
}

export const searchFolder = (element, id) => {
  if (!element) return null
  if (element.id === id) {
    return element
  }
  if (element.children) {
    let result = null
    element.children.forEach(child => {
      result = searchFolder(child, id)
    })

    return result
  }

  return null
}
export const getChildren = (type, folder) => {
  const children = []
  if (type === TABLE_FOLDERS) {
    if (folder.properties?.length) {
      children.push({
        name: folder.name,
        id: `${folder.id}_properties`,
        table: TABLE_PROPERTIES,
        parent_id: folder.id,
        count: folder.properties?.length,
        icon: 'mdi-database',
        title: 'Properties',
        query: { column: 'folder_id', value: folder.id, operator: 'eq' },
      })
    }
    if (folder.listings?.length) {
      children.push({
        name: folder.name,
        id: `${folder.id}_listings`,
        table: TABLE_LISTINGS,
        parent_id: folder.id,
        count: folder.listings?.length,
        icon: 'mdi-text-search',
        title: 'Listings',
        query: { column: 'folder_id', value: folder.id, operator: 'eq' },
      })
    }
    if (folder.templates?.length) {
      children.push({
        name: folder.name,
        id: `${folder.id}_templates`,
        table: TABLE_TEMPLATES,
        parent_id: folder.id,
        count: folder.templates?.length,
        icon: 'mdi-file-image',
        title: 'Templates',
        query: { column: 'folder_id', value: folder.id, operator: 'eq' },
      })
    }
    if (folder.campaigns?.length) {
      children.push({
        name: folder.name,
        id: `${folder.id}_campaigns`,
        table: TABLE_CAMPAIGNS,
        parent_id: folder.id,
        count: folder.campaigns?.length,
        icon: 'mdi-email-fast-outline',
        title: 'Campaigns',
        query: { column: 'folder_id', value: folder.id, operator: 'eq' },
      })
    }
    if (folder.workflows?.length) {
      children.push({
        name: folder.name,
        id: `${folder.id}_workflows`,
        table: TABLE_WORKFLOWS,
        parent_id: folder.id,
        count: folder.workflows?.length,
        icon: 'mdi-sitemap-outline',
        title: 'Workflows',
        query: { column: 'folder_id', value: folder.id, operator: 'eq' },
      })
    }
    if (folder.files?.length) {
      children.push({
        name: folder.name,
        id: `${folder.id}_files`,
        table: TABLE_FILES,
        parent_id: folder.id,
        count: folder.files?.length,
        icon: 'mdi-file-document',
        title: 'Files',
        query: { column: 'folder_id', value: folder.id, operator: 'eq' },
      })
    }
  } else if (type === TABLE_CONTACTS) {
    if (folder.listings) {
      folder.listings = orderBy(folder.listings, [listing => listing.name.toLowerCase()], ['asc'])
      folder.listings.forEach(listing => {
        children.push({
          name: folder.name,
          title: listing.name,
          id: `${listing.id}_listings`,
          table: TABLE_CONTACTS,
          parent_id: folder.id,
          count: listing.objects,
          icon: 'mdi-text-search',
          query: { column: `listings->>${listing.id}`, operator: 'neq', value: 'null' },
        })
      })
    }
  } else if (type === TABLE_PROPERTIES) {
    if (folder.models) {
      folder.models = orderBy(folder.models, [model => model.name?.toLowerCase()], ['asc'])
      folder.models.forEach(model => {
        children.push({
          name: folder.name,
          title: model.name,
          id: `${model.id}_models`,
          table: TABLE_PROPERTIES,
          parent_id: folder.id,
          count: model.properties.length,
          icon: 'mdi-toy-brick',
          query: { column: 'model_id', operator: 'eq', value: model.id },
        })
      })
    }
  }

  return children
}
