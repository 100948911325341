export default {
  AllProjects: 'All projects',
  Dashboard: 'Dashboard',
  Olympics: 'Olympics',
  Admins: 'Admins',
  Settings: 'Settings',
  Organizations: 'Organizations',
  Projects: 'Projects',
  Project: 'Project',
  AccountSettings: 'Settings',
  CreateProject: 'Create a project',
  StartProject: 'Start project',
  ActiveProject: 'Active project',
  ActivePlan: 'Active plan',
  Complete: 'Complete',
  Cancel: 'Cancel',
  Next: 'Next',
  Previous: 'Previous',
  Reset: 'Reset',
  CreateOrganization: 'Create an organization',
  AllowedImageFormats: 'Allowed JPG, GIF or PNG',
  UploadPicture: 'Upload picture',
  Name: 'Name',
  Organization: 'Organization',
  Description: 'Description',
  ProjectDetails: "Project's details",
  SetupProjectDetails: 'Setup your project details',
  OrganizationPlan: "Organization's plan",
  SetupOrganizationPlan: 'Setup your plan',
  ReviewProjectInformations: 'Review project informations',
  SubmitProject: 'Submit',
  Monthly: 'Monthly',
  Annually: 'Annually',
  PricingPlans: 'Pricing plans',
  PricingDetails: 'Start building for free, then add a plan. Account plans unlock additional features.',
  Popular: 'Popular',
  ChoosePlan: 'Choose plan',
  ChooseYourPlan: 'Choose plan',
  CurrentPlan: 'Current plan',
  CurrentPlanIs: 'Current plan is',
  month: 'month',
  year: 'year',
  Edit: 'Edit',
  Delete: 'Delete',
  Submit: 'Submit',
  Save: 'Save',
  BillingAddress: 'Billing address',
  Address: 'Address',
  EditAddress: 'Edit address',
  AddBillingAddress: 'Add your billing address.',
  PaymentMethods: 'Payment methods',
  AddCard: 'Add card',
  Company: 'Company',
  BillingEmail: 'Billing email',
  VATNumber: 'VAT number',
  Country: 'Country',
  ZipCode: 'Zip code',
  CardExpiresAt: 'Card expires at',
  CardNumber: 'Card number',
  CardHolder: 'Card holder',
  CardStatus: 'Card status',
  General: 'General',
  BillingPlan: 'Plan',
  BillingPayment: 'Billing',
  Members: 'Members',
  Invoices: 'Invoices',
  CreateInvoice: 'Create invoice',
  CancelSubscription: 'Cancel subscription',
  PayInvoice: 'Pay invoice',
  SeeInvoice: 'See invoice',
  Upgrade: 'Upgrade',
  Active: 'Active',
  UpgradePlan: 'Upgrade plan',
  ChooseBestPlan: 'Choose the best plan for your project.',
  PaymentInterval: 'Payment interval',
  CreatedAt: 'Created at',
  UpdatedAt: 'Updated at',
  UpdatedBy: 'Updated by',
  CreatedBy: 'Created by',
  EndAt: 'End at',
  StartAt: 'Start at',
  SubscriptionStatus: 'Subscription status',
  Days: 'Days',
  Profile: 'Profile',
  Logout: 'Logout',
  MM: 'MM',
  YYYY: 'YYYY',
  CVV: 'CVV',
  Primary: 'Primary',
  Secondary: 'Secondary',
  Expired: 'Expired',
  SubscriptionIncomplete: 'Your subscription is incomplete. Please pay the invoice to active it.',
  NeedAttention: 'We need your attention!',
  AllOrganizations: 'All organizations',
  View: 'View',
  Website: 'Website',
  UploadPhoto: 'Upload new photo',
  FullName: 'Full name',
  OrganizationCreatedSuccessfully: 'Organization created successfully.',
  OrganizationSavedSuccessfully: 'Organization saved successfully.',
  ProjectSavedSuccessfully: 'Project saved successfully.',
  ProjectCreatedSuccessfully: 'Project created Successfully.',
  ProfileSavedSuccessfully: 'Profile saved successfully.',
  PaswwordSavedSuccessfully: 'Password saved successfully.',
  WorkspaceCreatedSuccessfully: 'Workspace created successfully.',
  SavedSuccessfully: 'Saved successfully.',
  OrganizationDetails: "Organization's details",
  SetupOrganizationDetails: 'Setup your organization details',
  OrganizationBilling: "Organization's billing",
  SetupOrganizationBilling: 'Setup your payment method',
  Create: 'Create',
  Finish: 'Finish',
  ViewInvoice: 'View invoice',
  SearchInvoice: 'Search invoice',
  SelectStatus: 'Select status',
  Phone: 'Phone',
  SearchMember: 'Search member',
  AddMember: 'Add a member',
  EditMember: 'Edit member',
  Role: 'Role',
  Email: 'Email',
  InviteMember: 'Invite a member to organization',
  MemberNeedBeRegistered: "Members you'd like to invite must already be registered",
  AreYouSure: 'Are you sure?',
  DeletingMemberPermanent: "Deleting member is permanent and can't be undone..",
  PermissionDeniedProject: 'You need to be an owner member to create a project.',
  DangerZone: 'Danger Zone',
  DeleteOrganizationDetails:
    'Deleting this organization will also remove all projects affiliated. Make sure you have made a backup if you want to keep your data.',
  DeleteOrganization: 'Delete organization',
  DeleteProjectDetails:
    'Deleting this project will also remove all data affiliated. Make sure you have made a backup if you want to keep your data.',
  DeleteProject: 'Delete project',
  Password: 'Password',
  ForgotPassword: 'Forgot password?',
  WelcomeSignin: 'Welcome! 👋🏻',
  WelcomeSigninDetails: 'Please sign-in to your account and start the adventure',
  Login: 'Login',
  NewOnPlatform: 'New on our platform?',
  CreateAnAccount: 'Create an account',
  SigninTermsPolicy: 'By signing in, you agree to Micleo’s Terms of Service and Privacy Policy.',
  Or: 'Or',
  OR: 'OR',
  AND: 'AND',
  WelcomeSignup: 'Adventure starts here 🚀',
  WelcomeSignupDetails: 'Please sign-up and start the adventure',
  ConfirmYourEmailSignup: 'Please follow the link send by email to confirm your signup.',
  ConfirmYourEmailUpdated: 'Please follow the link send by email to confirm your change.',
  ResendConfirmation: 'Resend confirmation',
  SignUp: 'Sign up',
  SignupTermsPolicy: 'By signing up, you agree to Micleo’s Terms of Service and Privacy Policy.',
  AlreadyHaveAccount: 'Already have an account?',
  SignInInstead: 'Sign in instead',
  PrivacyPolicy: 'Privacy policy',
  Close: 'Close',
  ForgotPasswordDetails: "Enter your email and we'll send you instructions to reset your password",
  ClickResetLink: 'Please click on the reset link in the email.',
  SendResetLink: 'Send reset link',
  BackToLogin: 'Back to login',
  NotAuthorized: 'You are not authorized! 🔐',
  NotAuthorizedDetails: 'You don’t have permission to access this page. Go Home!',
  BackToHome: 'Back to home',
  PageNotFound: 'Page not found',
  PageNotFoundDetails: "We couldn't find the page you are looking for.",
  ErrorOccured: 'Error occured',
  ErrorOccuredDetails: "We couldn't find the page you are looking for.",
  UnderMaintenance: 'Under maintenance! 🚧',
  UnderMaintenanceDetails: "Sorry for the inconvenience but we're performing some maintenance at the moment",
  SearchProject: 'Search project',
  TypeDelete: 'Type in “Delete”',
  PermissionDeniedOrganization: 'You must create a billing address to go next.',
  Member: 'Member',
  Actions: 'Actions',
  Action: 'Action',
  Total: 'Total',
  Date: 'Date',
  Status: 'Status',
  paid: 'Paid',
  open: 'Open',
  draft: 'Draft',
  processing: 'Processing',
  void: 'Void',
  uncollectible: 'Uncollectible',
  ID: '#ID',
  Slug: '#Slug',
  Contacts: 'Contacts',
  Export: 'Export',
  SearchContact: 'Search contact',
  EditContacts: 'Edit contacts',
  EditContact: 'Edit contact',
  sheets: 'contact(s)',
  DeleteContacts: 'Delete contacts',
  EditLists: 'Edit lists',
  EditDatas: 'Edit datas',
  GroupedAction: 'Grouped action',
  ImportContacts: 'Import contacts',
  AddContact: 'Add contact',
  AllContacts: 'All contacts',
  DeleteContactsAlert: 'Delete permanently this contact(s)?',
  tooShort: 'Too short',
  French: 'French',
  English: 'English',
  AllrightsReserved: 'All rights Reserved',
  FieldRequired: 'This field is required',
  RequiredError: '%{field} is required',
  FieldEmailError: 'The Email field must be a valid email',
  FieldPasswordError:
    'Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars',
  FieldConfirmPasswordError: 'Password and confirmation field do not match',
  FieldIntegerError: 'This field must be an integer',
  FieldRegexError: 'The field format is invalid',
  FieldInvalidFormatError: 'The field format is invalid',
  InvalidFormatError: '%{value} is invalid for %{field}',
  FieldAlphaError: 'This field may only contain alphabetic characters',
  FieldURLError: 'URL is invalid',
  FieldIntegerBetweenError: 'Enter number betweefn %{min} and %{max}',
  FieldMinLengthError: 'This field must be at least %{length} characters',
  FieldMaxLengthError: 'This field must be at most %{length} characters',
  MinLengthError: '%{field} must be at least %{length} characters',
  MaxLengthError: '%{field} must be at most %{length} characters',
  UniqueError: "'%{value}' already exists, %{field} must be unique",
  'Invalid login credentials': 'Invalid login credentials',
  'Password should be at least 6 characters': 'Password should be at least 6 characters',
  'too-short': 'Too short',
  'too-long': 'Too long',
  'invalid-country-code': 'Invalid country code',
  reader: 'reader',
  owner: 'owner',
  editor: 'editor',
  resource_missing: 'Please fill a default payment method to continue.',
  SeeMyPortail: 'See my portail',
  CancelSubscriptionDetails:
    'Your plan will be cancelled, but it will remain available until the end of your billing period. If you change your mind, you can renew your subscription.',
  ActionsTooltip: 'Perform actions on the table',
  SettinsTooltip: 'Import, configure the contact form',
  AddListingTootltip: 'Create a listing / a view',
  SearchOrganization: 'Search an organization',
  ChangePassword: 'Change Password',
  CurrentPassword: 'Current Password',
  NewPassword: 'New Password',
  PasswordRequirements: 'Password Requirements:',
  PasswordMinimum8: 'Minimum 8 characters long',
  Password1LowerCase: 'At least one lowercase character',
  Password1Symbol: 'At least one number, symbol, or whitespace character',
  ConfirmPassword: 'Confirm Password',
  RecentDevices: 'Recent devices',
  Browser: 'Browser',
  Device: 'Device',
  Location: 'Location',
  RecentActivity: 'Recent Activity',
  MFA: 'Multi-Factor Authentication',
  MFAEnabled: 'Multi-Factor Authentication is enabled.',
  MFADisabled: 'Multi-Factor Authentication is not enabled yet.',
  MFAInfo:
    'Two-factor authentication with TOTP adds an additional layer of security to your account by requiring more than just a password to log in.',
  EnableMFA: 'Enable MFA',
  DisableMFA: 'Disable MFA',
  FieldMaxLength: 'Field must be {length} characters or less',
  ConnectedAccounts: 'Connected Accounts',
  unverified: 'Unverified',
  verified: 'Verified',
  EnterCodeMFA: 'Enter your TOTP Code',
  InvalidTOTP: 'Invalid TOTP code entered',
  ScanQRCodeTOTP: 'Scan this QR code with your TOTP App',
  Language: 'Language',
  Appearance: 'Appearance',
  Applications: 'Applications',
  Integrations: 'Integrations',
  Campaigns: 'Campaigns',
  Folders: 'Folders',
  Listings: 'Listings / Views',
  Activities: 'Activities',
  Properties: 'Properties',
  AdvancedSettings: 'Advanced Settings',
  Marketing: 'Marketing',
  Workflows: 'Workflows',
  YourPassword: 'Your password',
  PasswordInfo: 'Be sure to change your password regularly to improve your account security.',
  Security: 'Security',
  AddProperty: 'Add a property',
  SearchProperty: 'Search property',
  CreditCard: 'Credit card',
  ResetPassword: 'Reset Password',
  DefaultValue: 'Default value',
  Validation: 'Validation',
  Type: 'Type',
  Category: 'Category',
  Required: 'Required',
  Minimum: 'Minimum',
  Maximum: 'Maximum',
  Regex: 'Regex',
  Unique: 'Unique',
  text: 'Text',
  textarea: 'Textarea',
  number: 'Number',
  date: 'Date',
  time: 'Time',
  array: 'Array',
  phone: 'Phone',
  email: 'Email',
  password: 'Password',
  url: 'URL',
  color: 'Color',
  file: 'File',
  boolean: 'Boolean',
  singleline: 'Singleline',
  dropdown: 'Dropdown',
  switch: 'Switch',
  slider: 'Slider',
  rating: 'Rating',
  radioboxes: 'Radioboxes',
  checkboxes: 'Checkboxes',
  combobox: 'Combobox',
  slug: 'Slug',
  MinimumLength: 'Minimum Length',
  MaximumLength: 'Maximum Length',
  'date-picker': 'Date Picker',
  'time-picker': 'Time Picker',
  'date-time-picker': 'Date Time Picker',
  EditProperty: 'Edit property',
  TimeZone: 'Time Zone',
  NewProperty: 'New property',
  ModelsProperties: 'Properties models',
  AddPropertyTootltip: 'Add a new property for your contacts',
  ContactsProperties: 'Contacts properties',
  Notifications: 'Notifications',
  ReadAllNotifications: 'Read all notifications',
  Chat: 'Chat',
  Chats: 'Chats',
  StartConversation: 'Start Conversation',
  Search: 'Search',
  CreateChat: 'Create Chat',
  Send: 'Send',
  TypeYourMessage: 'Type your message',
  Header: 'Header',
  EditProperties: 'Edit properties',
  Visibility: 'Visibility',
  Hidden: 'Hidden',
  Details: 'Details',
  Import: 'Import',
  Table: 'Tableau',
  NewItem: 'New item',
  EditItems: 'Edit items',
  SearchItem: 'Search item',
  NewFolder: 'Add folder',
  EditFolders: 'Edit folders',
  EditFolder: 'Edit folder',
  Path: 'Path',
  Folder: 'Folder',
  SearchFolder: 'Search Folder',
  FolderError: 'This folder has subfolders',
  '#': '#',
  ToggleAll: 'Toggle all',
  ClearAll: 'Clear all',
  true: 'True',
  false: 'False',
  items: 'item(s)',
  noresultsmatching: 'No results matching. Press <kbd>{enter}</kbd> to create a new one',
  Add: 'Add',
  Options: 'Options',
  NewOption: 'Add option',
  EditOption: 'Edit option',
  EditOptions: 'Edit options',
  SearchOption: 'Search option',
  Value: 'Value',
  Firstname: 'Firstname',
  Lastname: 'Lastname',
  'Job Title': 'Job Title',
  ImportData: 'Import your data',
  ImportDataDetails: 'Choose a file to import or copy/paste data',
  ImportFile: 'Import file',
  CopyPaste: 'Copy/Paste',
  AllowedImportFormats: 'CSV or XLS extensions allowed',
  MappingImport: 'Mapping your data',
  MappingImportDetails: 'Match your model and your imported data',
  replace: 'Replace',
  merge: 'Merge',
  Model: 'Model',
  DragDrop: 'Drag and drop',
  ConfirmImport: 'Confirmation',
  ConfirmImportDetails: 'Validation before import',
  Conflict: 'Conflict',
  ItemsImportedDetails: '%{inserts} items(s) added and %{updates} updated!',
  ItemsImported: 'Items(s) successfully imported!',
  Items: 'Items',
  ExportColumns: 'Choose your columns',
  ExportColumnsDetails: 'Define the columns of your export',
  ConfirmExport: 'Confirmation',
  ConfirmExportDetails: 'Validation before export',
  Error: 'Error',
  errors: 'error(s)',
  corrects: 'correct(s)',
  Test: 'Test',
  'color-picker': 'Color picker',
  Encrypt: 'Encrypt',
  Color: 'Color',
  ReadOnly: 'ReadOnly',
  NewListing: 'New Listing / View',
  SearchListing: 'Search Listing / View',
  EditListings: 'Edit Listings / Views',
  EditListing: 'Edit Listing / View',
  AddListing: 'Add Listing / View',
  Property: 'Property',
  Condition: 'Condition',
  Filters: 'Filters',
  Filter: 'Filter',
  AddCondition: 'Add condition',
  AddFilter: 'Add filtrer',
  Operation: 'Operation',
  LastSyncAt: 'Last synchronization',
  NewCampaign: 'New Campaign',
  SearchCampaign: 'Search Campaign',
  EditCampaigns: 'Edit Campaigns',
  EditCampaign: 'Edit Campaign',
  AddCampaign: 'Add Campaign',
  NewTemplate: 'New Template',
  SearchTemplate: 'Search Template',
  EditTemplates: 'Edit Templates',
  EditTemplate: 'Edit Template',
  AddTemplate: 'Add Template',
  scheduled: 'Scheduled',
  archived: 'Archived',
  active: 'Active',
  sent: 'Sent',
  campaign: 'Campaign',
  Campaign: 'Campaign',
  transactional: 'Transactional',
  Editor: 'Editor',
  auto: 'Automatic',
  manual: 'Manual',
  Subject: 'Subject',
  SenderEmail: 'Sender email',
  SenderName: 'Sender name',
  ReplyTo: 'Reply To',
  ScheduledAt: 'Scheduled At',
  DeliveredAt: 'Delivered At',
  Recipients: 'Recipients',
  EditRecipients: 'Edit Recipients',
  Statistics: 'Statistics',
  Models: 'Models / Objects',
  NewModel: 'New Model',
  SearchModel: 'Search Model',
  EditModels: 'Edit Models',
  EditModel: 'Edit Model',
  AddModel: 'Add Model',
  Confirm: 'Confirm',
  NewIntegration: 'New Integration',
  SearchIntegration: 'Search Integration',
  EditIntegrations: 'Edit Integrations',
  AddIntegration: 'Add Integration',
  Enable: 'Enable',
  Disable: 'Disable',
  Enabled: 'Enabled',
  Disabled: 'Disabled',
  payments: 'Payments',
  events: 'Events',
  sms: 'SMS',
  SelectTags: 'Select a Tag',
  api_key: 'API Key',
  api_secret: 'API Secret',
  SendCampaign: "Send the campaign '%{campaign}' to %{recipients} recipient(s).",
  AddWebhook: 'Add Webhook',
  TestCampaign: 'Test the campaign',
  ViewStats: 'View Stats',
  ViewLogs: 'View Logs',
  SearchActivity: 'Search Activity',
  Logs: 'Logs',
  SearchLog: 'Search log',
  created: 'Created',
  done: 'Done',
  TaskID: 'Task ID',
  TaskDetails: 'Task Détails',
  error: 'Error',
  NewWorkflow: 'New Workflow',
  SearchWorkflow: 'Search Workflow',
  EditWorkflows: 'Edit Workflows',
  EditWorkflow: 'Edit Workflow',
  AddWorkflow: 'Add Workflow ',
  Input: 'Input',
  Output: 'Output',
  NewObjectInListing: 'New contact in Listing',
  NewActivity: 'New Activity',
  SendCampaignAction: 'Send Campaign',
  AddToListing: 'Add to Listing',
  SetProperties: 'Save properties',
  Delay: 'Delay',
  Delays: 'Delays',
  Times: 'Times',
  Triggers: 'Triggers',
  Conditions: 'Conditions',
  domain: 'Domain',
  Recipient: 'Recipient',
  account_sid: 'Account SID',
  auth_token: 'Auth token',
  token_id: 'Token ID',
  token_secret: 'Token secret',
  Default: 'Default',
  Data: 'Data',
  NewData: 'New Data',
  SearchData: 'Search Data',
  EditData: 'Edit Data',
  AddData: 'Add Data',
  Templates: 'Templates',
  trialdays: 'trial days',
  CancelAt: 'Cancel at',
  NewForm: 'New Form',
  SearchForm: 'Search Form',
  EditForms: 'Edit Forms',
  EditForm: 'Edit Form',
  AddForm: 'Add Form',
  fm: {
    components: {
      fields: {
        input: 'Input',
        textarea: 'Textarea',
        number: 'Number',
        radio: 'Radio',
        checkbox: 'Checkbox',
        time: 'Time',
        date: 'Date',
        rate: 'Rate',
        color: 'Color',
        select: 'Select',
        switch: 'Switch',
        slider: 'Slider',
        text: 'Text',
        blank: 'Custom',
        fileupload: 'File',
        imgupload: 'Image',
        editor: 'Editor',
        cascader: 'Cascader',
        table: 'Sub-table',
        grid: 'Grid',
        tabs: 'Tabs',
        divider: 'Divider',
      },
      basic: {
        title: 'Basic Component',
      },
      advance: {
        title: 'Advance Component',
      },
      layout: {
        title: 'Layout',
      },
    },
    description: {
      containerEmpty: 'You can drag and drop the item from the left to add components',
      configEmpty: 'Please add a component',
      tableEmpty: 'You can drag and drop the item from the left to add components',
      uploadJsonInfo: 'There is the format of JSON below，you can overwrite it with you own JSON code',
    },
    message: {
      copySuccess: 'Copy Successed',
      validError: 'Form data validation failed',
    },
    actions: {
      import: 'Import JSON',
      clear: 'Clear',
      preview: 'Preview',
      json: 'Generate JSON',
      code: 'Generate Code',
      getData: 'Get Data',
      reset: 'Reset',
      copyData: 'Copy Data',
      cancel: 'Cancel',
      confirm: 'Confirm',
      addOption: 'Add Option',
      addColumn: 'Add Column',
      addTab: 'Add Tab',
      upload: 'Upload',
      add: 'Add',
    },
    config: {
      form: {
        title: 'Form Attribute',
        labelPosition: {
          title: 'Label Position',
          left: 'Left',
          right: 'Right',
          top: 'Top',
        },
        labelWidth: 'Label Width',
        size: 'Size',
        customClass: 'Custom Class',
      },
      widget: {
        title: 'Component Attribute',
        model: 'ID',
        name: 'Name',
        width: 'Width',
        height: 'Height',
        size: 'Size',
        labelWidth: 'Label Width',
        custom: 'Custom',
        placeholder: 'Placeholder',
        layout: 'Layout',
        block: 'Block',
        inline: 'Inline',
        contentPosition: 'Content Position',
        left: 'Left',
        right: 'Right',
        center: 'Center',
        showInput: 'Display Input Box',
        min: 'Minimum',
        max: 'Maximum',
        step: 'Step',
        multiple: 'Multiple',
        filterable: 'Searchable',
        allowHalf: 'Allow Half',
        showAlpha: 'Support transparency options',
        showLabel: 'Show lable',
        option: 'Option',
        staticData: 'Static Data',
        remoteData: 'Remote Date',
        remoteFunc: 'Remote Function',
        value: 'Value',
        label: 'Label',
        childrenOption: 'Sub-Option',
        defaultValue: 'Default Value',
        showType: 'Display type',
        isRange: 'Range Time',
        isTimestamp: 'Get time stamp',
        startPlaceholder: 'Placeholder of start time',
        endPlaceholder: 'Placeholder of end time',
        format: 'Format',
        limit: 'Maximum Upload Count',
        isQiniu: 'Upload with Qiniu Cloud',
        tokenFunc: 'A funchtin to get Qiniu Uptoken',
        imageAction: 'Picture upload address',
        tip: 'Text Prompt',
        action: 'Upload Address',
        defaultType: 'Data Type',
        string: 'String',
        object: 'Object',
        array: 'Array',
        number: 'Number',
        boolean: 'Boolean',
        integer: 'Integer',
        float: 'Float',
        url: 'URL',
        email: 'E-mail',
        hex: 'Hexadecimal',
        gutter: 'Grid Spacing',
        columnOption: 'Column Configuration',
        span: 'Grid spans',
        justify: 'Horizontal Arrangement',
        justifyStart: 'Start',
        justifyEnd: 'End',
        justifyCenter: 'Center',
        justifySpaceAround: 'Space Around',
        justifySpaceBetween: 'Space Between',
        align: 'Vertical Arrangement',
        alignTop: 'Top',
        alignMiddle: 'Middle',
        alignBottom: 'Bottom',
        type: 'Type',
        default: 'Default',
        card: 'Tabs',
        borderCard: 'Border-Card',
        tabPosition: 'Tab Position',
        top: 'Top',
        bottom: 'Bottom',
        tabOption: 'Label Configuration',
        tabName: 'Tab Name',
        customClass: 'Custom Class',
        attribute: 'Attribute Action',
        dataBind: 'Data Binding',
        hidden: 'Hidden',
        readonly: 'Read Only',
        disabled: 'Disabled',
        editable: 'Text box is editable',
        clearable: 'Display Clear Button',
        arrowControl: 'Use the arrow for time selection',
        isDelete: 'Deletable',
        isEdit: 'Editable',
        showPassword: 'Display Password',
        validate: 'Validation',
        required: 'Required',
        patternPlaceholder: 'Fill in the regular expressions',
        newOption: 'New Option',
        tab: 'Tab',
        validatorRequired: 'Required',
        validatorType: 'Invaild format',
        validatorPattern: 'Unmatched pattern',
        showWordLimit: 'Show word limit',
        maxlength: 'Max length',
      },
    },
    upload: {
      preview: 'preview',
      edit: 'replace',
      delete: 'delete',
    },
  },
  Form: 'Form',
  others: 'Others',
  button: 'Button',
  Left: 'Left',
  Right: 'Right',
  Top: 'Top',
  Bottom: 'Bottom',
  Margin: 'Margin',
  Padding: 'Padding',
  TextAlign: 'Text Align',
  Columns: 'Columns',
  Size: 'Size',
  BlocAlignHorizontal: 'Bloc Align Horizontal',
  BlocAlignVertical: 'Bloc Align Vertical',
  Forms: 'Forms',
  Dark: 'Dark',
  Outlined: 'Outlined',
  FontFamily: 'Font Family',
  trialing: 'Trialing',
  incomplete: 'Incomplete',
  UsageSummary: 'Usage Summary',
  ViewActivities: 'View Activities',
  RolesPermissions: 'Roles & Permissions',
  Administrator: 'Administrator',
  Reader: 'Reader',
  AddRole: 'Add role',
  SearchRole: 'Search role',
  read: 'Read',
  create: 'Create',
  update: 'Update',
  delete: 'Delete',
  Permissions: 'Permissions',
  SelectAll: 'Select All',
  EditRole: 'Edit Role',
  Roles: 'Roles',
  Objects: 'Objects',
  Subscriptions: 'Subscriptions',
  Files: 'Files',
  NewFile: 'New Document',
  SearchFile: 'Search a Document',
  EditFiles: 'Edit Documents',
  EditFile: 'Edit Document',
  AddFile: 'Add Documennt',
  File: 'File',
  UploadFile: 'Upload File',
  Upload: 'Upload',
  Thumbnail: 'Thumbnail',
  Viewer: 'Viewer',
  Extension: 'Extension',
  click: 'click',
  total: 'total',
  opened: 'opened',
  unique_opened: 'unique_opened',
  request: 'request',
  delivred: 'delivred',
  Download: 'Download',
  QuickstartTemplates: 'Quickstart templates',
  view: 'View',
  State: 'State',
  Comments: 'Comments',
  Repositories: 'Repositories',
  NewIssue: 'New Issue',
  SortBy: 'Sort by',
  EmbedCode: 'Embed Code',
  CopyCode: 'Copy the code',
  Tokens: 'Access Tokens',
  AddToken: 'Add Token',
  EditToken: 'Edit Token',
  SearchToken: 'Search Token',
  CreateSupabaseAccount: 'Supabase Account',
  CreateSupabaseAccountDetails: 'Connect to your Supabase account and authorize the Micleo App.',
  SupabaseIntegration: 'Supabase Integration',
  SupabaseAPIToken: 'Supabase API Token',
  SupabaseAPITokenDetails: 'Create and retrieve a new Supabase Access Token',
  SupabaseProjects: 'Supabase Projects',
  SupabaseProjectsDetails: 'Import your new Supabase Project and start the configuration.',
  NoProjects: 'No projects found',
  AddProject: 'Add Project',
  RemoveProject: 'Remove Project',
  DatabaseVersion: 'Database Version',
  DatabaseHost: 'Database Host',
  Region: 'Region',
  ProjectAPIKeys: 'Keys',
  SearchAPIKey: 'Search API Key',
  ProjectSettings: 'Project Settings',
  Health: 'Health',
  APIsettings: 'API Settings',
  DatabaseSettings: 'Database Settings',
  Database: 'Database',
  SMTPSettings: 'SMTP Settings',
  ImportProject: 'Import Project',
  db_schema: 'Exposed schemas',
  db_extra_search_path: 'Extra search path',
  max_rows: 'Max rows',
  jwt_secret: 'JWT secret',
  pool_mode: 'Pool Mode',
  ignore_startup_parameters: 'Ignore startup parameters',
  max_client_conn: 'Max Client Connections',
  connection_string: 'Connection string',
  smtp_admin_email: 'Admin Email / Sender Email',
  smtp_host: 'Host',
  smtp_port: 'Port number',
  smtp_user: 'Username',
  smtp_pass: 'Password',
  smtp_max_frequency: 'Minimum interval between emails being sent',
  smtp_sender_name: 'Admin Name / Sender Name',
  rate_limit_email_sent: 'Rate limit Email send',
  ProjectUrl: 'Project URL',
  AllWorkspaces: 'All Workspaces',
  CreateWorkspace: 'Create Workspace',
  DeleteWorkspace: 'Delete Workspace',
  DeleteWorkspaceDetails:
  'Deleting this workspace will also remove all data affiliated. Make sure you have made a backup if you want to keep your data.',
  WorkspaceSavedSuccessfully: 'Workspace saved successfully.',
  AppCustomizer: 'App Config.',
  AppName: 'App Name',
  FooterName: 'Footer/Copyright Name',
  AppMetaDescription: 'App Meta Description',
  Theming: 'Theming',
  Skin: 'Skin',
  Mode: 'Mode',
  MenuLayout: 'Menu Layout',
  AppBarType: 'App Bar Type',
  FooterType: 'Footer Type',
  RouterTransition: 'Router Transition',
  Colors: 'Colors',
  Light: 'Light',
  Success: 'Success',
  Info: 'Info',
  Warning: 'Warning',
  Logo: 'Logo',
  LogoDark: 'Dark Logo',
  Gtag: 'Gtag',
  ViewApp: 'View App',
  TriggerUpdate: 'Trigger Update',
  UploadLogo: 'Upload Logo',
  SupabaseProject: 'Supabase Project',
}
