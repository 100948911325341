import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase, supabaseDomain } from '@/plugins/supabase'

// eslint-disable-next-line import/prefer-default-export
export const useWorkspacesLogsStore = defineStore({
  id: 'workspaces_logs',
  state: () => ({
    Log: null,
  }),
  getters: {

  },
  actions: {
    async getLogs(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0

      let query = supabase
        .from('logs')
        .select(
          '*',
          { count: 'exact' },
        )
        .eq('project_id', payload.workspaceId).order('created_at', { ascending: false })

      if (payload && payload.options.itemsPerPage !== -1 && from !== to) query = query.range(from, to)
      const { data, count, error } = await query
      if (error) {
        throw error
      }

      return { data, count }
    },

    async getLog(id) {
      const { data, error } = await supabase
        .from('logs')
        .select(
          '*',
        )
        .eq('id', id)
        .single()
      if (error) {
        throw error
      }
      data.secret = data.decrypted_secret

      return data
    },

    async listenLogs(workspaceId) {
      if (supabaseDomain.indexOf('localhost') < 0) {
        return supabase
          .channel('listenLogs')
          .on('postgres_changes', {
            event: '*', schema: 'public', table: 'logs', filter: `project_id=eq.${workspaceId}`,
          }, async payload => {
            this.$patch({ eventType: payload.eventType, item: payload.new.id ? payload.new : payload.old })
          })
          .subscribe()
      }

      return null
    },

    async listenLog(id) {
      this.Log = await this.getLog(id)

      return supabase
        .channel(`listenLog-${id}`)
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'logs',
            filter: `id=eq.${id}`,
          },
          async () => {
            this.Log = await this.getLog(id)
          },
        )
        .subscribe()
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWorkspacesLogsStore, import.meta.hot))
}
