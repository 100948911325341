import { defineStore, acceptHMRUpdate } from 'pinia'
import { appConfig } from '@/plugins/micleoConfig'
// eslint-disable-next-line import/prefer-default-export
export const useConfigStore = defineStore({
  id: 'config',
  state: () => ({
    app: {
      name: appConfig?.theme?.app.name,
      copyright: appConfig?.theme?.app.copyright,
      description: appConfig?.theme?.app.description,
      logo: appConfig?.theme?.app.logo,
      logoDark: appConfig?.theme?.app.logoDark,
      contentLayoutNav: localStorage.getItem('menu')
        ? localStorage.getItem('menu')
        : appConfig?.theme?.app.contentLayoutNav,
      routeTransition: appConfig?.theme?.app.routeTransition,
      skinVariant: localStorage.getItem('theme-skin-variant')
        ? localStorage.getItem('theme-skin-variant')
        : appConfig?.theme?.app.skinVariant,
      contentWidth: appConfig?.theme?.app.contentWidth,
    },
    menu: {
      isMenuHidden: appConfig?.theme?.menu.isMenuHidden,
      isVerticalNavMini: appConfig?.theme?.menu.isVerticalNavMini,
    },
    appBar: {
      type: appConfig?.theme?.appBar.type,
      isBlurred: appConfig?.theme?.appBar.isBlurred,
    },
    footer: {
      type: appConfig?.theme?.footer.type,
    },
    themes: appConfig?.theme?.themes,
  }),
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
}
