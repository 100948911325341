<template>
  <v-card
    class="widget-view"
    flat
    :class="{active: selectWidget?.key == element.key}"
    :label="element.name"
    @click.native.stop="handleSelectWidget(index)"
  >
    <v-form-base
      :class="getClass(options)"
      :schema="{ field: { schema :contactsStore.getSchemaProperty(element.options, true) }}"
    ></v-form-base>
    <div v-if="selectWidget?.key == element.key"
         class="widget-view-action"
    >
      <v-icon style="color:white !important;"
              small
              class="mx-1"
              @click.stop="handleWidgetClone(index)"
      >
        mdi-content-copy
      </v-icon>
      <v-icon style="color:white !important;"
              small
              class="mx-1"
              @click.stop="handleWidgetDelete(index)"
      >
        mdi-delete-outline
      </v-icon>
    </div>

    <div v-if="selectWidget?.key == element.key"
         class="widget-view-drag"
    >
      <v-icon style="color:white !important;"
              small
              class="mx-1 drag-widget"
      >
        mdi-arrow-all
      </v-icon>
    </div>
  </v-card>
</template>

<script>
import { useContactsStore } from '@/stores/contacts.store'

export default {
  components: {
  },
  props:
  {
    element: {
      type: Object,
      default: null,
    },
    select: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const contactsStore = useContactsStore()

    const getClass = options => {
      let className = ''
      if (!options) return className
      className += ` text-${options.align} align-${options.align}`

      return className
    }

    return {
      contactsStore,
      getClass,
    }
  },
  data() {
    return {
      selectWidget: this.select,
    }
  },
  watch: {
    select(val) {
      this.selectWidget = val
    },
    selectWidget: {
      handler(val) {
        this.$emit('update:select', val)
      },
      deep: true,
    },
  },
  mounted() {

  },
  methods: {
    handleSelectWidget(index) {
      this.selectWidget = this.data.list[index]
    },
    handleWidgetDelete(index) {
      if (this.data.list.length - 1 === index) {
        if (index === 0) {
          this.selectWidget = {}
        } else {
          this.selectWidget = this.data.list[index - 1]
        }
      } else {
        this.selectWidget = this.data.list[index + 1]
      }

      this.$nextTick(() => {
        this.data.list.splice(index, 1)
      })
    },
    handleWidgetClone(index) {
      const key = `${Date.parse(new Date().toString())}_${Math.ceil(Math.random() * 99999)}`
      const cloneData = {
        ...this.data.list[index],
        key,

      }

      this.data.list.splice(index, 0, cloneData)

      this.$nextTick(() => {
        this.selectWidget = this.data.list[index + 1]
      })
    },
  },
}
</script>
