import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate, canACL } from '@/plugins/acl/checkaccess'
import ability, { setAbilities } from '@/plugins/acl/ability'
import { useUserStore } from '@/stores/user.store'
import { useTableStore } from '@/stores/table.store'
import projects from './projects'
import organizations from './organizations'
import workspaces from './workspaces'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userStore = useUserStore()
      if (userStore.isLoggedIn === true) {
        ability.update(setAbilities(to.params?.id))
        if (canACL('view', 'projects')) return { name: 'projects' }
        if (canACL('view', 'workspaces')) return { name: 'workspaces' }
        if (canACL('view', 'organizations')) return { name: 'organizations' }

        return { name: 'account-settings', params: { tab: 0 } }
      }
      if (userStore.isLoggedIn === false) {
        return { name: 'mfa' }
      }

      return { name: 'auth-login', query: to.query }
    },
  },
  ...projects,
  ...organizations,
  ...workspaces,
  {
    path: '/error-500',
    name: 'error-500',
    component: () => import('@/pages/Error500.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/pages/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/pages/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/pages/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login2',
    name: 'auth-login2',
    component: () => import('@/pages/Login-v2.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/mfa',
    name: 'mfa',
    component: () => import('@/pages/MFA.vue'),
    meta: {
      layout: 'blank',
      resource: 'MFA',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/pages/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/pages/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/pages/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/account-settings/:tab',
    name: 'account-settings',
    component: () => import('@/pages/Account.vue'),
    meta: {
      layout: 'content',
      resource: 'profile',
      action: 'manage',
    },
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/pages/Chats.vue'),
    meta: {
      layout: 'content',
      resource: 'chats',
      action: 'manage',
    },
  },
  {
    path: '/template/:id',
    name: 'template',
    component: () => import('@/pages/Template.vue'),
    meta: {
      layout: 'empty',
      resource: 'public',
    },
  },
  {
    path: '/form/:id',
    name: 'form',
    component: () => import('@/pages/WidgetForm.vue'),
    meta: {
      layout: 'empty',
      resource: 'public',
    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/pages/Test.vue'),
    meta: {
      layout: 'empty',
      resource: 'public',
    },
  },
  {
    path: '/tokens',
    name: 'tokens',
    component: () => import('@/pages/tokens/AccessTokens.vue'),
    meta: {
      layout: 'content',
      resource: 'tokens',
      action: 'manage',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach(async (to, _, next) => {
  if (to.params.folder === 0) to.params.folder = localStorage.getItem('folder') || 0
  if (to.params.chat === 0) to.params.chat = localStorage.getItem('chat') || 0

  const userStore = useUserStore()
  const tableStore = useTableStore()

  if (to.meta?.table) tableStore.route = to

  const user = await userStore.refreshAdmin()
  userStore.user = user
  ability.update(setAbilities(to.params?.id))

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!user) return next({ name: 'auth-login', query: to.query })

    if (userStore.isLoggedIn === false) return next({ name: 'mfa' })

    return next({ name: 'not-authorized' })
  }

  // reset password Email
  if (to.hash.includes('type=recovery')) {
    return next({
      name: 'reset-password',
      query: { access_token: to.hash.replace('#access_token=', '').split('&')[0] },
    })
  }

  // Confirm your signup Email
  if (to.hash.includes('#access_token=')) {
    userStore.user = await userStore.getAdmin(to.hash.replace('#access_token=', '').split('&')[0])
    ability.update(setAbilities(to.params?.id))

    return next('/')
  }

  if (to.hash.includes('error') || to.query.error) {
    next('error-500')
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && user) {
    next('/')
  }

  return next()
})

export default router
