import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase, supabaseDomain } from '@/plugins/supabase'

// eslint-disable-next-line import/prefer-default-export
export const useWorkspacesStore = defineStore({
  id: 'workspaces',
  state: () => ({
    workspace: null,
  }),
  getters: {

  },
  actions: {
    async getWorkspaces(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0

      let query = supabase
        .from('workspaces')
        .select(
          '*, organization:organizations(id, name)',
          { count: 'exact' },
        )
        .order('created_at', { ascending: false })
      if (payload?.search) {
        query = query.or(`name.ilike.%${payload.search}%`)
      }
      if (payload && payload.options.itemsPerPage !== -1 && from !== to) query = query.range(from, to)
      const { data, count, error } = await query
      if (error) {
        throw error
      }

      return { data, count }
    },

    async getWorkspace(id) {
      const { data, error } = await supabase
        .from('workspaces')
        .select(
          '*',
        )
        .eq('id', id)
        .single()
      if (error) {
        throw error
      }

      return data
    },

    async listenWorkspaces() {
      if (supabaseDomain.indexOf('localhost') < 0) {
        return supabase
          .channel('listenWorkspaces')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'workspaces' }, async payload => {
            this.$patch({ eventType: payload.eventType, item: payload.new.id ? payload.new : payload.old })
          })
          .subscribe()
      }

      return null
    },

    async listenWorkspace(id) {
      this.workspace = await this.getWorkspace(id)

      return supabase
        .channel(`listenWorkspace-${id}`)
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'workspaces',
            filter: `id=eq.${id}`,
          },
          payload => {
            this.workspace = payload
          },
        )
        .subscribe()
    },
    async deleteWorkspace(workspace) {
      const { error } = await supabase
        .from('workspaces').delete()
        .eq('id', workspace.id)

      if (error) {
        throw error
      }

      return true
    },
    async saveWorkspace(workspace) {
      const { error } = await supabase
        .from('workspaces')
        .update(workspace)
        .eq('id', workspace.id)

      if (error) {
        return Promise.reject(error)
      }

      return true
    },

    async uploadWorkspaceLogo(workspaceid, img) {
      const { data, error } = await supabase.storage.from('workspaces').upload(`logos/${workspaceid}/${img.name}`, img,
        {
          cacheControl: '3600',
          upsert: true,
        })
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async getWorkspaceLogo(workspaceid, img) {
      const { data } = await supabase.storage.from('workspaces').getPublicUrl(`logos/${workspaceid}/${img.name}`)

      return data
    },

  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWorkspacesStore, import.meta.hot))
}
