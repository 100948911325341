import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase, supabaseDomain } from '@/plugins/supabase'
import { useUserStore } from '@/stores/user.store'

// eslint-disable-next-line import/prefer-default-export
export const useChatsStore = defineStore({
  id: 'chats',
  state: () => ({
    contacts: [],
    chats: [],
    chat: {},
    notifications: [],
    nbMessages: 0,
    subscriptionChats: null,
    subscriptionMembers: null,
    lastMessagesRequest: null,
    lastNotifsRequest: null,
    channel: null,
    messages: null,
  }),
  actions: {
    async updateMember(_member) {
      const userStore = useUserStore()
      const member = _member

      const chatIndex = this.chats.findIndex(item => item.id === member.chat_id)
      if (chatIndex !== -1) {
        if (this.chats[chatIndex].members) {
          const index = this.chats[chatIndex].members.findIndex(item => item.admin_id === member.admin_id)
          if (index !== -1) {
            this.chats[chatIndex].members[index] = member
            this.chats.splice(chatIndex, 1, this.chats[chatIndex])
          }
        }
        if (this.chat.id === this.chats[chatIndex].id) {
          this.chat = this.chats[chatIndex]
        }

        if (member.admin_id === userStore.user.id) {
          const index = this.notifications.findIndex(chat => chat.id === member.chat_id)
          if (index !== -1) this.notifications.splice(index, 1, this.chats[chatIndex])
          else this.getNotifications()
        }
      } else if (member.admin_id === userStore.user.id) {
        this.getNotifications()
      }
    },

    async listenOnline(id) {
      if (supabaseDomain.indexOf('localhost') < 0) {
        const userStore = useUserStore()
        if (this.channel) userStore.removeChannel(this.channel)
        this.channel = supabase.channel(id, {
          config: {
            presence: {
              key: userStore.user.id,
            },
          },
        })

        this.channel.on('presence', { event: 'sync' }, () => {
          let index = 0
          this.contacts.forEach(contact => {
            const editedContact = contact
            if (this.channel.presenceState()[contact.id]) editedContact.online = true
            else editedContact.online = false
            if (editedContact.id === userStore.user.id) userStore.user.online = editedContact.online
            this.contacts.splice(index, 1, editedContact)
            index += 1
          })
        })

        this.channel.subscribe(async status => {
          if (status === 'SUBSCRIBED') {
            await this.channel.track({ online_at: new Date().toISOString() })
          }
        })
      }

      return null
    },

    async fetchChatsAndContacts() {
      const userStore = useUserStore()
      this.chat = {}
      await this.getContacts()
      await this.getNotifications()

      // to do filter on notifs   await this.getChats()

      if (this.subscriptionChats) userStore.removeChannel(this.subscriptionChats)
      if (this.subscriptionMembers) userStore.removeChannel(this.subscriptionMembers)

      this.subscriptionChats = await this.listenChats()
      this.subscriptionMembers = await this.listenMembers()
    },
    async saveChat(chat) {
      const userStore = useUserStore()
      const members = []
      chat.members.forEach(member => {
        members.push(member)
      })
      members.push(userStore.user.id)
      const { error } = await supabase.rpc('create_chat', { chatname: chat.name, members })
      if (error) {
        return Promise.reject(error)
      }
      this.fetchChatsAndContacts()

      return true
    },

    async updateSeen() {
      const { data, error } = await supabase.rpc('update_seen_chat', { chatid: this.chat.id })
      if (error) {
        return Promise.reject(error)
      }

      this.getNotifications()

      return data
    },

    async getChat(id) {
      const userStore = useUserStore()
      const { data, error } = await supabase.from('chats').select('*, members:chats_members(*)').eq('id', id).single()
      if (error) {
        return Promise.reject(error)
      }
      const index = this.chats.findIndex(item => item.id === data.id)
      if (index >= 0) {
        this.chats.splice(index, 1, data)
      }
      const contact = this.contacts.find(item => item.id === userStore.user.id)
      if (contact) contact.online = true
      this.listenOnline(id)

      return data
    },
    async sendMessage(message, attachment) {
      const userStore = useUserStore()
      if (!this.chat || !userStore.user) return null
      const { data, error } = await supabase
        .from('chats_messages')
        .insert({
          chat_id: this.chat.id,
          message,
          attachment,
          sender_id: userStore.user.id,
        })
        .select()
        .single()
      if (error) {
        return Promise.reject(error)
      }
      this.messages.push(data)
      this.getChat(this.chat.id)

      return data
    },

    async getContacts() {
      const { data, error } = await supabase.from('admins').select('id, fullname, email, avatar')
      if (error) {
        return Promise.reject(error)
      }
      this.contacts = data

      return data
    },

    async getChats(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0
      let query = supabase
        .from('chats')
        .select('*, members:chats_members(*)', { count: 'exact' })
        .order('updated_at', { ascending: false })
      if (payload?.search) {
        query = query.or(`name.ilike.%${payload.search}%`)
      }
      if (payload?.options.itemsPerPage !== -1 && from !== to) query = query.range(from, to)
      const { data, count, error } = await query
      if (error) {
        throw error
      }
      this.chats = data
      if (this.chat.id) {
        if (this.chats.findIndex(chat => chat.id === this.chat.id) === -1) {
          this.chat = {}
        }
      }

      return { data, count }
    },

    async getNotifications() {
      const userStore = useUserStore()

      if ((this.lastNotifsRequest && new Date().getTime() - this.lastNotifsRequest.getTime() < 500) || !userStore.user?.id) {
        return null
      }

      const { data, error } = await supabase
        .from('chats')
        .select('*, members:chats_members!inner(*)')
        .eq('chats_members.admin_id', userStore.user?.id)
        .gt('chats_members.unseen_messages', 0)
        .order('updated_at', { ascending: false })

      if (error) {
        throw error
      }

      if (this.chat.id) {
        if (this.chats.findIndex(chat => chat.id === this.chat.id) === -1) {
          this.chat = {}
        }
      }
      this.lastNotifsRequest = new Date()
      this.notifications = data

      return data
    },

    async getMessages(payload) {
      if (this.lastMessagesRequest && new Date().getTime() - this.lastMessagesRequest.getTime() < 1000) {
        return null
      }
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0
      let query = supabase.from('chats_messages').select('*', { count: 'exact' })
      if (payload?.search) {
        query = query.or(`message.ilike.%${payload.search}%`)
      }
      if (payload?.options?.itemsPerPage !== -1 && from !== to) query = query.range(from, to)
      query = query.eq('chat_id', payload.chatId).order('updated_at', { ascending: false })
      const { data, count, error } = await query
      if (error) {
        throw error
      }
      this.nbMessages = count
      this.lastMessagesRequest = new Date()
      this.messages = data

      return { data: data.reverse(), count }
    },
    async listenChats() {
      if (supabaseDomain.indexOf('localhost') < 0) {
        return supabase
          .channel('listenChats')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'chats' }, async payload => {
            const chat = payload.new.id ? payload.new : payload.old
            const chatIndex = this.chats.findIndex(item => item.id === chat.id)
            if (chatIndex !== -1) {
              chat.members = this.chats[chatIndex].members
              this.chats[chatIndex].last_message = chat.last_message
              this.chats[chatIndex].last_message_time = chat.last_message_time

              // this.chats.splice(chatIndex, 1, chat)
            }
            this.$patch({ eventType: payload.eventType, item: payload.new.id ? payload.new : payload.old })
          })
          .subscribe()
      }

      return null
    },

    async listenMembers() {
      if (supabaseDomain.indexOf('localhost') < 0) {
        return supabase
          .channel('listenMembers')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'chats_members' }, async payload => {
            const member = payload.new.id ? payload.new : payload.old
            this.updateMember(member)
            this.$patch({ eventType: payload.eventType, item: payload.new.id ? payload.new : payload.old })
          })
          .subscribe()
      }

      return null
    },
    async deleteChat() {
      const { error } = await supabase.rpc('delete_chat', { chatid: this.chat.id })

      if (error) {
        throw error
      }
      this.chat = {}
    },
    async uploadDoc(name, img) {
      const { data, error } = await supabase.storage.from('chats').upload(`docs/${name}`, img)
      if (error) {
        return Promise.reject(error)
      }

      return data
    },
    async getDoc(name) {
      const { data } = await supabase.storage.from('chats').getPublicUrl(`docs/${name}`)

      return data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChatsStore, import.meta.hot))
}
