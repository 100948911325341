<template>
  <v-row
    class="pa-3 mt-2"
  >
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.workspace"
    ></alert-error>
    <v-snackbar
      v-model="isSaved"
      outlined
      text
      color="primary"
      shaped
      top
      right
    >
      {{ $t('WorkspaceSavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-col
      cols="12"
      lg="7"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("AppCustomizer") }}
          </h5>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            class="multi-col-validation"
            :disabled="!$canACL('update', 'workspaces', workspace)"
            @submit.prevent="save"
          >
            <v-row v-if="workspace.settings?.theme?.app"
                   no-gutters
            >
              <v-col cols="12">
                <h3 class="font-weight-bold text-button mb-1 mt-3">
                  {{ $t('General') }}
                </h3>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="workspace.settings.theme.app.name"
                  :label="$t('AppName')"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required]"
                  class="mb-2 me-3"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="workspace.settings.theme.app.version"
                  :label="$t('Version')"
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-2 me-3"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="workspace.settings.theme.app.copyright"
                  :label="$t('FooterName')"
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-2 me-3"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="workspace.settings.theme.app.description"
                  :label="$t('AppMetaDescription')"
                  outlined
                  dense
                  hide-details="auto"
                  height="80"
                  class="mb-2 me-3"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="workspace.settings.theme.app.gtag"
                  :label="$t('Gtag')"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mb-2 me-3"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-text-field
                      v-model="workspace.settings.theme.app.logo"
                      :label="$t('Logo')"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      class="mb-2 me-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      small
                      color="primary"
                      class="me-3 mt-1"
                      :loading="loadingUpload"
                      :disabled="loadingUpload"
                      @click="uploadClickLogo"
                    >
                      <v-icon>
                        mdi-cloud-upload-outline
                      </v-icon>
                    </v-btn>

                    <input
                      ref="refInputEl"
                      type="file"
                      accept=".jpeg,.png,.jpg,GIF"
                      :hidden="true"
                      @change="handleFileSelectedLogo"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-text-field
                      v-model="workspace.settings.theme.app.logoDark"
                      :label="$t('LogoDark')"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      class="mb-2 me-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      small
                      color="primary"
                      class="me-3 mt-1"
                      :loading="loadingUploadDark"
                      :disabled="loadingUploadDark"
                      @click="uploadClickLogoDark"
                    >
                      <v-icon>
                        mdi-cloud-upload-outline
                      </v-icon>
                    </v-btn>

                    <input
                      ref="refInputEl2"
                      type="file"
                      accept=".jpeg,.png,.jpg,GIF"
                      :hidden="true"
                      @change="handleFileSelectedLogoDark"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <h3 class="font-weight-bold text-button mb-1 mt-3">
                  {{ $t('Theming') }}
                </h3>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="workspace.settings.theme.app.skinVariant"
                  :items="skinOptions"
                  outlined
                  item-text="label"
                  item-value="value"
                  hide-details
                  dense
                  :label="$t('Skin')"
                  class="mb-2 me-3"
                ></v-select>
              </v-col>
              <v-col cols="12"
                     md="6"
              >
                <v-select
                  v-model="workspace.settings.theme.app.isDark"
                  :items="[{label: 'Light', value: false}, {label: 'Dark', value: true}]"
                  outlined
                  item-text="label"
                  item-value="value"
                  hide-details
                  dense
                  :label="$t('Mode')"
                  class="mb-2 me-3"
                ></v-select>
              </v-col>
              <v-col cols="12"
                     md="6"
              >
                <v-select
                  v-model="workspace.settings.theme.app.isRtl"
                  :items="[{label: 'Right to left', value: true}, {label: 'Left to right', value: false}]"
                  outlined
                  item-text="label"
                  item-value="value"
                  hide-details
                  dense
                  :label="$t('RTL')"
                  class="mb-2 me-3"
                ></v-select>
              </v-col>
              <v-col cols="12"
                     md="6"
              >
                <v-select
                  v-model="workspace.settings.theme.app.contentLayoutNav"
                  :items="appContentLayoutNavOptions"
                  outlined
                  item-text="label"
                  item-value="value"
                  hide-details
                  dense
                  :label="$t('MenuLayout')"
                  class="mb-2 me-3"
                ></v-select>
              </v-col>
              <v-col cols="12"
                     md="6"
              >
                <v-select
                  v-model="workspace.settings.theme.appBar.type"
                  :items="appBarTypeOptions"
                  outlined
                  item-text="label"
                  item-value="value"
                  hide-details
                  dense
                  :label="$t('AppBarType')"
                  class="mb-2 me-3"
                ></v-select>
              </v-col>

              <v-col cols="12"
                     md="6"
              >
                <v-select
                  v-model="workspace.settings.theme.footer.type"
                  :items="footerTypeOptions"
                  outlined
                  item-text="label"
                  item-value="value"
                  hide-details
                  dense
                  :label="$t('FooterType')"
                  class="mb-2 me-3"
                ></v-select>
              </v-col>
              <v-col cols="12"
                     md="6"
              >
                <v-select
                  v-model="workspace.settings.theme.app.routeTransition"
                  :items="routerTransitionOptions"
                  outlined
                  hide-details
                  dense
                  :label="$t('RouterTransition')"
                  class="mb-2 me-3"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <h3 class="font-weight-bold text-button mb-1 mt-3">
                  {{ $t('Colors') }}
                </h3>
              </v-col>
              <v-col cols="12"
                     md="4"
              >
                <div class="px-5">
                  <h5 class="text-button mb-1">
                    <v-icon class="me-1">
                      mdi-weather-sunny
                    </v-icon>{{ $t('Light') }}
                  </h5>
                  <span class="text-caption">{{ $t('Primary') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.light.primary"
                    @change="onChangeColorP('primary', $event)"
                  ></color-picker>
                  <span class="text-caption">{{ $t('Secondary') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.light.secondary"
                    @change="onChangeColorP('secondary', $event)"
                  ></color-picker>
                  <span class="text-caption">{{ $t('Success') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.light.success"
                    @change="onChangeColorP('success', $event)"
                  ></color-picker>
                  <span class="text-caption">{{ $t('Info') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.light.info"
                    @change="onChangeColorP('info', $event)"
                  ></color-picker>
                  <span class="text-caption">{{ $t('Warning') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.light.warning"
                    @change="onChangeColorP('warning', $event)"
                  ></color-picker>

                  <span class="text-caption">{{ $t('Error') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.light.error"
                    @change="onChangeColorP('error', $event)"
                  ></color-picker>
                </div>
              </v-col>
              <v-col cols="12"
                     md="4"
              >
                <div class="px-5">
                  <h5 class="text-button mb-1">
                    <v-icon class="me-1">
                      mdi-weather-night
                    </v-icon>{{ $t('Dark') }}
                  </h5>
                  <span class="text-caption">{{ $t('Primary') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.dark.primary"
                    @change="onChangeColorD('primary', $event)"
                  ></color-picker>
                  <span class="text-caption">{{ $t('Secondary') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.dark.secondary"
                    @change="onChangeColorD('secondary', $event)"
                  ></color-picker>
                  <span class="text-caption">{{ $t('Success') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.dark.success"
                    @change="onChangeColorD('success', $event)"
                  ></color-picker>
                  <span class="text-caption">{{ $t('Info') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.dark.info"
                    @change="onChangeColorD('info', $event)"
                  ></color-picker>
                  <span class="text-caption">{{ $t('Warning') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.dark.warning"
                    @change="onChangeColorD('warning', $event)"
                  ></color-picker>

                  <span class="text-caption">{{ $t('Error') }}</span>
                  <color-picker
                    :value="workspace.settings.theme.themes.dark.error"
                    @change="onChangeColorD('error', $event)"
                  ></color-picker>
                </div>
              </v-col>
              <v-col
                cols="12"
              >
                <div class="d-flex mt-6 justify-center">
                  <v-btn
                    :loading="loading"
                    :disabled="loading || !$canACL('update', 'workspaces', workspace)"
                    color="primary"
                    class="me-3 mt-4"
                    type="submit"
                  >
                    {{ $t("Save") }}
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    class="mt-4"
                    type="reset"
                    :disabled="loading || !$canACL('update', 'workspaces', workspace)"
                    @click.prevent="resetForm"
                  >
                    {{ $t("Cancel") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="12"
      md="5"
    >
      <v-progress-linear v-if="loadFrame"
                         indeterminate
                         style="width: 450px;"
      ></v-progress-linear>
      <iframe id="frame"
              :key="iframeKey"
              :src="getUrl()"
              title="Micleo"
              width="910px"
              height="640px"
              @load="onLoadFrame"
      ></iframe>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref, toRef, computed, getCurrentInstance,
} from 'vue'
import {
  required,
} from '@core/utils/validation'
import AlertError from '@/components/AlertError.vue'
import { useWorkspacesStore } from '@/stores/workspaces.store'
import ColorPicker from '@/components/ColorPicker.vue'
import { uuidv7 } from 'uuidv7'

export default {
  components: {
    AlertError,
    ColorPicker,
  },
  props: {
    workspace: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const loading = ref(false)
    const loadingUpload = ref(false)
    const loadingUploadDark = ref(false)
    const vm = getCurrentInstance().proxy
    const loadFrame = ref(true)
    const isSaved = ref(false)
    const form = ref(null)
    const errors = ref({})
    const isDeleteDialogVisible = ref(false)
    const loadingDelete = ref(false)
    const isAlertVisible = ref(false)
    const workspaceStore = useWorkspacesStore()
    const iframeKey = ref(uuidv7())

    const workspace = toRef(props, 'workspace')

    const save = () => {
      errors.value = {}
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      loading.value = true
      workspaceStore.saveWorkspace(workspace.value)
        .then(() => {
          iframeKey.value = uuidv7()
          loadFrame.value = true
          loading.value = false
          isSaved.value = true
        })
        .catch(error => {
          isAlertVisible.value = true
          loading.value = false
          errors.value = {
            workspace: error.message,
          }
          if (error.response) {
            errors.value = {
              workspace: error.response.data?.error?.message || error.response.data?.message,
            }
          }
        })
    }

    const resetForm = () => {
      errors.value = {}
      workspaceStore.getWorkspace(workspace.value.id)
        .then(data => {
          workspaceStore.workspace = data
        })
    }

    const routerTransitionOptions = [
      { text: 'Scroll X', value: 'scroll-x-transition' },
      { text: 'Scroll X Reverse', value: 'scroll-x-reverse-transition' },
      { text: 'Scroll Y', value: 'scroll-y-transition' },
      { text: 'Scroll Y Reverse', value: 'scroll-y-reverse-transition' },
      { text: 'Slide X', value: 'slide-x-transition' },
      { text: 'Slide X Reverse', value: 'slide-x-reverse-transition' },
      { text: 'Slide Y', value: 'slide-y-transition' },
      { text: 'Slide Y Reverse', value: 'slide-y-reverse-transition' },
      { text: 'Fade', value: 'fade-transition' },
    ]

    const skinOptions = computed(() => {
      const options = [
        { label: 'Default', value: 'default' },
        { label: 'Bordered', value: 'bordered' },
        { label: 'Semi Dark', value: 'semi-dark' },
      ]

      return options
    })

    const appContentWidthOptions = [
      { label: 'Full Width', value: 'full' },
      { label: 'Boxed', value: 'boxed' },
    ]

    const appBarTypeOptions = computed(() => {
      const types = [
        { label: 'Fixed', value: 'fixed' },
        { label: 'Static', value: 'static' },
        { label: 'Hidden', value: 'hidden' },
      ]

      return types
    })

    const footerTypeOptions = [
      { label: 'Fixed', value: 'fixed' },
      { label: 'Static', value: 'static' },
      { label: 'Hidden', value: 'hidden' },
    ]

    const appContentLayoutNavOptions = [
      { label: 'Vertical', value: 'vertical' },
      { label: 'Horizontal', value: 'horizontal' },
    ]

    const onChangeColorP = (field, value) => {
      workspace.value.settings.theme.themes.light[field] = value
    }

    const onChangeColorD = (field, value) => {
      workspace.value.settings.theme.themes.dark[field] = value
    }

    const getUrl = () => `https://${props.workspace.id}.micleo.com`

    const onLoadFrame = () => {
      loadFrame.value = false
    }

    const uploadClickLogo = () => {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      vm.$refs.refInputEl.dispatchEvent(event)
    }

    const uploadClickLogoDark = () => {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      vm.$refs.refInputEl2.dispatchEvent(event)
    }

    const handleFileSelectedLogo = async e => {
      loadingUpload.value = true
      if (
        e.target.files.length > 0
        && (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png')
      ) {
        workspaceStore.uploadWorkspaceLogo(workspace.value.id, e.target.files[0])
          .then(async () => {
            const url = await workspaceStore.getWorkspaceLogo(workspace.value.id, e.target.files[0])
            workspace.value.settings.theme.app.logo = url.publicUrl
            loadingUpload.value = false
          })
          .catch(async () => {
            const url = await workspaceStore.getWorkspaceLogo(workspace.value.id, e.target.files[0])
            workspace.value.settings.theme.app.logo = url.publicUrl
            loadingUpload.value = false
          })
      } else {
        errors.value = {
          image: 'Allowed JPG, GIF or PNG.',
        }
      }
    }

    const handleFileSelectedLogoDark = async e => {
      loadingUploadDark.value = true
      if (
        e.target.files.length > 0
        && (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png')
      ) {
        workspaceStore.uploadWorkspaceLogo(workspace.value.id, e.target.files[0])
          .then(async () => {
            const url = await workspaceStore.getWorkspaceLogo(workspace.value.id, e.target.files[0])
            workspace.value.settings.theme.app.logoDark = url.publicUrl
            loadingUploadDark.value = false
          })
          .catch(async () => {
            const url = await workspaceStore.getWorkspaceLogo(workspace.value.id, e.target.files[0])
            workspace.value.settings.theme.app.logoDark = url.publicUrl
            loadingUploadDark.value = false
          })
      } else {
        errors.value = {
          image: 'Allowed JPG, GIF or PNG',
        }
      }
    }

    return {
      workspace,
      form,
      save,
      resetForm,
      isSaved,
      loading,
      loadingDelete,
      isAlertVisible,
      errors,
      isDeleteDialogVisible,
      validators: {
        required,
      },

      appContentLayoutNavOptions,
      skinOptions,
      appContentWidthOptions,
      appBarTypeOptions,
      footerTypeOptions,
      routerTransitionOptions,
      onChangeColorP,
      onChangeColorD,
      getUrl,
      iframeKey,
      onLoadFrame,
      loadFrame,
      uploadClickLogo,
      uploadClickLogoDark,
      handleFileSelectedLogo,
      handleFileSelectedLogoDark,
      loadingUpload,
      loadingUploadDark,
    }
  },
}
</script>
<style scoped>
#frame {
    -moz-transform:scale(0.5);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.5);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.5);
    -webkit-transform-origin: 0 0;
    border:2px solid black;
}
</style>
