<template>
  <v-card id="user-view"
          class="h-full overflow-auto"
          flat
  >
    <v-tabs v-model="tabSettings"
            class="user-tabs pt-2"
            centered
    >
      <v-tab :disabled="!selectWidget.key">
        {{ $t('Settings') }}
      </v-tab>
      <v-tab>
        {{ $t('Form') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabSettings"
                  class="mb-3"
    >
      <v-tab-item>
        <v-form v-if="selectWidget.key"
                class="mt-5 mx-3"
        >
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
            >
              <v-chip class="text-caption mx-1">
                <v-icon class="me-3">
                  {{ getType(selectWidget.options?.type).icon }}
                </v-icon>{{ $t(getType(selectWidget.options?.type).value) }}
              </v-chip>
              <v-chip class="text-caption mx-1">
                <v-icon class="me-3">
                  {{ getAppearance(selectWidget.options.appearance).icon }}
                </v-icon>{{ $t(getAppearance(selectWidget.options.appearance).value) }}
              </v-chip>
            </v-col>
            <v-col
              cols="12"
              class="mt-3"
            >
              <v-chip class="text-caption mx-1"
                      :disabled="selectWidget.key==='button' || selectWidget.key==='captcha'"
                      :close="selectWidget.key.indexOf('_') ===-1"
                      :color="selectWidget.key.indexOf('_') ===-1 ? 'primary': ''"
                      :outlined="selectWidget.key.indexOf('_') ===-1"
                      @click:close="resetKey"
              >
                ID : {{ selectWidget.key }}
              </v-chip>
              <v-btn v-if="false"
                     color="primary"
                     icon
                     class="mx-1"
                     :disabled="selectWidget.key.indexOf('_') !==-1"
                     @click="$emit('add-property', selectWidget.key.indexOf('_') ===-1 ? selectWidget.key: '')"
              >
                <v-icon class="me-1">
                  mdi-cogs
                </v-icon>
              </v-btn>
            </v-col>
            <v-row v-if="selectWidget.key.indexOf('_') !==-1 && selectWidget.options?.appearance !=='grid' && selectWidget.options?.appearance !=='button'"
                   no-gutters
            >
              <v-col
                cols="12"
              >
                <v-divider class="my-3"></v-divider>
                <v-select
                  v-model="selectWidget.key"
                  :items="getContactProperties()"
                  item-value="slug"
                  item-text="name"
                  :label="$t('Data')"
                  outlined
                  dense
                  class="mx-1 mt-3"
                  hide-details="auto"
                  clearable
                  prepend-inner-icon="mdi-database"
                  @change="onContact"
                  @click:append-icon="refreshProperties()"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="selectWidget.options?.appearance !== 'grid' && selectWidget.options?.appearance !== 'html' && selectWidget.options?.appearance !== 'captcha' && selectWidget.options?.appearance !== 'button'"
                   no-gutters
            >
              <v-col
                cols="12"
              >
                <v-divider class="my-3"></v-divider>
                <v-text-field
                  v-model="selectWidget.options.name"
                  :label="$t('Label')"
                  outlined
                  dense
                  class="mb-2 mx-1"
                  hide-details="auto"
                  prepend-inner-icon="mdi-label"
                >
                </v-text-field>
                <v-text-field
                  v-if="selectWidget.options.appearance !=='radioboxes'
                    && selectWidget.options.appearance !=='checkboxes'
                    && selectWidget.options.appearance !=='simple-checkbox'"
                  v-model="selectWidget.options.placeholder"
                  :label="$t('Placeholder')"
                  outlined
                  dense
                  class="mb-3 mx-1"
                  hide-details="auto"
                  prepend-inner-icon="mdi-text-recognition"
                >
                </v-text-field>
                <v-checkbox
                  v-model="selectWidget.options.required"
                  :label="$t('Required')"
                  class="mx-1 pa-0"
                ></v-checkbox>
                <v-checkbox
                  v-model="selectWidget.options.readonly"
                  :label="$t('ReadOnly')"
                  class="mx-1 pa-0"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="selectWidget.options?.appearance === 'html'">
              <v-col cols="12">
                <v-divider class="my-3"></v-divider>
                <vue-editor v-model="selectWidget.options.name"
                            :editor-toolbar="[[{ 'header': [1, 2, 3, 4, false] }, ],['bold', 'italic', 'underline', 'strike', { list: 'ordered'}, { list: 'bullet'}]]"
                />
              </v-col>
            </v-row>
            <v-row v-if="selectWidget.options?.appearance === 'button'"
                   no-gutters
            >
              <v-col
                cols="12"
              >
                <v-divider class="my-3"></v-divider>
                <v-text-field
                  v-model="selectWidget.options.name"
                  :label="$t('Label')"
                  outlined
                  dense
                  class="mb-2 mx-1"
                  hide-details="auto"
                  prepend-inner-icon="mdi-label"
                >
                </v-text-field>
                <color-picker :value="selectWidget.options.color"
                              :label="$t('Color')"
                              class="mx-1"
                              @change="onChangeColor"
                ></color-picker>
                <span class="text-caption">
                  {{ $t('Settings') }}
                </span>
                <v-checkbox
                  v-model="selectWidget.options.dark"
                  :label="$t('Dark')"
                  class="ma-1 ml-10 pa-0"
                ></v-checkbox>

                <v-checkbox
                  v-model="selectWidget.options.outlined"
                  :label="$t('Outlined')"
                  class="ma-1 ml-10 pa-0"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
              ></v-col>
            </v-row>
            <v-row v-if="selectWidget.options?.appearance === 'grid'"
                   cols="12"
                   no-gutters
            >
              <v-col cols="12">
                <v-divider class="my-3"></v-divider>
              </v-col>
              <v-col cols="9">
                <p>{{ $t('Columns') }}</p>
              </v-col>
              <v-col cols="3">
                <v-btn
                  x-small
                  color="primary"
                  outlined
                  @click="addColumn"
                >
                  <v-icon>mdi-table-column-plus-after</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-card outlined
                        class="pa-3 ma-0"
                >
                  <v-tabs v-model="tabCols"
                          class="user-tabs"
                  >
                    <v-tab v-for="(col , i) in selectWidget.columns"
                           :key="i"
                    >
                      Col {{ (i+1) }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabCols">
                    <v-tab-item v-for="(col , i) in selectWidget.columns"
                                :key="i"
                    >
                      <v-row class="mt-6"
                             no-gutters
                      >
                        <v-col
                          cols="4"
                        >
                          <span class="text-caption">
                            {{ $t('Size') }}
                          </span>
                        </v-col>
                        <v-col
                          cols="8"
                        >
                          <v-slider
                            v-model="col.cols"
                            max="12"
                            min="1"
                            hide-details
                            thumb-label
                            thumb-size="20"
                          ></v-slider>
                        </v-col>
                        <v-col
                          cols="12"
                          class="mt-3"
                        >
                          <span class="text-caption">{{ $t('TextAlign') }}</span>

                          <v-btn-toggle
                            v-model="col.options.text"
                            mandatory
                            dense
                            class="mt-1"
                          >
                            <v-btn value="left">
                              <v-icon>mdi-format-align-left</v-icon>
                            </v-btn>

                            <v-btn value="center">
                              <v-icon>mdi-format-align-center</v-icon>
                            </v-btn>

                            <v-btn value="right">
                              <v-icon>mdi-format-align-right</v-icon>
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-row no-gutters
                               class="mt-6"
                        >
                          <v-col cols="8">
                            <span class="text-caption">
                              {{ $t('Padding') }}
                            </span>
                          </v-col>
                          <v-col cols="4">
                            <v-switch
                              v-model="col.options.padding"
                              dense
                            ></v-switch>
                          </v-col>

                          <v-col
                            v-if="col.options.padding"
                            cols="6"
                          >
                            <v-slider
                              v-model="col.options.paddingLeft"
                              :label="$t('Left')"
                              max="16"
                              min="0"
                              dense
                              thumb-label
                              thumb-size="20"
                            ></v-slider>
                          </v-col>
                          <v-col
                            v-if="col.options.padding"
                            cols="6"
                          >
                            <v-slider
                              v-model="col.options.paddingRight"
                              :label="$t('Right')"
                              max="16"
                              min="0"
                              dense
                              thumb-label
                              thumb-size="20"
                            ></v-slider>
                          </v-col>
                          <v-col
                            v-if="col.options.padding"
                            cols="6"
                          >
                            <v-slider
                              v-model="col.options.paddingTop"
                              :label="$t('Top')"
                              max="16"
                              min="0"
                              dense
                              thumb-label
                              thumb-size="20"
                            ></v-slider>
                          </v-col>
                          <v-col
                            v-if="col.options.padding"
                            cols="6"
                          >
                            <v-slider
                              v-model="col.options.paddingBottom"
                              :label="$t('Bottom')"
                              max="16"
                              min="0"
                              dense
                              thumb-label
                              thumb-size="20"
                            ></v-slider>
                          </v-col>
                        </v-row>
                        <v-col cols="12">
                          <v-divider class="my-3"></v-divider>
                        </v-col>
                        <v-col cols="12">
                          <v-btn color="error"
                                 outlined
                                 x-small
                                 @click="removeColumn(i)"
                          >
                            {{ $t('Delete') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-divider class="my-3"></v-divider>
            </v-col>
            <v-col
              cols="12"
            >
              <v-btn v-if="selectWidget.options?.type === 'array' || selectWidget.options.appearance === 'dropdown' || selectWidget.options.appearance === 'radioboxes'"
                     color="primary"
                     outlined
                     block
                     class="pa-0 ma-0"
                     :disabled="selectWidget.key.indexOf('_') !==-1"
                     @click="$emit('add-property', selectWidget.key.indexOf('_') ===-1 ? selectWidget.key: '', '/properties_items')"
              >
                <v-icon class="me-1">
                  mdi-pencil
                </v-icon>
                <span>{{ $t('EditOptions') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item>
        <v-form
          class="mt-5 mx-3"
        >
          <v-row
            no-gutters
          >
            <v-col
              cols="4"
            >
              <span class="text-caption">
                {{ $t('FontFamily') }}
              </span>
            </v-col>
            <v-col
              cols="8"
            >
              <v-select
                v-model="form.options.fontfamily"
                :label="$t('FontFamily')"
                prepend-inner-icon="mdi-format-font"
                class="mb-2 mx-1"
                :items="fonts"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col
              cols="4"
            >
              <span class="text-caption">
                {{ $t('Size') }}
              </span>
            </v-col>
            <v-col
              cols="8"
            >
              <v-slider
                v-model="form.options.width"
                max="100"
                min="1"
                hide-details
                thumb-label
                thumb-size="20"
              ></v-slider>
            </v-col>
            <v-col cols="12">
              <span class="text-caption">{{ $t('BlocAlignHorizontal') }}</span>
              <v-btn-toggle
                v-model="form.options.justify"
                mandatory
                dense
                class="my-1"
              >
                <v-btn value="start">
                  <v-icon>mdi-format-horizontal-align-left</v-icon>
                </v-btn>

                <v-btn value="center">
                  <v-icon>mdi-format-horizontal-align-center</v-icon>
                </v-btn>

                <v-btn value="end">
                  <v-icon>mdi-format-horizontal-align-right</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12">
              <span class="text-caption">{{ $t('BlocAlignVertical') }}</span>
              <v-btn-toggle
                v-model="form.options.align"
                mandatory
                dense
                class="my-1"
              >
                <v-btn value="start">
                  <v-icon>mdi-format-vertical-align-top</v-icon>
                </v-btn>

                <v-btn value="center">
                  <v-icon>mdi-format-vertical-align-center</v-icon>
                </v-btn>

                <v-btn value="end">
                  <v-icon>mdi-format-vertical-align-bottom</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  ref, getCurrentInstance, watch,
} from 'vue'
import { useVModel } from '@vueuse/core'
import { uuidv7 } from 'uuidv7'
import { usePropertiesStore } from '@/stores/properties.store'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
    composants: {
      type: Object,
      required: true,
    },
    selectWidget: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    tabSettings: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const propertiesStore = usePropertiesStore()
    const selectWidget = useVModel(props, 'selectWidget', emit)
    const tabSettings = useVModel(props, 'tabSettings', emit)
    const form = useVModel(props, 'form', emit)
    const vm = getCurrentInstance().proxy
    const tabCols = ref(null)
    const options = ref(null)
    const fonts = ref(['Arial, sans-serif',
      'Verdana , sans-serif',
      'Tahoma , sans-serif',
      'Trebuchet MS , sans-serif',
      'Times New Roman , serif',
      'Georgia , serif',
      'Garamond , serif',
      'Courier New, monospace',
      'Brush Script MT, cursive'])
    let delayColor = null

    watch(selectWidget, () => {
      options.value = propertiesStore.properties.find(p => p.id === selectWidget.value?.options?.id)
    })

    const getType = value => {
      const type = props.groups.find(t => t.value === value)

      return type || { value, icon: 'mdi-cogs' }
    }

    const getAppearance = value => {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const index in props.groups) {
        const appearance = props.composants[props.groups[index].value]?.find(a => a.value === value)
        if (appearance) return appearance
      }

      return { value, icon: 'mdi-cogs' }
    }

    const resetKey = () => {
      selectWidget.value.key = `${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}`
    }

    const getContactProperties = () => {
      const items = []

      propertiesStore.properties
        .filter(p => p?.type === selectWidget.value.options?.type)
        .forEach(it => {
          if (
            !props.form.list.find(
              l => (l.key === it.slug && selectWidget.value.key !== it.slug)
                || l.columns?.find(c => c.key === it.slug && selectWidget.value.key !== it.slug),
            )
          ) {
            it.disabled = false
          } else {
            it.disabled = true
          }
          items.push(it)
        })

      return items
    }

    const onContact = () => {
      if (selectWidget.value.key) {
        const prop = propertiesStore.properties.find(p => p.slug === selectWidget.value.key)
        if (prop) {
          selectWidget.value.options = JSON.parse(JSON.stringify(prop))
        }
      } else {
        selectWidget.value.key = `${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}`
      }
    }

    const addColumn = () => {
      selectWidget.value.columns.push({
        list: [], key: `${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}`, cols: 6, options: {},
      })
      tabCols.value = selectWidget.value.columns.length - 1
    }

    const removeColumn = index => {
      selectWidget.value.columns.splice(index, 1)
    }

    const addOption = () => {
      selectWidget.value.options.properties_items.push({
        id: uuidv7(), name: '', project_id: vm.$router.currentRoute.params.id, property_id: selectWidget.value.options.id,
      })
    }

    const onChangeColor = color => {
      if (delayColor) { clearTimeout(delayColor) }
      delayColor = setTimeout(() => {
        selectWidget.value.options.color = color
        selectWidget.value.key = `${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}`
      }, 250)
    }

    return {
      getType,
      getAppearance,
      resetKey,
      getContactProperties,
      onContact,
      tabSettings,
      selectWidget,
      tabCols,
      addColumn,
      removeColumn,
      addOption,
      onChangeColor,
      form,
      fonts,
      options,
    }
  },
}
</script>
