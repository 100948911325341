import { defineStore, acceptHMRUpdate } from 'pinia'
import { count } from 'sms-length'
import { supabase } from '@/plugins/supabase'
import { useFoldersStore } from '@/stores/folders.store'
import { useChatsStore } from '@/stores/chats.store'
import { required, emailValidator } from '@core/utils/validation'
import { canACL } from '@/plugins/acl/checkaccess'
import { TABLE_TEMPLATES, displayMode } from '@core/utils'
import { appConfig } from '@/plugins/micleoConfig'
import { useProjectsStore } from './projects.store'
import { useFilesStore } from './files.store'

const appAPI = appConfig?.url ? `${appConfig?.url}/functions/v1/api` : process.env.VUE_APP_API

// eslint-disable-next-line import/prefer-default-export
export const useTemplatesStore = defineStore({
  id: TABLE_TEMPLATES,
  state: () => ({
    templates: [],
  }),
  actions: {

    getHeader(payload, item) {
      return {
        title: 'Templates',
        icon: 'mdi-newspaper-variant',
        name: ['name'],
        folder: payload?.folder,
        display: displayMode(TABLE_TEMPLATES, 'grid'),
        newData: 'NewTemplate',
        actionData: 'EditTemplates',
        editData: 'EditTemplate',
        search: 'SearchTemplate',
        searchIcon: 'mdi-magnify',
        add: {
          text: 'AddTemplate',
          icon: 'mdi-plus',
        },
        actions: [{
          title: 'Save', icon: 'mdi-content-save-outline', action: 'save', loading: false, acl: 'create,update',
        }, {
          title: 'Duplicate',
          icon: 'mdi-content-duplicate',
          action: 'duplicate',
          acl: 'create',
          disabled: !item?.created_at,
        },
        {
          title: 'Delete',
          icon: 'mdi-delete-outline',
          action: 'delete',
          acl: 'delete',
          color: 'error',
          disabled: !item?.created_at,
        }],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['updated_at'],
        sortDesc: [true],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: 12,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
          search: true,
        },
        {
          text: 'Type',
          value: 'type',
          filterable: true,
          type: 'font-weight-semibold text-button',
        },
        {
          text: 'Thumbnail',
          value: 'thumbnail',
          type: 'file-small',
        },
        {
          text: 'UpdatedAt',
          value: 'updated_at',
          type: 'date',
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      const projectsStore = useProjectsStore()
      const integrations = projectsStore.GET_PROJECT?.organization?.integrations.filter(f => f.type?.includes('email') && f.enabled)

      return {
        // general
        name: null,
        type: 'email',
        description: null,
        email: {
          html: null,
          design: null,
          subject: null,
          'sender-name': null,
          'sender-email': null,
          'replyto-email': null,
        },
        thumbnail: null,
        sms: {
          'sender-name': null,
        },
        integration_id: integrations[0]?.id,
        folder_id: payload?.folder_id,
        project_id: payload?.project_id,
      }
    },

    getSchema(item, items, payload) {
      const foldersStore = useFoldersStore()
      const chatsStore = useChatsStore()
      const projectsStore = useProjectsStore()
      const filesStore = useFilesStore()

      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }

      schema.col1.schema.general = {
        title: 'General',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'px-3 ma-2',
        schema: {
          name: {
            type: 'text',
            label: 'Name',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-format-header-1',
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
          },
          type: {
            type: 'select',
            label: 'Type',
            class: 'mx-1 mb-2',
            items: [
              { text: 'Email', value: 'email' },
              { text: 'SMS', value: 'sms' },
            ],
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
            disabled: !!item?.created_at,
          },
          description: {
            type: 'textarea',
            label: 'Description',
            class: 'mx-1 mb-2',
            rules: items.length > 0 ? [] : [],
            hidden: !!items.length,
          },
          integration_id: {
            type: 'autocomplete',
            label: 'Integration',
            class: 'mx-1 mb-2',
            col: 12,
            clearable: true,
            complex: true,
            chips: true,
            prependInnerIcon: 'mdi-store-search',
            dense: true,
            'item-value': 'id',
            'item-text': 'name',
            items: projectsStore.GET_PROJECT?.organization?.integrations.filter(f => f.type?.includes(item?.type?.toLowerCase()) && f.enabled),
            rules: items.length > 0 ? [] : [required],
          },
          folder_id: {
            type: 'autocomplete',
            subtype: 'folders',
            label: 'Folders',
            class: 'mx-1 mb-2',
            col: 12,
            clearable: true,
            complex: true,
            prependInnerIcon: 'mdi-folder-open',
            dense: true,
            items: foldersStore.folders,
            rules: items.length > 0 ? [] : [required],
          },
        },
      }

      if (!canACL('view', 'folders', { id: payload?.project_id })) {
        delete schema.col1.schema.general.schema.folder_id
      }

      if (!canACL('view', 'integrations', { id: projectsStore.GET_PROJECT?.organization?.id })) {
        delete schema.col1.schema.general.schema.integration_id
      }

      schema.col2.schema.editor = {
        title: 'Editor',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'text-center',
        hidden: !!items.length,
        schema: {
          template_editor: {
            type: 'image',
            'lazy-src': `${appAPI}/placeholder?w=260&h=300&t=`,
            src: filesStore.getThumbnail(item?.thumbnail),
            width: '250',
            height: '280',
            style: 'border: 1px solid #ccc; cursor: pointer;',
            col: 12,
            class: 'mb-5',
            hidden: !!items.length || item?.type !== 'email',
          },
          'sms->>message': {
            type: 'textarea',
            label: 'Message',
            class: 'mx-1 mb-2',
            rules: items.length > 0 || item?.type !== 'sms' ? [] : [required],
            hidden: !!items.length || item?.type !== 'sms',
          },
          'sms->>details': {
            type: 'json',
            label: 'Message',
            class: 'mb-3',
            rules: items.length > 0 || item?.type !== 'sms' ? [] : [required],
            hidden: !!items.length || item?.type !== 'sms' || !item['sms->>message'],
          },
        },
      }

      schema.col1.schema.textemail = {
        title: 'TextPart',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'px-3 ma-2',
        hidden: !!items.length || item?.type !== 'email' || true,
        schema: {
          'email->>text': {
            type: 'textarea',
            label: 'Message',
            rules: items.length > 0 || item?.type !== 'email' ? [] : [],
          },
        },
      }

      schema.col3.schema.settings = {
        title: 'Settings',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'px-3 ma-2',
        hidden: !!items.length,
        schema: {
          'sms->>sender-name': {
            type: 'text',
            label: 'SenderName',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-account-circle',
            rules: items.length > 0 || item?.type !== 'sms' ? [] : [required],
            hidden: !!items.length || item?.type !== 'sms',
          },
          'email->>subject': {
            type: 'text',
            label: 'Subject',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-format-text',
            rules: items.length > 0 || item?.type !== 'email' ? [] : [required],
            hidden: !!items.length || item?.type !== 'email',
          },
          'email->>sender-name': {
            type: 'text',
            label: 'SenderName',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-account-circle',
            rules: items.length > 0 || item?.type !== 'email' ? [] : [required],
            hidden: !!items.length || item?.type !== 'email',
          },
          'email->>sender-email': {
            type: 'text',
            label: 'SenderEmail',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-at',
            rules: items.length > 0 || item?.type !== 'email' ? [] : [required, emailValidator],
            hidden: !!items.length || item?.type !== 'email',
          },
          'email->>replyto-email': {
            type: 'text',
            label: 'ReplyTo',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-at',
            rules: items.length > 0 || item?.type !== 'email' ? [] : [emailValidator],
            hidden: !!items.length || item?.type !== 'email',
          },
        },
      }

      if (item?.id) {
        schema.col3.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col3.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col3.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          class: 'mx-1 mb-2',
          label: 'UpdatedBy',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }

      return schema
    },

    async onAction(action, payload) {
      return payload?.item
    },

    onChangeSMSContent(item) {
      if (item && item['sms->>message']) {
        item['sms->>details'] = count(item['sms->>message'])
      } else if (item) item['sms->>details'] = null
    },

    async getTemplates(projectId) {
      const { data, count: countTemplates, error } = await supabase
        .from(TABLE_TEMPLATES)
        .select('*', { count: 'exact' })
        .eq('project_id', projectId)
        .order('updated_at', { ascending: false })
      if (error) {
        return Promise.reject(error)
      }
      this.templates = data

      return { data, count: countTemplates }
    },

  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTemplatesStore, import.meta.hot))
}
