<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/valid-v-for -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div :style="`min-height: ${height}px !important;`">
    <v-progress-linear v-if="loading"
                       indeterminate
    ></v-progress-linear>

    <v-data-iterator
      v-model="items"
      :loading="loading"
      :headers="config.tableColumns.filter(c => !c.hidden)"
      :items="listTable"
      :options.sync="options"
      :server-items-length="totalTable ? totalTable : -1"
      show-select
      :item-key="config.tableOptions.itemKey || 'id'"
      :dense="config.tableOptions.dense"
      :multi-sort="config.tableOptions.multiSort || true"
      :disable-sort="config.tableOptions.disableSort || false"
      :items-per-page="config.tableOptions.itemsPerPage || 10"
      :height="height"
      :footer-props="{
        'items-per-page-options': [config.tableOptions.itemsPerPage, 10, 12, 20, 100, 1000, -1],
      }"
      checkbox-color="primary"
      class="text-no-wrap custom-table mx-6"
      :mobile-breakpoint="mobileBreakPoint"
      style="position: relative; z-index: 0"
      :fixed-header="config.tableOptions.fixedHeader || true"
    >
      <template #header>
        <div
          class="align-center flex-wrap pb-0 mx-3 d-flex"
        >
          <div class="d-flex flex-grow-1 align-center pb-3"></div>
          <v-spacer></v-spacer>

          <div class="d-flex align-center pb-3">
            <v-select
              v-model="config.tableOptions.sortBy[0]"
              outlined
              dense
              hide-details
              :items="config.tableColumns.filter(c => c.sortable === undefined || c.sortable)"
              prepend-inner-icon="mdi-magnify"
              :label="$t('SortBy')"
              class="me-3"
            >
              <template #selection="data">
                {{ $t(data.item.text) }}
              </template>
              <template #item="data">
                {{ $t(data.item.text) }}
              </template>
            </v-select>
            <v-btn-toggle
              v-model="config.tableOptions.sortDesc[0]"
              mandatory
            >
              <v-btn
                depressed
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                depressed
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </template>
      <template #default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.id"
            :md="getGrid()"
            sm="4"
            cols="12"
            align-self="center"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 4 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto my-2 cursor-pointer text-center pa-0"
                width="220"
                @click="$emit('on-edit', item)"
              >
                <div>
                  <v-avatar
                    color="primary"
                    class="v-avatar-light-bg primary--text"
                    tile
                    height="165"
                    width="220"
                  >
                    <v-img
                      v-if="getImageCol()?.type.indexOf('file') >= 0 && item[getImageCol()?.value]"
                      :src="filesStore.getThumbnail(item[getImageCol()?.value])"
                    ></v-img>
                    <v-img
                      v-else-if="getImageCol()?.type.indexOf('files') >= 0 && item.property_slug"
                      :src="filesStore.getThumbnail(`${item?.bucket_id}/${item?.project_id}/${item?.folder_id}/${item?.property_slug}/${item?.name}`, item?.metadata?.mimetype)"
                    ></v-img>
                    <v-img
                      v-else-if="getImageCol()?.type.indexOf('files') >= 0 && !item.property_slug"
                      :src="filesStore.getThumbnail(`${item?.bucket_id}/${item?.project_id}/${item?.folder_id}/${item?.name}`, item?.metadata?.mimetype)"
                    ></v-img>
                    <v-icon v-else
                            style="font-size: 60px;"
                            color="primary"
                    >
                      {{ item.icon ||getImageCol()?.icon || config.header.icon }}
                    </v-icon>
                  </v-avatar>
                  <v-divider></v-divider>

                  <div
                    class="px-3 py-2"
                    style="zoom: 0.6; min-height:100px;"
                  >
                    <template v-for="header in getHeaders(item)">
                      <div
                        class="d-flex text-left"
                        style="margin-bottom: 3px;"
                      >
                        <span class="d-none">{{ header.text }}</span>
                        <table-cell-item
                          :header="header"
                          :item="item"
                          :filter="false"
                          :source="{ type: header.type.replace('text-button', 'text-uppercase'), value: item[header.value]?.name || item[header.value] , text: item[header.value]?.name || item[header.value]}"
                          @on-click="onClick"
                        >
                        </table-cell-item>
                      </div>
                    </template>
                  </div>
                  <v-simple-checkbox
                    v-if="item?.id?.toString().indexOf('_') === -1"
                    style="position: absolute; top: 0px; right: -6px;"
                    color="primary"
                    :value="
                      !!items.find(
                        it => it[config.tableOptions.itemKey || 'id'] === item[config.tableOptions.itemKey || 'id'],
                      )
                    "
                    @click="$emit('toggle-select', item)"
                  ></v-simple-checkbox>
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import {
  ref,
} from 'vue'
import useAppConfig from '@/stores/appconfig'
import TableCellItem from '@/views/table/TableCellItem.vue'
import { useFilesStore } from '@/stores/files.store'

export default {
  components: {
    TableCellItem,
  },
  props: {
    config: {
      type: Object,
      default: null,
      required: true,
    },
    options: {
      type: Object,
      default: null,
      required: true,
    },
    listTable: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totalTable: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  setup(props, { emit }) {
    const filesStore = useFilesStore()
    const item = useVModel(props, 'item', emit)
    const config = useVModel(props, 'config', emit)
    const options = useVModel(props, 'options', emit)
    const isDelete = useVModel(props, 'isDelete', emit)
    const listTable = useVModel(props, 'listTable', emit)
    const items = useVModel(props, 'items', emit)
    const loading = useVModel(props, 'loading', emit)
    const mobileBreakPoint = ref(0)

    const {
      appContentLayoutNav,
    } = useAppConfig()

    const getGrid = () => {
      if (appContentLayoutNav.value === 'horizontal') {
        return config.value.isLeftSidebarOpen ? 3 : 2
      }

      return config.value.isLeftSidebarOpen ? 4 : 3
    }

    const getImageCol = () => config.value.tableColumns.find(h => h.type.indexOf('file') >= 0)

    const getHeaders = () => {
      const headers = []
      config.value.tableColumns.filter(h => !h.hidden && h.type.indexOf('file') === -1 && h.type !== 'actions').forEach(header => {
        headers.push(header)
      })

      return headers
    }

    const onClick = (val, _item) => {
      emit('on-click', val, _item)
    }

    return {
      item,
      options,
      isDelete,
      listTable,
      items,
      loading,
      getImageCol,
      filesStore,
      appContentLayoutNav,
      getGrid,
      getHeaders,
      onClick,
      mobileBreakPoint,
    }
  },
}
</script>
