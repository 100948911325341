<template>
  <div
    v-if="projectsStore.project"
    id="user-view"
  >
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      class="user-tabs"
      style="position: relative; z-index: 0;"
      show-arrows
    >
      <v-tab
        v-for="(t, index) in tabs"
        :key="t.icon"
        :disabled="!$canACL(t.action, t.resource, projectsStore.project) || index !== 0"
        @click="onTabEvent(index)"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ t.icon }}
        </v-icon>
        <span>{{ $t(t.title) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items
      id="user-tabs-content"
      v-model="tab"
    >
      <v-tab-item>
        <project-details
          :project="projectsStore.project"
          :organizations="organizationsStore.organizations"
        ></project-details>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { useOrganizationsStore } from '@/stores/organizations.store'
import { useProjectsStore } from '@/stores/projects.store'
import { useUserStore } from '@/stores/user.store'
import ProjectDetails from '@/views/projects/settings/ProjectDetails.vue'
import { appConfig } from '@/plugins/micleoConfig'
import {
  getCurrentInstance, onMounted, onUnmounted,
  ref,
} from 'vue'

export default {
  metaInfo: {
    title: 'Project',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    ProjectDetails,
  },
  setup() {
    const tab = ref(0)
    const subscription = ref(null)
    const vm = getCurrentInstance().proxy
    const userStore = useUserStore()
    const projectsStore = useProjectsStore()
    const organizationsStore = useOrganizationsStore()

    onMounted(async () => {
      subscription.value = await projectsStore.listenProject(vm.$router.currentRoute.params.id)
      organizationsStore.getOrganizations()
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    const onTabEvent = t => {
      vm.$router.push({ name: 'settings', params: { tab: t, id: vm.$router.currentRoute.params.id } })
    }

    if (vm.$router.currentRoute.params?.tab) {
      tab.value = parseInt(vm.$router.currentRoute.params.tab, 10)
    }

    // tabs
    const tabs = [
      {
        title: 'General', icon: 'mdi-application', action: 'update', resource: 'projects',
      },
    ]

    return {
      projectsStore,
      organizationsStore,
      tab,
      tabs,
      onTabEvent,
    }
  },
  data() {
    return {
      subscription: null,
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
