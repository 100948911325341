<template>
  <div v-resize="onResize">
    <v-snackbar
      v-model="isAlertVisible"
      color="error"
      outlined
      text
      shaped
      top
      right
    >
      {{ errors.import }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="error"
          v-bind="attrs"
          @click="isAlertVisible = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-stepper
      class="custom-header overflow-y-auto"
      :height="formHeight"
      style="background-color: rgba(94, 86, 105, 0.04);"
    >
      <!-- Header -->
      <v-stepper-header class="ma-0 pa-0">
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
          :rules="[() => stepValidationResult[1]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3"
              :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
            >01</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
              >{{ $t('ExportColumns') }}</span>
              <span
                class="text-xs"
                :class="stepValidationResult[1] ? 'text--secondary' : 'error--text'"
              >{{
                $t('ExportColumnsDetails')
              }}</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content
          step="1"
          class="pa-0 ma-0 px-0 pb-2"
        >
          <v-form
            ref="step1Form"
          >
            <table-content
              v-if="subconfig"
              :config.sync="subconfig"
              :options.sync="options"
              :list-table.sync="listTable"
              :list-local.sync="listLocal"
              :is-delete.sync="isDelete"
              :loading.sync="loading"
              :item="{}"
              :items.sync="items"
              @on-refresh="onRefresh"
            ></table-content>
          </v-form>
        </v-stepper-content>

        <!-- Stepper Content: Step 2 -->
        <v-stepper-content
          step="2"
          class="pa-0 ma-0 px-0 pb-2"
        >
          <v-form
            ref="step2Form"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-alert
                  v-if="errors.import"
                  border="left"
                  colored-border
                  text
                  color="error"
                  class="subtitle-2"
                >
                  {{ errors.import }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card class="pa-3 ma-0">
      <div class="d-flex justify-space-between">
        <v-btn
          v-if="activeStep==1"
          outlined
          @click="$emit('on-previous')"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-badge
          :value="!loadingStep"
          color="secondary"
          :content="count"
          overlap
          top
          left
        >
          <v-btn
            color="primary"
            class="me-2"
            :disabled="loadingStep"
            :loading="loadingStep"
            @click="handleStepValidation(1, true, true)"
          >
            {{ $t('Complete') }}
          </v-btn>
        </v-badge>
      </div>
    </v-card>
  </div>
</template>
<script>
import {
  ref, onMounted, onUnmounted, getCurrentInstance,
} from 'vue'
import { useVModel } from '@vueuse/core'
import chunk from 'lodash/chunk'
import sortBy from 'lodash/sortBy'
import TableContent from '@/views/table/TableContent.vue'
import useTable from '@/views/table/useTable'
import { usePropertiesStore } from '@/stores/properties.store'
import { useTableStore } from '@/stores/table.store'
import { TABLE_CONTACTS } from '@core/utils'

export default {
  components: {
    TableContent,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    directory: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const activeStep = ref(1)
    const loadingStep = ref(false)
    const config = useVModel(props, 'config', emit)
    const selectedItems = useVModel(props, 'selectedItems', emit)
    const directory = useVModel(props, 'directory', emit)
    const formHeight = ref(0)
    const step1Form = ref(null)
    const step2Form = ref(null)
    const subconfig = ref(null)
    const tableColumns = ref([])
    const propertiesStore = usePropertiesStore()
    const tableStore = useTableStore()

    if (props.count === 0) { emit('on-previous', 0) }

    const {
      listTable,
      listLocal,
      searchQuery,
      totalTable,
      setLocalData,
      loading,
      item,
      items,
      isDelete,
      options,
      isAlertVisible,
      errors,
      fetchDatas,
    } = useTable(subconfig.value || props.config, false)

    const stepValidationResult = ref({
      1: true,
      2: true,
    })

    // Step Validation
    const handleStepValidation = async (step, navigateNext = true, isFinalStep = false) => {
      // Get Form templateRef of Step from argument
      const templateRef = (() => {
        if (step === 1) return step1Form.value
        if (step === 2) return step2Form.value

        return null
      })()

      // If no validate step templateRef is found just return
      if (!templateRef) return

      // If step is valid proceed to the next step
      const isStepValid = templateRef.validate()

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid

      if (isStepValid) {
        if (navigateNext && !isFinalStep) activeStep.value += 1
        else if (!navigateNext) activeStep.value -= 1
      }

      if (isFinalStep && isStepValid) {
        errors.value = {}
        loadingStep.value = true
        let data = null
        try {
          if (selectedItems.value.length > 0) {
            selectedItems.value?.forEach(it => {
              Object.keys(it).forEach(key => {
                if (items.value.length > 0 && !items.value.find(col => col.slug === key)) {
                  delete it[key]
                }
                if ((!Object.keys(config.value.model).includes(key) && key !== 'id') || key === 'project_id') {
                  delete it[key]
                }
              })
            })

            import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({ json2excel }) => {
              const chunked = chunk(selectedItems.value, 50000)
              chunked?.forEach(tab => {
                data = tab
                tableColumns.value.forEach(col => {
                  data = JSON.parse(JSON.stringify(data).replace(new RegExp(col.slug, 'g'), col.name))
                })
                json2excel({
                  data,
                  name: config.value.currentTable,
                  formateDate: 'yyyy/mm/dd',
                })
              })
              loadingStep.value = false
              setTimeout(() => {
                selectedItems.value = []
                emit('on-previous', config.value.currentTable ? 2 : 0)
              }, 50)
            }).catch(error => {
              isAlertVisible.value = true
              loadingStep.value = false
              errors.value = {
                import: error.message,
              }
              if (error.response) {
                errors.value = {
                  import: error.response.data?.error?.message || error.response.data?.message,
                }
              }
            })
          } else {
            tableStore.getExport(directory.value[directory.value.length - 1]).then(allItems => {
              import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({ json2excel }) => {
                allItems?.forEach(it => {
                  Object.keys(it)?.forEach(key => {
                    if (items.value.length > 0 && !items.value.find(col => col.slug === key)) {
                      delete it[key]
                    }
                    if ((!Object.keys(config.value.model).includes(key) && key !== 'id') || key === 'project_id') {
                      delete it[key]
                    }
                  })
                })
                const chunked = chunk(allItems, 50000)
                chunked?.forEach(tab => {
                  data = tab
                  tableColumns.value.forEach(col => {
                    data = JSON.parse(JSON.stringify(data).replace(new RegExp(col.slug, 'g'), col.name))
                  })
                  json2excel({
                    data,
                    name: config.value.currentTable,
                    formateDate: 'yyyy/mm/dd',
                  })
                })
                loadingStep.value = false
                setTimeout(() => emit('on-previous', config.value.currentTable ? 2 : 0), 0)
              }).catch(error => {
                isAlertVisible.value = true
                loadingStep.value = false
                errors.value = {
                  import: error.message,
                }
                if (error.response) {
                  errors.value = {
                    import: error.response.data?.error?.message || error.response.data?.message,
                  }
                }
              })
            }).catch(error => {
              isAlertVisible.value = true
              loadingStep.value = false
              errors.value = {
                import: error.message,
              }
              if (error.response) {
                errors.value = {
                  import: error.response.data?.error?.message || error.response.data?.message,
                }
              }
            })
          }
        } catch (error) {
          isAlertVisible.value = true
          loadingStep.value = false
          errors.value = {
            import: error.message,
          }
          if (error.response) {
            errors.value = {
              import: error.response.data?.error?.message || error.response.data?.message,
            }
          }
        }
      }
    }

    onMounted(async () => {
      subconfig.value = JSON.parse(JSON.stringify(config.value))
      subconfig.value.header.display = 'table'
      subconfig.value.tableOptions = {
        sortBy: ['sort_index'],
        sortDesc: [false],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: -1,
        fixedHeader: true,
        page: 1,
        height: 0,
        filters: {},
      }
      subconfig.value.tableColumns = [/* { text: '#', value: 'sort_index', type: 'drag' }, */
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
          sortable: false,
        }]
      tableColumns.value = []
      if (config.value.currentTable === TABLE_CONTACTS) {
        propertiesStore.getProperties(tableStore.route.params.id, 'contacts').then(properties => {
          properties.forEach((p, index) => {
            tableColumns.value.push({
              name: vm.$t(p.name), sort_index: index, id: p.id, slug: p.encrypt ? `properties_crypted->>${p.slug}` : `properties->>${p.slug}`, position: index,
            })
          })
          setLocalData(tableColumns.value, subconfig.value.tableOptions)
        })
      } else {
        Object.keys(config.value.model).forEach((key, index) => {
          const schema = tableStore.getCurrentSchema(key)
          if (schema) {
            tableColumns.value.push({
              name: vm.$t(schema.label), sort_index: index, id: key, slug: key, position: tableStore.getPositionSchema(key),
            })
          }
        })
        sortBy(tableColumns.value, ['position'])
        tableColumns.value.forEach((d, index) => {
          d.index = index
        })
        setLocalData(tableColumns.value, subconfig.value.tableOptions)
      }
    })

    onUnmounted(() => {
    })

    const onResize = () => {
      formHeight.value = window.innerHeight - 320
    }

    const onRefresh = () => {
      fetchDatas()
    }

    return {
      handleStepValidation,
      activeStep,
      loadingStep,

      // Step Validations
      stepValidationResult,

      // Template Refs
      step1Form,
      step2Form,
      config,
      onResize,
      onRefresh,
      formHeight,

      listTable,
      listLocal,
      searchQuery,
      totalTable,
      loading,
      item,
      items,
      isDelete,
      options,
      isAlertVisible,
      errors,
      fetchDatas,
      subconfig,
    }
  },
}
</script>
