<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          v-if="$vuetify.theme.isDark && appLogoDark || !$vuetify.theme.isDark && appLogo"
          :src="$vuetify.theme.isDark ? appLogoDark: appLogo"
          max-height="30px"
          max-width="30px"
          :alt="appName"
          contain
        ></v-img>

        <h2 class="ml-1 font-weight-semibold appName">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex align-center h-full pa-16 pe-0 mr-16">
              <v-img
                contain
                max-width="100%"
                height="500"
                :src="require(`@/assets/images/contacts-large-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          cols="12"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <!-- title -->
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t('ResetPassword') }}
                  </p>
                  <p class="mb-2">
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="resetPasswordForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="password"
                      outlined
                      class="mb-3"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      prepend-inner-icon="mdi-form-textbox-password"
                      :error-messages="errors.password != '' ? $t(errors.password) : errors.password"
                      :label="$t('NewPassword')"
                      :append-icon="isPasswordVisible ? 'mdi-eye-off-outline': 'mdi-eye-outline'"
                      hide-details="auto"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>
                    <v-text-field
                      v-model="repeatePassword"
                      class="mb-3"
                      :label="$t('ConfirmPassword')"
                      outlined
                      hide-details="auto"
                      :type="isPasswordVisibleConfirm ? 'text' : 'password'"
                      prepend-inner-icon="mdi-form-textbox-password"
                      :rules="[validators.required,validators.confirmedValidator(repeatePassword,password)]"
                      :append-icon="isPasswordVisibleConfirm ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                      @click:append="isPasswordVisibleConfirm = !isPasswordVisibleConfirm"
                    ></v-text-field>
                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="loading"
                      :disabled="loading"
                    >
                      {{ $t('Submit') }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <span class="me-2">
                    {{ $t('AlreadyHaveAccount') }}
                  </span>
                  <router-link :to="{name:'auth-login'}">
                    {{ $t('SignInInstead') }}
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user.store'
import { appConfig } from '@/plugins/micleoConfig'
import { confirmedValidator, emailValidator, required } from '@core/utils/validation'
import { getCurrentInstance, ref } from 'vue'

export default {
  metaInfo: {
    title: 'Reset Password',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  setup() {
    const resetPasswordForm = ref(null)
    const isPasswordVisible = ref(false)
    const isPasswordVisibleConfirm = ref(false)
    const password = ref('')
    const repeatePassword = ref('')
    const errors = ref({})
    const loading = ref(false)
    const vm = getCurrentInstance().proxy

    const userStore = useUserStore()

    const handleFormSubmit = () => {
      const isFormValid = resetPasswordForm.value.validate()

      if (!isFormValid) return

      loading.value = true
      userStore.updatePassword(password.value)
        .then(userData => {
          if (userData !== null) {
            loading.value = false
            vm.$router.push('/')
          }
        })
        .catch(err => {
          loading.value = false
          errors.value = {
            password: err.message,
          }
        })
    }

    return {
      isPasswordVisible,
      isPasswordVisibleConfirm,
      password,
      repeatePassword,
      errors,
      loading,

      // appConfig.theme
      appName: appConfig.theme.app.name,
      appLogo: appConfig.theme.app.logo,
      appLogoDark: appConfig.theme.app.logoDark,
      validators: {
        required,
        emailValidator,
        confirmedValidator,
      },
      resetPasswordForm,
      handleFormSubmit,
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
