import { ref, watch } from 'vue'
import { useIntegrationsStore } from '@/stores/integrations.store'

export default function useList(organizationId) {
  const listTable = ref([])
  const searchQuery = ref('')
  const loading = ref(true)
  const tagsFilter = ref(null)
  const isAddDialogVisible = ref(false)

  const integrationsStore = useIntegrationsStore()

  const fetchDatas = () => {
    integrationsStore
      .getIntegrations(organizationId, searchQuery.value, tagsFilter.value)
      .then(data => {
        listTable.value = data
        loading.value = false
      })
      .catch(() => {
        listTable.value = []
        loading.value = false
      })
  }

  fetchDatas()
  watch([searchQuery, tagsFilter], () => {
    loading.value = true
    fetchDatas()
  })

  return {
    searchQuery,
    listTable,
    loading,
    fetchDatas,
    isAddDialogVisible,
    tagsFilter,
  }
}
