<template>
  <v-dialog
    v-model="isConfirmDialogVisibleLocal"
    max-width="450"
  >
    <v-form @submit.prevent="confirmAction">
      <v-card class="pa-sm-6 pa-3">
        <v-card-title class="justify-center text-h5">
          {{ $t("AreYouSure") }}
        </v-card-title>
        <v-card-text class="pt-5">
          <v-alert
            v-if="message"
            border="left"
            colored-border
            text
            color="primary"
            class="subtitle-2"
          >
            {{ $t(message, payload) }}
          </v-alert>
          <div class="mt-6 d-flex justify-center">
            <v-btn
              color="primary"
              class="me-3"
              :loading="isLoading"
              type="submit"
            >
              {{ $t("Confirm") }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-confirm-dialog-visible', false)"
            >
              {{ $t("Cancel") }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { ref, getCurrentInstance, watch } from 'vue'
import { useVModel } from '@vueuse/core'

export default {
  props: {
    message: {
      type: String,
      default: null,
    },
    payload: {
      type: Object,
      default: null,
    },
    isConfirmDialogVisible: {
      type: Boolean,
      required: true,
    },
    isConfirm: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const confirm = ref('')
    const disabled = ref(true)
    const vm = getCurrentInstance().proxy
    const isConfirmDialogVisibleLocal = useVModel(props, 'isConfirmDialogVisible', emit)

    const confirmAction = () => {
      emit('confirm-action')
    }
    watch(isConfirmDialogVisibleLocal, newVal => {
      if (newVal) {
        confirm.value = ''
        disabled.value = true
      }
    })

    const up = () => {
      if (confirm.value === vm.$t('Confirm')) {
        disabled.value = false
      } else disabled.value = true
    }

    return {
      confirm,
      disabled,
      confirmAction,
      up,
      isConfirmDialogVisibleLocal,
    }
  },
}
</script>

<style lang="">
</style>
