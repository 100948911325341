<template>
  <v-dialog
    v-model="isDeleteDialogVisibleLocal"
    max-width="450"
  >
    <v-form @submit.prevent="confirmDelete">
      <v-card class="pa-sm-6 pa-3">
        <v-card-title class="justify-center text-h5">
          {{ $t("AreYouSure") }}
        </v-card-title>
        <v-card-text class="pt-5">
          <v-alert
            v-if="message"
            border="left"
            colored-border
            text
            color="error"
            class="subtitle-2"
          >
            {{ message }}
          </v-alert>
          <v-text-field
            v-if="isConfirm"
            v-model="confirm"
            color="error"
            single-line
            dense
            outlined
            hide-details
            :prepend-inner-icon="disabled?'mdi-alert':'mdi-check'"
            :label="$t('TypeDelete')"
            @input="up"
          ></v-text-field>
          <div class="mt-6 d-flex justify-center">
            <v-btn
              :disabled="disabled && isConfirm"
              color="error"
              class="me-3"
              :loading="isLoading"
              type="submit"
            >
              {{ $t("Delete") }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-delete-dialog-visible', false)"
            >
              {{ $t("Cancel") }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { ref, getCurrentInstance, watch } from 'vue'
import { useVModel } from '@vueuse/core'

export default {
  props: {
    message: {
      type: String,
      default: null,
    },
    isDeleteDialogVisible: {
      type: Boolean,
      required: true,
    },
    isConfirm: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const confirm = ref('')
    const disabled = ref(true)
    const vm = getCurrentInstance().proxy
    const isDeleteDialogVisibleLocal = useVModel(props, 'isDeleteDialogVisible', emit)

    const confirmDelete = () => {
      emit('confirm-delete')
    }
    watch(isDeleteDialogVisibleLocal, newVal => {
      if (newVal) {
        confirm.value = ''
        disabled.value = true
      }
    })

    const up = () => {
      if (confirm.value === vm.$t('Delete')) {
        disabled.value = false
      } else disabled.value = true
    }

    return {
      confirm,
      disabled,
      confirmDelete,
      up,
      isDeleteDialogVisibleLocal,
    }
  },
}
</script>

<style lang="">
</style>
