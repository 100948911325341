<template>
  <div>
    <confirm-dialog-delete
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingAction"
      :is-confirm="false"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>

    <v-snackbar
      v-model="isAlertVisible"
      color="error"
      outlined
      text
      shaped
      top
      right
    >
      {{ errors.chat }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="error"
          v-bind="attrs"
          @click="isAlertVisible = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-card class="max-h-content-container app-chat position-relative overflow-hidden text-sm">
      <!-- Navigation Drawer: Left Sidebar -->
      <v-navigation-drawer
        v-model="isLeftSidebarOpen"
        width="374"
        touchless
        :right="$vuetify.rtl"
        mobile-breakpoint="sm"
        :temporary="$vuetify.breakpoint.xsOnly"
        absolute
      >
        <chat-left-sidebar-content
          :user="userStore.user"
          :contacts="chatsStore.contacts"
          :chats="chatsStore.chats"
          :active-chat-id="chatsStore.chat ? chatsStore.chat.id : null"
          :profile-user-minimal-data="userStore.user"
          @close-left-sidebar="isLeftSidebarOpen = false"
          @open-chat="openChat"
          @add-chat="addChat"
          @open-chat-user-profile-sidebar-content="isUserProfileSidebarOpen = true"
        ></chat-left-sidebar-content>
      </v-navigation-drawer>

      <!-- Navigation Drawer: Active Chat -->
      <v-navigation-drawer
        v-model="isActiveChatInfo"
        width="374"
        absolute
        temporary
        :right="!$vuetify.rtl"
        touchless
      >
        <chat-info
          :chat="chatsStore.chat"
          :contacts="chatsStore.contacts"
          @close-chat-info="isActiveChatInfo = false"
        ></chat-info>
      </v-navigation-drawer>

      <div
        class="chat-content-area h-full d-flex flex-column"
        :style="contentStyles"
      >
        <!-- Active Chat -->
        <div
          v-if="chatsStore.chat.name"
          class="h-full"
        >
          <!-- Navbar -->
          <div
            class="d-flex align-center justify-space-between px-5 py-4"
            style="height:72px"
          >
            <!-- Sidebar Toggler, Avatar & Name -->
            <div class="d-flex align-center">
              <v-btn
                icon
                class="me-2 d-inline-block d-md-none"
              >
                <v-icon
                  v-show="!isLeftSidebarOpen"
                  @click="isLeftSidebarOpen = true"
                >
                  mdi-menu
                </v-icon>
              </v-btn>
              <div
                class="v-avatar-group me-3 "
              >
                <avatar-member
                  v-for="admin in chatData.admins"
                  :key="admin.id+''+admin.online"
                  :member="admin"
                  xsmall
                ></avatar-member>
              </div>
              <div class="d-flex flex-column ">
                <p class="mb-0 text--primary font-weight-medium d-none d-sm-block">
                  {{ chatsStore.chat.name }}
                </p>
                <p class="mb-0 text--primary">
                  {{ chatsStore.nbMessages }} <v-icon small>
                    mdi-message-text-outline
                  </v-icon> /
                  {{ chatsStore.chat.members.length }} <v-icon small>
                    mdi-account-outline
                  </v-icon>
                </p>
              </div>
            </div>

            <!-- Active Chat Actions -->
            <div class="d-flex align-baseline">
              <v-icon
                v-if="false"
                disabled
                class="me-3 cursor-pointer d-none d-sm-inline-flex"
                color="primary"
              >
                mdi-magnify
              </v-icon>
              <v-icon
                class="cursor-pointer me-3"
                color="primary"
                :disabled="(chatsStore.chat.created_by !==userStore.user.id)"
                @click="(isDeleteDialogVisible = true)"
              >
                mdi-delete
              </v-icon>

              <v-icon
                class="cursor-pointer me-3"
                color="primary"
                @click="(isActiveChatInfo = true)"
              >
                mdi-information
              </v-icon>
            </div>
          </div>

          <v-divider></v-divider>

          <perfect-scrollbar
            ref="refChatLogPS"
            :options="perfectScrollbarOptions"
            class="ps-chat-log h-full"
          >
            <chat-log
              :contacts="chatsStore.contacts"
              :user="userStore.user"
              :chat-data="chatsStore.chat"
              @scroll-chat="scrollChat"
            ></chat-log>
          </perfect-scrollbar>

          <v-form
            autocomplete="off"
            @submit.prevent="sendMessage"
          >
            <input
              ref="refInputEl"
              type="file"
              accept=".jpeg,.png,.jpg,GIF"
              :hidden="true"
              @change="handleFileSelected"
            />
            <div class="pb-5 px-5 flex-grow-0 msg-input d-flex">
              <v-chip
                v-if="attachment"
                class="mt-2 me-1"
                color="primary"
                close
                @click="(attachment= null)"
              >
                <v-icon>mdi-file</v-icon>
              </v-chip>
              <v-text-field
                :key="chatsStore.chat.id"
                v-model="chatInputMessage"
                :placeholder="$t('TypeYourMessage')"
                solo

                hide-details
                height="50"
                @click="onClickChatInputMessage"
              >
                <template #append>
                  <v-btn
                    v-if="false"
                    icon
                    disabled
                  >
                    <v-icon>mdi-microphone</v-icon>
                  </v-btn>
                  <v-btn
                    :loading="loadingUpload"
                    icon
                    class="me-3"
                    @click="uploadClick"
                  >
                    <v-icon>
                      mdi-attachment
                    </v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    elevation="0"
                    type="submit"
                    :loading="loadingSend"
                    :icon="$vuetify.breakpoint.xsOnly"
                  >
                    <template v-if="$vuetify.breakpoint.smAndUp">
                      {{ $t('Send') }}
                    </template>
                    <template v-else>
                      <v-icon>mdi-send-outline</v-icon>
                    </template>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </v-form>
        </div>

        <!-- Start Conversation -->
        <div
          v-else
          class="d-flex align-center justify-center flex-grow-1 flex-column"
        >
          <v-avatar
            size="109"
            class="mb-6"
          >
            <v-icon
              size="100"
              text
              class="rounded-0 text--primary"
            >
              mdi-message-text-outline
            </v-icon>
          </v-avatar>

          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            small
            outlined
            @click="startConversation"
          >
            <v-icon
              left
            >
              mdi-menu
            </v-icon>
            {{ $t('StartConversation') }}
          </v-btn>
        </div>
      </div>
    </v-card>

    <v-dialog
      v-model="isNewChatDialog"
      max-width="450"
    >
      <v-card class="pa-sm-6 pa-3">
        <v-form
          ref="form"
          class="multi-col-validation"
          @submit.prevent="saveChat"
        >
          <v-card-title
            class="justify-center text-h5"
          >
            {{ $t("StartConversation") }}
          </v-card-title>
          <v-card-text class="pt-3">
            <v-row class="mt-1">
              <v-col
                cols="12"
                class="py-1"
              >
                <v-text-field
                  v-model="room.name"
                  autofocus
                  prepend-inner-icon="mdi-text"
                  outlined
                  dense
                  :rules="[validators.required]"
                  hide-details
                  :label="$t('Name')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                class="py-1"
              >
                <v-autocomplete
                  v-model="room.members"
                  multiple
                  :label="$t('Members')"
                  chips
                  :items="chatsStore.contacts"
                  item-value="id"
                  item-text="fullname"
                  :item-disabled="isDisabled"
                  clearable
                  :rules="[validators.required]"
                  outlined
                >
                  <template #selection="data">
                    <div
                      height="80"
                      class="d-flex align-center"
                    >
                      <v-avatar
                        size="30"
                        color="primary"
                        class="v-avatar-light-bg primary--text"
                      >
                        <v-img
                          v-if="data.item.avatar"
                          :src="data.item.avatar"
                        ></v-img>
                        <span
                          v-else
                          class="font-weight-medium"
                        >{{ avatarText(data.item.fullname) }}</span>
                      </v-avatar>

                      <div
                        class="d-flex flex-column ms-3"
                      >
                        <span class="d-block   font-weight-semibold text-truncate">{{ data.item.fullname }}
                          <span class="text-xs font-weight-light"> {{ data.item.email }}</span> </span>
                      </div>
                    </div>
                  </template>
                  <template #item="data">
                    <div
                      height="80"
                      class="d-flex align-center"
                    >
                      <v-avatar
                        size="30"
                        color="primary"
                        class="v-avatar-light-bg primary--text"
                      >
                        <v-img
                          v-if="data.item.avatar"
                          :src="data.item.avatar"
                        ></v-img>
                        <span
                          v-else
                          class="font-weight-medium"
                        >{{ avatarText(data.item.fullname) }}</span>
                      </v-avatar>

                      <div
                        class="d-flex flex-column ms-3"
                      >
                        <span class="d-block font-weight-semibold text-truncate">{{ data.item.fullname }}
                          <span class="text-xs font-weight-light"> {{ data.item.email }}</span></span>
                      </div>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-alert
            v-if="errors.chat"
            border="left"
            colored-border
            text
            color="error"
            class="subtitle-2"
          >
            {{ errors.chat }}
          </v-alert>
          <v-card-actions class="mt-6 d-flex justify-center">
            <v-btn
              :loading="loadingAction"
              :disabled="loadingAction"
              type="submit"
              color="primary"
              class="me-3"
            >
              {{ $t('Create') }}
            </v-btn>
            <v-btn
              outlined
              color="secondary"
              @click="isNewChatDialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AvatarMember from '@/components/AvatarMember.vue'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import { useChatsStore } from '@/stores/chats.store'
import { useUserStore } from '@/stores/user.store'
import ChatInfo from '@/views/chats/ChatInfo.vue'
import ChatLeftSidebarContent from '@/views/chats/ChatLeftSidebarContent.vue'
import ChatLog from '@/views/chats/ChatLog.vue'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { avatarText } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import { appConfig } from '@/plugins/micleoConfig'
import {
  computed,
  getCurrentInstance,
  nextTick,
  ref,
} from 'vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  metaInfo: {
    title: 'Chats',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.name },
    ],
  },
  components: {
    PerfectScrollbar,
    ChatLog,
    AvatarMember,
    ConfirmDialogDelete,
    ChatLeftSidebarContent,
    ChatInfo,
  },
  setup() {
    const isNewChatDialog = ref(false)
    const loadingAction = ref(false)
    const loadingSend = ref(false)
    const room = ref({})
    const errors = ref({})
    const isAlertVisible = ref(false)
    const vm = getCurrentInstance().proxy
    const isDeleteDialogVisible = ref(false)
    const loadingUpload = ref(false)
    const attachment = ref(null)
    const isActiveChatInfo = ref(false)
    const form = ref(null)

    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 374 })

    // * Store module Registration
    const chatsStore = useChatsStore()
    const userStore = useUserStore()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    const chatData = computed(() => {
      const admins = []
      chatsStore.chat.members.forEach(item => {
        const admin = chatsStore.contacts.find(contact => contact.id === item.admin_id)
        if (admin) { admins.push(admin) }
      })

      return { admins }
    })

    const scrollChat = () => {
      if (chatsStore.chat.id) {
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      }
    }

    const addChat = () => {
      errors.value = {}
      if (form.value)form.value.reset()
      room.value = { name: `Room #${(Math.random() + 1).toString(36).substring(7).toUpperCase()}`, members: [] }
      isNewChatDialog.value = true
    }
    const saveChat = () => {
      loadingAction.value = true
      chatsStore
        .saveChat(room.value)
        .then(data => {
          if (data !== null) {
            isNewChatDialog.value = false
            loadingAction.value = false
          }
        })
        .catch(error => {
          loadingAction.value = false
          isAlertVisible.value = true
          errors.value = {
            chat: error.message,
          }
          if (error.response) {
            errors.value = {
              chat: error.response.data?.error?.message || error.response.data?.message,
            }
          }
        })
    }

    const chatInputMessage = ref('')
    const openChat = chatId => {
      if (!chatId || chatId === '0') { return }

      // Reset send message input value
      chatInputMessage.value = ''
      localStorage.setItem('chat', chatId)

      chatsStore.getChat(chatId).then(data => {
        chatsStore.chat = data
        chatsStore.updateSeen()
      }).catch(error => {
        localStorage.removeItem('chat')
        isAlertVisible.value = true
        errors.value = {
          chat: error.message,
        }
        if (error.response) {
          errors.value = {
            chat: error.response.data?.error?.message || error.response.data?.message,
          }
        }
      })

      // if smAndDown =>  Close Chat & Contacts left sidebar
      if (vm.$vuetify.breakpoint.smAndDown) isLeftSidebarOpen.value = false
    }

    if (localStorage.getItem('chat')) {
      openChat(localStorage.getItem('chat'))
    }

    const sendMessage = () => {
      if (!chatInputMessage.value && !attachment.value) return

      loadingSend.value = true

      chatsStore.sendMessage(chatInputMessage.value, attachment.value).then(() => {
        chatInputMessage.value = ''
        attachment.value = null
        loadingSend.value = false
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      }).catch(error => {
        loadingSend.value = false
        isAlertVisible.value = true
        errors.value = {
          chat: error.message,
        }
        if (error.response) {
          errors.value = {
            chat: error.response.data?.error?.message || error.response.data?.message,
          }
        }
      })
    }

    // Open Sidebar in smAndDown when "start conversation" is clicked
    const startConversation = () => {
      if (vm.$vuetify.breakpoint.mdAndUp) return
      isLeftSidebarOpen.value = true
    }

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const onClickChatInputMessage = () => {
      chatsStore.updateSeen().then(() => {
      }).catch(error => {
        isAlertVisible.value = true
        errors.value = {
          chat: error.message,
        }
        if (error.response) {
          errors.value = {
            chat: error.response.data?.error?.message || error.response.data?.message,
          }
        }
      })
    }

    const confirmDelete = () => {
      chatsStore.deleteChat().then(() => {
        isDeleteDialogVisible.value = false
      }).catch(error => {
        isAlertVisible.value = true
        errors.value = {
          chat: error.message,
        }
        if (error.response) {
          errors.value = {
            chat: error.response.data?.error?.message || error.response.data?.message,
          }
        }
      })
    }

    const isDisabled = item => item.id === userStore.user.id

    const uploadClick = () => {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      vm.$refs.refInputEl.dispatchEvent(event)
    }

    const handleFileSelected = async e => {
      loadingUpload.value = true
      if (
        e.target.files.length > 0
        && (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png')
      ) {
        const base64 = await new Response(e.target.files[0]).text()
        const md5 = await vm.CryptoJS.MD5(base64).toString()
        chatsStore.uploadDoc(md5, e.target.files[0]).then(async () => {
          const url = await chatsStore.getDoc(md5)
          attachment.value = url.publicUrl
          loadingUpload.value = false
        })
          .catch(async () => {
            const url = await chatsStore.getDoc(md5)
            attachment.value = url.publicUrl
            loadingUpload.value = false
          })
      } else {
        loadingUpload.value = false
        isAlertVisible.value = true
        errors.value = {
          chat: 'Allowed JPG, GIF or PNG',
        }
      }
    }

    return {

      // Layout
      isLeftSidebarOpen,
      contentStyles,

      // Templare ref
      refChatLogPS,

      chatInputMessage,
      openChat,
      sendMessage,

      // startConversation
      startConversation,

      // Perfect Scrollbar
      perfectScrollbarOptions,

      // Filter
      avatarText,
      isNewChatDialog,
      loadingAction,
      addChat,
      errors,
      validators: {
        required,
      },
      room,
      saveChat,
      chatsStore,
      userStore,
      isDisabled,
      loadingSend,
      isAlertVisible,
      onClickChatInputMessage,
      scrollChat,
      confirmDelete,
      isDeleteDialogVisible,
      uploadClick,
      handleFileSelected,
      loadingUpload,
      attachment,
      chatData,
      isActiveChatInfo,
      form,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/chat.scss';
</style>
