<template>
  <v-col
    :lg="appContentLayoutNav === 'horizontal' ? 3 : 4"
    md="4"
    sm="6"
    cols="12"
  >
    <v-card
      v-if="workspace"
      :disabled="!$canACL('view', 'workspaces',workspace)"
      height="100%"
      min-width="280"
      outlined
    >
      <v-card-title class="">
        <h5 class="font-weight-bold text-uppercase">
          {{ workspace.name }}
        </h5>
      </v-card-title>
      <v-card-subtitle class="pb-0">
        <span class="text-button">{{ workspace.organization?.name }}</span>
      </v-card-subtitle>
      <v-card-text>
        <v-chip
          outlined
          class="mb-3"
        >
          {{ workspace.settings?.region }}
        </v-chip>
      </v-card-text>
      <v-card-actions
        class="pa-2 pt-0"
      >
        <v-btn
          color="primary"
          block
          dark
          large
          @click="goTo"
        >
          {{ $t("Settings") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import {
  getCurrentInstance,
} from 'vue'
import useAppConfig from '@/stores/appconfig'
import { useWorkspacesStore } from '@/stores/workspaces.store'

export default {
  components: {
  },
  props: {
    workspace: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const {
      appContentLayoutNav,
    } = useAppConfig()

    const vm = getCurrentInstance().proxy
    const workspaceStore = useWorkspacesStore()

    const goTo = async () => {
      workspaceStore.workspace = await workspaceStore.getWorkspace(props.workspace.id)
      vm.$router.push({ name: 'edit-workspace', params: { id: props.workspace.id, tab: 0 } })
    }

    return {
      appContentLayoutNav,
      goTo,
    }
  },
}
</script>

<style>

</style>
