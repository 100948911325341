<template>
  <div class="h-full overflow-auto">
    <v-card v-for="(type, index) in groups"
            :key="index"
            class="pa-2 ma-2"
            outlined
    >
      <v-card-title class="pa-2">
        <v-icon color="primary"
                class="me-3"
        >
          {{ type.icon }}
        </v-icon>
        <span class="text-button font-weight-sem primary--text">
          {{ $t(type.value) }}
        </span>
      </v-card-title>
      <draggable
        :list="composants[type.value]"
        v-bind="{ group: { name: 'people', pull: 'clone', put: false }, sort: false, ghostClass: 'ghost' }"
      >
        <template v-for="(appearance, i) in composants[type.value]">
          <div :type="type.value"
               :appearance="appearance.value"
          >
            <v-list-item :key="i"
                         dense
                         class="cursor-pointer"
            >
              <v-list-item-icon>
                <v-icon v-text="appearance.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-uppercase">
                {{ $t(appearance.value) }}
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon small>
                  mdi-arrow-all
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </template>
      </draggable>
    </v-card>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  components: {
    Draggable,
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
    composants: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
    }
  },
}
</script>
