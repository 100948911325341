import { defineStore, acceptHMRUpdate } from 'pinia'
import { useChatsStore } from '@/stores/chats.store'
import { useFoldersStore } from '@/stores/folders.store'
import { canACL } from '@/plugins/acl/checkaccess'
import { required } from '@core/utils/validation'
import { supabase } from '@/plugins/supabase'
import { TABLE_FORMS, displayMode } from '@core/utils'
import { Clipboard } from 'v-clipboard'

const slugify = require('slugify')

// eslint-disable-next-line import/prefer-default-export
export const useFormsStore = defineStore({
  id: TABLE_FORMS,
  state: () => ({
    forms: [],
  }),
  actions: {
    getHeader(payload, item) {
      return {
        title: 'Forms',
        icon: 'mdi-list-box-outline',
        name: ['name'],
        folder: payload?.folder,
        display: displayMode(TABLE_FORMS, 'grid'),
        newData: 'NewForm',
        actionData: 'EditForms',
        editData: 'EditForm',
        search: 'SearchForm',
        searchIcon: 'mdi-magnify',
        add: {
          text: 'AddForm',
          icon: 'mdi-plus',
        },
        actions: [{
          title: 'Save', icon: 'mdi-content-save-outline', action: 'save', loading: false, acl: 'create,update',
        }, {
          title: 'Duplicate',
          icon: 'mdi-content-duplicate',
          action: 'duplicate',
          acl: 'create',
          disabled: !item?.created_at,
        },
        {
          title: 'Delete',
          icon: 'mdi-delete-outline',
          action: 'delete',
          acl: 'delete',
          color: 'error',
          disabled: !item?.created_at,
        }],
      }
    },

    getTableOptions() {
      return {
        sortBy: ['updated_at'],
        sortDesc: [true],
        dense: false,
        multiSort: true,
        disableSort: false,
        itemsPerPage: -1,
        fixedHeader: true,
        groupDesc: [],
        groupBy: [],
        mustSort: false,
        page: 1,
        height: 0,
        filters: {},
      }
    },

    getTableColumns() {
      return [
        {
          text: 'Name',
          value: 'name',
          type: 'font-weight-semibold text-button primary--text',
          filterable: true,
          search: true,
        },
        {
          text: 'UpdatedAt',
          value: 'updated_at',
          type: 'date',
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          type: 'actions',
          sortable: false,
        },
      ]
    },

    getModel(payload) {
      return {
        // general
        name: null,
        slug: null,
        folder_id: payload?.folder_id,
        project_id: payload?.project_id,
        payload: null,
      }
    },

    getSchema(item, items, payload) {
      const chatsStore = useChatsStore()
      const foldersStore = useFoldersStore()

      const schema = {
        col1: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col2: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
        col3: {
          type: 'wrap',
          col: payload.isLeftSidebarOpen ? 12 : { cols: 12, md: 4 },
          flat: true,
          nobackground: true,
          schema: {},
        },
      }

      schema.col1.schema.general = {
        title: 'General',
        type: 'wrap',
        col: 12,
        outlined: true,
        shaped: true,
        ripple: false,
        class: 'px-3 ma-2',
        schema: {
          name: {
            type: 'text',
            label: 'Name',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-format-header-1',
            rules: items.length > 0 ? [] : [required],
            hidden: !!items.length,
          },
          slug: {
            type: 'text',
            label: 'Slug',
            class: 'mx-1 mb-2',
            prependInnerIcon: 'mdi-key-outline',
            disabled: true,
            hidden: !!items.length,
          },
          folder_id: {
            type: 'autocomplete',
            subtype: 'folders',
            label: 'Folders',
            class: 'mx-1 mb-2',
            col: 12,
            clearable: true,
            complex: true,
            prependInnerIcon: 'mdi-folder-open',
            dense: true,
            items: foldersStore.folders,
            rules: items.length > 0 ? [] : [required],
          },
        },
      }

      if (!canACL('view', 'folders', { id: payload?.project_id })) {
        delete schema.col1.schema.general.schema.folder_id
      }

      schema.col2.schema.details = {
        title: 'Actions',
        type: 'wrap',
        outlined: true,
        shaped: true,
        col: 12,
        ripple: false,
        class: 'pa-3 ma-2',
        hidden: !!items.length,
        schema: {
          form_editor: {
            type: 'btn',
            label: 'EditForm',
            outlined: true,
            small: true,
            class: 'mb-3',
            iconLeft: 'mdi-pencil',
            color: 'primary',
            offset: 2,
            col: 6,
          },
          copy_widget: {
            type: 'btn',
            label: 'CopyCode',
            outlined: true,
            small: true,
            class: 'mb-3',
            iconLeft: 'mdi-content-copy',
            color: 'primary',
            disabled: !item?.id,
            offset: 2,
            col: 6,
          },
          test: {
            type: 'btn',
            label: 'TestForm',
            outlined: true,
            small: true,
            class: 'mb-3',
            iconLeft: 'mdi-open-in-new',
            color: 'primary',
            disabled: !item?.id,
            offset: 2,
            col: 6,
          },
        },
      }

      if (item?.id) {
        schema.col3.schema.details = {
          title: 'Details',
          type: 'wrap',
          outlined: true,
          shaped: true,
          col: 12,
          ripple: false,
          class: 'pa-3 ma-2',
          schema: {},
        }
        schema.col3.schema.details.schema.id = {
          type: 'text',
          label: 'ID',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-key',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_at = {
          type: 'date-time-picker',
          label: 'CreatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil-plus',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.created_by = {
          type: 'select',
          subtype: 'admins',
          label: 'CreatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
        schema.col3.schema.details.schema.updated_at = {
          type: 'date-time-picker',
          label: 'UpdatedAt',
          class: 'mx-1 mb-2',
          prependInnerIcon: 'mdi-pencil',
          disabled: true,
          col: 12,
          hidden: !!items.length,
        }
        schema.col3.schema.details.schema.updated_by = {
          type: 'select',
          subtype: 'admins',
          label: 'UpdatedBy',
          class: 'mx-1 mb-2',
          disabled: true,
          'item-value': 'id',
          'item-text': 'fullname',
          hidden: !!items.length,
          col: 12,
          items: chatsStore.contacts,
        }
      }

      return schema
    },

    async onAction(action, payload) {
      if (action === 'test') {
        window.open(`${window.location.originL}form/${payload?.item?.id}`, '_blank').focus()
      } else if (action === 'copy_widget') {
        Clipboard.copy(`<iframe src="${window.location.originL}form/${payload?.item?.id}?dark=false" frameborder="0" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px" height="100%" width="100%"></iframe>`)
      }

      return payload?.item
    },

    async getForms(projectId) {
      const { data, error } = await supabase.from(TABLE_FORMS).select('*').eq('project_id', projectId)
      if (error) {
        return Promise.reject(error)
      }
      this.forms = data

      return this.forms
    },

    onChangeName(name, item) {
      if (!item?.created_at) {
        if (name) {
          item.slug = slugify(name, {
            replacement: '-', // replace spaces with replacement character, defaults to `-`
            remove: /[^a-zA-Z0-9 ]/g, // remove characters that match regex, defaults to `undefined`
            lower: true, // convert to lower case, defaults to `false`
            strict: false, // strip special characters except replacement, defaults to `false`
            trim: true, // trim leading and trailing replacement chars, defaults to `true`
          })
        } else {
          // table.value.item.id = ''
        }
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFormsStore, import.meta.hot))
}
