<template>
  <div>
    <div>
      <alert-error :is-alert-visible.sync="isAlertVisible"
                   :error="errors.token"
      ></alert-error>

      <v-snackbar v-model="isFormSubmittedSnackbarVisible"
                  shaped
                  top
                  right
      >
        {{ $t('WorkspaceCreatedSuccessfully') }}

        <template #action="{ attrs }">
          <v-btn icon
                 color="secondary"
                 v-bind="attrs"
                 @click="isFormSubmittedSnackbarVisible = false"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-stepper v-model="activeStep"
               class="custom-header"
    >
      <!-- Header -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step :complete="activeStep > 1"
                        step="1"
                        :rules="[() => stepValidationResult[1]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3"
              :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
            >01</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
              >{{ $t('CreateSupabaseAccount') }}</span>
              <span class="text-xs"
                    :class="stepValidationResult[1] ? 'text--secondary' : 'error--text'"
              >{{
                $t('CreateSupabaseAccountDetails')
              }}</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="activeStep > 2"
                        step="3"
                        :rules="[() => stepValidationResult[2]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3"
              :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
            >02</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
              >{{ $t('SupabaseProjects') }}</span>
              <span class="text-xs"
                    :class="stepValidationResult[2] ? 'text--secondary' : 'error--text'"
              >{{
                $t('SupabaseProjectsDetails')
              }}</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <v-form ref="step1Form"
                  class="pb-6 pt-2"
          >
            <v-row justify="center"
                   align="center"
            >
              <v-col cols="12"
                     md="10"
              >
                <v-alert
                  outlined
                  class="subtitle-2 justify-center align-center"
                >
                  <div class="d-flex mb-3">
                    <span class="text-h6 font-weight-semibold">1. {{ $t('CreateSupabaseAccount') }}</span>
                    <span class="text-caption me-3 mx-3 mt-1"><a href="https://supabase.com"
                                                                 target="blank"
                    >Supabase</a></span>
                  </div>
                  <div class="my-3">
                    {{ $t('CreateSupabaseAccountDetails') }}
                  </div>
                  <v-img :src="require(`@/assets/images/connect-supabase-${!$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
                         width="200"
                         class="cursor-pointer mb-3"
                         @click="connectSupabase()"
                  />
                </v-alert>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-space-between">
            <v-btn outlined
                   @click="cancel()"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              class="me-2"
              :disabled="!token"
              @click="handleStepValidation(1, true)"
            >
              {{ $t('Next') }}
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-form ref="step2Form"
                  class="pb-6 pt-2"
          >
            <v-row justify="center"
                   align="center"
            >
              <v-col
                cols="12"
                md="10"
              >
                <v-alert
                  outlined
                  disabled
                  class="subtitle-2 justify-center align-center"
                >
                  <div class="d-flex  mb-3">
                    <span class="text-h6 font-weight-semibold">2. {{ $t('SupabaseProjects') }}</span>
                    <span class="text-caption me-3 mx-3 mt-1">
                      <a href="https://database.new"
                         target="blank"
                      >{{ $t('CreateWorkspace') }}</a></span>

                    <v-spacer></v-spacer>
                    <v-btn icon
                           color="primary"
                           class="me-3"
                           @click="getProjects()"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex my-2">
                    {{ $t('SupabaseProjectsDetails') }}
                  </div>
                  <v-row
                    class="ma-3"
                  >
                    <v-col
                      v-if="!sources || sources?.length === 0"
                      cols="12"
                      md="4"
                    >
                      <v-progress-circular
                        v-if="loadingProjects"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <div v-else>
                        <span>{{ $t('NoProjects') }}</span>
                        <v-btn icon
                               color="primary"
                               class="me-3"
                               @click="getProjects()"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col v-for="source in sources"
                           :key="source.id"
                           cols="12"
                           md="6"
                    >
                      <v-card
                        :disabled="source.disabled || !!source.health.find(h => h.healthy === false)"
                        height="100%"
                        min-width="280"
                        outlined
                      >
                        <v-card-title class="">
                          <h5 class="font-weight-bold text-uppercase">
                            {{ source.name }}
                          </h5>
                        </v-card-title>
                        <v-card-subtitle class="pb-0">
                          <span class="text-button">{{ source.organization.name }}</span>
                        </v-card-subtitle>
                        <v-card-text>
                          <v-chip
                            outlined
                            class="mb-3"
                          >
                            {{ source.region }}
                          </v-chip>
                          <div>
                            <v-chip v-for="(service) in source.health"
                                    :key="service.name"
                                    outlined
                                    small
                                    class="me-1 mt-2 text-button"
                                    :color="service.healthy ? 'primary': 'error'"
                            >
                              <v-icon v-if="service.healthy"
                                      small
                                      class="me-1"
                              >
                                mdi-check
                              </v-icon>
                              <v-icon v-else
                                      small
                                      class="me-1"
                              >
                                mdi-alert-circle
                              </v-icon>
                              {{ service.name }}
                            </v-chip>
                          </div>
                        </v-card-text>
                        <v-card-actions
                          class="pa-2 pt-0"
                        >
                          <v-btn
                            color="primary"
                            block
                            dark
                            large
                            :outlined="source.selected"
                            @click="source.selected = !source.selected"
                          >
                            {{ source.selected ? $t("RemoveProject") : $t("ImportProject") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
          <div class="d-flex justify-space-between">
            <v-btn outlined
                   @click="handleStepValidation(2)"
            >
              {{ $t('Previous') }}
            </v-btn>
            <v-btn
              color="primary"
              class="me-2"
              :loading="loading"
              :disabled="loading || sources?.filter( s => s.selected).length === 0"
              @click="handleStepValidation(3, true, true)"
            >
              {{ $t('Submit') }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import AlertError from '@/components/AlertError.vue'
import { appConfig } from '@/plugins/micleoConfig'
import { useOrganizationsStore } from '@/stores/organizations.store'
import { useWorkspacesStore } from '@/stores/workspaces.store'
import axios from '@axios'
import {
  alphaDashValidator,
  alphaValidator,
  confirmedValidator,
  emailValidator,
  required,
  urlValidator,
} from '@core/utils/validation'
import {
  getCurrentInstance,
  onMounted, onUnmounted,
  ref,
} from 'vue'

export default {
  metaInfo: {
    title: 'Create Project',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    AlertError,
  },
  setup() {
    const appAPI = appConfig?.url ? `${appConfig?.url}/functions/v1/api` : process.env.VUE_APP_API

    // Stepper's active step
    const activeStep = ref(1)
    const errors = ref({})
    const tokens = ref([])
    const token = ref(null)
    const sources = ref(null)
    const loading = ref(null)
    const loadingProjects = ref(null)
    const isAlertVisible = ref(false)

    const vm = getCurrentInstance().proxy
    const organizationsStore = useOrganizationsStore()
    const workspaceStore = useWorkspacesStore()

    const getProjects = async () => {
      loadingProjects.value = true
      sources.value = null
      const workspaces = await workspaceStore.getWorkspaces()
      axios.post('admin/workspaces', { token: token.value?.access_token }).then(response => {
        sources.value = []
        response.data?.forEach(s => {
          s.selected = false
          s.disabled = !!workspaces.data.find(p => p.id === s.id)
          sources.value.push(s)
        })
        loadingProjects.value = false
      }).catch(error => {
        loadingProjects.value = false
        isAlertVisible.value = true
        errors.value = { token: error.response.data }
      })
    }

    onMounted(async () => {
    })

    onUnmounted(() => {

    })

    const isFormSubmittedSnackbarVisible = ref(false)

    // Template refs
    const step1Form = ref(null)
    const step2Form = ref(null)

    const stepValidationResult = ref({
      1: true,
      2: true,
    })

    const cancel = () => {
      vm.$router.push({ name: 'workspaces' })
    }

    const handleStepValidation = (step, navigateNext = false, isFinalStep = false) => {
      // If step is valid proceed to the next step
      const isStepValid = true

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid

      if (isStepValid) {
        if (navigateNext && !isFinalStep) activeStep.value += 1
        else if (!navigateNext) activeStep.value -= 1
      }

      if (isStepValid) {
        if (activeStep.value === 2 && !isFinalStep) getProjects()
        if (isFinalStep) {
          loading.value = true
          axios.post('admin/create-workspaces', { access_token: token.value?.access_token, refresh_token: token.value?.refresh_token, projects: sources.value.filter(s => s.selected) }).then(() => {
            isFormSubmittedSnackbarVisible.value = true
            loading.value = false
            setTimeout(() => vm.$router.push({ name: 'workspaces' }), 2500)
          }).catch(error => {
            loading.value = false
            isAlertVisible.value = true
            errors.value = { token: error.response.data }
          })
        }
      }
    }

    if (vm.$router.currentRoute.query.access_tk) {
      token.value = {
        access_token: vm.$router.currentRoute.query.access_tk,
        refresh_token: vm.$router.currentRoute.query.refresh_tk,
      }

      // vm.$router.push({ name: 'create-workspace' })
      handleStepValidation(1, true)
    }

    const connectSupabase = () => {
      window.location.href = `${appAPI}/connect-supabase/login`
    }

    return {
      organizationsStore,
      activeStep,
      errors,

      // Field Validators
      validators: {
        required,
        alphaDashValidator,
        confirmedValidator,
        alphaValidator,
        urlValidator,
        emailValidator,
      },

      // Step Validations
      stepValidationResult,

      // Template Refs
      step1Form,
      step2Form,

      // Snackbar
      isFormSubmittedSnackbarVisible,
      cancel,
      handleStepValidation,
      sources,
      loading,
      loadingProjects,
      getProjects,
      isAlertVisible,
      tokens,
      token,
      connectSupabase,
    }
  },
}
</script>
