<template>
  <div>
    <v-snackbar v-model="isError"
                outlined
                text
                color="primary"
                shaped
                top
                right
    >
      {{ error }}
      <template #action="{ attrs }">
        <v-btn icon
               color="primary"
               v-bind="attrs"
               @click="isSaved = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="filesStore.isAdd"
      max-width="500"
      max-height="400"
    >
      <dashboard
        ref="dash"
        :uppy="uppy"
        :props="{
          inline: true,
          height: 400,
          theme: $vuetify.theme.dark ? 'dark' : 'light',
        }"
        :plugins="['Webcam', 'ImageEditor']"
      />
    </v-dialog>
    <v-btn
      color="primary"
      dark
      class="mt-1 mx-1"
      :small="!$vuetify.breakpoint.xs"
      :x-small="$vuetify.breakpoint.xs"
      :loading="loadingUpload"
      :disabled="loadingUpload"
      @click="filesStore.isAdd = true"
    >
      <v-icon
        :left="!$vuetify.breakpoint.xs"
      >
        mdi-file-document-plus
      </v-icon>
      <span class="d-none d-sm-block">{{ $t("AddFile") }}</span>
    </v-btn>
  </div>
</template>

<script>
import {
  ref, getCurrentInstance, onUnmounted,
} from 'vue'
import { useVModel } from '@vueuse/core'
import { useFoldersStore } from '@/stores/folders.store'
import { Dashboard } from '@uppy/vue'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/webcam/dist/style.css'
import '@uppy/image-editor/dist/style.css'

import Uppy from '@uppy/core'
import Webcam from '@uppy/webcam'
import Tus from '@uppy/tus'
import ImageEditor from '@uppy/image-editor'

import French from '@uppy/locales/lib/fr_FR'
import English from '@uppy/locales/lib/en_US'
import { appConfig } from '@/plugins/micleoConfig'

import { useProjectsStore } from '@/stores/projects.store'
import { supabaseDomain } from '@/plugins/supabase'
import { useFilesStore } from '@/stores/files.store'
import { TABLE_FILES } from '@/@core/utils'

export default {
  components: {
    Dashboard,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loadingUpload = ref(false)
    const isError = ref(false)
    const error = ref(null)
    const foldersStore = useFoldersStore()
    const projectsStore = useProjectsStore()
    const filesStore = useFilesStore()
    const vm = getCurrentInstance().proxy
    const config = useVModel(props, 'config', emit)

    const uppy = new Uppy({
      locale: vm.$i18n.locale === 'fr' ? French : English,
    })

    const SUPABASE_ANON_KEY = appConfig?.tokens?.SUPABASE_ANON
    const STORAGE_BUCKET = TABLE_FILES

    const supabaseToken = JSON.parse(localStorage.getItem(supabaseDomain))
    const accessToken = supabaseToken?.access_token

    const supabaseStorageURL = `${appConfig?.url}/storage/v1/upload/resumable`

    uppy.use(Tus, {
      endpoint: supabaseStorageURL,
      headers: {
        authorization: `Bearer ${accessToken}`,
        apikey: SUPABASE_ANON_KEY,
      },
      uploadDataDuringCreation: true,
      chunkSize: 6 * 1024 * 1024,
      allowedMetaFields: ['bucketName', 'objectName', 'contentType', 'cacheControl'],
      onError(err) {
        error.value = err
        isError.value = true
      },
    })

    uppy.on('file-added', file => {
      const folder = `${projectsStore.project.id}/${config.value.header?.folder?.id || foldersStore.root.id}`
      const fileName = file.name
      const supabaseMetadata = {
        bucketName: STORAGE_BUCKET,
        objectName: folder ? `${folder}/${fileName}` : fileName,
        contentType: file.type,
      }

      file.meta = {
        ...file.meta,
        ...supabaseMetadata,
      }
    })

    uppy.on('complete', result => {
      if (result.failed.length === 0) {
        filesStore.isAdd = false
        uppy.cancelAll()
      }
      vm.$parent.$emit('on-refresh')
    })
    uppy.use(Webcam)
    uppy.use(ImageEditor, {
      quality: 0.8,
    })

    onUnmounted(() => {
      uppy.close()
    })

    return {
      loadingUpload,
      isError,
      uppy,
      error,
      filesStore,
    }
  },
}
</script>
