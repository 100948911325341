import { defineStore, acceptHMRUpdate } from 'pinia'
import { supabase, supabaseDomain } from '@/plugins/supabase'
import axios from '@axios'

// eslint-disable-next-line import/prefer-default-export
export const useProjectsStore = defineStore({
  id: 'projects',
  state: () => ({
    project: null,
    eventType: null,
    projectId: null,
  }),
  getters: {
    GET_PROJECT(state) {
      return state.project ? state.project : null
    },
  },
  actions: {
    async getProjects(payload) {
      const from = payload?.options ? (payload.options.page - 1) * payload.options.itemsPerPage : 0
      const to = payload?.options ? from + payload.options.itemsPerPage - 1 : 0

      let query = supabase
        .from('projects')
        .select(
          '*, organization:organizations(*, integrations(id, name, type, enabled), subscription:subscriptions(* , price:prices(*, product:products(*))), roles(id), members(details:admins!members_admin_id_fkey(id, fullname, avatar))))',
          { count: 'exact' },
        )
        .order('created_at', { ascending: false })
      if (payload?.search) {
        query = query.or(`name.ilike.%${payload.search}%, description.ilike.%${payload.search}%`)
      }
      if (payload && payload.options.itemsPerPage !== -1 && from !== to) query = query.range(from, to)
      const { data, count, error } = await query
      if (error) {
        throw error
      }

      return { data, count }
    },

    async getProject(id) {
      const { data, error } = await supabase
        .from('projects')
        .select(
          '*, organization:organizations(*, integrations(id, name, type, enabled), subscription:subscriptions(* , price:prices(*, product:products(*))), roles(id), members(details:admins!members_admin_id_fkey(id, fullname, avatar)))))',
        )
        .eq('id', id)
        .single()
      if (error) {
        throw error
      }

      return data
    },

    async listenProjects() {
      if (supabaseDomain.indexOf('localhost') < 0) {
        return supabase
          .channel('listenProjects')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'projects' }, async payload => {
            this.$patch({ eventType: payload.eventType, item: payload.new.id ? payload.new : payload.old })
          })
          .subscribe()
      }

      return null
    },

    async listenProject(id) {
      this.project = await this.getProject(id)

      return supabase
        .channel(`listenProject-${id}`)
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'projects',
            filter: `id=eq.${id}`,
          },
          async () => {
            this.project = await this.getProject(id)
          },
        )
        .subscribe()
    },

    async uploadProjectCover(projectid, img) {
      const { data, error } = await supabase.storage.from('projects').upload(`cover/${projectid}/${img.name}`, img,
        {
          cacheControl: '3600',
          upsert: true,
        })
      if (error) {
        return Promise.reject(error)
      }

      return data
    },

    async getProjectCover(projectid, img) {
      const { data } = await supabase.storage.from('projects').getPublicUrl(`cover/${projectid}/${img.name}`)

      return data
    },

    async createProject(project) {
      const { data, error } = await supabase
        .from('projects')
        .insert({
          name: project.name,
          description: project.description,
          image: project.image,
          organization_id: project.organization,
          locales: project.locales?.sort(),
          timezone: project.timezone,
          quickstart: project.quickstart,
        })
        .select()
        .single()

      if (error) {
        return Promise.reject(error)
      }

      return this.getProject(data.id)
    },

    async saveProject(project) {
      const { error } = await supabase
        .from('projects')
        .update({
          name: project.name,
          description: project.description,
          image: project.image,
          locales: project.locales?.sort(),
          timezone: project.timezone,
          quickstart: project.quickstart,
        })
        .eq('id', project.id)

      if (error) {
        return Promise.reject(error)
      }

      return this.getProject(project.id)
    },

    async deleteProject(project) {
      try {
        const data = await axios.post('delete-project', { record: project })

        return data.data
      } catch (error) {
        return Promise.reject(error.response.data)
      }
    },

    async getStatistics(projectid) {
      const { data, error } = await supabase.rpc('get_statistics', { projectid })
      if (error) {
        return Promise.reject(error)
      }

      return data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProjectsStore, import.meta.hot))
}
