<template>
  <app-card-actions action-refresh
                    :loading="loading"
                    @refresh="onRefresh"
  >
    <template slot="title">
      <div
        v-if="projectsStore.GET_PROJECT!=null"
      >
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text me-1"
          rounded
          size="34"
        >
          <v-img
            v-if="projectsStore.GET_PROJECT.image"
            :src="projectsStore.GET_PROJECT.image"
          ></v-img>
          <span
            v-else
            class="font-weight-semibold"
          >{{ avatarText(projectsStore.GET_PROJECT.name) }}</span>
        </v-avatar><span class="font-weight-semibold text-button">
          {{ projectsStore.GET_PROJECT.name }} /
        </span>
        <v-icon>mdi-folder-table-outline</v-icon>
      </div>
    </template>
    <v-card-text>
      <v-row>
        <v-col v-for="data in statisticsData?.filter(d => $canACL(d.action, d.to, projectsStore.GET_PROJECT))"
               :key="data.title"
               cols="6"
               :md="appContentLayoutNav === 'horizontal' ? 3 : 4"
               class="d-flex align-center"
        >
          <div
            class="d-flex"
          >
            <v-avatar size="44"
                      :color="data.color"
                      rounded
                      class="elevation-1"
            >
              <v-icon dark
                      color="white"
                      size="30"
                      @click="goTo(data)"
              >
                {{ data.icon }}
              </v-icon>
            </v-avatar>
            <div class="ms-3"
                 style="cursor:pointer;"
                 @click="goTo(data)"
            >
              <p class="text-xs ma-0 text-overline"
                 style="font-size: 0.6rem !important;"
              >
                {{ $t(data.title) }}
              </p>
              <h3 class="text-xl font-weight-semibold">
                {{ data.total || '-' }}
              </h3>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mx-3"></v-divider>

    <v-card v-if="$canACL('view', 'campaigns', projectsStore.GET_PROJECT)"
            flat
            class="mx-4 my-0"
    >
      <v-card-text>
        <v-row>
          <v-col v-for="(item) in campaignDetails"
                 :key="item.avatar"
                 cols="12"
                 md="6"
          >
            <div
              class="d-flex align-start pe-5"
            >
              <v-avatar
                rounded
                size="38"
                color="#5e56690a"
                class="me-4"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-avatar>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div>
                  <h4 class="font-weight-medium">
                    {{ item.title }}
                  </h4>
                  <span class="text-xs text-no-wrap text-caption">{{ $t(item.subtitle) }}(s)</span>
                </div>

                <v-spacer></v-spacer>

                <div
                  style="width: 100px"
                >
                  <p class="text--primary font-weight-bold mb-1 text-xs">
                    {{ item.total }} / {{ item.max }}
                  </p>
                  <v-progress-linear
                    :value="item.progress"
                    :color="item.color"
                  ></v-progress-linear>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </app-card-actions>
</template>

<script>
import { onMounted, getCurrentInstance, ref } from 'vue'
import { useProjectsStore } from '@/stores/projects.store'
import AppCardActions from '@/components/AppCardActions.vue'
import { avatarText } from '@core/utils/filter'
import useAppConfig from '@/stores/appconfig'
import {
  TABLE_ACTIVITIES, TABLE_CAMPAIGNS, TABLE_CONTACTS, TABLE_FOLDERS, TABLE_LISTINGS, TABLE_TEMPLATES, TABLE_MODELS, TABLE_WORKFLOWS, TABLE_FORMS, TABLE_FILES, TABLE_LOGS,
} from '@/@core/utils'
import { useTableStore } from '@/stores/table.store'

export default {
  components: {
    AppCardActions,
  },
  setup() {
    const projectsStore = useProjectsStore()
    const tableStore = useTableStore()
    const vm = getCurrentInstance().proxy
    const loading = ref(true)
    const {
      appContentLayoutNav,
    } = useAppConfig()
    const campaignDetails = ref([
      {
        icon: 'mdi-email',
        title: 'Emails',
        subtitle: 'sent',
        total: '',
        progress: '85',
        color: 'primary',
      },
      {
        icon: 'mdi-message',
        title: 'SMS',
        subtitle: 'sent',
        total: '',
        progress: '65',
        color: 'primary',
      },
    ])

    const statisticsData = ref([
      {
        title: 'Contacts',
        to: TABLE_CONTACTS,
        total: '',
        icon: 'mdi-account-group',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Listings',
        to: TABLE_LISTINGS,
        total: '',
        icon: 'mdi-format-list-group',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Models',
        to: TABLE_MODELS,
        total: '',
        icon: 'mdi-shape',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Forms',
        to: TABLE_FORMS,
        total: '',
        icon: 'mdi-list-box-outline',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Campaigns',
        to: TABLE_CAMPAIGNS,
        total: '',
        icon: 'mdi-email-fast-outline',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Templates',
        to: TABLE_TEMPLATES,
        total: '',
        icon: 'mdi-file-image',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Activities',
        to: TABLE_ACTIVITIES,
        total: '',
        icon: 'mdi-timeline-check-outline',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Workflows',
        to: TABLE_WORKFLOWS,
        total: '',
        icon: 'mdi-sitemap-outline',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Folders',
        to: TABLE_FOLDERS,
        total: '',
        icon: 'mdi-folder-multiple',
        color: 'primary',
        action: 'view',
      },

      {
        title: 'Files',
        to: TABLE_FILES,
        total: '',
        icon: 'mdi-file-document',
        color: 'primary',
        action: 'view',
      },
      {
        title: 'Logs',
        to: TABLE_LOGS,
        total: '',
        icon: 'mdi-alert-box',
        color: 'primary',
        action: 'view',
      },
    ])

    const goTo = item => {
      const model = tableStore.getModel(item.to)
      if (Object.keys(model).includes('folder_id')) {
        vm.$router.push({
          name: item.to,
          params: {
            id: vm.$router.currentRoute.params.id,
            path: btoa(JSON.stringify([{ t: item.to }])),
            mode: 'view',
            folder: 0,
          },
        })
      } else {
        vm.$router.push({
          name: item.to,
          params: {
            id: vm.$router.currentRoute.params.id,
            path: btoa(JSON.stringify([{ t: item.to }])),
            mode: 'view',
          },
        })
      }
    }

    const onRefresh = hideOverlay => {
      if (vm.$router.currentRoute.params.id === '0') return
      loading.value = true
      projectsStore.getStatistics(vm.$router.currentRoute.params.id).then(data => {
        if (data?.length) {
          statisticsData.value.forEach(item => {
            item.total = data[0][item.title.toLocaleLowerCase()]
          })
          campaignDetails.value.forEach(item => {
            item.total = data[0][item.title.toLocaleLowerCase()] || 0
            item.progress = (item.total / data[0].activities) * 100 || 0
            item.max = data[0].activities
          })
        }

        if (hideOverlay) { hideOverlay() }
        loading.value = false
      })
    }

    onMounted(async () => {
      onRefresh()
    })

    return {
      projectsStore,
      statisticsData,
      goTo,
      onRefresh,
      avatarText,
      loading,
      campaignDetails,
      appContentLayoutNav,
    }
  },
}
</script>
