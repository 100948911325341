<template>
  <v-container
    class="ma-0 pa-0"
    fluid
  >
    <v-snackbar
      v-model="isSaved"
      outlined
      text
      color="primary"
      shaped
      bottom
      right
    >
      {{ $t('SavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card
      style="height:100%"
    >
      <v-progress-linear
        v-if="loadingAction"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <div
        id="editor"
        class="unlayer-editor overflow-hidden"
        :style="style"
      />
    </v-card>
  </v-container>
</template>
<script>
import {
  ref, watch, getCurrentInstance, onMounted, computed,
} from 'vue'
import { useVModel } from '@vueuse/core'
import { useTemplatesStore } from '@/stores/templates.store'
import { required } from '@core/utils/validation'
import { appConfig } from '@/plugins/micleoConfig'
import { usePropertiesStore } from '@/stores/properties.store'
import { useFormsStore } from '@/stores/forms.store'
import { redirectTo } from '@/plugins/supabase'
import useAppConfig from '@/stores/appconfig'

export default {
  components: {
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const loadingAction = ref(true)
    const form = ref(null)
    const errors = ref({})
    const isSaved = ref(false)
    const isDialogVisible = useVModel(props, 'isDialogVisible', emit)
    const item = useVModel(props, 'item', emit)
    const templatesStore = useTemplatesStore()
    const formsStore = useFormsStore()
    const vm = getCurrentInstance().proxy
    const style = computed(() => `height: ${window.innerHeight - 260}px;`)
    const { isDark } = useAppConfig()

    // called when the editor is created
    const editorLoaded = () => {
      loadingAction.value = true
      const propertiesStore = usePropertiesStore()
      const contactsTags = {}
      const specialLinks = [{
        name: vm.$t('Unsubscribe'),
        href: '{{ unsubscribe }}',
        target: '_blank',
      }]
      formsStore.getForms(vm.$router.currentRoute.params.id).then(forms => {
        forms.forEach(f => {
          specialLinks.push({
            name: f.name,
            href: `${redirectTo}/form/${f.id}`,
            target: '_blank',
          })
        })
      })
      propertiesStore.getProperties(vm.$router.currentRoute.params.id, 'contacts').then(properties => {
        properties.forEach(p => {
          contactsTags[p.slug] = {
            name: p.name,
            value: `{{contacts.${p.slug}}}`,
          }
        })

        // const mergeTags = { contacts: { name: 'Contacts', mergeTags: contactsTags } }

        const setttings = {
          id: 'editor',
          locale: localStorage.getItem('locale') || 'en',
          projectId: appConfig?.tokens?.UNLAYER_PROJECT_ID, // replace with your project id
          options: {},
          appearance: {
            theme: isDark.value ? 'dark' : 'light',
            panels: {
              tools: {
                dock: 'right',
              },
            },
          },
          displayMode: 'email',
          mergeTags: contactsTags,
          specialLinks,
        }
        // eslint-disable-next-line no-undef
        const editor = unlayer.createEditor(setttings)

        const design = item.value['email->>design'] || null
        if (design) {
          editor.loadDesign(design)
        }

        editor.addEventListener('editor:ready', () => {

        })

        editor.addEventListener('design:loaded', () => {
          loadingAction.value = false
        })

        editor.addEventListener('design:updated', () => {
        // Design is updated by the user

          editor.exportHtml(data => {
            const json = data.design // design json
            const { html } = data // design html
            item.value['email->>design'] = json
            item.value['email->>html'] = html
            item.value.thumbnail = '⏳'

          // isSaved.value = true
          })
        })
      })
    }

    onMounted(() => {
      editorLoaded()
    })

    watch(isDialogVisible, () => {
      if (form.value) form.value.resetValidation()
      if (isDialogVisible.value) {
        editorLoaded()
      }
    })

    return {
      form,
      loadingAction,
      item,
      errors,
      validators: {
        required,
      },
      templatesStore,
      isSaved,
      style,
    }
  },
}
</script>
<style>
</style>
