<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="450"
  >
    <v-card class="pa-sm-6 pa-3">
      <v-card-title
        class="justify-center text-h5"
      >
        {{ $t("ModelsProperties") }}
      </v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchModel"
          single-line
          dense
          outlined
          hide-details
          :placeholder="$t('SearchProperty')"
          class="me-3"
          clearable
          prepend-inner-icon="mdi-database-search"
          clear-icon="mdi-close"
        ></v-text-field>
      </v-card-text>
      <v-virtual-scroll
        class="mt-3"
        :items="models"
        height="540"
        item-height="165"
      >
        <template
          #default="{ item }"
        >
          <v-card
            class="pa-0"
            outlined
            height="160"
          >
            <v-card-title class="d-flex">
              <span class="font-weight-semibold text-button primary--text">{{ item.name }}</span>
              <v-spacer></v-spacer>
              <div class="d-flex">
                <v-chip class="me-3">
                  <v-icon class="me-3">
                    {{ getType(item.type).icon }}
                  </v-icon>{{ $t(getType(item.type).value) }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-chip>
                  <v-icon class="me-3">
                    {{ getAppearance(item.appearance).icon }}
                  </v-icon>{{ $t(getAppearance(item.appearance).value) }}
                </v-chip>
              </div>
            </v-card-title>
            <v-card-subtitle class="d-flex mt-1">
              <v-spacer></v-spacer>
              <v-btn
                small
                class="me-3"
                color="primary"
                :disabled="isHere(item) || loading"
                :loading="loading"
                @click="saveProperty(item)"
              >
                <v-icon
                  :left="!$vuetify.breakpoint.xs"
                >
                  mdi-database-plus
                </v-icon>
                <span class="d-none d-sm-block">{{ $t("AddProperty") }}</span>
              </v-btn>
            </v-card-subtitle>
          </v-card>
        </template>
      </v-virtual-scroll>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  ref, getCurrentInstance, watch,
} from 'vue'
import { useVModel } from '@vueuse/core'
import { usePropertiesStore } from '@/stores/properties.store'
import { useFoldersStore } from '@/stores/folders.store'
import types from '@/views/projects/settings/types.json'
import appearances from '@/views/projects/settings/appearances.json'
import properties from '@/views/projects/settings/properties.json'
import {
  getLastItemID,
} from '@core/utils'

export default {
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false,
    },
    folder: {
      type: Object,
      default: null,
    },
    directory: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const searchModel = ref(null)
    const loading = ref(false)
    const models = ref(properties)
    const property = ref(null)
    const folder = useVModel(props, 'folder', emit)
    const isDialogVisible = useVModel(props, 'isDialogVisible', emit)

    const propertiesStore = usePropertiesStore()
    const foldersStore = useFoldersStore()

    watch(isDialogVisible, () => {
      if (isDialogVisible.value) {
        loading.value = true
        const decodedPath = atob(vm.$router.currentRoute.params.path).split('/')
        if (decodedPath.length >= 3) {
          propertiesStore.getPropertiesID(vm.$router.currentRoute.params.id, decodedPath[1].split(':')[1]).then(() => {
            loading.value = false
          })
        }
      }
    })

    const getType = value => {
      const type = types.filter(it => it.value === value)

      return type.length > 0 ? type[0] : {}
    }
    const getAppearance = value => {
      const appearance = appearances.filter(it => it.value === value)

      return appearance.length > 0 ? appearance[0] : {}
    }
    const isHere = _property => !!propertiesStore.properties?.find(p => p.slug === _property.slug)
    const saveProperty = _property => {
      if (_property) { property.value = _property }
      property.value.project_id = vm.$router.currentRoute.params.id
      property.value.folder_id = folder.value?.parent_id || foldersStore.root?.id
      property.value.model_id = getLastItemID(props.directory)
      isDialogVisible.value = false
      emit('on-add', property.value)
    }

    return {
      getType,
      getAppearance,
      searchModel,
      propertiesStore,
      models,
      isHere,
      saveProperty,
      isDialogVisible,
      loading,
    }
  },
}
</script>
