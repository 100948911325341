<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mx-5 mt-2">
        <h1>{{ $t('AllProjects') }} </h1>
        <v-spacer></v-spacer>
        <div :class="$vuetify.breakpoint.xs? 'mt-3 d-flex align-center':'d-flex align-center'">
          <v-text-field
            v-model="searchQuery"
            class="me-3 search-table"
            single-line
            dense
            outlined
            hide-details
            :placeholder="$t('SearchProject')"
            clearable
            prepend-inner-icon="mdi-folder-search"
            clear-icon="mdi-close"
          ></v-text-field>
          <v-tooltip
            open-delay="500"
            top
          >
            <template #activator="{ on: onTooltip }">
              <v-btn
                elevation="2"
                color="primary"
                :disabled="!$canACL('create', 'projects')"
                v-on="onTooltip"
                @click="createProject"
              >
                <v-icon>
                  mdi-folder-plus
                </v-icon>
              </v-btn>
            </template>
            <span> {{ $t('CreateProject') }}</span>
          </v-tooltip>
        </div>
      </v-row>
      <v-row class="mt-10">
        <card-project
          v-if="loading"
          :user="userStore.user"
        >
        </card-project>

        <card-project
          v-for="project in listTable"
          :key="project.id"
          :project="project"
          :user="userStore.user"
        >
        </card-project>
        <v-col
          v-if="!loading && (!listTable|| listTable.length === 0)"
          lg="3"
          sm="4"
          cols="12"
        >
          <v-card
            height="100%"
            min-height="250"
            min-width="300"
            justify-center
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-btn
              elevation="2"
              color="primary"
              :disabled="!$canACL('create', 'projects')"
              @click="createProject"
            >
              <v-icon class="me-1">
                mdi-folder-plus
              </v-icon>
              {{ $t('CreateProject') }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-pagination
        v-model="page"
        class="mt-10"
        :length="nbPages"
      ></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
import { useProjectsStore } from '@/stores/projects.store'
import { useUserStore } from '@/stores/user.store'
import CardProject from '@/views/projects/CardProject.vue'
import useProjectsList from '@/views/projects/useProjectsList'
import { appConfig } from '@/plugins/micleoConfig'
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted, onUnmounted,
  ref,
} from 'vue'

export default {
  metaInfo: {
    title: 'Projects',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  components: {
    CardProject,
  },
  setup() {
    const subscription = ref(null)
    const vm = getCurrentInstance().proxy

    const userStore = useUserStore()
    const projectsStore = useProjectsStore()

    const {
      listTable, totalListTable, searchQuery, page, nbPages, loading,
    } = useProjectsList()

    onBeforeMount(async () => {
    })

    onMounted(async () => {
      projectsStore.project = null
      subscription.value = await projectsStore.listenProjects()
    })

    onUnmounted(() => {
      userStore.removeChannel(subscription.value)
    })

    const createProject = () => {
      vm.$router.push({ name: 'create-project' })
    }

    return {
      userStore,
      projectsStore,
      searchQuery,
      page,
      listTable,
      totalListTable,
      nbPages,
      loading,
      createProject,
    }
  },
}
</script>
<style lang="scss" scoped></style>
