// eslint-disable-next-line max-classes-per-file
import ability from './ability'
import {
  Organizations, Projects, Activities, Campaigns, Contacts, Integrations, Invoices, Listings, Logs, Objects, Properties, Roles, Subscriptions, Templates, Forms, Folders, Files, Models, Workflows, Members, Dashboard, Settings, Repositories, Workspaces,
} from './entities'

export const canNavigate = to => {
  // const rule = ability.rules.find(r => r.subject === to.meta.resource && (r.action === to.meta.action || r.action === 'manage') /* && ((to.params?.id && r.conditions?.id === to.params?.id) || !to.params?.id) */)

  if (to.params?.id?.length > 0) {
    if (to.meta.resource === 'organizations') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Organizations({ id: to.params.id }))) }
    if (to.meta.resource === 'projects') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Projects({ id: to.params.id }))) }
    if (to.meta.resource === 'activities') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Activities({ id: to.params.id }))) }
    if (to.meta.resource === 'campaigns') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Campaigns({ id: to.params.id }))) }
    if (to.meta.resource === 'contacts') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Contacts({ id: to.params.id }))) }
    if (to.meta.resource === 'integrations') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Integrations({ id: to.params.id }))) }
    if (to.meta.resource === 'members') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Members({ id: to.params.id }))) }
    if (to.meta.resource === 'invoices') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Invoices({ id: to.params.id }))) }
    if (to.meta.resource === 'listings') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Listings({ id: to.params.id }))) }
    if (to.meta.resource === 'logs') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Logs({ id: to.params.id }))) }
    if (to.meta.resource === 'objects') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Objects({ id: to.params.id }))) }
    if (to.meta.resource === 'properties') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Properties({ id: to.params.id }))) }
    if (to.meta.resource === 'properties_items') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Properties({ id: to.params.id }))) }
    if (to.meta.resource === 'roles') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Roles({ id: to.params.id }))) }
    if (to.meta.resource === 'subscriptions') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Subscriptions({ id: to.params.id }))) }
    if (to.meta.resource === 'templates') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Templates({ id: to.params.id }))) }
    if (to.meta.resource === 'forms') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Forms({ id: to.params.id }))) }
    if (to.meta.resource === 'files') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Files({ id: to.params.id }))) }
    if (to.meta.resource === 'folders') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Folders({ id: to.params.id }))) }
    if (to.meta.resource === 'models') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Models({ id: to.params.id }))) }
    if (to.meta.resource === 'workflows') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Workflows({ id: to.params.id }))) }
    if (to.meta.resource === 'dashboard') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Dashboard({ id: to.params.id }))) }
    if (to.meta.resource === 'settings') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Settings({ id: to.params.id }))) }
    if (to.meta.resource === 'repositories') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Repositories({ id: to.params.id }))) }
    if (to.meta.resource === 'workspaces') { return to.matched.some(route => ability.can(route.meta.action || 'view', new Workspaces({ id: to.params.id }))) }
  }

  return to.matched.some(route => ability.can(route.meta.action || 'view', route.meta.resource))
}

export const canACL = (actions, resource, data) => {
  if (!actions) return ability.can(actions || 'view', resource)

  const splitted = actions.split(',')
  let isOK = false

  if (data?.id?.length > 0) {
  // eslint-disable-next-line consistent-return
    splitted.forEach(action => {
      if (resource === 'organizations') { isOK = ability.can(action || 'view', new Organizations({ id: data.id })) }
      if (resource === 'projects') { isOK = ability.can(action || 'view', new Projects({ id: data.id })) }
      if (resource === 'activities') { isOK = ability.can(action || 'view', new Activities({ id: data.id })) }
      if (resource === 'campaigns') { isOK = ability.can(action || 'view', new Campaigns({ id: data.id })) }
      if (resource === 'contacts') { isOK = ability.can(action || 'view', new Contacts({ id: data.id })) }
      if (resource === 'integrations') { isOK = ability.can(action || 'view', new Integrations({ id: data.id })) }
      if (resource === 'invoices') { isOK = ability.can(action || 'view', new Invoices({ id: data.id })) }
      if (resource === 'members') { isOK = ability.can(action || 'view', new Members({ id: data.id })) }
      if (resource === 'listings') { isOK = ability.can(action || 'view', new Listings({ id: data.id })) }
      if (resource === 'logs') { isOK = ability.can(action || 'view', new Logs({ id: data.id })) }
      if (resource === 'objects') { isOK = ability.can(action || 'view', new Objects({ id: data.id })) }
      if (resource === 'properties') { isOK = ability.can(action || 'view', new Properties({ id: data.id })) }
      if (resource === 'properties_items') { isOK = ability.can(action || 'view', new Properties({ id: data.id })) }
      if (resource === 'roles') { isOK = ability.can(action || 'view', new Roles({ id: data.id })) }
      if (resource === 'subscriptions') { isOK = ability.can(action || 'view', new Subscriptions({ id: data.id })) }
      if (resource === 'templates') { isOK = ability.can(action || 'view', new Templates({ id: data.id })) }
      if (resource === 'forms') { isOK = ability.can(action || 'view', new Forms({ id: data.id })) }
      if (resource === 'files') { isOK = ability.can(action || 'view', new Files({ id: data.id })) }
      if (resource === 'folders') { isOK = ability.can(action || 'view', new Folders({ id: data.id })) }
      if (resource === 'models') { isOK = ability.can(action || 'view', new Models({ id: data.id })) }
      if (resource === 'workflows') { isOK = ability.can(action || 'view', new Workflows({ id: data.id })) }
      if (resource === 'dashboard') { isOK = ability.can(action || 'view', new Dashboard({ id: data.id })) }
      if (resource === 'settings') { isOK = ability.can(action || 'view', new Settings({ id: data.id })) }
      if (resource === 'repositories') { isOK = ability.can(action || 'view', new Repositories({ id: data.id })) }
      if (resource === 'workspaces') { isOK = ability.can(action || 'view', new Workspaces({ id: data.id })) }
      if (isOK) return true
    })

    return isOK
  }

  return ability.can(actions || 'view', resource)
}

// export const canNavigate = () => true
export const _ = undefined
