import { Ability, AbilityBuilder } from '@casl/ability'
import { useUserStore } from '@/stores/user.store'
import params from '@/router/params'
import { initialAbility } from './config'

export const setAbilities = pId => {
  const { can, rules } = new AbilityBuilder()

  const userStore = useUserStore()
  const user = userStore.GET_USER
  const projectId = params.getId('project') || pId

  can('view', 'public')

  if (user) {
    if (userStore.isLoggedIn) {
      can('view', 'connected')
      can('manage', 'profile')
      user.permissions?.forEach(p => {
        p.permissions.forEach(action => {
          can(action.toLowerCase().replace('select', 'read').replace('insert', 'create'), p.resource)
        })
      })

      /* if (isNotAdmin) {
        can('view', 'projects')
        can('view', 'organizations')
        can('create', 'organizations')
        can('manage', 'chats')
        if (isNotAdmin.indexOf('localhost')) can('manage', 'workspaces')
      } else {
        can('manage', 'workspaces')
      } */

      // can('manage', 'tokens')

      if (user.members?.length > 0) {
        user.members.forEach(member => {
          member.role?.permissions
            ?.filter(p => p.parent === 'organizations')
            .forEach(permission => {
              can(
                permission.type.toLowerCase().replace('select', 'read').replace('insert', 'create'),
                permission.resource,
                { id: { $eq: member.organization_id } },
              )
            })
          if (member.organization?.projects) {
            member.organization.projects.forEach(project => {
              if (project.id === projectId) {
                can('view', 'dashboard', { id: { $eq: project.id } })
                can('view', 'settings', { id: { $eq: project.id } })
                member.role?.permissions
                  ?.filter(p => p.parent === 'projects' || p.resource === 'projects')
                  .forEach(permission => {
                    can(
                      permission.type.toLowerCase().replace('select', 'read').replace('insert', 'create'),
                      permission.resource,
                      { id: { $eq: project.id } },
                    )
                  })
              }
            })
          }

          if (member.organization?.workspaces) {
            member.organization.workspaces.forEach(workspace => {
              member.role?.permissions
                ?.filter(p => p.resource === 'workspaces')
                .forEach(permission => {
                  can(
                    permission.type.toLowerCase().replace('select', 'read').replace('insert', 'create'),
                    permission.resource,
                    { id: { $eq: workspace.id } },
                  )
                })
            })
          }
        })
      }
    } else {
      can('view', 'MFA')
    }
  }

  return rules
}
export default new Ability(initialAbility)
