import { ref, watch } from 'vue'
import { useChatsStore } from '@/stores/chats.store'

export default function useList() {
  const listTable = ref([])

  const searchQuery = ref('')
  const options = ref({
    page: ref(1),
    itemsPerPage: 6,
  })
  const totalListTable = ref(0)
  const loading = ref(true)
  const nbPages = ref(0)
  const page = ref(1)
  const chatsStore = useChatsStore()

  const fetchDatas = () => {
    chatsStore
      .getChats({ search: searchQuery.value, options: { page: page.value, itemsPerPage: 6 } })
      .then(data => {
        listTable.value = data.data
        totalListTable.value = data.count
        nbPages.value = Math.ceil(totalListTable.value / options.value.itemsPerPage)
        loading.value = false
      })
      .catch(() => {
        listTable.value = []
        totalListTable.value = 0
        nbPages.value = 0
        loading.value = false
      })
  }

  fetchDatas()
  watch([searchQuery, page], () => {
    loading.value = true
    fetchDatas()
  })

  chatsStore.$subscribe(async mutation => {
    if (mutation.payload) {
      if (mutation.payload.eventType !== 'UPDATE') {
        fetchDatas()
      }
    }
  })

  return {
    searchQuery,
    page,
    listTable,
    totalListTable,
    loading,
    fetchDatas,
    nbPages,
  }
}
