<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          v-if="$vuetify.theme.isDark && appLogoDark || !$vuetify.theme.isDark && appLogo"
          :src="$vuetify.theme.isDark ? appLogoDark: appLogo"
          max-height="30px"
          max-width="30px"
          :alt="appName"
          contain
        ></v-img>

        <h2 class="ml-1 font-weight-semibold appName">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex align-center h-full pa-16 pe-0 mr-16">
              <v-img
                contain
                max-width="100%"
                height="500"
                :src="require(`@/assets/images/contacts-large-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          cols="12"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t('MFA') }}
                  </p>
                  <p class="mb-2">
                    {{ $t('EnterCodeMFA') }}
                  </p>
                </v-card-text>

                <v-card-text>
                  <v-alert
                    v-if="errors.mfa"
                    border="left"
                    colored-border
                    text
                    color="error"
                    icon="mdi-lock"
                  >
                    <span class="text-overline">{{ $t(errors.mfa) }}</span><br />
                    <span class="text-caption"> {{ $t(errors.details) }}</span>
                  </v-alert>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form>
                    <v-otp-input
                      :disabled="loading"
                      length="6"
                      type="number"
                      @finish="onFinish"
                      @input="onChange"
                    ></v-otp-input>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <v-btn
                    text
                    plain
                    @click="goBacktoLogin"
                  >
                    <v-icon
                      size="24"
                    >
                      mdi-chevron-left
                    </v-icon>
                    {{ $t('BackToLogin') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user.store'
import { emailValidator, required } from '@core/utils/validation'
import { getCurrentInstance, ref } from 'vue'
import { appConfig } from '@/plugins/micleoConfig'

export default {
  metaInfo: {
    title: 'MFA',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  setup() {
    const userStore = useUserStore()

    const forgetPassword = ref(null)
    const errors = ref({
      mfa: null,
      details: null,
    })

    const email = ref('')
    const loading = ref(false)
    const vm = getCurrentInstance().proxy
    const factorId = ref(null)

    if (vm.$router.currentRoute.params && vm.$router.currentRoute.params.factorId) {
      factorId.value = vm.$router.currentRoute.params.factorId
    }

    const onFinish = rsp => {
      errors.value = {
        mfa: null,
        details: null,
      }
      loading.value = true
      if (!factorId.value) {
        userStore.getMFAAuth(rsp).then(async () => {
          loading.value = false
          userStore.user = await userStore.getAdmin()
          vm.$router.push('/')
        })
          .catch(err => {
            loading.value = false
            errors.value.mfa = 'InvalidTOTP'
            errors.value.details = err
          })
      } else {
        userStore.getMFAChallenge(factorId.value, rsp).then(async () => {
          loading.value = false
          userStore.user = await userStore.getAdmin()
          vm.$router.push('/')
        })
          .catch(err => {
            loading.value = false
            errors.value.mfa = 'InvalidTOTP'
            errors.value.details = err
          })
      }
    }

    const goBacktoLogin = () => {
      userStore.signOut()
    }

    const onChange = () => {
      errors.value = {
        mfa: null,
        details: null,
      }
    }

    return {
      onFinish,
      onChange,
      loading,
      forgetPassword,
      email,
      errors,
      appName: appConfig.theme.app.name,
      appLogo: appConfig.theme.app.logo,
      appLogoDark: appConfig.theme.app.logoDark,
      validators: {
        required,
        emailValidator,
      },
      goBacktoLogin,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
