<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          v-if="$vuetify.theme.isDark && appLogoDark || !$vuetify.theme.isDark && appLogo"
          :src="$vuetify.theme.isDark ? appLogoDark: appLogo"
          max-height="30px"
          max-width="30px"
          :alt="appName"
          contain
        ></v-img>

        <h2 class="ml-1 font-weight-semibold appName">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex align-center h-full pa-16 pe-0 mr-16">
              <v-img
                contain
                max-width="100%"
                height="500"
                :src="require(`@/assets/images/contacts-large-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          cols="12"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t('ForgotPassword') }}
                  </p>
                  <p class="mb-2">
                    {{ $t('ForgotPasswordDetails') }}
                  </p>
                </v-card-text>

                <v-card-text>
                  <v-alert
                    v-model="isAlertVisible"
                    border="left"
                    colored-border
                    text
                    color="primary"
                    icon="mdi-email-open-outline"
                  >
                    {{ $t('ClickResetLink') }}
                  </v-alert>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="forgetPassword"
                    @submit.prevent="forgot"
                  >
                    <v-text-field
                      v-model="email"
                      :error-messages="errors.email"
                      :rules="[validators.required, validators.emailValidator]"
                      prepend-inner-icon="mdi-email-outline"
                      outlined
                      :label="$t('Email')"
                      placeholder="john@example.com"
                      hide-details="auto"
                      class="mb-4"
                    ></v-text-field>

                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      block
                      color="primary"
                      type="submit"
                    >
                      {{ $t('SendResetLink') }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <v-btn
                    text
                    plain
                    :to="{name:'auth-login'}"
                  >
                    <v-icon
                      size="24"
                    >
                      mdi-chevron-left
                    </v-icon>
                    <span>{{ $t('BackToLogin') }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user.store'
import { emailValidator, required } from '@core/utils/validation'
import { appConfig } from '@/plugins/micleoConfig'
import { ref } from 'vue'

export default {
  metaInfo: {
    title: 'Forgot Password',
    titleTemplate: `%s | ${appConfig.theme.app.name}`,
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: appConfig.theme.app.description },
    ],
  },
  setup() {
    const userStore = useUserStore()

    const forgetPassword = ref(null)
    const errors = ref({
      email: '',
    })

    const email = ref('')
    const isAlertVisible = ref(false)
    const loading = ref(false)

    const forgot = () => {
      loading.value = true
      userStore.resetPassword(email.value).then(userData => {
        if (userData !== null) {
          isAlertVisible.value = true
          loading.value = false
        }
      }).catch(err => {
        loading.value = false
        if (err.message.toLowerCase().includes('password')) {
          errors.value.password = err.message
        } else {
          errors.value.email = err.message
        }
      })
    }

    return {
      forgot,
      loading,
      forgetPassword,
      email,
      errors,
      isAlertVisible,
      appName: appConfig.theme.app.name,
      appLogo: appConfig.theme.app.logo,
      appLogoDark: appConfig.theme.app.logoDark,
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
